import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    padding: 15px 0 0 15px;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Side = styled.div`
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    margin: 0;
    height: calc(100vh - 56px);
    width: 400px;
    min-width: 400px;
    background-color: ${Colors.LIGHT_GREY};
    border-left: 1px solid ${Colors.BACKGROUND};
    display: flex;
    flex-direction: column;
`;

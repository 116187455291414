import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import { Content, Footer, LoaderContainer, NoDataContainer, NoDataTitle } from './styles';
import { Button, Loader, Modal } from 'components';
import { useSavedSearches } from 'hooks';
import SavedSearchListItem from '../SavedSearchListItem';

function SavedSearchesModal(props) {
    const { visible, onClose, onSearch, onEdit } = props;
    const { getSavedSearches, isLoading, savedSearches } = useSavedSearches();

    const handleOnSearch = (savedSearch) => {
        onSearch(savedSearch);
        onClose();
    };

    const handleOnEdit = (savedSearch) => {
        onEdit(savedSearch);
        onClose();
    };

    const renderLoader = () => (
        <LoaderContainer>
            <Loader size={40} />
        </LoaderContainer>
    );

    const renderContent = () => {
        if (savedSearches.length === 0) return renderEmptyContent();

        return (
            <Content>
                {savedSearches.map((savedSearch) => (
                    <SavedSearchListItem
                        onEdit={handleOnEdit}
                        key={savedSearch.id}
                        savedSearch={savedSearch}
                        onSearch={handleOnSearch}
                    />
                ))}
            </Content>
        );
    };

    const renderEmptyContent = () => (
        <NoDataContainer>
            <NoDataTitle>No saved searches found</NoDataTitle>
        </NoDataContainer>
    );

    const renderFooter = () => (
        <Footer>
            <Grid.Row>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
            </Grid.Row>
        </Footer>
    );

    return (
        <Modal
            title="Saved searches"
            renderContent={isLoading ? renderLoader : renderContent}
            onClose={onClose}
            visible={visible}
            onShow={getSavedSearches}
            renderFooter={renderFooter}
        />
    );
}

SavedSearchesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

SavedSearchesModal.defaultProps = {
    visible: false,
};

export default SavedSearchesModal;

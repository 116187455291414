import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Loader, Button, SingleSelect } from 'components';
import { Text } from './styles';
import { svgAsPngUri } from 'save-svg-as-png';
import { DateUtils } from 'utils';

function ChartTop(props) {
    const {
        graphId,
        error,
        chart,
        isChartLoading,
        isChartLoaded,
        grouped,
        search,
        printSize,
        initialHeight,
        initialWidth,
        showChart,
        updateLanguage,
        language,
        languages,
    } = props;

    const exportImg = () => {
        const parent = document.getElementById(`parent-${graphId}`);
        const imgWidth = 3840;

        if (grouped) {
            const svg = parent.getElementsByTagName('svg')[0];
            const screenWidth = window.screen.availWidth;
            const docWidth = document.body.clientWidth;

            const w = screenWidth / docWidth;
            const scale = imgWidth / screenWidth;

            svgAsPngUri(svg, { scale: scale * w }).then((resp) => {
                const img = new Image();
                img.src = resp;
                img.onload = () => {
                    createImage(img);
                };
            });
        } else if (graphId && !grouped) {
            const canvas = document.getElementById('print');
            createImage(canvas);
        }
    };

    const createImage = (img) => {
        const chrt = document.getElementById('print');
        const w = chrt.getAttribute('width');
        const h = chrt.getAttribute('height');
        const ratio = (h * 100) / w;
        let from = search.from;
        let to = search.to;
        if (search.date) {
            const date = DateUtils.getRelativeDateRange(search.date);
            from = date[0];
            to = date[1];
        }

        const title = `DebunkEU_chart${graphId}_${from}_${to}.png`;
        const imgWidth = printSize * 2;
        const imgHeight = ratio ? imgWidth * (ratio / 100) : (imgWidth * initialHeight) / initialWidth;

        const resizeCanvas = document.createElement('canvas');
        resizeCanvas.width = imgWidth;
        resizeCanvas.height = imgHeight;

        const resizeCtx = resizeCanvas.getContext('2d');
        resizeCtx.imageSmoothingEnabled = true;
        resizeCtx.drawImage(img, 0, 0, imgWidth, imgHeight);

        const image = resizeCanvas.toDataURL('image/png');

        const a = document.createElement('a');
        a.href = image;
        a.download = title;
        document.body.appendChild(a);

        a.click();
    };

    const RenderText = () => {
        let txt = 'Please select a chart';
        if (error) txt = 'Error, the chart could not be loaded';
        else if (chart && graphId && chart.data && chart.data.length === 0)
            txt = 'No data. Please change search parameters.';
        return isChartLoading ? '' : <Text>{txt}</Text>;
    };

    return (
        <>
            {isChartLoaded && showChart && chart.graph ? (
                <Grid.Row justifyContent="space-between" mb={20}>
                    <Grid.Col flex={1}>
                        <Button onClick={exportImg} label="Download chart" />
                    </Grid.Col>
                    <Grid.Col flex={1}>
                        <Text>
                            {chart.graph.name} (ID {chart.graph.id})
                        </Text>
                    </Grid.Col>
                    <Grid.Col flex={1}>
                        <SingleSelect name="languages" onChange={updateLanguage} options={languages} value={language} />
                    </Grid.Col>
                </Grid.Row>
            ) : (
                <Grid.Row>
                    <RenderText />
                </Grid.Row>
            )}
            {isChartLoading && (
                <Grid.Row justifyContent="center" mt={20}>
                    <Loader />
                </Grid.Row>
            )}
        </>
    );
}

ChartTop.propTypes = {
    updateLanguage: PropTypes.func.isRequired,
    graphId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    printSize: PropTypes.number.isRequired,
    initialHeight: PropTypes.number.isRequired,
    initialWidth: PropTypes.number.isRequired,
    error: PropTypes.bool.isRequired,
    chart: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    isChartLoaded: PropTypes.bool.isRequired,
    isChartLoading: PropTypes.bool.isRequired,
    grouped: PropTypes.bool.isRequired,
    showChart: PropTypes.bool.isRequired,
    language: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    languages: PropTypes.array.isRequired,
};

export default ChartTop;

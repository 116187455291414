export const GET_ARTICLE_START = 'GET_ARTICLE_START';
export const GET_ARTICLE = 'GET_ARTICLE';
export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR';

export const LABEL_ARTICLE_START = 'LABEL_ARTICLE_START';
export const LABEL_ARTICLE = 'LABEL_ARTICLE';
export const LABEL_ARTICLE_ERROR = 'LABEL_ARTICLE_ERROR';

export const RESET_ARTICLE = 'RESET_ARTICLE';

export const IMPORT_ARTICLE_START = 'IMPORT_ARTICLE_START';
export const IMPORT_ARTICLE = 'IMPORT_ARTICLE';
export const IMPORT_ARTICLE_ERROR = 'IMPORT_ARTICLE_ERROR';

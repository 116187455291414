export const GET_CHARTS_START = 'GET_CHARTS_START';
export const GET_OPTIONS_START = 'GET_OPTIONS_START';
export const GET_TYPES_START = 'GET_TYPES_START';
export const GET_CHARTS = 'GET_CHARTS';
export const GET_CHART = 'GET_CHART';
export const GET_CHART_ERROR = 'GET_CHART_ERROR';
export const GET_CHARTS_OPTIONS = 'GET_CHARTS_OPTIONS';
export const GET_CHARTS_TYPES = 'GET_CHARTS_TYPES';
export const GET_CHARTS_ERROR = 'GET_CHARTS_ERROR';
export const GET_CHART_ARTICLES_ERROR = 'GET_CHART_ARTICLES_ERROR';
export const UPDATE_CHARTS_START = 'UPDATE_CHARTS_START';
export const CREATE_CHART = 'CREATE_CHART';
export const UPDATE_CHART = 'UPDATE_CHART';
export const REMOVE_CHART = 'REMOVE_CHART';
export const GET_CHART_START = 'GET_CHART_START';
export const GET_CHART_ARTICLES_START = 'GET_CHART_ARTICLES_START';
export const GET_CHART_ARTICLES = 'GET_CHART_ARTICLES';
export const RESET_CHART_ARTICLES = 'RESET_CHART_ARTICLES';

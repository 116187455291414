import React, { useEffect } from 'react';
import * as Styles from './styles';
import { LabelingForm } from 'containers';
import { ArticleDetails } from 'features/article';

function LabelingPage() {
    useEffect(() => {
        document.title = 'Debunk EU - Labelling';
    }, []);

    return (
        <Styles.Container>
            <ArticleDetails labeling />
            <Styles.Side>
                <LabelingForm />
            </Styles.Side>
        </Styles.Container>
    );
}

export default LabelingPage;

import { AuthTypes } from 'core/types';
import { AuthService, ToastService } from 'core/services';

export function login(request, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: AuthTypes.LOGIN_START });
            const { token, refresh_token } = await AuthService.login(request);
            dispatch({ type: AuthTypes.LOGIN, payload: { token, refreshToken: refresh_token } });
            callback();
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.LOGIN_ERROR });
        }
    };
}

export function getProfile() {
    return async (dispatch) => {
        try {
            dispatch({ type: AuthTypes.GET_PROFILE_START });
            const profile = await AuthService.getProfile();
            dispatch({ type: AuthTypes.GET_PROFILE, payload: { profile } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.GET_PROFILE_ERROR });
        }
    };
}

export function setUsername(switchUser) {
    return async (dispatch) => {
        try {
            dispatch({ type: AuthTypes.SET_USERNAME, payload: { switchUser } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.GET_PROFILE_ERROR });
        }
    };
}

export function logout() {
    return {
        type: AuthTypes.LOGOUT,
    };
}

export function forgotPassword(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: AuthTypes.LOGIN_START });
            await AuthService.forgotPassword(request);
            dispatch({ type: AuthTypes.FORGOT_PASSWORD });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.RESET_PASSWORD_ERROR, payload: { exception: 'Invalid email' } });
        }
    };
}

export function resetPassword(token, request) {
    return async (dispatch) => {
        try {
            await AuthService.resetPassword(token, request);
            dispatch({ type: AuthTypes.RESET_PASSWORD });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.RESET_PASSWORD_ERROR, payload: { exception: exception.response.data.message } });
        }
    };
}

export function removeError() {
    return async (dispatch) => {
        dispatch({ type: AuthTypes.RESET_PASSWORD_ERROR });
    };
}

import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArticlesActions } from 'features/articles/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import usePaging from '../usePaging';
import { useFilter } from 'hooks';
import { useArticlesCount } from 'features/articles/hooks';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { UrlUtils } from 'utils';
import debounce from 'lodash.debounce';

function useArticles(props) {
    const { labeling } = props;
    const { filter, setFilterValue } = useFilter();
    const { paging } = usePaging();
    const { getArticlesCount } = useArticlesCount();
    const dispatch = useDispatch();
    const location = useLocation();
    const { articles, isLoading, isLoaded, isCancelingReserved, isReserving, count, chartData } = useSelector(
        (state) => state.articles,
    );
    const [selectedArticlesIds, setSelectedArticlesIds] = useState([]);
    const sort = { sort: filter.sort, order: filter.order };

    const getArticleHandler = (newFilter, newPaging) => {
        dispatch(ArticlesActions.getArticles({ filter: newFilter, paging: newPaging }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetArticles = useCallback(
        debounce(function (newFilter, newPaging) {
            getArticleHandler(newFilter, newPaging);
        }, 300),
        [],
    );

    useDeepCompareEffect(() => {
        if (filter.chart || checkParametersDiff()) return;
        labeling && getArticlesCount();
        if (!filter.labeling_status && labeling) {
            dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
        } else {
            debouncedGetArticles(filter, paging);
        }
    }, [dispatch, filter, paging]);

    const checkParametersDiff = () => {
        let diff = false;
        const search = _.omit(UrlUtils.parseSearch(location.search), 'page');

        Object.entries(search).map((entry) => {
            const [key, value] = entry;
            if ((filter[key] && _.isEqual(filter[key], value)) === false) diff = true;
        });

        return diff;
    };

    const reserveArticles = (onError) => {
        const reserveArticlesSort = { sort: 'infometer', order: 'desc' };
        const reserveFilter = { ...filter, ...reserveArticlesSort };
        dispatch(
            ArticlesActions.reserveArticles(
                { filter: reserveFilter, paging },
                selectedArticlesIds,
                () => {
                    clearSelectedArticlesIds();

                    if (filter.labeling_status === undefined) {
                        dispatch(ArticlesActions.getReservedArticles({ sort, paging }));
                    } else {
                        setFilterValue('labeling_status', undefined);
                    }
                },
                onError,
            ),
        );
    };

    const cancelReservedArticles = () => {
        dispatch(ArticlesActions.cancelReservedArticles({ filter, paging }));
    };

    const selectArticleId = (id) => {
        setSelectedArticlesIds(_.xor(selectedArticlesIds, [id]));
    };

    const clearSelectedArticlesIds = () => {
        setSelectedArticlesIds([]);
    };

    return {
        articles,
        isLoading,
        isCancelingReserved,
        isReserving,
        isLoaded,
        reserveArticles,
        cancelReservedArticles,
        selectedArticlesIds,
        selectArticleId,
        clearSelectedArticlesIds,
        total: count,
        chartData,
    };
}

export default useArticles;

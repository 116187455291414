import update from 'immutability-helper';
import { ParameterTypes, AuthTypes } from 'core/types';

const initialState = {
    isLoading: false,
};

export default (state = initialState, { type }) => {
    switch (type) {
        case ParameterTypes.ADD_PARAMETER_START: {
            return update(state, {
                isLoading: { $set: true },
            });
        }

        case ParameterTypes.ADD_PARAMETER: {
            return update(state, {
                isLoading: { $set: false },
            });
        }

        case ParameterTypes.ADD_PARAMETER_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Images } from 'styles/constant';
import { Grid, Form } from 'styles/components';
import { SharedTypes } from 'utils';

function SingleSelectContainer(props) {
    const { value, style, placeholder, options, onClear, clearable, forceOpen } = props;
    const option = options.find((option) => option.value === value);

    const renderContent = () => {
        if (option) {
            return <Form.Value>{option.label}</Form.Value>;
        }

        if (placeholder) {
            return <Form.Placeholder>{placeholder}</Form.Placeholder>;
        }
    };

    return (
        <Form.Container style={forceOpen ? { borderRadius: '5px 5px 0 0', borderBottom: 'none' } : style}>
            <Form.ValueContainer>{renderContent()}</Form.ValueContainer>
            {value && clearable && (
                <Grid.Col mr={10}>
                    <Form.Icon size={9} onClick={onClear} src={Images.CLOSE} />
                </Grid.Col>
            )}
            {!forceOpen && (
                <Grid.Col mr={5}>
                    <Form.Icon src={Images.DROPDOWN_ARROW} />
                </Grid.Col>
            )}
        </Form.Container>
    );
}

SingleSelectContainer.propTypes = {
    value: SharedTypes.NumberOrString,
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onClear: PropTypes.func,
    clearable: PropTypes.bool,
    forceOpen: PropTypes.bool,
    style: PropTypes.object,
    placeholder: PropTypes.string,
};

SingleSelectContainer.defaultProps = {
    style: {},
    value: null,
    onClear: () => {},
    clearable: true,
    placeholder: null,
    forceOpen: null,
};

export default SingleSelectContainer;

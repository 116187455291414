import React from 'react';
import Form from '../Form';
import autoBind from 'auto-bind';
import { Button, Input } from 'components';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Validations } from 'utils';
import { Container } from './styles';
import { Link } from 'react-router-dom';

class ForgotPasswordForm extends Form {
    constructor(props) {
        super(props);

        this.validations = {
            email: (value) => Validations.notEmpty(value, 'Email is required'),
        };

        this.state = {
            checked: true,
            fields: {
                email: { value: null, error: null, dirty: false },
            },
        };

        autoBind(this);
    }

    componentDidMount() {
        this.validateForm();
    }

    submit() {
        const { fields } = this.state;
        const { onSubmit } = this.props;

        if (this.validateForm()) {
            onSubmit({
                email: fields.email.value,
            });
        }
    }

    render() {
        const { fields } = this.state;
        const { style, loading } = this.props;

        return (
            <Container style={style}>
                <Grid.Row mb={25}>
                    <Input
                        type="email"
                        placeholder="Enter your email"
                        label="Email"
                        value={fields.email.value}
                        dirty={fields.email.dirty}
                        error={fields.email.error}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        name="email"
                    />
                </Grid.Row>
                <Grid.Row mb={15}>
                    <Button
                        style={{ textTransform: 'uppercase', height: 36 }}
                        fullWidth
                        disabled={this.hasErrors()}
                        loading={loading}
                        label="Send instructions"
                        onClick={this.submit}
                    />
                </Grid.Row>
                <Grid.Row mb={15} justifyContent="space-between">
                    <Link to="/auth/login">Return to login</Link>
                </Grid.Row>
            </Container>
        );
    }
}

ForgotPasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

ForgotPasswordForm.defaultProps = {
    style: {},
    loading: false,
};

export default ForgotPasswordForm;

import { store } from 'core/store';
import { TextUtils } from 'utils';

export function checkAvailableParameters(search) {
    const { parameters } = store.getState().parameters;
    const { categories } = store.getState().categories;
    const { domains } = store.getState().domains;
    const { filters } = store.getState().filters;
    const { languages } = store.getState().languages;
    const { users } = store.getState().users;
    let parameterMissing = false;

    Object.entries(search).map((item) => {
        let [key, value] = item;

        if (key.includes('parameters')) {
            const id = TextUtils.getParameterId(key);
            const parameter = parameters.find((p) => p.id === id);

            if (parameter) {
                value.length &&
                    !!value[0] &&
                    value.map((v) => {
                        let valueExists = parameter.values.find((val) => val.id === v);
                        if (!valueExists) {
                            value = value.filter((val) => val !== v);
                            parameterMissing = true;
                            parameter.type !== 'A2' && delete search[key];
                        }
                    });
                if (parameter.type === 'A2') search[key] = value.length > 0 ? value : [''];
            } else {
                delete search[key];
                parameterMissing = true;
            }
        } else if (Array.isArray(value)) {
            const list =
                key === 'categories_ids'
                    ? categories
                    : key === 'domain_ids'
                    ? domains
                    : key === 'filters_ids'
                    ? filters
                    : key === 'lang'
                    ? languages
                    : users;
            value.map((val) => {
                const exists = list.find((obj) => (key === 'lang' ? obj.iso : obj.id) === val);
                if (!exists) {
                    value = value.filter((v) => v !== val);
                    parameterMissing = true;
                }
            });
            search[key] = value;
            value.length === 0 && delete search[key];
        }
        return item;
    });

    return { search, parameterMissing };
}

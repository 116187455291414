import React from 'react';
import * as Styles from './styles';
import { Images } from 'styles/constant';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { usePaging } from 'features/articles/hooks';

function Paging(props) {
    const { visiblePages } = props;
    const { onPrevious, previousDisabled, onPage, page, pageDisabled, onNext, nextDisabled, pagesCount } =
        usePaging(visiblePages);
    const from = Math.max(1, page - visiblePages);
    const to = Math.min(page + visiblePages, pagesCount);

    return (
        <Styles.Container>
            <Styles.Page onClick={onPrevious} disabled={previousDisabled}>
                <Styles.Icon src={Images.ARROW_LEFT} />
            </Styles.Page>
            {_.range(from, to + 1).map((currentPage) => (
                <Styles.Page
                    onClick={() => onPage(currentPage)}
                    active={currentPage === page}
                    disabled={pageDisabled}
                    key={currentPage}
                >
                    <Styles.Label>{currentPage}</Styles.Label>
                </Styles.Page>
            ))}
            <Styles.Page onClick={onNext} disabled={nextDisabled}>
                <Styles.Icon src={Images.ARROW_RIGHT} />
            </Styles.Page>
        </Styles.Container>
    );
}

Paging.propTypes = {
    visiblePages: PropTypes.number,
};

Paging.defaultProps = {
    visiblePages: 0,
};

export default Paging;

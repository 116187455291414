import React from 'react';
import {
    LinkWrapper,
    StyledLink,
    ItemIcon,
    ItemLabel,
    Container,
    ProfileDetails,
    Name,
    CompanyName,
    ProfileIcon,
} from './styles';
import { Images } from 'styles/constant';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { AuthActions, InitialActions } from 'core/actions';
import { connect } from 'react-redux';
import { Dropdown } from 'components';
import { useProfile, useUpdateSearch, usePermissions } from 'hooks';

function ProfileDropdown(props) {
    const { logout, setUsername, switchUser, getInitialData } = props;
    const { profile } = useProfile();
    useUpdateSearch();
    const [hasManualImportPermission, hasCreateChartsPermission] = usePermissions([
        'ROLE_CREATE_MANUAL_IMPORTS',
        'ROLE_CREATE_CHARTS',
    ]);

    const onSwitchUser = () => {
        setUsername(null);
        getInitialData();
    };

    const renderButton = () => (
        <ProfileDetails>
            <Grid.Col mr={15} alignItems="flex-end">
                <Name red={!!switchUser}>
                    {profile.username} ({profile.role})
                </Name>
                <CompanyName red={!!switchUser}>{profile.organisation ? profile.organisation.name : null}</CompanyName>
            </Grid.Col>
            <Grid.Col>
                <ProfileIcon src={switchUser ? Images.USER : Images.ACCOUNT} switched={switchUser} />
            </Grid.Col>
        </ProfileDetails>
    );

    const links = [
        {
            label: 'Bookmarks',
            to: '#',
            icon: Images.BOOKMARK_WHITE,
        },
        {
            label: 'Emails',
            to: '/emails',
            icon: Images.EMAIL,
        },
        {
            label: 'Users',
            to: '/users',
            icon: Images.GROUP,
        },
        {
            label: 'Create charts',
            to: '/chart-list',
            icon: Images.STATISTICS,
            disabled: !hasCreateChartsPermission,
        },
        {
            label: 'Filters',
            to: '/filters',
            icon: Images.FILTERWHITE,
        },
        {
            label: 'Organisations',
            to: '/organisations',
            icon: Images.ORGANISATION,
        },
        {
            label: 'Import a publication',
            to: '/import-publication',
            icon: Images.UPLOAD,
            disabled: !hasManualImportPermission,
        },
    ];

    const renderDropdown = () => (
        <Container>
            {links.map((x) => (
                <LinkWrapper key={x.label} disabled={x.disabled}>
                    <StyledLink to={x.to} className="dropdown-item">
                        <ItemIcon src={x.icon} />
                        <ItemLabel>{x.label}</ItemLabel>
                    </StyledLink>
                </LinkWrapper>
            ))}
            {switchUser ? (
                <LinkWrapper>
                    <StyledLink to="#" onClick={onSwitchUser} className="dropdown-item">
                        <ItemIcon src={Images.LOG_OUT} />
                        <ItemLabel red>Exit impersonation</ItemLabel>
                    </StyledLink>
                </LinkWrapper>
            ) : (
                <LinkWrapper>
                    <StyledLink
                        to="#"
                        onClick={() => {
                            logout();
                            const translateElement = document.getElementById('google_translate_element');
                            translateElement && translateElement.classList.remove('show');
                        }}
                        className="dropdown-item"
                    >
                        <ItemIcon src={Images.LOG_OUT} />
                        <ItemLabel>Log out</ItemLabel>
                    </StyledLink>
                </LinkWrapper>
            )}
        </Container>
    );

    return <Dropdown renderButton={renderButton} renderDropdown={renderDropdown} openOnClick onClose={() => {}} />;
}

ProfileDropdown.propTypes = {
    logout: PropTypes.func.isRequired,
    setUsername: PropTypes.func.isRequired,
    getInitialData: PropTypes.func.isRequired,
    users: PropTypes.array,
    switchUser: PropTypes.object,
};

function mapStateToProps(state) {
    const { users, auth } = state;
    return { users: users.users, switchUser: auth.switchUser };
}

const mapDispatchToProps = {
    logout: AuthActions.logout,
    setUsername: AuthActions.setUsername,
    getProfile: AuthActions.getProfile,
    getInitialData: InitialActions.getInitialData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);

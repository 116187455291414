import styled from 'styled-components/macro';
import { Colors, Images } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';
import { Grid } from 'styles/components';
import { Button } from 'components';

export const Container = styled.div`
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${hexToRgba(Colors.BLACK, 0.5)};
    display: ${(props) => (props.visible ? 'flex' : 'none')};
`;

export const Wrapper = styled.div`
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background-color: ${Colors.WHITE};
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    padding: 20px;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.BACKGROUND};
`;

export const Title = styled.span`
    font-weight: 700;
    font-size: 18px;
    color: ${Colors.BLACK};
`;

export const CloseIconContainer = styled.div`
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.SECONDARY};

    &:hover {
        cursor: pointer;
    }
`;

export const CloseIcon = styled.img`
    padding-right: 1px;
`;

export const Content = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    border-bottom: 1px solid ${Colors.BACKGROUND};
`;

export const Footer = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
`;

export const FooterSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Section = styled.div`
    padding: 15px;
    background-color: ${(props) => (props.white ? Colors.WHITE : Colors.LIGHT_GREY)};
    margin-bottom: 20px;
    border: 1px solid ${Colors.TAB};
    border-radius: 5px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const SelectCol = styled(Grid.Col)`
    width: 170px;
`;

export const ButtonWrapper = styled.div`
    background: ${Colors.BADGE_BACKGROUND};
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 40px;

    > div > div:last-of-type {
        min-width: 150px;
        max-width: 300px;
        left: auto;
        right: 0;
    }
`;

export const ExportButton = styled(Button)`
    border-radius: 5px 0 0 5px;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};

    &:hover,
    &:active,
    &:focus {
        opacity: ${(props) => (props.disabled ? 0.4 : 0.77)};
    }
`;

export const MenuItem = styled.div`
    line-height: 40px;
    display: block;
    padding: 0 10px;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    border-left: 1px solid ${Colors.BACKGROUND};
    border-right: 1px solid ${Colors.BACKGROUND};
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    opacity: ${(props) => (props.disabled ? 0.4 : 1)};

    &:hover,
    &:active,
    &:focus {
        opacity: ${(props) => (props.disabled ? 0.4 : 0.7)};
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }

    &:first-of-type {
        border-top: 1px solid ${Colors.BACKGROUND};
        border-radius: 5px 5px 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 5px 5px;
    }
`;

export const DropdownToggle = styled.div`
    transform: rotate(90deg);
    background: url(${Images.ARROW_RIGHT}) no-repeat center/8px;
    height: 40px;
    width: 40px;
    border-bottom: 1px solid ${Colors.WHITE};
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

export const SliderRow = styled(Grid.Row)`
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

import React from 'react';
import * as Styles from './styles';
import { Grid } from 'styles/components';
import update from 'immutability-helper';
import { Button, CloseIcon, Input, SingleSelect, Tooltip } from 'components';
import { LOGICAL_OPERATORS, GROUP_TYPES, QueryBuilderGroupTypes } from 'constant';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import RuleForm from '../RuleForm';
import { ReactComponent as QuestionSvg } from 'assets/img/question.svg';

const initialGroup = {
    type: QueryBuilderGroupTypes.GROUP,
    query: {
        logicalOperator: 'All',
        nearSlope: 0,
        children: [],
    },
};

const initialRule = {
    type: QueryBuilderGroupTypes.RULE,
    query: {
        selectedOperator: 'contains',
        value: '',
        rule: 'content',
        selectedOperand: 'Content',
        caseSensitive: false,
    },
};

function GroupForm(props) {
    const { group, onChange, onRemove, level, language, shouldTranslate, handleTranslateButtonClick } = props;
    const colors = [
        '#231F20',
        '#D71D24',
        '#988F91',
        '#F5767B',
        '#E2E3E4',
        '#FFBFC1',
        '#000000',
        '#4C4C4C',
        '#999999',
        '#E5E5E5',
    ];

    const handleOnChange = (event) => {
        const { name, value } = event;
        onChange(
            update(group, {
                [name]: { $set: value },
            }),
        );
    };

    const handleSlopeChange = (event) => {
        const { name, value } = event;
        const valid = /^\d*[1-9]+\d*$/.test(value) || value === '0';
        onChange(
            update(group, {
                [name]: { $set: valid ? parseFloat(value) : group[name] },
            }),
        );
    };

    const handleAddChild = (type) => {
        const groupToAdd = type === QueryBuilderGroupTypes.RULE ? initialRule : initialGroup;
        onChange(
            update(group, {
                children: { $set: [groupToAdd, ...group.children] },
            }),
        );
    };

    const handleRemoveChild = (indexToRemove) => {
        onChange(
            update(group, {
                children: { $set: group.children.filter((_, index) => indexToRemove !== index) },
            }),
        );
    };

    const handleChildChange = (child, index) => {
        onChange(
            update(group, {
                children: {
                    [index]: {
                        query: { $set: child },
                    },
                },
            }),
        );
    };

    return (
        <Styles.Container border={colors[level - 1]}>
            <Styles.Header>
                <Grid.Col mr={10}>
                    <Styles.Title>Match type</Styles.Title>
                </Grid.Col>
                <Grid.Col mr={5}>
                    <Styles.InputContainer>
                        <SingleSelect
                            disabled={group.children.find((c) => c.type === 'query-builder-group') ? 'Near' : null}
                            clearable={false}
                            value={group.logicalOperator}
                            searchable={false}
                            onChange={handleOnChange}
                            name="logicalOperator"
                            options={LOGICAL_OPERATORS}
                        />
                    </Styles.InputContainer>
                </Grid.Col>
                {group.children.find((c) => c.type === 'query-builder-group') && (
                    <Tooltip
                        id="matchTypeTooltip"
                        tooltipContent="NEAR option is possible with rules only. Please remove groups inside the block to select
                                NEAR."
                    >
                        <Styles.QuestionMarkCol>
                            <QuestionSvg />
                        </Styles.QuestionMarkCol>
                    </Tooltip>
                )}
                {group.logicalOperator === 'Near' && (
                    <Grid.Col mr={10}>
                        <Grid.Row>
                            <Grid.Col mr={10}>
                                <Styles.Title>Slope</Styles.Title>
                            </Grid.Col>
                            <Grid.Col>
                                <Styles.InputContainer style={{ overflow: 'hidden', height: 40 }}>
                                    <Input value={group.nearSlope} onChange={handleSlopeChange} name="nearSlope" />
                                </Styles.InputContainer>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                )}
                {onRemove && (
                    <Grid.Col flex={1} alignItems="flex-end">
                        <CloseIcon onClick={onRemove} />
                    </Grid.Col>
                )}
            </Styles.Header>
            <Styles.Content>
                <Grid.Row>
                    <Grid.Col mr={10}>
                        <Styles.InputContainer>
                            <SingleSelect
                                clearable={false}
                                value="content"
                                searchable={false}
                                onChange={() => {}}
                                name="type"
                                options={GROUP_TYPES}
                            />
                        </Styles.InputContainer>
                    </Grid.Col>
                    <Grid.Col flex={1}>
                        <Button onClick={() => handleAddChild(QueryBuilderGroupTypes.RULE)} label="Add rule" />
                    </Grid.Col>
                    {group.logicalOperator !== 'Near' && (
                        <Grid.Col ml={10}>
                            <Button
                                onClick={() => handleAddChild(QueryBuilderGroupTypes.GROUP)}
                                label="Add group"
                                disabled={level > 9}
                            />
                        </Grid.Col>
                    )}
                </Grid.Row>
                {group.children.map((child, index) => (
                    <Grid.Row key={index} mt={15}>
                        {child.type === QueryBuilderGroupTypes.RULE && (
                            <RuleForm
                                onRemove={() => handleRemoveChild(index)}
                                rule={child.query}
                                onChange={(child) => handleChildChange(child, index)}
                                language={language}
                                shouldTranslate={shouldTranslate}
                                handleTranslateButtonClick={handleTranslateButtonClick}
                            />
                        )}
                        {child.type === QueryBuilderGroupTypes.GROUP && (
                            <GroupForm
                                level={level + 1}
                                onRemove={() => handleRemoveChild(index)}
                                group={child.query}
                                onChange={(child) => handleChildChange(child, index)}
                                language={language}
                                shouldTranslate={shouldTranslate}
                                handleTranslateButtonClick={handleTranslateButtonClick}
                            />
                        )}
                    </Grid.Row>
                ))}
            </Styles.Content>
        </Styles.Container>
    );
}

GroupForm.propTypes = {
    group: SharedTypes.QueryType.isRequired,
    onChange: PropTypes.func.isRequired,
    level: PropTypes.number.isRequired,
    onRemove: PropTypes.func,
    language: PropTypes.string,
    shouldTranslate: PropTypes.bool,
    handleTranslateButtonClick: PropTypes.func,
};

GroupForm.defaultProps = {
    onRemove: null,
};

export default GroupForm;

import update from 'immutability-helper';
import { InitialTypes, AuthTypes } from 'core/types';

const initialState = {
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type }) => {
    switch (type) {
        case InitialTypes.GET_INITIAL_DATA_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case InitialTypes.GET_INITIAL_DATA: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        case InitialTypes.GET_INITIAL_DATA_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

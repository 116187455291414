import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePermission } from 'hooks';
import { Forbidden } from 'pages';

function ProtectedRoute(props) {
    const { isLogged, permission } = props;
    const hasPermission = usePermission(permission ?? '');

    if (!isLogged) {
        return <Redirect to="/auth/login" />;
    }

    if (permission && !hasPermission) {
        return <Forbidden />;
    }

    return <Route {...props} />;
}

ProtectedRoute.propTypes = {
    isLogged: PropTypes.bool.isRequired,
    permission: PropTypes.permission,
};

function mapStateToProps(state) {
    const { auth } = state;
    const { isLogged } = auth;
    return { isLogged };
}

export default connect(mapStateToProps)(ProtectedRoute);

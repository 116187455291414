import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';
import { Link } from 'react-router-dom';

export const Name = styled.span`
    font-size: 14px;
    line-height: 1.29;
    font-weight: 700;
    color: ${(props) => (props.red ? Colors.PRIMARY : Colors.WHITE)};
`;

export const CompanyName = styled(Name)`
    font-weight: 400;
`;

export const ProfileIcon = styled.div`
    background: no-repeat ${(props) => (props.switched ? '4px' : 'center')} / ${(props) => (props.switched ? 15 : 24)}px,
        ${(props) => (props.switched ? Colors.WHITE : Colors.BLACK)};
    background-image: url(${(props) => props.src});
    border-radius: 50%;
    width: 24px;
    height: 24px;
`;

export const ProfileDetails = styled(Grid.Row)`
    padding: 0 15px;
    height: 56px;

    &:hover {
        cursor: pointer;
        background-color: ${Colors.HEADER_BACKGROUND_SECONDARY};
    }
`;

export const LinkWrapper = styled.div`
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    a {
        pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    }
    &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed;' : 'pointer')};
        background-color: ${(props) => (props.disabled ? 'unset' : Colors.HEADER_BACKGROUND_SECONDARY)};
    }
`;

export const StyledLink = styled(Link)`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const ItemLabel = styled.span`
    font-size: 14px;
    color: ${(props) => (props.red ? Colors.PRIMARY : Colors.PLACEHOLDER)};
`;

export const ItemIcon = styled.img`
    margin-right: 15px;
    width: 18px;
    height: 18px;
`;

export const Container = styled.div`
    padding: 10px;
    background-color: ${Colors.BLACK};
`;

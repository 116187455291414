import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../../../styles/components';
import { Button, SingleSelect, Tabs, Input, Tooltip } from 'components';
import { FilterQueryBuilder } from '../../index';
import { Section } from '../../Filter/styles';
import { Buttons } from 'styles/constant';
import { ReactComponent as QuestionSvg } from 'assets/img/question.svg';
import { LanguageRowLabel, QuestionMarkCol } from './styles';

function LanguageSettings(props) {
    const {
        onTabChange,
        activeTab,
        languagesSelected,
        selectedFilter,
        filters,
        selectedFilterLanguage,
        importFilterLanguage,
        queries,
        onQueryChange,
        onQueryImport,
        onFilterSelect,
        nameTranslated,
        setNameTranslated,
        onClear,
    } = props;

    const activeQueries = queries[activeTab];
    const importDisabled = activeQueries ? !!activeQueries.query : false;

    return (
        <Section>
            <Grid.Row mb={20}>
                <Tabs onSelect={(e) => onTabChange(e)} active={activeTab} tabs={languagesSelected.tabs} />
            </Grid.Row>
            <Grid.Row mb={20}>
                <Grid.Col flex={1}>
                    <Input
                        value={nameTranslated}
                        onChange={(e) => setNameTranslated(e)}
                        name="name"
                        label="Name translated"
                    />
                </Grid.Col>
                <Grid.Col flex={1} />
                <Grid.Col>
                    <Button label="Clear this form" onClick={onClear} type={Buttons.SECONDARY} />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row mb={20}>
                <Grid.Col flex={1}>
                    <Grid.Row alignItems="flex-end">
                        <LanguageRowLabel>Import data and structure from other filter</LanguageRowLabel>
                        <Grid.Col flex={1} mr={10}>
                            <SingleSelect
                                value={selectedFilter.id}
                                label="Filter"
                                name="filter"
                                onChange={(e) => onFilterSelect(e)}
                                options={filters.map((f) => {
                                    return { label: f.name, value: f.id };
                                })}
                            />
                        </Grid.Col>
                        <Grid.Col flex={1} mr={10}>
                            <SingleSelect
                                value={selectedFilterLanguage.value}
                                label="Language"
                                name="language"
                                onChange={importFilterLanguage}
                                options={
                                    selectedFilter.lang
                                        ? selectedFilter.lang.map((l) => {
                                              return { label: l.lang.iso, value: l.filter_lang_id };
                                          })
                                        : []
                                }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Grid.Row>
                                <Button label="Import data" onClick={onQueryImport} disabled={importDisabled} />
                                <Tooltip
                                    id="matchTypeTooltip"
                                    tooltipContent="Using the import you can import data and structure from any filter. To do this, you first need to clear the form."
                                >
                                    <QuestionMarkCol>
                                        <QuestionSvg />
                                    </QuestionMarkCol>
                                </Tooltip>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>

            <FilterQueryBuilder
                queryString={queries[activeTab] ? queries[activeTab].query : ''}
                onChange={onQueryChange}
                language={activeTab}
            />
        </Section>
    );
}

LanguageSettings.propTypes = {
    activeTab: PropTypes.string,
    languagesSelected: PropTypes.object,
    selectedFilterLanguage: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    selectedFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onTabChange: PropTypes.func,
    setNameTranslated: PropTypes.func,
    onQueryImport: PropTypes.func,
    onQueryChange: PropTypes.func,
    importFilterLanguage: PropTypes.func,
    onFilterSelect: PropTypes.func,
    filters: PropTypes.array,
    queries: PropTypes.object,
    nameTranslated: PropTypes.string,
    shouldTranslate: PropTypes.bool,
    handleTranslateButtonClick: PropTypes.func,
    onClear: PropTypes.func,
};

export default LanguageSettings;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../../../styles/components';
import { ExpandedMultiSelect, Input, SingleSelect, Slider } from '../../../components';
import { Wrapper } from '../styles';
import { Section } from '../../Filter/styles';

function FilterSettings(props) {
    const {
        name,
        setName,
        category,
        setCategory,
        active,
        setActive,
        languagesSelected,
        onLanguageChange,
        languageList,
        categoryList,
        primary,
        setPrimary,
    } = props;

    return (
        <Section>
            <Grid.Row alignItems="flex-start" mb={20}>
                <Grid.Col flex={1}>
                    <Grid.Row mb={20}>
                        <Input label="Name" value={name} onChange={(e) => setName(e.value)} name="name" />
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <SingleSelect
                            label="Category"
                            value={category.value}
                            onChange={(e) => setCategory(e)}
                            options={categoryList}
                            name="categories"
                        />
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <Slider
                            name="active"
                            value={active ? 1 : 0}
                            onChange={() => setActive(!active)}
                            label="Active"
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Slider
                            name="primary"
                            value={primary ? 1 : 0}
                            onChange={() => setPrimary(!primary)}
                            label="Primary"
                        />
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col flex={1}>
                    <Wrapper>
                        <ExpandedMultiSelect
                            label="Languages"
                            value={languagesSelected.language.value}
                            onChange={(e) => onLanguageChange(e)}
                            options={languageList}
                            name="languages"
                        />
                    </Wrapper>
                </Grid.Col>
            </Grid.Row>
        </Section>
    );
}

FilterSettings.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    category: PropTypes.object,
    setCategory: PropTypes.func,
    active: PropTypes.bool,
    setActive: PropTypes.func,
    languagesSelected: PropTypes.object,
    onLanguageChange: PropTypes.func,
    languageList: PropTypes.array,
    categoryList: PropTypes.array,
    primary: PropTypes.bool,
    setPrimary: PropTypes.func,
};

export default FilterSettings;

import styled from 'styled-components/macro';
import { Colors, Images } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: ${Colors.WHITE};
    border-bottom: 1px solid ${Colors.BACKGROUND};
`;

export const Content = styled.div`
    width: 100%;
    grid-gap: 10px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
`;

export const ButtonWrapper = styled.div`
    background: ${Colors.PRIMARY};
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 40px;

    > div > div:last-of-type {
        min-width: 150px;
        max-width: 300px;
        left: auto;
        right: 0;
    }
`;

export const MenuItem = styled.div`
    line-height: 40px;
    display: block;
    padding: 0 10px;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    border-left: 1px solid ${Colors.BACKGROUND};
    border-right: 1px solid ${Colors.BACKGROUND};
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }

    &:first-of-type {
        border-top: 1px solid ${Colors.BACKGROUND};
        border-radius: 5px 5px 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 5px 5px;
    }
`;

export const SearchButton = styled.div`
    background: url(${Images.SEARCH_WHITE}) no-repeat center/20px;
    height: 40px;
    width: 60px;
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

export const DropdownToggle = styled.div`
    background: url(${Images.SAVE_WHITE}) no-repeat center/16px;
    height: 40px;
    width: 40px;
    border-left: 1px solid ${Colors.WHITE};
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

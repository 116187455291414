export const PRIMARY = '#e90010';
export const SECONDARY = '#555555';
export const TERTIARY = '#ffc9cd';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const BACKGROUND = '#dddddd';
export const DANGER = '#e90010';
export const PLACEHOLDER = '#999999';
export const BADGE_TEXT = '#555555';
export const BADGE_BACKGROUND = '#eeeeee';
export const LIGHT_GREY = '#f5f5f5';
export const MODAL_BORDER = '#cccccc';
export const TAB = '#bbbbbb';
export const GREEN = '#00c321';
export const HOVER = '#f0f0f0';
export const TOOLTIP = '#333333';
export const TABLE_FILTERS = '#f2f2f2';
export const SIMILAR_BORDER = '#979797';
export const BORDER_LIGHT = '#aaaaaa';
export const WARNING = '#ffc107';

export const HEADER_TEXT = '#999999';
export const HEADER_ACTIVE_BORDER = '#e90010';
export const HEADER_BACKGROUND_SECONDARY = '#222222';

import { useDispatch, useSelector } from 'react-redux';
import { SavedSearchesActions } from 'core/actions';

function useAddSavedSearch() {
    const dispatch = useDispatch();
    const { isLoading, isLoaded } = useSelector((state) => state.savedSearches);

    const addSavedSearch = (request, callback) => {
        dispatch(SavedSearchesActions.addSavedSearch(request, callback));
    };

    return {
        addSavedSearch,
        isLoading,
        isLoaded,
    };
}

export default useAddSavedSearch;

import httpClient from '../httpClient';
import qs from 'qs';

const baseUrl = '/charts';

export const getCharts = async () => {
    return httpClient.get(baseUrl);
};

export const getChartOptions = async () => {
    return httpClient.get(`${baseUrl}/fields`);
};

export const getChartTypes = async () => {
    return httpClient.get('/chart-types');
};

export const createChart = (chart) => {
    return httpClient.post(baseUrl, chart);
};

export const updateChart = (id, chart) => {
    return httpClient.put(`${baseUrl}/${id}`, chart);
};

export const deleteChart = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const getChart = async (id, data) => {
    return httpClient.get(`${baseUrl}/${id}`, { params: data });
};

export const getChartArticles = async (id, data) => {
    return httpClient.get(`${baseUrl}/${id}/data?${qs.stringify(data, { encode: false })}`);
};

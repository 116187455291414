import React, { useEffect } from 'react';
import { Container } from './styles';
import { Grid } from 'styles/components';
import { ChartsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { connect } from 'react-redux';
import { Title, Wrapper, Button } from './styles';
import { COLUMNS } from './config';
import { IconButton, Table } from '../../components';
import { useHistory } from 'react-router-dom';
import { EDIT, REMOVE, SEARCH_WHITE } from '../../styles/constant/images';
import Loader from '../../components/Loader';

function ChartListPage(props) {
    const {
        charts,
        deleteChart,
        isLoading,
        isLoaded,
        getCharts,
        isUpdating,
        options,
        isOptionsLoaded,
        isOptionsLoading,
        getChartOptions,
    } = props;
    const history = useHistory();
    const columns = COLUMNS(options);

    useEffect(() => {
        document.title = 'Debunk EU - List of Charts';
    }, []);

    useEffect(() => {
        getChartOptions();
        getCharts();
    }, [getCharts, getChartOptions]);

    const onDelete = (id) => {
        const confirm = window.confirm('Delete chart?');
        confirm && deleteChart(id);
    };

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row>
                    <Title>Charts</Title>
                </Grid.Row>
                <Grid.Row>
                    <Wrapper>
                        <Button to="/chart/new">Create new</Button>
                        <Button to="/edit-organisation-charts">Edit organisation charts</Button>
                    </Wrapper>
                </Grid.Row>
                {(isLoading || isUpdating || isOptionsLoading) && (
                    <Grid.Row mt={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}
            </Grid.FixedContainer>
            {isLoaded && isOptionsLoaded && !isUpdating && (
                <Table
                    checkboxes={false}
                    editableCells={[]}
                    updateData={() => {}}
                    columns={[
                        ...columns,
                        {
                            Header: 'Actions',
                            Cell: function CelRenderer(row) {
                                return (
                                    <Grid.Row justifyContent="center">
                                        <IconButton to="#" bg={SEARCH_WHITE} type="SEARCH" small="small" />
                                        <IconButton
                                            bg={EDIT}
                                            type="EDIT"
                                            small="small"
                                            to={`/chart/${row.row.original.id}`}
                                        />
                                        <IconButton
                                            to="#"
                                            onClick={() => onDelete(row.row.original.id)}
                                            bg={REMOVE}
                                            type="REMOVE"
                                            small="small"
                                        />
                                    </Grid.Row>
                                );
                            },
                        },
                    ]}
                    data={charts}
                    hiddenColumns={['Period']}
                    type="charts"
                    history={history}
                    preSelected={[]}
                />
            )}
        </Container>
    );
}

ChartListPage.propTypes = {
    charts: PropTypes.arrayOf(SharedTypes.ChartsListItemType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    deleteChart: PropTypes.func,
    getCharts: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool,
    isOptionsLoaded: PropTypes.bool,
    isOptionsLoading: PropTypes.bool,
    options: PropTypes.object,
    getChartOptions: PropTypes.func,
    resetChartsState: PropTypes.func,
};

function mapStateToProps(state) {
    const { charts } = state;
    const { isLoading, isLoaded, isUpdating, isOptionsLoaded, isOptionsLoading, options } = charts;
    return {
        charts: charts.charts,
        isLoaded,
        isLoading,
        isUpdating,
        isOptionsLoaded,
        isOptionsLoading,
        options,
    };
}

const mapDispatchToProps = {
    deleteChart: ChartsActions.deleteChart,
    getCharts: ChartsActions.getCharts,
    getChartOptions: ChartsActions.getChartOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartListPage);

import httpClient from '../httpClient';

const baseUrl = '/auth';

export const login = async (request) => {
    return httpClient.post('/login_check', request);
};

export const getProfile = async () => {
    return httpClient.get(`${baseUrl}/profile`);
};

export const refreshToken = async (request) => {
    return httpClient.post(`${baseUrl}/refresh`, {
        token: request.token,
        refresh_token: request.refreshToken,
    });
};

export const forgotPassword = async (request) => {
    return httpClient.post('/forgot-password', request);
};

export const resetPassword = async (token, request) => {
    return httpClient.post(`/reset-password/${token}`, request);
};

export let PERIODS = [
    {
        name: 'Minutes',
        min: 0,
        max: 59,
    },
    {
        name: 'Hours',
        min: 0,
        max: 23,
    },
    {
        name: 'Day of month',
        min: 1,
        max: 31,
    },
    {
        name: 'Month',
        min: 1,
        max: 12,
    },
    {
        name: 'Day of week',
        min: 1,
        max: 7,
    },
];

export const VALIDATE_SCHEDULE = (value, i) => {
    const { min, max } = PERIODS[i];
    const valueArr = value.split(',');
    const duplicates = [];
    let valid = true;

    valueArr.map((str) => {
        const v = parseInt(str);
        if (duplicates.includes(str)) valid = false;
        if ((str !== v.toString() && str[0] != 0) || v > max || v < min) valid = false;
        if (str[0] == 0 && (str.length > 2 || str[1] == 0)) valid = false;
        duplicates.push(str);
    });

    if (PERIODS[i].max > 9 && value === '00') valid = true;
    if (value === '*') valid = true;

    return valid;
};

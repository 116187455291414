import { UsersTypes } from 'core/types';
import { UsersService, ToastService } from 'core/services';

export function getUsers(subset) {
    return async (dispatch) => {
        try {
            dispatch({ type: UsersTypes.GET_USERS_START });
            const users = await UsersService.getUsers(subset);
            dispatch({ type: UsersTypes.GET_USERS, payload: { users } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

export function getUser(id, subset = true) {
    return async (dispatch) => {
        try {
            dispatch({ type: UsersTypes.GET_USER_START });
            const user = await UsersService.getUser(id, subset);
            dispatch({ type: UsersTypes.GET_USER, payload: { user } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

export function getProjects() {
    return async (dispatch) => {
        try {
            const projects = await UsersService.getProjects();
            dispatch({ type: UsersTypes.GET_PROJECTS, payload: { projects } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

export function createUser(user) {
    return async (dispatch) => {
        try {
            dispatch({ type: UsersTypes.UPDATE_USERS_START });
            await UsersService.createUser(user);
            dispatch({ type: UsersTypes.CREATE_USER });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

export function updateUser(id, user) {
    return async (dispatch) => {
        try {
            dispatch({ type: UsersTypes.UPDATE_USERS_START });
            await UsersService.updateUser(id, user);
            dispatch({ type: UsersTypes.CREATE_USER });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

export function deleteUser(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: UsersTypes.UPDATE_USERS_START });
            await UsersService.deleteUser(id);
            dispatch({ type: UsersTypes.REMOVE_USER });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: UsersTypes.GET_USERS_ERROR });
        }
    };
}

import React from 'react';
import Form from '../Form';
import autoBind from 'auto-bind';
import { Button, Input, Checkbox } from 'components';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Validations } from 'utils';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import { Icon } from '../ResetPasswordForm/styles';
import { EYE_HIDE, EYE_SHOW } from '../../styles/constant/images';

class LoginForm extends Form {
    constructor(props) {
        super(props);

        this.validations = {
            username: (value) => Validations.notEmpty(value, 'Username is required'),
            password: (value) => Validations.notEmpty(value, 'Password is required'),
        };

        this.state = {
            passwordType: 'password',
            checked: true,
            fields: {
                username: { value: null, error: null, dirty: false },
                password: { value: null, error: null, dirty: false },
            },
        };

        autoBind(this);
    }

    componentDidMount() {
        this.validateForm();
    }

    submit() {
        const { fields } = this.state;
        const { onSubmit } = this.props;

        if (this.validateForm()) {
            onSubmit({
                username: fields.username.value,
                password: fields.password.value,
            });
        }
    }

    render() {
        const { fields, checked, passwordType } = this.state;
        const { style, loading } = this.props;

        const eyeStyle = {
            alignSelf: 'flex-start',
            paddingTop: 20,
            height: 58,
            marginLeft: -30,
            marginRight: 10,
        };

        return (
            <Container style={style}>
                <Grid.Row mb={15}>
                    <Input
                        placeholder="Enter your username"
                        label="Username"
                        value={fields.username.value}
                        dirty={fields.username.dirty}
                        error={fields.username.error}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        name="username"
                    />
                </Grid.Row>
                <Grid.Row mb={15}>
                    <Input
                        type={passwordType}
                        placeholder="Enter your password"
                        label="Password"
                        value={fields.password.value}
                        dirty={fields.password.dirty}
                        error={fields.password.error}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        name="password"
                    />
                    <Grid.Col
                        style={eyeStyle}
                        onClick={() =>
                            this.setState({ passwordType: passwordType === 'password' ? 'text' : 'password' })
                        }
                    >
                        {passwordType === 'password' ? <Icon src={EYE_HIDE} /> : <Icon src={EYE_SHOW} />}
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row mb={15} justifyContent="space-between">
                    <Grid.Col>
                        <Checkbox
                            checked={checked}
                            label="Remember me"
                            onBlur={this.handleBlur}
                            onChange={() => this.setState({ checked: !checked })}
                            name="rememberMe"
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Link to="/auth/forgot-password">Forgot password?</Link>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Button
                        style={{ textTransform: 'uppercase', height: 36 }}
                        fullWidth
                        disabled={this.hasErrors()}
                        loading={loading}
                        label="Sign in"
                        onClick={this.submit}
                    />
                </Grid.Row>
            </Container>
        );
    }
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
    style: {},
};

export default LoginForm;

import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Form } from 'styles/components';
import { Mapper, SharedTypes } from 'utils';
import { ParameterTypesList } from 'constant';

function ParameterA1MultiSelect(props) {
    const { parameter, selectedParameterValues, onParameterValueAdd, onParameterValueClear, disabled } = props;
    const selectedParameterValuesIds = selectedParameterValues.map((parameterValue) => parameterValue.id);

    const isSelected = (id) => {
        return selectedParameterValuesIds.includes(id);
    };

    const handleChange = (id) => {
        const parameterValue = { id, parameterId: parameter.id, type: ParameterTypesList.A1 };
        isSelected(id) ? onParameterValueClear(parameterValue) : onParameterValueAdd(parameterValue);
    };

    return (
        <Styles.Container>
            <Form.Label>{parameter.name}</Form.Label>
            <Styles.Content>
                {Mapper.mapParameterValuesToOptions(parameter.values, 'value', 'asc').map((option) => (
                    <Styles.Badge
                        disabled={disabled}
                        key={option.value}
                        onClick={() => handleChange(option.value)}
                        selected={isSelected(option.value)}
                    >
                        {option.label}
                    </Styles.Badge>
                ))}
            </Styles.Content>
        </Styles.Container>
    );
}

ParameterA1MultiSelect.propTypes = {
    disabled: PropTypes.bool,
    parameter: SharedTypes.ParameterType.isRequired,
    onParameterValueAdd: PropTypes.func.isRequired,
    onParameterValueClear: PropTypes.func.isRequired,
    selectedParameterValues: PropTypes.arrayOf(SharedTypes.LabelingParameterValueType),
};

ParameterA1MultiSelect.defaultProps = {
    selectedParameterValues: [],
    disabled: false,
};

export default ParameterA1MultiSelect;

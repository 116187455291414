import React from 'react';
import * as Styles from './styles';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';
import { SharedTypes } from 'features/articles/utils';

function Infometer(props) {
    const { infometer } = props;

    const renderTooltip = () => (
        <Styles.Tooltip className="tooltip">
            <Grid.Row alignItems="flex-start" mb={5}>
                <Grid.Col mr={5}>
                    <Styles.Icon color={Colors.PRIMARY} />
                </Grid.Col>
                <Grid.Col>
                    <Styles.Label>
                        <Styles.Bold>RELEVANCE {infometer.importance}%</Styles.Bold> indicates level of importance of
                        the article based on its content
                    </Styles.Label>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row alignItems="flex-start" mb={5}>
                <Grid.Col mr={5}>
                    <Styles.Icon color={Colors.SECONDARY} />
                </Grid.Col>
                <Grid.Col>
                    <Styles.Label>
                        <Styles.Bold>SOCIAL METRICS {infometer.social}%</Styles.Bold> shows popularity of the article in
                        social networks
                    </Styles.Label>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row alignItems="flex-start">
                <Grid.Col mr={5}>
                    <Styles.Icon color={Colors.TERTIARY} />
                </Grid.Col>
                <Grid.Col>
                    <Styles.Label>
                        <Styles.Bold>REACH {infometer.reach}%</Styles.Bold> equals the number of potential readers of
                        the website
                    </Styles.Label>
                </Grid.Col>
            </Grid.Row>
        </Styles.Tooltip>
    );

    const getSocialValue = () => {
        const importance = +infometer.importance;
        const reach = +infometer.reach;
        const social = +infometer.social;
        const availableSpaceForSocial = 100 - (importance + reach);

        return social > availableSpaceForSocial ? availableSpaceForSocial : social;
    };

    return (
        <Styles.Container>
            <Styles.Value>{`${infometer.total}%`}</Styles.Value>
            <Styles.BarContainer>
                <Styles.Bar color={Colors.PRIMARY} value={infometer.importance} />
                <Styles.Bar color={Colors.TERTIARY} value={infometer.reach} />
                <Styles.Bar color={Colors.SECONDARY} value={getSocialValue()} />
            </Styles.BarContainer>
            {renderTooltip()}
        </Styles.Container>
    );
}

Infometer.propTypes = {
    infometer: SharedTypes.InfometerType.isRequired,
};

export default Infometer;

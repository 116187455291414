import { ParametersTypes } from 'core/types';
import { ParametersService, ToastService } from 'core/services';

export function getParameters() {
    return async (dispatch) => {
        try {
            dispatch({ type: ParametersTypes.GET_PARAMETERS_START });
            const parameters = await ParametersService.getParameters();
            dispatch({ type: ParametersTypes.GET_PARAMETERS, payload: { parameters } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ParametersTypes.GET_PARAMETERS_ERROR });
        }
    };
}

export const GET_EMAILS_START = 'GET_EMAILS_START';
export const UPDATE_EMAILS_START = 'UPDATE_EMAILS_START';
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const CONVERT_EMAIL_HASH_START = 'CONVERT_EMAIL_HASH_START';
export const CONVERT_EMAIL_HASH = 'CONVERT_EMAIL_HASH';
export const CONVERT_EMAIL_HASH_ERROR = 'CONVERT_EMAIL_HASH_ERROR';
export const GET_EMAILS_ERROR = 'GET_EMAILS_ERROR';
export const CREATE_EMAIL = 'CREATE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';

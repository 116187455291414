import React from 'react';
import PropTypes from 'prop-types';
import { Buttons } from 'styles/constant';
import { Content, Footer, Description } from './styles';
import { Button, Modal } from 'components';

function ExpirationModal(props) {
    const { visible, onClose } = props;

    const renderContent = () => (
        <Content>
            <Description>Article reservation will expire soon!</Description>
        </Content>
    );

    const renderFooter = () => (
        <Footer>
            <Button label="Close" type={Buttons.TERTIARY} onClick={onClose} />
        </Footer>
    );

    return (
        <Modal
            title="Reservation end alert"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

ExpirationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

ExpirationModal.defaultProps = {
    visible: false,
};

export default ExpirationModal;

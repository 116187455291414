import React, { useEffect } from 'react';
import * as Styles from './styles';
import { ArticleDetails } from 'features/article';

const SearchPage = () => {
    useEffect(() => {
        document.title = 'Debunk EU - Search';
    }, []);

    return (
        <Styles.Container>
            <ArticleDetails />
        </Styles.Container>
    );
};

export default SearchPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

function GridContainer(props) {
    const { className, children, rowGap, columnGap, itemsCount } = props;

    return (
        <Container itemsCount={itemsCount} columnGap={columnGap} rowGap={rowGap} className={className}>
            {children}
        </Container>
    );
}

GridContainer.propTypes = {
    className: PropTypes.string,
    rowGap: PropTypes.number,
    itemsCount: PropTypes.number,
    columnGap: PropTypes.number,
    children: PropTypes.node.isRequired,
};

GridContainer.defaultProps = {
    className: null,
    rowGap: 15,
    columnGap: 15,
    itemsCount: 3,
};

export default GridContainer;

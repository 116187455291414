import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
export const Header = styled(Grid.Row)`
    flex-shrink: 0;
    height: 60px;
    padding: 0 15px;
    background-color: ${Colors.WHITE};
    border-bottom: 1px solid ${Colors.BACKGROUND};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
    flex-shrink: 1;
    overflow-y: auto;
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 15px;
    background-color: ${Colors.WHITE};
    border-top: 1px solid ${Colors.BACKGROUND};
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
`;

export const FooterSection = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const ValuesContainer = styled.div`
    margin-top: 10px;
    width: 100%;

    > div {
        margin-bottom: 5px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

export const CollapseHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 5px 0 15px;
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }

    &:before {
        transition: 0.3s;
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
        content: '';
        transform: rotate(${(props) => (props.open ? 45 : -45)}deg);
        display: inline-block;
        margin-right: 5px;
    }
`;

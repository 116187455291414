import _ from 'lodash';

export const notEmpty = (value, errorMessage) => {
    return !value || _.trim(value).length === 0 ? errorMessage : null;
};

export const validEmail = (value, errorMessage) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(String(value).toLowerCase()) ? null : errorMessage;
};

import axios from 'axios';
import { store } from 'core/store';
import { DateUtils } from 'utils';
import { AuthTypes } from 'core/types';
import { AuthService, ToastService } from 'core/services/index';
import { AuthActions } from 'core/actions';

export const baseUrl = process.env.REACT_APP_BASE_URL;

const httpClient = axios.create({
    baseURL: `${baseUrl}/api`,
});

httpClient.interceptors.request.use(async (request) => {
    const { token, switchUser } = store.getState().auth;
    const { override_user_domains, override_user_filters } = store.getState().search.search;

    const params = request.params ? request.params : {};

    if (override_user_domains) {
        params.override_user_domains = override_user_domains;
    }

    if (override_user_filters) {
        params.override_user_filters = override_user_filters;
    }

    if (switchUser) {
        params._switch_user = switchUser.username;
    }

    if (DateUtils.getRelativeDate(params.date)) {
        const [dateFrom, dateTo] = DateUtils.getRelativeDateRange(params.date);
        delete params.date;
        params.from = dateFrom;
        params.to = dateTo;
    }

    request.params = params;

    if (request.params.project_id) {
        delete request.params.project_id;
    }

    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
});

httpClient.interceptors.response.use(
    async (response) => {
        return response.data;
    },
    async (error) => {
        const { response, config } = error;
        const originalRequest = config;
        const { status } = response;
        const { token, refreshToken } = store.getState().auth;

        if (config.url === '/login_check' || config.url === '/auth/refresh') {
            store.dispatch(AuthActions.logout());
            return Promise.reject(error);
        }

        if (status === 401 || status === 403) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch({ type: AuthTypes.REFRESH_TOKEN_START });
                const response = await AuthService.refreshToken({ token, refreshToken }).catch(() => {
                    store.dispatch(AuthActions.logout());
                });
                store.dispatch({
                    type: AuthTypes.REFRESH_TOKEN,
                    payload: { token: response.token, refreshToken: response.refresh_token },
                });
                ToastService.showSuccess('Session is successfully restored');
                originalRequest.headers.Authorization = `Bearer ${response.token}`;
                const newResponse = await axios(originalRequest);
                return newResponse.data;
            } else {
                store.dispatch(AuthActions.logout());
            }
        }

        return Promise.reject(error);
    },
);

export default httpClient;

import React, { useEffect } from 'react';
import { DomainsList } from 'containers';
import { Container } from './styles';
import { DomainsActions, UsersActions } from '../../core/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { SharedTypes } from '../../utils';
import { Grid } from '../../styles/components';
import Loader from '../../components/Loader';

function DomainsPage(props) {
    const { getUserDomains, userDomains, isLoading, isLoaded, getUser, user, isUserLoaded } = props;
    const history = useHistory();

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        getUserDomains(id);
        getUser(id, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.id) document.title = `Debunk EU - Assign User Domains - ${user.username}`;
    }, [user]);

    return (
        <>
            <Container>
                {isLoading && (
                    <Grid.Row pt={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}
                {isLoaded && isUserLoaded && <DomainsList history={history} user={user} userDomains={userDomains} />}
            </Container>
        </>
    );
}

DomainsPage.propTypes = {
    getUserDomains: PropTypes.func,
    getUser: PropTypes.func,
    user: PropTypes.object,
    userDomains: PropTypes.arrayOf(SharedTypes.UserDomainType),
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isUserLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { domains, users } = state;
    const { isLoading, isLoaded, isUpdating, isUpdated } = domains;
    return {
        userDomains: domains.userDomains,
        user: users.user,
        isLoading,
        isLoaded,
        isUserLoaded: users.isLoaded,
        isUpdating,
        isUpdated,
    };
}

const mapDispatchToProps = {
    getUserDomains: DomainsActions.getUserDomains,
    getUser: UsersActions.getUser,
    getUsers: UsersActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsPage);

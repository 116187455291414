import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UrlUtils } from 'utils';
import { useSelector } from 'react-redux';

const size = 25;

const getValidPage = (search) => {
    const page = getPage(search);
    return isPageValid(page) ? page : 1;
};

const getPage = (search) => {
    const params = UrlUtils.parseSearchWithIncludes(search, ['page']);
    return params.page;
};

const isPageValid = (page) => {
    return Number.isInteger(page) && page > 0;
};

function usePaging() {
    const { count, isLoading } = useSelector((state) => state.articles);
    const history = useHistory();
    const location = useLocation();
    const [page, setPage] = useState(getValidPage(location.search));

    useEffect(() => {
        setPage(getValidPage(location.search));
    }, [location.search]);

    const navigateToNext = () => {
        const search = UrlUtils.setSearchParam('page', page + 1);
        history.push({ search: search.toString() });
    };

    const navigateToPrevious = () => {
        const search = UrlUtils.setSearchParam('page', page - 1);
        history.push({ search: search.toString() });
    };

    const navigateToPage = (page) => {
        const search = UrlUtils.setSearchParam('page', page);
        history.push({ search: search.toString() });
    };

    const pagesCount = Math.ceil(count / size);
    const nextDisabled = page >= pagesCount || isLoading;
    const pageDisabled = isLoading;
    const previousDisabled = page <= 1 || isLoading;
    const paging = { offset: (page - 1) * size };

    return {
        pageDisabled,
        nextDisabled,
        previousDisabled,
        page,
        pagesCount,
        paging,
        onNext: navigateToNext,
        onPrevious: navigateToPrevious,
        onPage: navigateToPage,
    };
}

export default usePaging;

import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';

export const Container = styled(Grid.Row)`
    background-color: ${Colors.BLACK};
    width: 100%;
    padding: 0 15px;
`;

export const Logo = styled.img`
    height: 38px;
    cursor: pointer;
    transition: 0.3s;

    &:hover. &:active,
    &:focus {
        opacity: 0.7;
    }
`;

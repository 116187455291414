import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import charts from './charts';
import { ChartsActions } from 'core/actions';
import ChartTop from './ChartTop';
import ChartMain from './ChartMain';
import ChartBottom from './ChartBottom';
import { Grid } from 'styles/components';
import { Item, Wrapper, Muted, Parent, Title } from 'pages/Charts/styles';
import update from 'immutability-helper';
import Loader from 'components/Loader';
import { useCurrentWidth, useFilter } from 'hooks';
import _ from 'lodash';

const initialSettings = {
    printSize: 1920,
    initialWidth: 993,
    initialHeight: 993 * 0.6,
};

function ChartsContainer(props) {
    const {
        chartsArray,
        getChart,
        chart,
        isChartLoading,
        isChartLoaded,
        error,
        languages,
        resetChartArticles,
        isDataLoaded,
        isLoaded,
        isLoading,
    } = props;
    const [graphId, setGraphId] = useState(false);
    const [updateChart, setUpdateChart] = useState(false);
    const [w, setW] = useState(false);
    const [h, setH] = useState(false);
    const [grouped, setGrouped] = useState(false);
    const [showChart, setShowChart] = useState(false);
    const { printSize, initialHeight, initialWidth } = initialSettings;
    const languageArr = [
        { label: 'No translate', value: 'not_translated' },
        ...languages.map((l) => {
            return { label: l.name, value: l.id };
        }),
    ];
    const [language, setLanguage] = useState('not_translated');
    const [title, setTitle] = useState('');
    const [loadedCharts, setLoadedCharts] = useState({});
    const [showLabels, setShowLabels] = useState(true);
    const [type, setType] = useState();
    const currentWidth = useCurrentWidth();
    const { filter, updateFilter } = useFilter();
    const [prevFilter, setPrevFilter] = useState(filter);

    useEffect(() => {
        graphId && updateLanguage({ value: language });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showLabels]);

    useEffect(() => {
        graphId && resetChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWidth]);

    useEffect(() => {
        graphId && updateFilter({ ...filter, chart: graphId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphId]);

    useEffect(() => {
        if (filter.chart && !graphId) {
            setGraphId(filter.chart);
            getChart(filter.chart, _.omit(filter, ['chart']));
        }
        if (!_.isEqual(_.omit(prevFilter, ['chart']), _.omit(filter, ['chart']))) {
            resetChart();
            setLoadedCharts({});
            graphId && getChart(graphId, _.omit(filter, ['chart']));
            setPrevFilter(filter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        isDataLoaded && resetChartArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded]);

    useEffect(() => {
        error && setLoadedCharts({});
    }, [error]);

    useEffect(() => {
        if (isChartLoading) {
            resetChart();
        }

        if (isChartLoaded && chart.graph && updateChart) {
            if (!loadedCharts[graphId]) {
                setLoadedCharts(
                    update(loadedCharts, {
                        [graphId]: { $set: chart },
                    }),
                );
            }
            setCurrentChart(chart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart, updateChart, isChartLoaded, isChartLoading]);

    const setCurrentChart = (currentChart) => {
        const settings = chartsArray.find((c) => c.id === graphId);
        const type = settings.type.type;
        const show = type !== 'pie' && type !== 'donut';

        if (currentChart.data && Object.keys(currentChart.data).length > 0) {
            charts.setMainValues(settings, currentChart.data, settings.translations, graphId, show);
            const { width, height } = charts.getDimensions();
            charts.setOptionalValues(
                languages.find((l) => l.id === language),
                width,
                true,
                true,
            );

            setW(width);
            setH(height);
            setUpdateChart(false);
            setGrouped(type.includes('bar') && settings.rows.length > 1);
            setShowChart(currentChart.data && Object.keys(currentChart.data).length > 0);
            setShowLabels(show);
            setType(type);
        }
    };

    const updateLanguage = (e) => {
        const currentChart = loadedCharts[graphId] ? loadedCharts[graphId] : chart;
        const lang = languages.find((l) => l.id === e.value);
        const settings = chartsArray.find((c) => c.id === graphId);

        if (currentChart.data && !currentChart.data.length) {
            charts.resetAll();
            charts.setMainValues(settings, currentChart.data, settings.translations, graphId, showLabels);
            charts.setOptionalValues(lang, w, true, true);
            charts.createCharts();
            title.length > 0 && charts.updateTitle(title);
            setLanguage(e.value);
        }
    };

    const renderChartList = () => {
        return chartsArray.map((chart, i) => {
            return (
                <Item
                    key={i}
                    onClick={() => {
                        setTitle('');
                        setGraphId(chart.id);
                        setLanguage('not_translated');
                        if (!loadedCharts[chart.id] || chart.id === graphId)
                            getChart(chart.id, _.omit(filter, ['chart']));
                        else resetChart();
                    }}
                    active={graphId === chart.id}
                >
                    {chart.name}
                    <Muted> (ID {chart.id})</Muted>
                </Item>
            );
        });
    };

    const resetChart = () => {
        charts.resetAll();
        setShowChart(false);
        setW(false);
        setH(false);
        setUpdateChart(true);
    };

    const updateTitle = () => {
        charts.updateTitle(title);
    };

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    overflow: 'hidden',
                    transform: 'translateY(-100000vh)',
                }}
            >
                <div
                    style={{ width: '1920px', height: 1920 * (type === 'pie' || type === 'donut' ? 0.4 : 0.6) + 'px' }}
                >
                    <canvas id="print" />
                </div>
            </div>

            <Grid.Row alignItems="flex-start">
                <Grid.Col>
                    <Wrapper side>
                        <Title>Charts</Title>
                        {isLoading && (
                            <Grid.Row mt={30} justifyContent="center">
                                <Loader />
                            </Grid.Row>
                        )}
                        {isLoaded && renderChartList()}
                    </Wrapper>
                </Grid.Col>
                <Grid.Col flex={1}>
                    <Wrapper padding>
                        <ChartTop
                            showChart={showChart}
                            search={filter}
                            error={error}
                            width={w}
                            chart={loadedCharts[graphId] ? loadedCharts[graphId] : chart}
                            chartsArray={chartsArray}
                            graphId={graphId}
                            grouped={grouped}
                            initialHeight={initialHeight}
                            initialWidth={initialWidth}
                            isChartLoaded={isChartLoaded}
                            isChartLoading={isChartLoading}
                            language={language}
                            languages={languageArr}
                            updateLanguage={updateLanguage}
                            printSize={printSize}
                        />
                        <Grid.Row mb={20}>
                            <Parent
                                id={`parent-${graphId}`}
                                width={w || 0}
                                minHeight={h || 0}
                                height={grouped ? 'auto' : h || 0}
                            >
                                {isChartLoaded && showChart && graphId && (
                                    <ChartMain
                                        showLabels={showLabels}
                                        total={loadedCharts[graphId] ? loadedCharts[graphId].total : chart.total}
                                        graphSettings={chartsArray.find((ch) => ch.id === graphId)}
                                        search={filter}
                                        title={title}
                                        printSize={printSize}
                                        initialHeight={initialHeight}
                                        initialWidth={initialWidth}
                                        grouped={grouped}
                                        graphId={graphId}
                                        width={w}
                                        height={h}
                                    />
                                )}
                            </Parent>
                        </Grid.Row>
                        {isChartLoaded && showChart && (
                            <ChartBottom
                                showLabels={showLabels}
                                setShowLabels={() => setShowLabels(!showLabels)}
                                chart={loadedCharts[graphId] ? loadedCharts[graphId] : chart}
                                title={title}
                                setTitle={setTitle}
                                updateTitle={updateTitle}
                            />
                        )}
                    </Wrapper>
                </Grid.Col>
            </Grid.Row>
        </>
    );
}

ChartsContainer.propTypes = {
    error: PropTypes.bool.isRequired,
    isDataLoaded: PropTypes.bool.isRequired,
    chart: PropTypes.object,
    search: PropTypes.object.isRequired,
    chartsArray: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    isChartLoading: PropTypes.bool.isRequired,
    isChartLoaded: PropTypes.bool.isRequired,
    getChart: PropTypes.func.isRequired,
    resetChartArticles: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { charts, search, languages } = state;
    const { isChartLoading, isChartLoaded, error, isDataLoaded, isLoaded, isLoading } = charts;
    return {
        isDataLoaded,
        isChartLoading,
        isChartLoaded,
        error,
        isLoaded,
        isLoading,
        chart: charts.chart,
        search: search.search,
        languages: languages.languages,
    };
}

const mapDispatchToProps = {
    getChart: ChartsActions.getChart,
    resetChartArticles: ChartsActions.resetChartArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsContainer);

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Content = styled.div``;

export const Description = styled.p`
    font-size: 13px;
    color: ${Colors.SECONDARY};
    line-height: 18px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HelpList = styled.ul`
    padding-left: 20px;
    margin: 0;
`;

export const HelpListItem = styled.li`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    color: ${Colors.BADGE_TEXT};

    &:last-of-type {
        margin-bottom: 0;
    }
`;

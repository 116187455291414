import { EmailsTypes } from 'core/types';
import { EmailsService, ToastService } from 'core/services';

export function getEmails() {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.GET_EMAILS_START });
            const emails = await EmailsService.getEmails();
            dispatch({ type: EmailsTypes.GET_EMAILS, payload: { emails } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.GET_EMAILS_ERROR });
        }
    };
}

export function getEmail(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.GET_EMAILS_START });
            const email = await EmailsService.getEmail(id);
            dispatch({ type: EmailsTypes.GET_EMAIL, payload: { email } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.GET_EMAILS_ERROR });
        }
    };
}

export function createEmail(email) {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.UPDATE_EMAILS_START });
            await EmailsService.createEmail(email);
            dispatch({ type: EmailsTypes.CREATE_EMAIL });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.GET_EMAILS_ERROR });
        }
    };
}

export function updateEmail(id, email) {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.UPDATE_EMAILS_START });
            await EmailsService.updateEmail(id, email);
            dispatch({ type: EmailsTypes.CREATE_EMAIL });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.GET_EMAILS_ERROR });
        }
    };
}

export function deleteEmail(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.UPDATE_EMAILS_START });
            await EmailsService.deleteEmail(id);
            dispatch({ type: EmailsTypes.REMOVE_EMAIL });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.GET_EMAILS_ERROR });
        }
    };
}

export function convertEmailHash(hash) {
    return async (dispatch) => {
        try {
            dispatch({ type: EmailsTypes.CONVERT_EMAIL_HASH_START });
            const response = await EmailsService.convertEmailHash(hash);
            dispatch({ type: EmailsTypes.CONVERT_EMAIL_HASH, payload: { url: response.data.url } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: EmailsTypes.CONVERT_EMAIL_HASH_ERROR });
        }
    };
}

export const COLUMNS = [
    // {
    //     Header: 'ID',
    //     accessor: row => row.id,
    //     filter: "multiple",
    // },
    {
        Header: 'Name, description or comments',
        accessor: (row) => row.description,
        filter: 'multiple',
    },
    {
        Header: 'Subject',
        accessor: (row) => row.subject,
        filter: 'multiple',
    },
    {
        Header: 'Status',
        accessor: (row) => (row.status ? 'Active' : 'Inactive'),
        filter: 'multiple',
    },
    {
        Header: 'Interval',
        accessor: (row) => row.interval,
        filter: 'multiple',
    },
    {
        Header: 'Min infometer score',
        accessor: (row) => row.min_score,
        filter: 'multiple',
    },
    {
        Header: 'Limit',
        accessor: (row) => row.articles_limit,
        filter: 'multiple',
    },
    {
        Header: 'Schedule',
        accessor: (row) => row.parsed_schedule,
        filter: 'multiple',
    },
    {
        Header: 'Last sent',
        accessor: (row) => {
            if (row.last_sent) {
                let arr = [row.last_sent.slice(0, 10)];
                arr.push(row.last_sent.slice(11, 16));
                return arr.join('\r\n');
            } else return '';
        },
        filter: 'multiple',
    },
    {
        Header: 'Users',
        accessor: (row) => {
            let arr = [];
            row.users.map((u) => arr.push(u.username));
            return arr.join('\r\n');
        },
        filter: 'multiple',
    },
];

import { store } from 'core/store';
import httpClient from '../httpClient';

const baseUrl = '/languages';

export const getLanguages = async () => {
    return httpClient.get(baseUrl);
};

export const getLanguageById = (id) => {
    const languages = store.getState().languages.languages;
    return languages.find((language) => language.id === id);
};

export const getLanguageByIso = (iso) => {
    const languages = store.getState().languages.languages;
    return languages.find((language) => language.iso === iso);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, OptionContainer } from './styles';
import { Form } from 'styles/components';
import { SharedTypes } from 'utils';
import Checkbox from '../Checkbox';
import _ from 'lodash';

function MultiButtonsSelect(props) {
    const { label, style, value, dirty, error, onChange, name, options } = props;

    const handleOnChange = (option) => {
        onChange({ name, value: _.xor(value, [option]) });
    };

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            <Content>
                {options.map((option) => (
                    <OptionContainer onClick={() => handleOnChange(option)} key={option.value}>
                        <Checkbox
                            label={option.label}
                            checked={value.some((item) => item.value === option.value)}
                            name={option.label}
                        />
                    </OptionContainer>
                ))}
            </Content>
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

MultiButtonsSelect.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(SharedTypes.OptionType),
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

MultiButtonsSelect.defaultProps = {
    style: {},
    label: null,
    value: null,
    dirty: false,
    error: null,
    disabled: false,
};

export default MultiButtonsSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image, Active } from './styles';
import { Images } from 'styles/constant';

function Avatar(props) {
    const { style, size, source, active } = props;

    return (
        <Container size={size} style={style}>
            <Image src={source} />
            {active && <Active size={size} />}
        </Container>
    );
}

Avatar.propTypes = {
    style: PropTypes.object,
    source: PropTypes.string,
    active: PropTypes.bool,
    size: PropTypes.number,
};

Avatar.defaultProps = {
    style: {},
    size: 25,
    active: false,
    source: Images.TEAM_1,
};

export default Avatar;

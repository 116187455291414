import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    background-color: ${Colors.WHITE};
    width: 100%;
    padding: 20px;
`;

export const Wrapper = styled.div`
    width: 100%;
    padding-left: 20px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { DateUtils, SharedTypes } from 'utils';
import { RelativeDates } from 'constant';

function RelativeDateSelect(props) {
    const { value, onChange } = props;

    const renderOption = (date) => (
        <Styles.Date active={date === value.date} onClick={() => onChange(date)}>
            {DateUtils.getRelativeDateLabel(date)}
        </Styles.Date>
    );

    return (
        <Styles.Container>
            <Styles.Header>
                <Styles.Title>Relative dates</Styles.Title>
            </Styles.Header>
            <Styles.DatesContainer>
                {renderOption(RelativeDates.TODAY)}
                {renderOption(RelativeDates.LAST_7_DAYS)}
                {renderOption(RelativeDates.LAST_30_DAYS)}
                {renderOption(RelativeDates.THIS_MONTH)}
                {renderOption(RelativeDates.LAST_MONTH)}
                {renderOption(RelativeDates.LAST_100_DAYS)}
                {renderOption(RelativeDates.THIS_YEAR)}
            </Styles.DatesContainer>
        </Styles.Container>
    );
}

RelativeDateSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: SharedTypes.DateRangeValue,
};

RelativeDateSelect.defaultProps = {
    value: null,
};

export default RelativeDateSelect;

import update from 'immutability-helper';
import { FiltersTypes, AuthTypes } from 'core/types';

const initialState = {
    filters: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
    table: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FiltersTypes.GET_FILTERS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                table: { $set: false },
            });
        }

        case FiltersTypes.GET_FILTERS: {
            return update(state, {
                isLoading: { $set: false },
                filters: { $set: payload.filters },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                table: { $set: payload.table },
            });
        }

        case FiltersTypes.UPDATE_FILTERS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case FiltersTypes.CREATE_FILTER:
        case FiltersTypes.UPDATE_FILTER:
        case FiltersTypes.REMOVE_FILTER: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case FiltersTypes.GET_FILTERS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

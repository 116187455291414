import styled from 'styled-components';
import { Colors } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`;

export const SliderContainer = styled.div`
    width: 48px;
    height: 24px;
    border-radius: 12px;
    transition: 0.3s;
    background-color: ${(props) => (props.checked ? hexToRgba(Colors.PRIMARY, 0.2) : Colors.WHITE)};
    border: 1px solid ${(props) => (props.checked ? Colors.PRIMARY : Colors.BACKGROUND)};
`;

export const SliderItem = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    position: relative;
    left: ${(props) => (props.checked ? 23 : -1)}px;
    top: -1px;
    transition: 0.3s;
    background-color: ${(props) => (props.checked ? Colors.PRIMARY : Colors.MODAL_BORDER)};
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};
    margin-left: 10px;
`;

import styled from 'styled-components';
import { Colors } from '../../constant';

export const Container = styled.div`
    margin: ${(props) => (props.ma ? props.ma : 0)}px;
    margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
    margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
    margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
    margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
    padding: ${(props) => (props.pa ? props.pa : 0)}px;
    padding-bottom: ${(props) => (props.pb ? props.pb : 0)}px;
    padding-top: ${(props) => (props.pt ? props.pt : 0)}px;
    padding-left: ${(props) => (props.pl ? props.pl : 0)}px;
    padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
`;

export const FixedContainer = styled(Container)`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;
    position: ${(props) => (props.sticky ? 'sticky' : 'initial')};
    background: ${(props) => (props.sticky ? Colors.WHITE : 'transparent')};
    z-index: 2;
    top: 0;
    border-bottom: ${(props) => (props.sticky ? '1px solid ' + Colors.BACKGROUND : 'none')};
`;

export const FluidContainer = styled(Container)`
    width: 100%;
    padding: 0 15px;
`;

export const Row = styled(Container)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
`;

export const Col = styled(Container)`
    flex: ${(props) => (props.flex ? props.flex : '0 1 auto')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
    display: flex;
    flex-shrink: ${(props) => (props.flexShrink !== null ? props.flexShrink : 1)};
    flex-direction: column;
`;

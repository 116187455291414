import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Canvas } from './styles';
import charts from '../charts';
import { ArticlesModal } from 'containers';
import { ChartsActions } from 'core/actions';
import { connect } from 'react-redux';

function ChartMain(props) {
    const {
        graphId,
        width,
        height,
        grouped,
        title,
        search,
        graphSettings,
        getChartArticles,
        chartArticles,
        isDataLoaded,
        isDataLoading,
    } = props;
    const [offset, setOffset] = useState(0);
    const [modal, setModal] = useState(false);
    const [chartSvg, setChartSvg] = useState({});
    const [elementClicked, setElementClicked] = useState({});

    useEffect(() => {
        const { chart, svg, chartPrint } = charts.createCharts();
        title.length > 0 && charts.updateTitle(title);
        setChartSvg({ chart, svg, chartPrint });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { chart, svg } = chartSvg;
        if (chart && chart.ctx) {
            chart.options.onClick = function (e, chartArr) {
                if (chartArr[0]) setElementClicked({ e, chartArr, el: this });
            };
            chart.update();
        } else if (svg) {
            onClickSvg();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartSvg]);

    useEffect(() => {
        Object.keys(chartSvg).length > 0 && Object.keys(elementClicked).length > 0 && onClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementClicked]);

    const getArticleList = (e, data) => {
        getChartArticles(graphId, data);
    };

    const calcOffset = (to) => {
        const { total } = chartArticles;
        let newOffset = offset;
        if (offset >= 25 && to === 'prev') {
            newOffset = offset - 25;
        }
        if (total - (offset + 25) > 0 && to === 'next') {
            newOffset = offset + 25;
        }
        setOffset(newOffset);
        return newOffset;
    };

    const onClickSvg = (to) => {
        const { svg } = chartSvg;
        const newOffset = calcOffset(to);
        svg.selectAll('.bar').on('click', (e, d) => {
            let data = search || {};
            data.offset = newOffset;
            data.value = graphSettings.values[0].col;
            data.rows = [
                [
                    d.parent_search_term.toUpperCase() === d.parent_search_term
                        ? d.parent_search_term.toLowerCase()
                        : d.parent_search_term,
                ],
                [d.search_term],
            ];
            getArticleList(e, data);
        });
    };

    const onClick = (to) => {
        const { e, chartArr, el } = elementClicked;
        const { type, grData, graphMain, sTermsArray, stacked, labels } = charts.getValues();
        const newOffset = calcOffset(to);

        if (chartArr.length > 0) {
            const chartArr = el.getElementAtEvent(e)[0];
            let parent = Object.values(graphMain)[type === 'line' ? chartArr._datasetIndex : chartArr._index];

            if (chartArr && parent.search_term) {
                let data = search || {};
                data.offset = newOffset;
                data.value = graphSettings.values[chartArr._datasetIndex]
                    ? graphSettings.values[chartArr._datasetIndex].col
                    : graphSettings.values[0].col;
                data.rows = [
                    [
                        parent.search_term.toUpperCase() === parent.search_term
                            ? parent.search_term.toLowerCase()
                            : parent.search_term,
                    ],
                ];

                // ======= pie/doughnut 'other' section =======
                const datasetLength = grData[chartArr._datasetIndex].length;
                if (
                    (type === 'pie' || type === 'doughnut') &&
                    chartArr._index === datasetLength - 1 &&
                    Object.keys(graphMain).length > datasetLength
                ) {
                    for (let i = 0; i < Object.keys(graphMain).length; i++) {
                        if (i > chartArr._index) {
                            data.rows[0].push(Object.values(graphMain)[i].search_term);
                        }
                    }
                }

                if (!!stacked || (graphSettings.columns && graphSettings.columns.length > 0)) {
                    if (type === 'line') {
                        const item = parent.children[labels[chartArr._index]]
                            ? parent.children[labels[chartArr._index]]
                            : parent.children[sTermsArray[chartArr._index]];
                        data.columns = {
                            0: [item.search_term],
                        };
                    } else {
                        let child = {};
                        Object.values(parent.children).map((c) => {
                            if (c.title == labels[chartArr._datasetIndex]) child = c;
                            return c;
                        });

                        data.columns = {
                            0: [child.search_term],
                        };
                    }
                }
                getArticleList(e, data);
            } else {
                setModal(false);
            }
        }
    };

    return (
        <>
            <Canvas id={`chart-${graphId}`} width={width || 0} height={grouped ? 'auto' : height || 0} />
            <ArticlesModal
                chartArticles={chartArticles}
                isDataLoaded={isDataLoaded}
                isDataLoading={isDataLoading}
                elementClicked={elementClicked}
                onClick={onClick}
                modal={modal}
                setModal={setModal}
                offset={offset}
                setOffset={setOffset}
            />
        </>
    );
}

ChartMain.propTypes = {
    grouped: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    graphId: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    printSize: PropTypes.number.isRequired,
    initialHeight: PropTypes.number.isRequired,
    initialWidth: PropTypes.number.isRequired,
    search: PropTypes.object,
    graphSettings: PropTypes.object,
    total: PropTypes.number,
    getChartArticles: PropTypes.func,
    isDataLoading: PropTypes.bool,
    isDataLoaded: PropTypes.bool,
    chartArticles: PropTypes.object,
};

function mapStateToProps(state) {
    const { charts } = state;
    const { isDataLoading, isDataLoaded } = charts;
    return {
        isDataLoading,
        isDataLoaded,
        chartArticles: charts.chartArticles,
    };
}

const mapDispatchToProps = {
    getChartArticles: ChartsActions.getChartArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartMain);

export const OPTIONS = (options, id, languages) => {
    if (id === 'languages') {
        options = languages.map((l) => {
            return { label: l.iso.toUpperCase(), value: l.iso.toUpperCase() };
        });
        options.push({ label: 'UNKNOWN', value: 'UNKNOWN' });
    }
    if (id === 'create date' || id === 'update date' || id === 'last login' || id === 'last sent') {
        options = options.map((o) => {
            return { label: o.label.slice(0, 10), value: o.value.slice(0, 10) };
        });
    }
    if (id === 'specialization in topic subcategory') {
        options = options.map((o) => {
            return {
                value: o.value.toString().split(',')[0].split(' &')[0],
                label: o.label.split(',')[0].split(' &')[0],
            };
        });
    }

    return options.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label } from './styles';

function Badge(props) {
    const { label, onClick } = props;

    const handleOnClick = (event) => {
        event.stopPropagation();
        onClick();
    };

    return (
        <Container onClick={handleOnClick}>
            <Label>{label}</Label>
        </Container>
    );
}

Badge.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

Badge.defaultProps = {
    onClick: () => {},
};

export default Badge;

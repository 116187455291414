import React, { useEffect, useState } from 'react';
import { Container, Content, Title, Image, ContentTop, Message } from './styles';
import { ForgotPasswordForm } from 'containers';
import { connect } from 'react-redux';
import { AuthActions } from 'core/actions';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { LOGO } from 'styles/constant/images';

function ForgotPasswordPage(props) {
    const { forgotPassword, passwordReminderSent, resetPassError, isLoading } = props;
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = 'Debunk EU - Password assistance – Step 1';
    }, []);

    const handleSubmit = (request) => {
        forgotPassword(request);
        setEmail(request.email);
    };

    return (
        <Container onSubmit={(event) => event.preventDefault()}>
            <ContentTop>
                <Grid.Row justifyContent="center">
                    <Image src={LOGO} />
                </Grid.Row>
            </ContentTop>
            <Content>
                <Grid.Row mb={30}>
                    <Title>Password assistance</Title>
                </Grid.Row>
                {resetPassError && email && !isLoading && (
                    <Grid.Row mb={30} mt={-20}>
                        <Message error>
                            We&apos;re sorry. This email address does not correspond to any account. If you continue to
                            receive errors, please contact{' '}
                            <a href="mailto:support@debunkeu.org"> support@debunkeu.org</a>
                        </Message>
                    </Grid.Row>
                )}
                {!resetPassError && passwordReminderSent && email && (
                    <Grid.Row mb={30} mt={-20}>
                        <Message>&#10003; We&apos;ve sent a link of password recovery to the email {email}</Message>
                    </Grid.Row>
                )}
                <ForgotPasswordForm onSubmit={handleSubmit} loading={isLoading} />
            </Content>
        </Container>
    );
}

ForgotPasswordPage.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    passwordReminderSent: PropTypes.bool.isRequired,
    resetPassError: PropTypes.string,
    isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
    const { auth } = state;
    const { passwordReminderSent, resetPassError, isLoading } = auth;
    return { passwordReminderSent, resetPassError, isLoading };
}

const mapDispatchToProps = {
    forgotPassword: AuthActions.forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
`;

export const TextInput = styled.textarea`
    height: ${(props) => props.height}px;
    resize: none;
    line-height: 1.5;
    width: 100%;
    border: 1px solid ${(props) => (props.error ? Colors.DANGER : Colors.LIGHT_GREY)};
    border-radius: 6px;
    background-color: ${Colors.WHITE};
    padding: 5px 10px;
    font-size: 14px;
    color: ${Colors.BLACK};
    transition: 0.3s;

    ${(props) =>
        props.disabled &&
        `
    opacity: 0.6;
  
    &:hover {
      cursor: no-drop;
    }  
  `}
`;

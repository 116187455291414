import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { Buttons, Colors } from 'styles/constant';

const getBackgroundColor = ({ type, disabled, outline }) => {
    const alpha = disabled ? 0.5 : 1;
    let color;

    if (outline) {
        return 'transparent';
    }

    switch (type) {
        case Buttons.SECONDARY: {
            color = Colors.SECONDARY;
            break;
        }

        case Buttons.TERTIARY: {
            color = Colors.BADGE_BACKGROUND;
            break;
        }

        case Buttons.WARNING: {
            color = Colors.WARNING;
            break;
        }

        default: {
            color = Colors.PRIMARY;
            break;
        }
    }

    return hexToRgba(color, alpha);
};

const getTextColor = ({ type }) => {
    switch (type) {
        case Buttons.SECONDARY: {
            return Colors.WHITE;
        }

        case Buttons.TERTIARY: {
            return Colors.BADGE_TEXT;
        }

        case Buttons.WARNING: {
            return '#212529';
        }

        default: {
            return Colors.WHITE;
        }
    }
};

export const Container = styled.button`
    border-radius: 5px;
    min-width: ${(props) => (props.label ? 70 : 40)}px;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 ${(props) => (props.label ? 15 : 0)}px;
    background-color: ${(props) => getBackgroundColor(props)};
    transition: 0.3s;

    &:hover {
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }

    &:hover,
    &:active,
    &:focus {
        opacity: 0.77;
    }
`;

export const Label = styled.span`
    color: ${(props) => getTextColor(props)};
    font-size: 14px;
    white-space: nowrap;
    font-weight: ${(props) => (props.outline ? 500 : 700)};
`;

export const Icon = styled.img`
    margin-right: ${(props) => (props.label ? 10 : 0)}px;
`;

export const LoaderContainer = styled.div`
    margin-left: 10px;
`;

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USER_START = 'GET_USER_START';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const UPDATE_USERS_START = 'UPDATE_USERS_START';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

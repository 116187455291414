import update from 'immutability-helper';
import { AuthTypes } from 'core/types';

const initialState = {
    switchUser: null,
    profile: {},
    permissions: [],
    token: null,
    refreshToken: null,
    isLogged: false,
    isLoading: false,
    isLoaded: false,
    isRefreshing: false,
    passwordReminderSent: false,
    resetPassError: '',
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case AuthTypes.GET_PROFILE_START:
        case AuthTypes.LOGIN_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                passwordReminderSent: { $set: false },
                resetPassError: { $set: null },
            });
        }

        case AuthTypes.REFRESH_TOKEN_START: {
            return update(state, {
                isRefreshing: { $set: true },
                passwordReminderSent: { $set: false },
                resetPassError: { $set: null },
            });
        }

        case AuthTypes.REFRESH_TOKEN: {
            return update(state, {
                token: { $set: payload.token },
                refreshToken: { $set: payload.refreshToken },
                isRefreshing: { $set: false },
                resetPassError: { $set: null },
            });
        }

        case AuthTypes.LOGIN: {
            return update(state, {
                isLogged: { $set: true },
                token: { $set: payload.token },
                refreshToken: { $set: payload.refreshToken },
                permissions: { $set: [] },
                isLoading: { $set: false },
                resetPassError: { $set: null },
            });
        }

        case AuthTypes.GET_PROFILE: {
            return update(state, {
                profile: { $set: payload.profile },
                isLoading: { $set: false },
                resetPassError: { $set: null },
            });
        }

        case AuthTypes.FORGOT_PASSWORD: {
            return update(state, {
                passwordReminderSent: { $set: true },
                resetPassError: { $set: null },
                isLoading: { $set: false },
                isLoaded: { $set: false },
            });
        }

        case AuthTypes.RESET_PASSWORD_ERROR: {
            return update(state, {
                resetPassError: { $set: payload ? payload.exception : null },
                isLoading: { $set: false },
                isLoaded: { $set: false },
            });
        }

        case AuthTypes.RESET_PASSWORD: {
            return update(state, {
                isLoaded: { $set: true },
                isLoading: { $set: false },
            });
        }

        case AuthTypes.SET_USERNAME: {
            return update(state, {
                switchUser: { $set: payload.switchUser },
            });
        }

        case AuthTypes.REFRESH_TOKEN_ERROR:
        case AuthTypes.LOGOUT:
        case AuthTypes.LOGIN_ERROR:
        case AuthTypes.GET_PROFILE_ERROR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, CheckContainer, Check } from './styles';

function Radio(props) {
    const { onChange, label, style, checked, disabled, name } = props;

    const handleOnChange = () => {
        onChange({ name, value: !checked });
    };

    return (
        <Container onClick={handleOnChange} disabled={disabled} style={style}>
            <CheckContainer checked={checked}>{checked && <Check />}</CheckContainer>
            {label && <Label>{label}</Label>}
        </Container>
    );
}

Radio.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

Radio.defaultProps = {
    style: {},
    label: null,
    onChange: () => {},
    checked: false,
    disabled: false,
};

export default Radio;

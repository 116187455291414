import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 4px;
    background-color: ${Colors.LIGHT_GREY};

    &:hover {
        + .tooltip {
            display: block;
        }
    }
`;

export const Label = styled.span`
    font-size: 12px;
    color: ${Colors.SECONDARY};
`;

import React, { useEffect } from 'react';
import { Grid } from 'styles/components';
import { OrganisationForm } from 'containers';
import { OrganisationsActions, ChartsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from './styles';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function OrganisationListPage(props) {
    const {
        organisations,
        getOrganisations,
        isLoaded,
        isLoading,
        getCharts,
        charts,
        isChartsLoaded,
        isLanguagesLoaded,
        languages,
        isUpdated,
    } = props;
    const history = useHistory();

    useEffect(() => {
        if (isUpdated) {
            history.push('/organisations');
        } else {
            getOrganisations();
            getCharts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOrganisations, getCharts, isUpdated]);

    return (
        <Container>
            {isLoading && (
                <Grid.Row mt={30} justifyContent="center">
                    <Loader />
                </Grid.Row>
            )}
            {isLoaded && isChartsLoaded && isLanguagesLoaded && (
                <OrganisationForm
                    organisations={organisations}
                    history={history}
                    languages={languages}
                    charts={charts}
                />
            )}
        </Container>
    );
}

OrganisationListPage.propTypes = {
    getOrganisations: PropTypes.func.isRequired,
    getCharts: PropTypes.func.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    charts: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isUpdated: PropTypes.bool,
    isChartsLoaded: PropTypes.bool,
    isLanguagesLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { organisations, charts, languages } = state;
    const { isLoading, isLoaded, isUpdated } = organisations;
    return {
        organisations: organisations.organisations,
        charts: charts.charts,
        languages: languages.languages,
        isChartsLoaded: charts.isLoaded,
        isLanguagesLoaded: languages.isLoaded,
        isLoaded,
        isLoading,
        isUpdated,
    };
}

const mapDispatchToProps = {
    getOrganisations: OrganisationsActions.getOrganisations,
    getCharts: ChartsActions.getCharts,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationListPage);

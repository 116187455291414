export const CALC_TYPES = [
    { label: 'Count', value: 'count' },
    { label: 'Sum', value: 'sum' },
    { label: 'Avg', value: 'avg' },
    { label: 'Favourability', value: 'favourability' },
];

export const PERIODS = [
    { label: 'day', value: 'day' },
    { label: 'week', value: 'week' },
    { label: 'month', value: 'month' },
    { label: 'quarter', value: 'quarter' },
    { label: 'year', value: 'year' },
];

export const CHART_OPTIONS = (options) => {
    return Object.values(options).map((o, i) => {
        return { label: o.title, value: Object.keys(options)[i] };
    });
};

import {
    AuthTypes,
    CategoriesTypes,
    DomainsTypes,
    FiltersTypes,
    InitialTypes,
    LanguagesTypes,
    ParametersTemplatesTypes,
    ParametersTypes,
    UsersTypes,
} from 'core/types';
import {
    AuthService,
    CategoriesService,
    DomainsService,
    FiltersService,
    LanguagesService,
    ParametersService,
    ParametersTemplatesService,
    ToastService,
    UsersService,
} from 'core/services';

export function getInitialData() {
    return async (dispatch) => {
        try {
            dispatch({ type: InitialTypes.GET_INITIAL_DATA_START });
            const profile = await AuthService.getProfile();
            const [categories, domains, filters, languages, users, parameters, parametersTemplates] = await Promise.all(
                [
                    CategoriesService.getCategories(),
                    DomainsService.getDomains(),
                    FiltersService.getFilters(),
                    LanguagesService.getLanguages(),
                    UsersService.getUsers(),
                    ParametersService.getParameters(),
                    ParametersTemplatesService.getParametersTemplates(),
                ],
            );
            dispatch({ type: CategoriesTypes.GET_CATEGORIES, payload: { categories } });
            dispatch({ type: DomainsTypes.GET_DOMAINS, payload: { domains } });
            dispatch({ type: FiltersTypes.GET_FILTERS, payload: { filters } });
            dispatch({ type: LanguagesTypes.GET_LANGUAGES, payload: { languages } });
            dispatch({ type: UsersTypes.GET_USERS, payload: { users } });
            dispatch({ type: ParametersTypes.GET_PARAMETERS, payload: { parameters } });
            dispatch({ type: ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES, payload: { parametersTemplates } });
            dispatch({ type: AuthTypes.GET_PROFILE, payload: { profile } });
            dispatch({ type: InitialTypes.GET_INITIAL_DATA });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: AuthTypes.SET_USERNAME, payload: { switchUser: null } });
            dispatch({ type: InitialTypes.GET_INITIAL_DATA_ERROR });
        }
    };
}

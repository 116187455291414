import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import * as Styles from './styles';
import Button from 'components/Button';
import { useArticleHide } from 'features/articles/hooks';
import { SharedTypes } from 'features/articles/utils';

function ArticleHideModal(props) {
    const { visible, onClose, article, similar } = props;
    const { hideArticleForAll, hideArticleForMe, isForAllLoading, isForMeLoading } = useArticleHide({
        article,
        similar,
    });

    const renderContent = () => (
        <Styles.Content>
            <Styles.Description>
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </Styles.Description>
        </Styles.Content>
    );

    const renderFooter = () => (
        <Styles.Footer>
            <Grid.Row>
                <Grid.Col>
                    <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col mr={10}>
                    <Button
                        label={`${article.isHiddenForMe ? 'Unhide' : 'Hide'} for me`}
                        loading={isForMeLoading}
                        disabled={isForAllLoading}
                        onClick={() => hideArticleForMe(onClose)}
                    />
                </Grid.Col>
                <Grid.Col>
                    <Button
                        label={`${article.isHiddenForAll ? 'Unhide' : 'Hide'} for all`}
                        loading={isForAllLoading}
                        disabled={isForMeLoading}
                        onClick={() => hideArticleForAll(onClose)}
                    />
                </Grid.Col>
            </Grid.Row>
        </Styles.Footer>
    );

    return (
        <Modal
            title="Hide article"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

ArticleHideModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    similar: PropTypes.bool.isRequired,
    article: SharedTypes.ArticleType.isRequired,
};

ArticleHideModal.defaultProps = {
    visible: false,
};

export default ArticleHideModal;

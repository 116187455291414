import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors } from '../../styles/constant';

export const IconBtn = styled(Link)`
    text-decoration: none;
    height: 40px;
    padding: 8px;
    border-radius: 5px;
    background: url(${(props) => props.bg}) no-repeat center;
    background-color: ${(props) => (props.type === 'SEARCH' ? Colors.PRIMARY : Colors.BADGE_BACKGROUND)};
    background-size: ${(props) => (props.type === 'REMOVE' ? 20 : 24)}px;
    margin: ${(props) => (props.small ? 0 : 5)}px;
    transform: ${(props) => (props.small ? 'scale(.7)' : 'none')};
    transition: 0.3s;
    color: ${Colors.BADGE_TEXT};
    font-size: 12px;
    white-space: nowrap;
    width: ${(props) => (props.text ? '100%' : '40px')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LinkWrapper = styled.div`
    cursor: ${(props) => (props.disabled ? 'not-allowed;' : 'pointer')};
    ${IconBtn} {
        pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
        opacity: ${(props) => (props.disabled ? 0.4 : 1)};

        &:hover,
        &:active,
        &focus {
            opacity: ${(props) => (props.disabled ? 0.4 : 0.75)};
        }
    }
`;

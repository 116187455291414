import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    border: 3px solid ${(props) => props.color};
    border-radius: 50%;
    border-top: 3px solid ${Colors.PRIMARY};
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    display: ${(props) => (props.isLoading ? 'block' : 'none')};
    animation: spin 2s linear infinite;
`;

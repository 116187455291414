import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    background-color: ${Colors.WHITE};
    border-radius: 5px;
    border: 1px solid ${Colors.BACKGROUND};
`;

export const OptionContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid ${Colors.BACKGROUND};

    &:hover {
        cursor: pointer;
    }

    &:last-of-type {
        border-bottom: none;
    }
`;

import update from 'immutability-helper';
import { AuthTypes } from 'core/types';
import { SimilarArticlesTypes } from 'features/articles/types';

const initialState = {
    similarArticles: [],
    count: 0,
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SimilarArticlesTypes.GET_SIMILAR_ARTICLES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case SimilarArticlesTypes.GET_SIMILAR_ARTICLES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                similarArticles: { $set: payload.similarArticles },
                count: { $set: payload.count },
            });
        }

        case SimilarArticlesTypes.REMOVE_SIMILAR_ARTICLE: {
            const index = state.similarArticles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return state;
            const filteredArticles = state.similarArticles.filter((_, i) => i !== index);

            return update(state, {
                similarArticles: { $set: filteredArticles },
            });
        }

        case SimilarArticlesTypes.SIMILAR_ARTICLE_BOOKMARK: {
            const index = state.similarArticles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                similarArticles: { [index]: { isBookmarked: { $set: payload.isBookmarked } } },
            });
        }

        case SimilarArticlesTypes.SIMILAR_ARTICLE_VOTE: {
            const index = state.similarArticles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                similarArticles: { [index]: { votes: { $set: payload.votes } } },
            });
        }

        case SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ALL: {
            const index = state.similarArticles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                similarArticles: {
                    [index]: {
                        isHiddenForAll: { $set: payload.isHiddenForAll },
                    },
                },
            });
        }

        case SimilarArticlesTypes.SIMILAR_ARTICLE_HIDE_FOR_ME: {
            const index = state.similarArticles.findIndex(
                (article) => article.id === payload.id && article.parentId === payload.parentId,
            );
            if (index === -1) return;

            return update(state, {
                similarArticles: {
                    [index]: {
                        isHiddenForMe: { $set: payload.isHiddenForMe },
                    },
                },
            });
        }

        case SimilarArticlesTypes.GET_SIMILAR_ARTICLES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import { store } from 'core/store';
import httpClient from '../httpClient';

const baseUrl = '/filters';

export const getFilters = async (subset) => {
    const params = { subset };
    return httpClient.get(baseUrl, { params });
};

export const getFilterById = (id) => {
    const filters = store.getState().filters.filters;
    return filters.find((filter) => filter.id === id);
};

export const createFilter = (filter, languages) => {
    return httpClient.post(baseUrl, { filter, languages });
};

export const updateFilter = (id, filter, languages) => {
    return httpClient.put(`${baseUrl}/${id}`, { filter, languages });
};

export const deleteFilter = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';
import { Grid } from 'styles/components';

export const Container = styled.div`
    border-radius: 5px;
    width: 100%;
    background-color: ${Colors.WHITE};
    box-shadow: 0 0 20px 0 ${hexToRgba(Colors.BLACK, 0.1)};
    position: relative;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    border: 2px solid ${(props) => (props.active ? Colors.BLACK : Colors.BACKGROUND)};

    &:hover {
        border: 2px solid ${Colors.BLACK};
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }
`;

export const Content = styled(Grid.Row)`
    padding: 15px;
`;

export const Footer = styled.div`
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.LIGHT_GREY};
`;

export const LanguageBadge = styled.span`
    display: inline-block;
    color: ${Colors.BADGE_TEXT};
    background-color: ${Colors.BADGE_BACKGROUND};
    font-size: 11px;
    height: 22px;
    line-height: 24px;
    text-align: center;
    width: 22px;
    text-transform: uppercase;
    border-radius: 3px;
`;

export const Title = styled.span`
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    word-break: break-word;
    color: ${Colors.SECONDARY};
    margin-right: 10px;
`;

export const Date = styled.span`
    font-size: 13px;
    color: ${Colors.SECONDARY};
`;

export const DateWrapper = styled.span`
    display: inline-block;
`;

export const Domain = styled.a`
    font-size: 13px;
    color: ${Colors.SECONDARY};
    text-decoration: underline;
    display: inline-block;
    margin-left: 10px;
`;

export const Reach = styled.span`
    font-size: 13px;
    color: ${Colors.SECONDARY};

    span {
        color: ${Colors.PRIMARY};
    }
`;

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: ${(props) => (props.labeling ? 'calc(100% - 415px)' : '100%')};
    padding: 20px;
    background-color: ${Colors.WHITE};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 2px solid ${Colors.BLACK};
    margin-right: auto;
`;

export const LoaderContainer = styled.div`
    padding: 25px 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.labeling ? 'calc(100% - 415px)' : '100%')};
    margin-right: auto;
`;

export const Wrapper = styled.div`
    width: 100%;
    overflow-y: hidden;
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

const getBackgroundColor = ({ active, type }) => {
    if (!active) {
        return Colors.BADGE_BACKGROUND;
    }

    if (type === 'negative') {
        return Colors.PRIMARY;
    }

    if (type === 'positive') {
        return Colors.GREEN;
    }

    return Colors.PLACEHOLDER;
};

const getIconBorderColor = ({ active, type }) => {
    if (active) {
        return Colors.WHITE;
    }

    if (type === 'negative') {
        return Colors.PRIMARY;
    }

    if (type === 'positive') {
        return Colors.GREEN;
    }

    return Colors.PLACEHOLDER;
};

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
`;

export const Badge = styled.button`
    border: none;
    height: 32px;
    padding: 0 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 13px;
    background-color: ${(props) => getBackgroundColor(props)};
    color: ${(props) => (props.active ? Colors.WHITE : Colors.BADGE_TEXT)};

    &:before {
        content: '';
        position: relative;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 5px;
        background-color: ${(props) => (props.active ? 'transparent' : getIconBorderColor(props))};
        border: 1px solid ${(props) => getIconBorderColor(props)};
    }

    &:hover {
        cursor: pointer;
    }
`;

export const Label = styled.label`
    font-size: 15px;
    line-height: 1.33;
    display: block;
    color: ${Colors.BLACK};
    margin-bottom: 10px;
`;

export const Error = styled.span`
    font-size: 13px;
    line-height: 1.33;
    display: block;
    color: ${Colors.DANGER};
    margin-top: 5px;
`;

import React, { useEffect, useState } from 'react';
import { Container, Content, Title, Image, ContentTop, Message } from './styles';
import { ResetPasswordForm } from 'containers';
import { connect } from 'react-redux';
import { AuthActions } from 'core/actions';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { LOGO } from 'styles/constant/images';
import { useHistory } from 'react-router-dom';

function ResetPasswordPage(props) {
    const { resetPassword, isLoaded, isLoading, resetPassError, removeError } = props;
    const [token, setToken] = useState('');
    const [username, setUsername] = useState('');
    const history = useHistory();

    useEffect(() => {
        document.title = 'Debunk EU - Password assistance – Step 2';
    }, []);

    useEffect(() => {
        isLoaded && history.push('/auth/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    useEffect(() => {
        removeError();
    }, [removeError]);

    useEffect(() => {
        const arr = history.location.pathname.split('/');
        const tkn = arr[arr.length - 2];
        const name = arr.pop();

        setToken(tkn);
        setUsername(atob(name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setToken, setUsername]);

    const onSubmit = (request) => {
        resetPassword(token, request);
    };

    return (
        <Container onSubmit={(event) => event.preventDefault()}>
            <ContentTop>
                <Grid.Row justifyContent="center">
                    <Image src={LOGO} />
                </Grid.Row>
            </ContentTop>
            <Content>
                <Grid.Row mb={30}>
                    <Title>Create a new password</Title>
                </Grid.Row>
                {!!resetPassError && (
                    <Grid.Row mb={30} mt={-20}>
                        <Message error>{resetPassError}</Message>
                    </Grid.Row>
                )}
                <Grid.Row mb={20} mt={-20}>
                    <Message simple>
                        The password must be at least 8 characters long and should contain at least 1 number and 1
                        special character.
                    </Message>
                </Grid.Row>
                <ResetPasswordForm onSubmit={onSubmit} loading={isLoading} username={username} />
            </Content>
        </Container>
    );
}

ResetPasswordPage.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    resetPassError: PropTypes.string,
    removeError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { auth } = state;
    const { isLoaded, isLoading, resetPassError } = auth;
    return { isLoaded, isLoading, resetPassError };
}

const mapDispatchToProps = {
    resetPassword: AuthActions.resetPassword,
    removeError: AuthActions.removeError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

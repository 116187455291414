import React, { useEffect, useState } from 'react';
import { FiltersList } from 'containers';
import { Title, Container } from './styles';
import { Grid } from '../../styles/components';
import PropTypes from 'prop-types';
import { SharedTypes } from '../../utils';
import { FiltersActions } from '../../core/actions';
import { connect } from 'react-redux';
import { Loader } from '../../components';

function FiltersPage(props) {
    const { filters, isLoaded, isLoading, getFilters } = props;
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        document.title = 'Debunk EU - List of Filters';
        // Makes sure that new subset is fetched when navigating from other pages
        if (!fetched) {
            getFilters('filterTable');
            setFetched(true);
        }
    }, [getFilters, fetched]);

    return (
        <>
            <Container>
                <Grid.FixedContainer>
                    <Title>Filters</Title>
                    {isLoading && (
                        <Grid.Row mt={30} justifyContent="center">
                            <Loader />
                        </Grid.Row>
                    )}
                </Grid.FixedContainer>
                {isLoaded && fetched && <FiltersList filters={filters} updateFilters={() => {}} />}
            </Container>
        </>
    );
}

FiltersPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    filters: PropTypes.arrayOf(SharedTypes.FilterType),
    getFilters: PropTypes.func.isRequired,
    deleteFilter: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { filters } = state;
    const { isLoading, isLoaded } = filters;
    return { filters: filters.filters, isLoading, isLoaded };
}

const mapDispatchToProps = {
    getFilters: FiltersActions.getFilters,
    deleteFilter: FiltersActions.deleteFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tab, Title } from './styles';

function Tabs(props) {
    const { style, tabs, active, onSelect } = props;

    return (
        <Container style={style}>
            {tabs.map((tab) => (
                <Tab onClick={() => onSelect(tab.key)} active={tab.key === active} key={tab.key}>
                    <Title active={tab.key === active}>{tab.title}</Title>
                </Tab>
            ))}
        </Container>
    );
}

const TabType = PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(TabType).isRequired,
    onSelect: PropTypes.func.isRequired,
    active: PropTypes.string.isRequired,
    style: PropTypes.object,
};

Tabs.defaultProps = {
    style: {},
};

export default Tabs;

import React, { useState } from 'react';
import * as Styles from './styles';
import { AutoComplete, Button } from 'components';
import { Mapper, SharedTypes, TextUtils } from 'utils';
import { Grid } from 'styles/components';
import { Buttons, Images } from 'styles/constant';
import PropTypes from 'prop-types';
import { useAddParameter } from 'hooks';
import { ParametersService } from 'core/services';
import { ParameterTypesList } from 'constant';
import { Icon } from '../../components/SelecedLabelingOption/styles';

function ParameterA3AutoComplete(props) {
    const { parameter, onParameterValueAdd, selectedParameterValues, disabled } = props;
    const { addParameterValue, isLoading } = useAddParameter();
    const [localParameterValue, setLocalParameterValue] = useState({ label: null, value: null });

    const handleAdd = () => {
        localParameterValue.value ? dispatchValue(localParameterValue.value) : handleAddNewValue();
    };

    const handleAddNewValue = () => {
        const request = {
            parameter_id: parameter.id,
            value: TextUtils.trim(localParameterValue.label),
            type: ParameterTypesList.A3,
        };

        addParameterValue(request, (parameter) => {
            const parameterValue = ParametersService.getParameterValueByValue(parameter.id, request.value);
            dispatchValue(parameterValue.id);
        });
    };

    const dispatchValue = (id) => {
        onParameterValueAdd({ id, parameterId: parameter.id, type: ParameterTypesList.A3 });
        setLocalParameterValue({ label: null, value: null });
    };

    const handleChange = (event) => {
        const { value } = event;
        setLocalParameterValue(value);
    };

    const getParameterValuesOptions = () => {
        const selectedParameterValuesIds = selectedParameterValues.map((parameterValue) => parameterValue.id);
        const filteredValues = parameter.values.filter(
            (parameterValue) => !selectedParameterValuesIds.includes(parameterValue.id),
        );
        return Mapper.mapParameterValuesToOptions(filteredValues);
    };

    const isAlreadyExist = () => {
        return (
            !localParameterValue.value &&
            !!ParametersService.getParameterValueByValue(parameter.id, localParameterValue.label)
        );
    };

    const clearParameterInput = () => {
        setLocalParameterValue({ label: null, value: null });
    };

    return (
        <Styles.Container>
            <Grid.Row mb={10}>
                <AutoComplete
                    disabled={disabled}
                    value={localParameterValue}
                    label={parameter.name}
                    onChange={handleChange}
                    placeholder="Select or enter new"
                    name="Parameter values"
                    options={getParameterValuesOptions()}
                />
                {localParameterValue.label && (
                    <Grid.Col ml={-30} mt={28} style={{ zIndex: 1 }}>
                        <Icon onClick={clearParameterInput} src={Images.CLOSE_GREY} />
                    </Grid.Col>
                )}
            </Grid.Row>
            <Grid.Row>
                <Button
                    onClick={handleAdd}
                    loading={isLoading}
                    disabled={!localParameterValue.label || isAlreadyExist()}
                    type={Buttons.SECONDARY}
                    label="Add to list"
                />
            </Grid.Row>
        </Styles.Container>
    );
}

ParameterA3AutoComplete.propTypes = {
    disabled: PropTypes.bool,
    parameter: SharedTypes.ParameterType.isRequired,
    onParameterValueAdd: PropTypes.func.isRequired,
    selectedParameterValues: PropTypes.arrayOf(SharedTypes.LabelingParameterValueType),
};

ParameterA3AutoComplete.defaultProps = {
    selectedParameterValues: [],
    disabled: false,
};

export default ParameterA3AutoComplete;

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    position: relative;

    .tooltip {
        transition: 0s;
    }

    &:hover {
        .tooltip {
            height: auto;
            padding: 5px;
            opacity: 1;
            transition-delay: 0.5s;
        }
    }
`;

export const Value = styled.span`
    font-size: 13px;
    font-weight: 700;
    margin-right: 10px;
    color: ${Colors.PRIMARY};
`;

export const BarContainer = styled.div`
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    width: 100%;
    background-color: ${Colors.BACKGROUND};
`;

export const Bar = styled.div`
    width: ${(props) => props.value}%;
    height: 100%;
    background-color: ${(props) => props.color};
`;

export const Tooltip = styled.div`
    position: absolute;
    left: 0;
    padding: 0;
    bottom: calc(100% + 20px);
    width: 330px;
    opacity: 0;
    border-radius: 3px;
    background-color: ${Colors.TOOLTIP};
    overflow: hidden;
    height: 0;
`;

export const Icon = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${(props) => props.color};
`;

export const Label = styled.span`
    font-size: 12px;
    color: ${Colors.WHITE};
    line-height: 18px;
`;

export const Bold = styled(Label)`
    font-weight: 700;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, ButtonContainer, DropdownContainer } from './styles';
import { SharedTypes } from 'utils';
import autoBind from 'auto-bind';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.dropdownElementRef = React.createRef();

        this.state = {
            active: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        const dropdownItemsElements = document.getElementsByClassName('dropdown-item');
        for (let dropdownItemElement of dropdownItemsElements) {
            dropdownItemElement.addEventListener('mouseup', this.handleDropdownItemClick);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);

        const dropdownItemsElements = document.getElementsByClassName('dropdown-item');
        for (let dropdownItemElement of dropdownItemsElements) {
            dropdownItemElement.removeEventListener('mouseup', this.handleDropdownItemClick);
        }
    }

    handleDropdownItemClick(event) {
        const { active } = this.state;
        if (this.dropdownElementRef && this.dropdownElementRef.current.contains(event.target) && active) {
            this.close();
        }
    }

    handleClickOutside(event) {
        const { active } = this.state;
        if (this.dropdownElementRef && !this.dropdownElementRef.current.contains(event.target) && active) {
            this.close();
        }
    }

    handleButtonClick() {
        const { disabled, onOpen, openOnClick, onButtonClick } = this.props;
        const { active } = this.state;
        const { current } = this.dropdownElementRef;
        const input = current.querySelectorAll('.autofocus')[0];

        window.setTimeout(function () {
            input && input.focus();
        }, 0);

        if (!disabled && openOnClick) {
            onOpen();
            this.setState({ active: !active });
        }
        onButtonClick && onButtonClick(!active);
    }

    close() {
        const { onClose, onButtonClick } = this.props;
        this.setState({ active: false });
        onClose();
        onButtonClick && onButtonClick(false);
    }

    open() {
        const { onOpen } = this.props;
        this.setState({ active: true });
        onOpen();
    }

    render() {
        const { renderButton, renderDropdown, width, left, right, top, disabled, forceOpen, large, error } = this.props;
        const { active } = this.state;

        return (
            <Container ref={this.dropdownElementRef} error={error}>
                <ButtonContainer disabled={disabled} active={active || forceOpen} onClick={this.handleButtonClick}>
                    {renderButton()}
                </ButtonContainer>
                <DropdownContainer
                    active={forceOpen || active}
                    top={top}
                    right={right}
                    left={left}
                    width={width}
                    large={large}
                    forceOpen={forceOpen}
                >
                    {renderDropdown()}
                </DropdownContainer>
            </Container>
        );
    }
}

Dropdown.propTypes = {
    renderButton: PropTypes.func.isRequired,
    renderDropdown: PropTypes.func.isRequired,
    openOnClick: PropTypes.bool,
    disabled: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    top: PropTypes.bool,
    forceOpen: PropTypes.bool,
    large: PropTypes.bool,
    width: SharedTypes.NumberOrString,
    left: SharedTypes.NumberOrString,
    right: SharedTypes.NumberOrString,
    error: PropTypes.bool,
    onButtonClick: PropTypes.func,
};

Dropdown.defaultProps = {
    onClose: () => {},
    onOpen: () => {},
    disabled: false,
    openOnClick: true,
    width: 'auto',
    left: 0,
    right: 0,
    top: false,
    forceOpen: false,
    large: false,
};

export default Dropdown;

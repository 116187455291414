import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Colors } from 'styles/constant';

function Loader(props) {
    const { style, loading, size, color } = props;
    return <Container color={color} size={size} isLoading={loading} style={style} />;
}

Loader.propTypes = {
    style: PropTypes.object,
    size: PropTypes.number,
    loading: PropTypes.bool,
    color: PropTypes.string,
};

Loader.defaultProps = {
    style: {},
    size: 20,
    color: Colors.LIGHT_GREY,
    loading: true,
};

export default Loader;

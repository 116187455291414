import update from 'immutability-helper';
import { DomainsTypes, AuthTypes } from 'core/types';

const initialState = {
    domains: [],
    userDomains: [],
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case DomainsTypes.GET_DOMAINS_START: {
            return update(state, {
                userDomains: { $set: [] },
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case DomainsTypes.GET_DOMAINS: {
            return update(state, {
                isLoading: { $set: false },
                domains: { $set: payload.domains },
                isLoaded: { $set: true },
            });
        }

        case DomainsTypes.GET_USER_DOMAINS: {
            return update(state, {
                userDomains: { $set: payload.domains },
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        case DomainsTypes.UPDATE_DOMAINS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case DomainsTypes.UPDATE_DOMAINS: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case DomainsTypes.RESET_UPDATE_DOMAINS: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case DomainsTypes.UPDATE_DOMAINS_ERROR:
        case DomainsTypes.GET_DOMAINS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import update from 'immutability-helper';
import { ParametersTypes, AuthTypes, ParameterTypes } from 'core/types';

const initialState = {
    parameters: [],
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ParametersTypes.GET_PARAMETERS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case ParametersTypes.GET_PARAMETERS: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                parameters: { $set: payload.parameters },
            });
        }

        case ParameterTypes.ADD_PARAMETER: {
            const newParameters = state.parameters;
            const parameterIndex = newParameters.findIndex((parameter) => parameter.id === payload.parameter.id);
            parameterIndex === -1
                ? newParameters.push(payload.parameter)
                : (newParameters[parameterIndex] = payload.parameter);
            return update(state, {
                parameters: { $set: newParameters },
            });
        }

        case ParametersTypes.GET_PARAMETERS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

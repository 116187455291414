import styled, { css } from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    position: relative;
    ${(props) =>
        props.gray &&
        css`
            text-decoration: none;
            height: ${(props) => (props.small ? 20 : 28)}px;
            padding: 8px;
            border-radius: 5px;
            background-color: ${Colors.BADGE_BACKGROUND};
            transition: 0.3s;
            font-size: 12px;
            white-space: nowrap;
            width: ${(props) => (props.small ? 20 : 28)}px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;

            img {
                transform: ${(props) => (props.iconSmall ? 'scale(0.6)' : 'scale(0.7)')};
                width: ${(props) => (props.small ? 18 : 24)}px;
            }

            &:hover {
                background-color: ${Colors.BACKGROUND};
            }
        `}

    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    &:hover {
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};

        .tooltip {
            display: block;
        }
    }
`;

export const Image = styled.img`
    display: block;
    filter: ${(props) =>
        props.active
            ? 'invert(6%) sepia(91%) saturate(7469%) hue-rotate(353deg) brightness(146%) contrast(103%)'
            : 'none'};
`;

export const Label = styled.span`
    font-weight: 500;
    font-size: 12px;
    color: ${Colors.WHITE};
    display: block;
    white-space: nowrap;
    background-color: ${Colors.TOOLTIP};
`;

export const Tooltip = styled.div`
    position: absolute;
    left: ${(props) => (props.left ? 0 : 'auto')};
    right: ${(props) => (props.left ? 'auto' : 0)};
    padding: 5px;
    bottom: calc(100% + 3px);
    width: auto;
    display: none;
    border-radius: 3px;
    background-color: ${Colors.TOOLTIP};
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 700;
    color: ${Colors.BLACK};
`;

export const Page = styled.button`
    height: 34px;
    border: 1px solid ${(props) => (props.active ? Colors.BLACK : Colors.BACKGROUND)};
    background-color: ${Colors.WHITE};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 10px;
    box-shadow: 0 0 4px 0 ${hexToRgba(Colors.BLACK, 0.1)};
    opacity: ${(props) => (props.disabled ? 0.8 : 1)};

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
        border: 1px solid ${(props) => (props.disabled && !props.active ? Colors.BACKGROUND : Colors.BLACK)};
    }
`;

export const Icon = styled.img``;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import charts from 'containers/ChartsContainer/charts';
import { Canvas } from '../ChartsContainer/ChartMain/styles';
import { Parent, Item } from '../../pages/Charts/styles';
import { Grid } from 'styles/components';

function DynamicsChart(props) {
    const { chartData } = props;
    const { labels, data } = chartData;
    const id = 'dynamics';
    const width = 420;

    useEffect(() => {
        if (labels) {
            charts.resetAll();
            charts.createSimpleDynamics(labels, data, width);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    return (
        <>
            <Grid.Row mb={-10} mt={-10} justifyContent="center">
                <Item>Number of publications</Item>
            </Grid.Row>
            <Grid.Row mb={20}>
                <Parent id={`parent-${id}`} width={width} height={width * 0.6}>
                    <Canvas id={id} />
                </Parent>
            </Grid.Row>
        </>
    );
}

DynamicsChart.propTypes = {
    chartData: PropTypes.object,
};
export default DynamicsChart;

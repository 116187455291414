import styled from 'styled-components/macro';
import { Grid } from 'styles/components';
import { Colors } from 'styles/constant';

export const LanguageRowLabel = styled.p`
    align-self: center;
    margin: 30px 25px 0 0;
`;

export const QuestionMarkCol = styled(Grid.Col)`
    width: 17px;
    margin-left: 5px;
    svg {
        fill: ${Colors.DANGER};
    }
`;

import React, { useEffect, useState } from 'react';
import { CategoriesList } from 'containers';
import { Container } from './styles';
import PropTypes from 'prop-types';
import { SharedTypes } from '../../utils';
import { CategoriesActions } from '../../core/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LIST } from '../../containers/CategoriesList/config';
import { Grid } from '../../styles/components';
import Loader from '../../components/Loader';

function CategoriesPage(props) {
    const { userFilters, getUserFilters, users, isLoading, isLoaded } = props;
    const [user, setUser] = useState(false);
    const history = useHistory();
    const filterList = LIST(userFilters);

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        getUserFilters(id);
        setUser(users.find((u) => u.id == id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserFilters, setUser]);

    useEffect(() => {
        if (user.id) document.title = `Debunk EU - Assign User Filters - ${user.username}`;
    }, [user]);

    return (
        <>
            <Container>
                {isLoading && (
                    <Grid.Row pt={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}
                {isLoaded && (
                    <CategoriesList history={history} userFilters={userFilters} filterList={filterList} user={user} />
                )}
            </Container>
        </>
    );
}

CategoriesPage.propTypes = {
    userFilters: PropTypes.arrayOf(SharedTypes.CategoryType),
    users: PropTypes.arrayOf(SharedTypes.UsersListItemType),
    getUserFilters: PropTypes.func,
    updateCategories: PropTypes.func,
    isLoaded: PropTypes.bool,
    isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
    const { categories, users } = state;
    const { isLoading, isLoaded } = categories;
    return { userFilters: categories.userCategories, users: users.users, isLoaded, isLoading };
}

const mapDispatchToProps = {
    getUserFilters: CategoriesActions.getUserFilters,
    updateCategories: CategoriesActions.updateCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);

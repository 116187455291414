import { UrlUtils } from 'utils';
import { useFilter } from 'hooks';

const getSort = (search) => {
    const params = UrlUtils.parseSearchWithIncludes(search, ['sort']);
    return params.sort;
};

const getOrder = (search) => {
    const params = UrlUtils.parseSearchWithIncludes(search, ['order']);
    return params.order;
};

const getDefaultSort = (labeling, labeling_status) => {
    const defaultSort = { sort: 'infometer', order: 'desc' };
    if (labeling && !labeling_status) defaultSort.sort = 'date';
    else if (labeling && labeling_status && labeling_status !== 'Not labeled') defaultSort.sort = 'labeling';
    return defaultSort;
};

const getValidSort = (location) => {
    const sort = getSort(location.search);
    const order = getOrder(location.search);
    return isSortValid(sort, order, location) ? { sort, order } : { sort: 'infometer', order: 'desc' };
};

const isSortValid = (sort, order, location) => {
    let validSort = ['infometer', 'date', 'crawldate', 'labeling'];
    const validOrder = ['asc', 'desc'];
    const params = UrlUtils.parseSearchWithIncludes(location.search, ['labeling_status']);
    const { labeling_status } = params;
    if (location.pathname.includes('labelling') && (!labeling_status || labeling_status === 'Not labeled'))
        validSort = validSort.filter((item) => item !== 'labeling');
    return validSort.includes(sort) && validOrder.includes(order);
};

function useSorting() {
    // const history = useHistory();
    // const location = useLocation();
    const { updateFilter } = useFilter();

    // useEffect(() => {
    //   const sort = getSort(location.search);
    //   const order = getOrder(location.search);
    //
    //   if (!isSortValid(sort, order, location)) {
    //     const search = UrlUtils.setSearchParams(getDefaultSort(location));
    //     const filter = UrlUtils.parseSearch(search);
    //     updateFilter(filter);
    //   }
    // }, [location.pathname]);

    const updateSort = (sort, order) => {
        const search = UrlUtils.setSearchParams({ sort, order });
        const filter = UrlUtils.parseSearch(search);
        updateFilter(filter);
    };

    return {
        updateSort,
        getValidSort,
        getDefaultSort,
    };
}

export default useSorting;

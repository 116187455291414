import { useDispatch, useSelector } from 'react-redux';
import { ParameterActions } from 'core/actions';

function useAddParameter() {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.parameter);

    const addParameterValue = (request, callback) => {
        dispatch(ParameterActions.addParameter(request, callback));
    };

    const addParameter = (request, callback) => {
        dispatch(ParameterActions.addParameter(request, callback));
    };

    return { isLoading, addParameterValue, addParameter };
}

export default useAddParameter;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Content = styled.div``;

export const Description = styled.p`
    font-size: 13px;
    color: ${Colors.SECONDARY};
    line-height: 18px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Side = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

import { toast } from 'react-toastify';

export const showGenericError = (error = 'An error has occurred') => {
    toast.error(error);
};

export const showError = (message) => {
    toast.error(message);
};

export const showSuccess = (message) => {
    toast.success(message);
};

import React, { useEffect, useState } from 'react';
import { Container, Pause, Text } from './styles';
import { useTimer } from 'hooks';
import { TimeUtils } from 'utils';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components';

const LabelingTimer = (props) => {
    const { isArticleLoaded, setLabelingTime, buttonDisabled } = props;
    const [timerModal, setTimerModal] = useState(false);
    const { timer, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0);

    useEffect(() => {
        timer > 0 && setLabelingTime({ name: 'time_spent', value: timer });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer]);

    useEffect(() => {
        timer > 0 && !isPaused && setTimerModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaused]);

    useEffect(() => {
        handleReset();
        !!isArticleLoaded && !buttonDisabled && handleStart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArticleLoaded]);

    const renderContent = () => {
        return <Text>Labelling time tracking is paused. Close this dialog to continue your work.</Text>;
    };

    const renderFooter = () => {
        return (
            <Button
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                    setTimerModal(false);
                    handleResume();
                }}
                label="Close"
                type="PRIMARY"
            />
        );
    };

    return (
        <>
            <Modal
                onClose={() => {
                    setTimerModal(false);
                    handleResume();
                }}
                renderContent={renderContent}
                renderFooter={renderFooter}
                visible={timerModal}
                title="Labelling is paused"
            />
            <Container>
                {TimeUtils.formatTime(timer)}
                <Pause
                    title="Pause labelling time tracking"
                    isPaused={isPaused}
                    onClick={isPaused ? handlePause : handleResume}
                />
            </Container>
        </>
    );
};

LabelingTimer.propTypes = {
    buttonDisabled: PropTypes.bool.isRequired,
    isArticleLoaded: PropTypes.bool.isRequired,
    setLabelingTime: PropTypes.func.isRequired,
};
export default LabelingTimer;

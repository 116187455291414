import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.p`
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
`;

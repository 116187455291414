import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Images } from 'styles/constant';
import { Container, Action, Icon, TranslateButton } from './styles';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { TranslateUtils, readTranslateCookie } from 'utils';
import { ToastService } from 'core/services';
import Tooltip from 'react-tooltip';

const Actions = (props) => {
    const [translateButtonClicked, setTranslateButtonClicked] = useState(!!readTranslateCookie());
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { className, zoomIn, zoomOut, fontSize } = props;

    const handleZoomIn = () => {
        if (fontSize === 30) return;
        zoomIn(fontSize + 1);
    };

    const handleZoomOut = () => {
        if (fontSize === 1) return;
        zoomOut(fontSize - 1);
    };

    const copyArticleUrl = () => {
        const url = `${window.location.origin}/search?id=${params.get('id')}&parentId=${params.get(
            'parentId',
        )}&project_id=${params.get('project_id')}`;

        navigator.clipboard.writeText(url);
        ToastService.showSuccess('The link is copied to clipboard');
    };

    return (
        <Container className={className}>
            <Grid.Row justifyContent="flex-end">
                <Grid.Col mr={10}>
                    <Action onClick={copyArticleUrl} data-tip data-for="copyArticleUrl">
                        <Icon src={Images.SHARE_DOTS} />
                    </Action>
                    <Tooltip id="copyArticleUrl" place="top" effect="solid">
                        Share link
                    </Tooltip>
                </Grid.Col>
                <Grid.Col mr={10} id="trans-button">
                    <Grid.Row>
                        <TranslateButton
                            onClick={() => {
                                const isClicked = TranslateUtils.handleTranslateContainer();
                                setTranslateButtonClicked(isClicked);
                            }}
                            clicked={translateButtonClicked}
                        >
                            <Icon src={Images.TRANSLATION} />
                        </TranslateButton>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col mr={10}>
                    <Action onClick={handleZoomIn}>
                        <Icon src={Images.ZOOM_IN_TEXT} />
                    </Action>
                </Grid.Col>
                <Grid.Col>
                    <Action onClick={handleZoomOut}>
                        <Icon src={Images.ZOOM_OUT_TEXT} />
                    </Action>
                </Grid.Col>
            </Grid.Row>
        </Container>
    );
};

Actions.propTypes = {
    className: PropTypes.string,
    zoomIn: PropTypes.func.isRequired,
    fontSize: PropTypes.number,
    zoomOut: PropTypes.func.isRequired,
};

Actions.defaultProps = {
    className: null,
    fontSize: 16,
};

export default Actions;

export const GET_RESERVED_ARTICLES_COUNT_START = 'GET_RESERVED_ARTICLES_COUNT_START';
export const GET_RESERVED_ARTICLES_COUNT = 'GET_RESERVED_ARTICLES_COUNT';
export const GET_RESERVED_ARTICLES_COUNT_ERROR = 'GET_RESERVED_ARTICLES_COUNT_ERROR';

export const GET_WAITING_ARTICLES_COUNT_START = 'GET_WAITING_ARTICLES_COUNT_START';
export const GET_WAITING_ARTICLES_COUNT = 'GET_WAITING_ARTICLES_COUNT';
export const GET_WAITING_ARTICLES_COUNT_ERROR = 'GET_WAITING_ARTICLES_COUNT_ERROR';

export const GET_LABELED_ARTICLES_COUNT_START = 'GET_LABELED_ARTICLES_COUNT_START';
export const GET_LABELED_ARTICLES_COUNT = 'GET_LABELED_ARTICLES_COUNT';
export const GET_LABELED_ARTICLES_COUNT_ERROR = 'GET_LABELED_ARTICLES_COUNT_ERROR';

export const GET_SKIPPED_ARTICLES_COUNT_START = 'GET_SKIPPED_ARTICLES_COUNT_START';
export const GET_SKIPPED_ARTICLES_COUNT = 'GET_SKIPPED_ARTICLES_COUNT';
export const GET_SKIPPED_ARTICLES_COUNT_ERROR = 'GET_SKIPPED_ARTICLES_COUNT_ERROR';

import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import * as Styles from './styles';
import { Infometer, LabelingState, Timer } from 'features/articles/components';
import { Mapper, SharedTypes } from 'features/articles/utils';
import { Badge, Checkbox, Icon } from 'components';
import { useArticlesListItem } from 'features/articles/hooks';
import ArticleHideModal from '../ArticleHideModal';
import { LabelingStates, RTLLanguages } from 'constant';
import VotesCount from 'features/articles/components/VotesCount';
import { ArticleUtils } from 'utils';

function ArticlesListItem(props) {
    const {
        article,
        onSimilarClick,
        similar,
        labeling,
        selected,
        onSelect,
        reserved,
        showExpireModal,
        disabledSimilar,
        similarParams,
        filter,
    } = props;
    const [hideModalVisible, setHideModalVisible] = useState(false);
    const { openArticle, voteArticle, isArticleOpen, isLoading, bookmarkArticle, isBookmarking, markExpired } =
        useArticlesListItem({ article, similar });
    const shouldDisable = article.expired || article.labelingState === LabelingStates.SUCCESS;
    const disabled = shouldDisable && reserved && labeling;
    const userVote = Mapper.getUserVote(article.votes);
    const [isSimilarLoaded, setIsSimilarLoaded] = useState(false);
    const { filters } = useSelector((state) => state.filters);

    useEffect(() => {
        [...document.querySelectorAll('.articleTitle')].map((item) => {
            item.setAttribute('translate', 'yes');
        });
    }, []);

    useEffect(() => {
        if (
            article &&
            similarParams &&
            (similarParams.id !== article.id || similarParams.parentId !== article.parentId)
        )
            setIsSimilarLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [similarParams]);

    useEffect(() => {
        setIsSimilarLoaded(false);
        onSimilarClick(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const handleUpVoteClick = () => {
        voteArticle(userVote === 1 ? 0 : 1);
    };

    const handleDownVoteClick = () => {
        voteArticle(userVote === -1 ? 0 : -1);
    };

    const renderReservationTime = () =>
        article.reservation &&
        article.reservation.time_left && (
            <Timer
                onExpire={markExpired}
                showExpireModal={showExpireModal}
                timeLeft={article.reservation.time_left - 60}
            />
        );

    const renderReservationState = () =>
        article.labelingState !== LabelingStates.ACTIVE && (
            <Grid.Col ml={15}>
                <LabelingState state={article.labelingState} />
            </Grid.Col>
        );

    const handleClick = () => {
        if (disabled) return;
        openArticle();
    };

    const handleSimilarClick = () => {
        if (isSimilarLoaded) {
            setIsSimilarLoaded(false);
            onSimilarClick(true);
        } else {
            setIsSimilarLoaded(true);
            onSimilarClick();
        }
    };

    const detailedFilters = article.filters.map((filter) => {
        const detailedFilter = filters.find((f) => f.id === filter.id);
        return detailedFilter ? { ...detailedFilter, language: filter.lang } : filter;
    });

    return (
        <Styles.Container disabled={disabled || disabledSimilar} active={isArticleOpen} onClick={handleClick}>
            <Styles.Content alignItems="flex-start">
                <Grid.Col flex={1}>
                    <Grid.Row mb={5} style={{ display: 'block' }}>
                        {labeling && (
                            <Checkbox
                                style={{ float: 'left', marginRight: 10 }}
                                onChange={onSelect}
                                checked={selected}
                                name="article"
                            />
                        )}
                        <Styles.Title
                            className="articleTitle"
                            lang={article.locale}
                            dir={RTLLanguages.includes(article.locale) ? 'rtl' : 'ltr'}
                        >
                            {article.title.slice(0, 100) + (article.title.length > 100 ? '...' : '')}
                        </Styles.Title>
                        <Styles.DateWrapper>
                            <Styles.Date>{article.publishDate}</Styles.Date>
                            <Styles.Domain href={article.url} target="_blank" rel="noopener noreferrer">
                                {article.domain}
                            </Styles.Domain>
                        </Styles.DateWrapper>
                    </Grid.Row>
                    <Grid.Row mt={10}>
                        <Grid.Col>
                            <Styles.LanguageBadge>{article.locale}</Styles.LanguageBadge>
                        </Grid.Col>
                        <Grid.Col ml={10} mr={10}>
                            <Styles.Reach>
                                DebunkReach®:{' '}
                                <span>
                                    {article.debunkReachAccumulated
                                        ? article.debunkReachAccumulated.toLocaleString().replaceAll(',', ' ')
                                        : 'not available'}
                                </span>
                            </Styles.Reach>
                        </Grid.Col>
                        <Grid.Col style={{ marginLeft: 'auto' }}>
                            <Grid.Row>
                                {article.filters && article.filters.length > 0 && (
                                    <Grid.Col style={{ position: 'relative' }} data-tip data-for={article.id}>
                                        <Badge
                                            label={`${uniq(article.filters.map((x) => x.id)).length} filter${
                                                article.filters.length === 1 ? '' : 's'
                                            }`}
                                            onClick={() => {}}
                                        />
                                        <Tooltip className="tooltip" id={article.id}>
                                            {ArticleUtils.addLanguagesToDuplicateFilters(detailedFilters).map(
                                                (filter, i) => (
                                                    <p key={i}>{filter.name}</p>
                                                ),
                                            )}
                                        </Tooltip>
                                    </Grid.Col>
                                )}
                                {article.similarCount > 0 && (
                                    <Grid.Col ml={10}>
                                        <Badge onClick={handleSimilarClick} label={`${article.similarCount} similar`} />
                                    </Grid.Col>
                                )}
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Styles.Content>
            <Styles.Footer>
                <Grid.Row>
                    <Grid.Col flex={1}>{article.infometer && <Infometer infometer={article.infometer} />}</Grid.Col>
                    {labeling && reserved && renderReservationTime()}
                    {labeling && reserved && renderReservationState()}
                </Grid.Row>

                <Grid.Row
                    mt={5}
                    justifyContent="space-between"
                    style={{ transform: 'scale(.9)', width: 'calc(110% + 10px)' }}
                >
                    <Grid.Col>
                        <Grid.Row>
                            <Grid.Col>
                                <Icon
                                    left
                                    style={{ opacity: 0.7 }}
                                    disabled={isBookmarking}
                                    active={article.isBookmarked}
                                    title="Bookmark"
                                    onClick={bookmarkArticle}
                                    source={Images.BOOKMARK}
                                />
                            </Grid.Col>
                            <Grid.Col ml={30}>
                                <Icon
                                    style={{ opacity: 0.7 }}
                                    disabled={isLoading}
                                    active={article.isHiddenForMe || article.isHiddenForAll}
                                    title="Hide"
                                    onClick={() => setHideModalVisible(true)}
                                    source={Images.VISIBILITY}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col ml={30}>
                        <Grid.Row>
                            <Icon
                                disabled={isLoading}
                                active={userVote === 1}
                                title="Up vote"
                                onClick={handleUpVoteClick}
                                source={Images.ARROW_UP}
                            />
                            <VotesCount votes={article.votes} />
                            <Icon
                                disabled={isLoading}
                                active={userVote === -1}
                                title="Down vote"
                                onClick={handleDownVoteClick}
                                source={Images.ARROW_DOWN}
                            />
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </Styles.Footer>
            <ArticleHideModal
                similar={similar}
                visible={hideModalVisible}
                article={article}
                onClose={() => setHideModalVisible(false)}
            />
        </Styles.Container>
    );
}

ArticlesListItem.propTypes = {
    article: SharedTypes.ArticleType.isRequired,
    showExpireModal: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    similar: PropTypes.bool,
    selected: PropTypes.bool,
    labeling: PropTypes.bool,
    reserved: PropTypes.bool,
    disabledSimilar: PropTypes.bool,
    onSimilarClick: PropTypes.func,
    similarParams: PropTypes.object,
    filter: PropTypes.object,
};

ArticlesListItem.defaultProps = {
    similar: false,
    labeling: false,
    reserved: false,
    selected: false,
    disabledSimilar: false,
    reservedTill: null,
    onSelect: () => {},
    onSimilarClick: () => {},
};

export default ArticlesListItem;

import { OrganisationsTypes } from 'core/types';
import { OrganisationsService, ToastService } from 'core/services';

export function getOrganisations() {
    return async (dispatch) => {
        try {
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS_START });
            const organisations = await OrganisationsService.getOrganisations();
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS, payload: { organisations } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS_ERROR });
        }
    };
}

export function createOrganisation(organisation) {
    return async (dispatch) => {
        try {
            dispatch({ type: OrganisationsTypes.UPDATE_ORGANISATIONS_START });
            await OrganisationsService.createOrganisation(organisation);
            dispatch({ type: OrganisationsTypes.CREATE_ORGANISATION });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS_ERROR });
        }
    };
}

export function updateOrganisation(id, organisation) {
    return async (dispatch) => {
        try {
            dispatch({ type: OrganisationsTypes.UPDATE_ORGANISATIONS_START });
            await OrganisationsService.updateOrganisation(id, organisation);
            dispatch({ type: OrganisationsTypes.CREATE_ORGANISATION });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS_ERROR });
        }
    };
}

export function deleteOrganisation(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: OrganisationsTypes.UPDATE_ORGANISATIONS_START });
            await OrganisationsService.deleteOrganisation(id);
            dispatch({ type: OrganisationsTypes.REMOVE_ORGANISATION });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: OrganisationsTypes.GET_ORGANISATIONS_ERROR });
        }
    };
}

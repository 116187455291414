import { CategoriesTypes } from 'core/types';
import { CategoriesService, ToastService } from 'core/services';

export function getCategories() {
    return async (dispatch) => {
        try {
            dispatch({ type: CategoriesTypes.GET_CATEGORIES_START });
            const categories = await CategoriesService.getCategories();
            dispatch({ type: CategoriesTypes.GET_CATEGORIES, payload: { categories } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: CategoriesTypes.GET_CATEGORIES_ERROR });
        }
    };
}

export function updateCategories(id, request) {
    return async (dispatch) => {
        try {
            dispatch({ type: CategoriesTypes.UPDATE_CATEGORIES_START });
            await CategoriesService.updateCategories(id, request);
            dispatch({ type: CategoriesTypes.UPDATE_CATEGORIES });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: CategoriesTypes.UPDATE_CATEGORIES_ERROR });
        }
    };
}

export function getUserFilters(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: CategoriesTypes.GET_CATEGORIES_START });
            const categories = await CategoriesService.getUserFilters(id);
            dispatch({ type: CategoriesTypes.GET_USER_CATEGORIES, payload: { categories } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: CategoriesTypes.GET_CATEGORIES_ERROR });
        }
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image, Tooltip, Label } from './styles';

function Icon(props) {
    const { source, onClick, title, className, active, disabled, gray, small, style, left, iconSmall } = props;

    const handleClick = (event) => {
        event.stopPropagation();
        if (disabled) return;
        onClick();
    };

    return (
        <Container
            small={small}
            disabled={disabled}
            className={className}
            onClick={handleClick}
            gray={gray}
            iconSmall={iconSmall}
        >
            <Image active={active} src={source} style={style} disabled={disabled} />
            {title && (
                <Tooltip className="tooltip" left={left}>
                    <Label>{title}</Label>
                </Tooltip>
            )}
        </Container>
    );
}

Icon.propTypes = {
    left: PropTypes.bool,
    active: PropTypes.bool,
    small: PropTypes.bool,
    source: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    gray: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    iconSmall: PropTypes.bool,
};

Icon.defaultProps = {
    title: null,
    left: false,
    active: false,
    small: false,
    disabled: false,
    gray: false,
    className: null,
    style: {},
    onClick: () => {},
};

export default Icon;

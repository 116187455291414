import httpClient from '../httpClient';
import axios from 'axios';

const baseUrl = '/emails';

export const getEmails = async () => {
    return httpClient.get(baseUrl + '?subset=emailTable');
};

export const getEmail = async (id) => {
    return httpClient.get(`${baseUrl}/${id}`);
};

export const createEmail = (email) => {
    return httpClient.post(baseUrl, email);
};

export const updateEmail = (id, email) => {
    return httpClient.put(`${baseUrl}/${id}`, email);
};

export const deleteEmail = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const convertEmailHash = (hash) => {
    return axios.get(process.env.REACT_APP_BASE_URL + `/email/campaign/${hash}`);
};

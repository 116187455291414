import { SearchTypes } from 'core/types';
import { DateUtils } from 'utils';
import { RelativeDates } from 'constant';

export function setSearch(search) {
    const newSearch = { ...search };
    const excludes = ['rows', 'value', 'columns', 'offset'];

    for (let param of excludes) {
        if (newSearch[param]) delete newSearch[param];
    }

    const hasRelativeDate = !!DateUtils.getRelativeDate(newSearch.date);
    const hasDateRange = !!DateUtils.getDate(newSearch.from) && !!DateUtils.getDate(newSearch.to);

    if (!hasRelativeDate && !hasDateRange) {
        newSearch.date = RelativeDates.TODAY;
    }

    return {
        type: SearchTypes.SET_SEARCH,
        payload: { search: newSearch },
    };
}

export const GET_SIMILAR_ARTICLES_START = 'GET_SIMILAR_ARTICLES_START';
export const GET_SIMILAR_ARTICLES = 'GET_SIMILAR_ARTICLES';
export const GET_SIMILAR_ARTICLES_ERROR = 'GET_SIMILAR_ARTICLES_ERROR';

export const SIMILAR_ARTICLE_VOTE_START = 'SIMILAR_ARTICLE_VOTE_START';
export const SIMILAR_ARTICLE_VOTE = 'SIMILAR_ARTICLE_VOTE';
export const SIMILAR_ARTICLE_VOTE_ERROR = 'SIMILAR_ARTICLE_VOTE_ERROR';

export const SIMILAR_ARTICLE_HIDE_FOR_ME_START = 'SIMILAR_ARTICLE_HIDE_FOR_ME_START';
export const SIMILAR_ARTICLE_HIDE_FOR_ME = 'SIMILAR_ARTICLE_HIDE_FOR_ME';
export const SIMILAR_ARTICLE_HIDE_FOR_ME_ERROR = 'SIMILAR_ARTICLE_HIDE_FOR_ME_ERROR';

export const SIMILAR_ARTICLE_HIDE_FOR_ALL_START = 'SIMILAR_ARTICLE_HIDE_FOR_ALL_START';
export const SIMILAR_ARTICLE_HIDE_FOR_ALL = 'SIMILAR_ARTICLE_HIDE_FOR_ALL';
export const SIMILAR_ARTICLE_HIDE_FOR_ALL_ERROR = 'SIMILAR_ARTICLE_HIDE_FOR_ALL_ERROR';

export const SIMILAR_ARTICLE_BOOKMARK_START = 'SIMILAR_ARTICLE_BOOKMARK_START';
export const SIMILAR_ARTICLE_BOOKMARK = 'SIMILAR_ARTICLE_BOOKMARK';
export const SIMILAR_ARTICLE_BOOKMARK_ERROR = 'SIMILAR_ARTICLE_BOOKMARK_ERROR';

export const REMOVE_SIMILAR_ARTICLE = 'REMOVE_SIMILAR_ARTICLE';

import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Icon, Loader } from 'components';
import { Colors, Images } from 'styles/constant';
import { LabelingStates } from 'constant';

function LabelingState(props) {
    const { state } = props;

    return (
        <Styles.Container>
            {state === LabelingStates.PENDING && <Loader color={Colors.WHITE} size={18} />}
            {state !== LabelingStates.PENDING && (
                <Icon source={state === LabelingStates.FAIL ? Images.DANGER : Images.SUCCESS} />
            )}
        </Styles.Container>
    );
}

LabelingState.propTypes = {
    state: PropTypes.string.isRequired,
};

export default LabelingState;

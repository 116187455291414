import { useDispatch, useSelector } from 'react-redux';
import { ArticleActions } from 'features/article/actions';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UrlUtils } from 'utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

const getArticleParams = (search) => {
    return UrlUtils.parseSearchWithIncludes(search, ['id', 'parentId']);
};

function useArticle() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [articleParams, setArticleParams] = useState(getArticleParams(location.search));
    const { article, isLoading, isLoaded } = useSelector((state) => state.article);

    useDeepCompareEffect(() => {
        if (articleParams.id && articleParams.parentId) {
            dispatch(ArticleActions.getArticle(articleParams.id, articleParams.parentId));
        } else {
            dispatch(ArticleActions.resetArticle());
        }
    }, [articleParams, dispatch]);

    useEffect(() => {
        setArticleParams(getArticleParams(location.search));
    }, [location.search]);

    return {
        article,
        isLoading,
        isLoaded,
    };
}

export default useArticle;

export const LOGIN_START = 'LOGIN_START';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const LOGOUT = 'LOGOUT';

export const SET_USERNAME = 'SET_USERNAME';

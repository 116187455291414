import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${Colors.WHITE};
    max-width: 420px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 15%);
    border-radius: 8px;
`;

export const Content = styled.div`
    width: 100%;
    padding: 25px;
    padding: 20px 0;
    width: 295px;

    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    button span {
        font-weight: 400;
    }
`;

export const Title = styled.h2`
    color: ${Colors.BLACK};
    font-size: 20px;
    font-weight: 500;
`;

export const Image = styled.img`
    width: 180px;
    height: auto;
`;

export const ContentTop = styled.div`
    width: 100%;
    border-radius: 8px 8px 0 0;
    background: ${Colors.BLACK};
    padding: 20px;
`;

export const Message = styled.div`
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    background: ${(props) => (props.error ? 'rgba(215, 29, 36, 0.0784314)' : 'rgba(76, 175, 80, 0.157)')};
    border-color: ${(props) => (props.error ? 'rgba(215, 29, 36, 0.0784314)' : 'rgba(76, 175, 80, 0.157)')};
    color: ${(props) => (props.error ? Colors.PRIMARY : 'rgb(76, 175, 80)')};

    a {
        color: inherit;
    }
`;

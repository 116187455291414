import React from 'react';
import PropTypes from 'prop-types';
import { Container, SliderItem, Label, SliderContainer } from './styles';

function Slider(props) {
    const { onChange, label, style, value, disabled, name } = props;

    const handleOnChange = () => {
        if (!disabled) {
            onChange({ name, value: value === 1 ? 0 : 1 });
        }
    };

    return (
        <Container onClick={handleOnChange} disabled={disabled} style={style}>
            <SliderContainer checked={value === 1}>
                <SliderItem checked={value === 1} />
            </SliderContainer>
            {label && <Label>{label}</Label>}
        </Container>
    );
}

Slider.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

Slider.defaultProps = {
    style: {},
    label: null,
    onChange: () => {},
    value: 0,
    disabled: false,
};

export default Slider;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
`;

export const Action = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.BADGE_BACKGROUND};

    &:hover {
        cursor: pointer;
    }
`;

export const TranslateButton = styled(Action)`
    background-color: ${(props) => (props.clicked ? 'rgba(66, 133, 244, 0.12)' : Colors.BADGE_BACKGROUND)};
    transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${(props) => (props.clicked ? 'inset 0px 0px 5px #c1c1c1' : 'none')};
`;

export const Icon = styled.img`
    width: 24px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Mapper, SharedTypes } from 'utils';
import * as Styles from './styles';
import SingleSelect from '../../SingleSelect';
import MultiSelect from '../../MultiSelect';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import Icon from '../../Icon';
import { ParametersService } from 'core/services';

function ParameterSelect(props) {
    const { parameters, onChange, value, onRemove, onParameterChange, takenParameters } = props;
    const parameterId = Number(Object.keys(value)[0]);
    const selectedParameter = ParametersService.getParameterById(parameterId);
    const availableParameters = parameters.filter(
        (parameter) => !takenParameters.includes(parameter.id) || parameter.id === parameterId,
    );

    const handleParameterChange = (event) => {
        const { value } = event;
        onParameterChange(parameterId, value);
    };

    const handleOnChange = (event) => {
        const { value } = event;
        onChange(parameterId, value);
    };

    return (
        <Styles.Container>
            <Grid.Row>
                <Grid.Col flex={1} mr={10} style={{ maxWidth: '100%' }}>
                    <Grid.Row mb={5}>
                        <SingleSelect
                            clearable={false}
                            placeholder="Search by name"
                            onChange={handleParameterChange}
                            name="test"
                            value={parameterId}
                            options={Mapper.mapParametersToOptions(availableParameters)}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <MultiSelect
                            disabled={!selectedParameter}
                            placeholder="Search by name"
                            onChange={handleOnChange}
                            name={`parameter[${parameterId}]`}
                            value={Object.values(value).flat(1)}
                            options={Mapper.mapParameterValuesToOptions(selectedParameter.values || [])}
                        />
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    <Icon onClick={() => onRemove(parameterId)} title="Remove" source={Images.CLOSE_GREY} />
                </Grid.Col>
            </Grid.Row>
        </Styles.Container>
    );
}

ParameterSelect.propTypes = {
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    onChange: PropTypes.func.isRequired,
    onParameterChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    takenParameters: PropTypes.arrayOf(SharedTypes.NumberOrString),
    value: PropTypes.object,
};

ParameterSelect.defaultProps = {
    value: null,
};

export default ParameterSelect;

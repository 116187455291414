import { useHistory } from 'react-router-dom';
import { UrlUtils } from 'utils';
import useSearch from '../useSearch';

function useFilter() {
    const history = useHistory();
    const { filter } = useSearch();

    const setFilterValue = (key, value) => {
        const newFilter = { ...filter, [key]: value };
        const search = UrlUtils.objectToSearch(newFilter);
        history.push({ search });
    };

    const updateFilter = (filter, path) => {
        const search = UrlUtils.objectToSearch(filter);
        path ? history.push({ pathname: path, search }) : history.push({ search });
    };

    const getFilters = (filter) => {
        return UrlUtils.objectToSearch(filter);
    };

    return {
        updateFilter,
        filter,
        setFilterValue,
        getFilters,
    };
}

export default useFilter;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as InfoSvg } from 'assets/img/info.svg';

const InfoBox = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    padding: 10px 15px 10px 12px;
    color: #31708f;
    background-color: #d9edf7;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 70%);
    border: 1px solid #bce8f1;

    svg {
        fill: #31708f;
        margin-right: 12px;
        min-width: 24px;
        min-height: 24px;
    }
`;

const MessageBox = ({ className, children }) => {
    return (
        <InfoBox className={className}>
            <InfoSvg />
            <p>{children}</p>
        </InfoBox>
    );
};

MessageBox.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
};

export default MessageBox;

import React, { useEffect } from 'react';
import * as Styles from './styles';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import { InitialActions } from 'core/actions';
import { connect } from 'react-redux';

function PageLoader(props) {
    const { switchUser, getInitialData, isLoading } = props;

    useEffect(() => {
        isLoading && !switchUser && getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchUser]);

    return (
        <Styles.Container>
            <Styles.Title>Loading initial data...</Styles.Title>
            <Loader size={40} />
        </Styles.Container>
    );
}

PageLoader.propTypes = {
    switchUser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    getInitialData: PropTypes.func,
    isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
    const { auth, initial } = state;
    return { switchUser: auth.switchUser, isLoading: initial.isLoading };
}

const mapDispatchToProps = {
    getInitialData: InitialActions.getInitialData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);

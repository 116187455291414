import { useDispatch, useSelector } from 'react-redux';
import { SavedSearchesActions } from 'core/actions';

function useUpdateSavedSearch() {
    const dispatch = useDispatch();
    const { isUpdating } = useSelector((state) => state.savedSearches);

    const updateSavedSearch = (id, request, callback) => {
        dispatch(SavedSearchesActions.updateSavedSearch(id, request, callback));
    };

    return {
        updateSavedSearch,
        isUpdating,
    };
}

export default useUpdateSavedSearch;

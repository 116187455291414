import update from 'immutability-helper';
import { UsersTypes } from 'core/types';

const initialState = {
    users: [],
    isLoading: false,
    isLoaded: false,
    isUserLoading: false,
    isUserLoaded: false,
    isUpdating: false,
    isUpdated: false,
    projects: [],
    user: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case UsersTypes.GET_USERS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                user: { $set: {} },
            });
        }

        case UsersTypes.GET_USER_START: {
            return update(state, {
                isUserLoading: { $set: true },
                isUserLoaded: { $set: false },
            });
        }

        case UsersTypes.GET_USERS: {
            return update(state, {
                isLoading: { $set: false },
                users: { $set: payload.users },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                user: { $set: {} },
            });
        }

        case UsersTypes.GET_USER: {
            return update(state, {
                isUserLoading: { $set: false },
                isUserLoaded: { $set: true },
                user: { $set: payload.user },
            });
        }

        case UsersTypes.GET_PROJECTS: {
            return update(state, {
                projects: { $set: payload.projects },
            });
        }

        case UsersTypes.UPDATE_USERS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case UsersTypes.CREATE_USER:
        case UsersTypes.UPDATE_USER:
        case UsersTypes.REMOVE_USER: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case UsersTypes.GET_USERS_ERROR: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }
        // case AuthTypes.LOGOUT: {
        //   return initialState;
        // }

        default: {
            return state;
        }
    }
};

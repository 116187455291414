import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${(props) => props.itemsCount}, minmax(0, 1fr));
    grid-row-gap: ${(props) => props.rowGap}px;
    grid-column-gap: ${(props) => props.columnGap}px;
    ${(props) => props.style}
`;

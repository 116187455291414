import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from './styles';
import { Grid } from 'styles/components';
import { Button, Modal, Loader } from 'components';

function ArticlesModal(props) {
    const { chartArticles, isDataLoading, isDataLoaded, onClick, modal, setModal, offset } = props;

    useEffect(() => {
        if (isDataLoading) document.body.style.cursor = 'wait';
        else document.body.style.cursor = 'default';
        chartArticles && isDataLoaded && setModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded, isDataLoading]);

    const renderModal = () => {
        if (chartArticles) {
            return (
                <>
                    {isDataLoading && <Loader />}
                    {isDataLoaded &&
                        chartArticles.documents &&
                        chartArticles.documents.map((doc, i) => {
                            return (
                                <Grid.Container key={i} mb={10}>
                                    <Grid.Row>
                                        <Text>
                                            {offset + i + 1}. Date: {doc.date_published.split(' ')[0]}
                                        </Text>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Text>
                                            Source:
                                            <a href={doc.url} target="_blank" rel="noreferrer noopener">
                                                {doc.domain}
                                            </a>
                                        </Text>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Text>
                                            <a
                                                href={`/article/${doc.id}/${doc._parent}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {doc.title}
                                            </a>
                                        </Text>
                                    </Grid.Row>
                                </Grid.Container>
                            );
                        })}
                    {chartArticles.message && (
                        <Grid.Row>
                            <Text>{chartArticles.message}</Text>
                        </Grid.Row>
                    )}
                </>
            );
        } else {
            return '';
        }
    };

    const renderFooter = () => {
        if (chartArticles && isDataLoaded) {
            return (
                <Grid.Container>
                    <Grid.Row>
                        <Grid.Col mr={10}>
                            <Button onClick={() => onClick('prev')} label="<" disabled={offset < 25} />
                        </Grid.Col>
                        <Grid.Col>
                            <Button
                                onClick={() => onClick('next')}
                                label=">"
                                disabled={chartArticles.total - (offset + 25) < 0}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Container>
            );
        } else {
            return '';
        }
    };

    return (
        <Modal
            onClose={() => setModal(false)}
            style={{ maxHeight: '75vh', overflow: 'auto' }}
            renderContent={renderModal}
            visible={modal}
            renderFooter={renderFooter}
            title={`Total: ${chartArticles.total || 0}`}
        />
    );
}

ArticlesModal.propTypes = {
    chartArticles: PropTypes.object,
    isDataLoading: PropTypes.bool.isRequired,
    isDataLoaded: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    setModal: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired,
    offset: PropTypes.number.isRequired,
};

export default ArticlesModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Badge, Content, Label, Error } from './styles';

function PositivitySelect(props) {
    const { onChange, name, value, dirty, error, label } = props;

    const handleChange = (val) => {
        const newValue = val === value ? null : val;
        onChange({ name, value: newValue });
    };

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Content>
                <Badge active={value === 'positive'} onClick={() => handleChange('positive')} type="positive">
                    Positive
                </Badge>
                <Badge active={value === 'neutral'} onClick={() => handleChange('neutral')} type="neutral">
                    Neutral
                </Badge>
                <Badge active={value === 'negative'} onClick={() => handleChange('negative')} type="negative">
                    Negative
                </Badge>
            </Content>
            {dirty && error && <Error>{error}</Error>}
        </Container>
    );
}

PositivitySelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

PositivitySelect.defaultProps = {
    onBlur: () => {},
    label: null,
    value: null,
    dirty: false,
    error: null,
    disabled: false,
};

export default PositivitySelect;

import React from 'react';
import { Container, Title, Text, LinkButton } from './styles';

function NotFound() {
    return (
        <Container>
            <Title>Page not found</Title>
            <Text>
                The page you were looking for could not be found. It might have been removed, renamed, or improperly
                embedded.
            </Text>
            <Text>Error 404</Text>
            <Text>
                You can contact <a href="mailto: support@debunkeu.org">support@debunkeu.org</a> for the assistance
            </Text>
            <LinkButton href="/search">Go to main page</LinkButton>
        </Container>
    );
}

export default NotFound;

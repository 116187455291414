import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.button`
    border: none;
    height: 34px;
    display: flex;
    align-items: center;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`;

export const Label = styled.span`
    color: ${Colors.BADGE_TEXT};
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    white-space: nowrap;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: ${Colors.WHITE};
`;

export const Option = styled.button`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${Colors.BLACK};
    background-color: ${(props) => (props.active ? Colors.LIGHT_GREY : Colors.WHITE)};
    border: none;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    height: 42px;
    padding: 0 5px;
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    text-align: left;

    &:hover {
        cursor: pointer;
        background-color: ${Colors.LIGHT_GREY};
    }
`;

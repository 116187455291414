import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.SECONDARY};

    &:hover {
        cursor: pointer;
    }
`;

export const Icon = styled.img`
    padding-right: 1px;
`;

import { useDispatch, useSelector } from 'react-redux';
import { SavedSearchesActions } from 'core/actions';

function useSavedSearches() {
    const dispatch = useDispatch();
    const { savedSearches, isLoading, isLoaded } = useSelector((state) => state.savedSearches);

    const getSavedSearches = () => {
        dispatch(SavedSearchesActions.getSavedSearches());
    };

    return {
        savedSearches,
        isLoading,
        isLoaded,
        getSavedSearches,
    };
}

export default useSavedSearches;

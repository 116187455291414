import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
    padding-top: 20px;
`;

export const User = styled.span`
    color: ${Colors.PRIMARY};
    margin-left: 5px;
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    padding: 0 0 64px;
`;

import React, { useState } from 'react';
import moment from 'moment';

import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Container,
    Section,
    Title,
    Row,
    AuthorCol,
    LanguageCol,
    DateCol,
    DomainRow,
    AuthorColWrapper,
    Message,
} from './styles';
import { Button, Input, DatePicker, Radio, SingleSelect, Loader } from 'components';
import { Buttons } from '../../styles/constant';
import { usePermission } from 'hooks';
import { Mapper } from 'utils';
import { ArticleActions } from 'features/article/actions';
import { NotFoundPage } from 'pages';

const REQUIRED_FIELD_ERROR = 'This field is required';

const FB_POST_REQUIRED_FIELDS = ['languageId', 'content', 'likes', 'shares', 'comments'];

const DATE_NOW = new Date();
DATE_NOW.setHours(0, 0, 0, 0);

const initialState = {
    url: '',
    date: null,
    title: '',
    content: '',
    author: '',
    languageId: null,
    shares: null,
    likes: null,
    comments: null,
    domainId: 2390,
    context: '',
};

const ManualImportPage = ({ languages, domains, isLoading, isLoaded, importArticle, isImporting }) => {
    const hasAssignDomainPermission = usePermission('ROLE_MANUAL_DOC_ASSIGN_DOMAIN');
    const hasManualImportPermission = usePermission('ROLE_CREATE_MANUAL_IMPORTS');

    const [state, setState] = useState(initialState);
    const [isArticle, setIsArticle] = useState(false);
    const [errors, setErrors] = useState();

    if (!hasManualImportPermission) {
        return <NotFoundPage />;
    }

    document.title = 'Debunk EU - Import a publication';

    const validateField = (fieldName, value) => {
        switch (fieldName) {
            case 'url': {
                return value.includes('http') ? null : 'Please enter a correct url';
            }
            default:
                return null;
        }
    };

    const validateRequiredFields = () => {
        const newErrors = { ...errors };
        if (!state.url) {
            newErrors.url = {
                dirty: true,
                error: REQUIRED_FIELD_ERROR,
            };
        }

        if (!isArticle) {
            FB_POST_REQUIRED_FIELDS.forEach((f) => {
                if (!state[f]) {
                    newErrors[f] = { dirty: true, error: REQUIRED_FIELD_ERROR };
                }
            });
        }

        setErrors(newErrors);
        return newErrors;
    };

    const handleChange = (value, field) => {
        const newState = { ...state, [field]: value };
        setState(newState);
        const fieldError = validateField(field, value);
        setErrors({ ...errors, [field]: { error: fieldError, dirty: true } });
    };

    const isInputNumberValid = (value) => {
        const valueNum = +value;
        const isNotNumber = isNaN(valueNum);
        const hasDecimals = value.includes('.') ? true : false;

        return !(isNotNumber || hasDecimals);
    };

    const handleNumberInputChange = (value, field) => {
        if (isInputNumberValid(value)) {
            handleChange(value, field);
        }
    };

    const onSubmit = async () => {
        const newErrors = validateRequiredFields();
        const hasError = newErrors ? Object.values(newErrors).some((x) => x.error) : null;
        if (hasError) {
            return;
        }

        const data = {
            ...state,
            date: state.date ? moment(state.date).format('YYYY-MM-DD HH:mm') : null,
        };
        try {
            await importArticle(data);
            if (!isImporting) {
                setState(initialState);
                setErrors(undefined);
                setIsArticle(false);
            }
        } catch (error) {
            return;
        }
    };

    const languageOptions = languages.map((l) => {
        return { label: l.name, value: l.id };
    });

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row>
                    <Title>Import a publication</Title>
                </Grid.Row>
                {isLoading && (
                    <Grid.Row mb={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}

                {isLoaded && (
                    <>
                        <Section>
                            <Grid.Row mb={20} flex={1}>
                                <Grid.Col mr={20}>
                                    <Radio
                                        label="Social post / video / podcast / other"
                                        checked={!isArticle}
                                        name="other"
                                        onChange={(e) => {
                                            setIsArticle(!e.value);
                                            setErrors(undefined);
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col flex={1}>
                                    <Radio
                                        label="Article"
                                        checked={isArticle}
                                        name="isArticle"
                                        onChange={(e) => {
                                            setIsArticle(e.value);
                                            setErrors(undefined);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Row>

                            <Grid.Row mb={10}>
                                <Input
                                    onChange={(e) => handleChange(e.value, 'url')}
                                    value={state.url}
                                    name="url"
                                    label="URL address"
                                    dirty={errors?.url?.dirty}
                                    error={errors?.url?.error}
                                />
                            </Grid.Row>
                            <AuthorColWrapper>
                                <Row mb={5} flex={1}>
                                    <AuthorCol flex={1}>
                                        <Input
                                            onChange={(e) => handleChange(e.value, 'author')}
                                            value={state.author}
                                            name="author"
                                            label="Author (optional)"
                                        />
                                    </AuthorCol>
                                    <LanguageCol flex={1}>
                                        <SingleSelect
                                            options={languageOptions}
                                            label="Language"
                                            name="language"
                                            value={state.languageId}
                                            onChange={(e) => handleChange(e.value, 'languageId')}
                                            dirty={errors?.languageId?.dirty}
                                            error={errors?.languageId?.error}
                                        />
                                    </LanguageCol>
                                    <DateCol flex={1}>
                                        <DatePicker
                                            label="Date"
                                            value={state.date}
                                            onChange={(e) => handleChange(e.value, 'date')}
                                            name="date"
                                            placeholder="Select date"
                                            selectsRange={false}
                                            maxDate={DATE_NOW}
                                            dirty={errors?.date?.dirty}
                                            error={errors?.date?.error}
                                        />
                                    </DateCol>
                                </Row>
                                {!isArticle && (
                                    <Message>
                                        In order to have representative social metrics, it is recommended to add
                                        publications that are at least 3 days old
                                    </Message>
                                )}
                            </AuthorColWrapper>

                            <Grid.Row mb={20}>
                                <Input
                                    onChange={(e) => handleChange(e.value, 'title')}
                                    value={state.title}
                                    name="title"
                                    label="Title (if available)"
                                />
                            </Grid.Row>

                            <Grid.Row mb={20}>
                                <Input
                                    onChange={(e) => handleChange(e.value, 'content')}
                                    value={state.content}
                                    name="content"
                                    label="Content"
                                    rows={10}
                                    dirty={errors?.content?.dirty}
                                    error={errors?.content?.error}
                                />
                            </Grid.Row>
                            <Grid.Row mb={20}>
                                <Input
                                    onChange={(e) => handleChange(e.value, 'context')}
                                    value={state.context}
                                    name="context"
                                    label="Context / Tags"
                                />
                            </Grid.Row>
                            <Grid.Row mb={20} flex={1}>
                                <Grid.Col mr={20}>
                                    <Input
                                        onChange={(e) => handleNumberInputChange(e.value, 'likes')}
                                        value={state.likes}
                                        name="likes"
                                        label="Likes"
                                        dirty={errors?.likes?.dirty}
                                        error={errors?.likes?.error}
                                    />
                                </Grid.Col>
                                <Grid.Col mr={20}>
                                    <Input
                                        onChange={(e) => handleNumberInputChange(e.value, 'comments')}
                                        value={state.comments}
                                        name="comments"
                                        label="Comments"
                                        dirty={errors?.comments?.dirty}
                                        error={errors?.comments?.error}
                                    />
                                </Grid.Col>
                                <Grid.Col mr={20}>
                                    <Input
                                        onChange={(e) => handleNumberInputChange(e.value, 'shares')}
                                        value={state.shares}
                                        name="shares"
                                        label="Shares"
                                        dirty={errors?.shares?.dirty}
                                        error={errors?.shares?.error}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            {hasAssignDomainPermission && (
                                <DomainRow mb={20}>
                                    <SingleSelect
                                        options={domains}
                                        label="Domain"
                                        name="domain"
                                        value={state.domainId}
                                        onChange={(e) => handleChange(e.value, 'domainId')}
                                        dirty={errors?.domainId?.dirty}
                                        error={errors?.domainId?.error}
                                    />
                                </DomainRow>
                            )}
                        </Section>

                        <Grid.Row>
                            <Grid.Col mr={10}>
                                <Button
                                    label="Submit"
                                    loading={isImporting}
                                    type={Buttons.PRIMARY}
                                    onClick={onSubmit}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </>
                )}
            </Grid.FixedContainer>
        </Container>
    );
};

ManualImportPage.propTypes = {
    history: PropTypes.object,
    languages: PropTypes.array.isRequired,
    domains: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isImporting: PropTypes.bool.isRequired,
    importArticle: PropTypes.bool.func,
};

function mapStateToProps(state) {
    const { languages, domains, article } = state;
    return {
        languages: languages.languages,
        domains: Mapper.mapDomainsToOptions(domains.domains),
        isLoaded: languages.isLoaded || domains.isLoaded,
        isLoading: languages.isLoading || domains.isLoading,
        isImporting: article.isImporting,
    };
}

const mapDispatchToProps = {
    importArticle: ArticleActions.importArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualImportPage);

let options = {
    labels: [],
    chartHeight: 500,
    chartWidth: 993,
    minXValue: 0,
    maxXValue: 0,
    minYValue: 0,
    maxYValue: 0,
    data: [],
    fontsize: 11,
    pointRadius: 0,
};

let offsets = [];

const fontHeightRatio = 1;
const fontWidthRatio = 1;
let offsetStep = 1;

function setOptions(config) {
    if (shouldReset(config)) {
        offsets = [];
    }

    options = { ...options, ...config };
}

function shouldReset(config) {
    if (options.data.length !== offsets.length) {
        return true;
    }

    if (options.data.length !== config.data.length) {
        return true;
    }

    if (options.labels.length !== config.labels.length) {
        return true;
    }

    for (let i = 0; i < config.data.length; i++) {
        const configItem = config.data[i];
        const optionsItem = options.data[i];

        if (configItem.x != optionsItem.x) {
            return true;
        }

        if (configItem.y != optionsItem.y) {
            return true;
        }
    }

    for (let i = 0; i < config.labels.length; i++) {
        const configItem = config.labels[i];
        const optionsItem = options.labels[i];

        if (configItem != optionsItem) {
            return true;
        }
    }

    return false;
}

function getOffset(index = null) {
    if (!offsets.length) {
        calculateOffsets();
    }

    if (index === null) {
        return offsets;
    }

    return offsets[index];
}

function calculateOffsets() {
    for (let i = 0; i < options.data.length; i++) {
        let offset = 0;
        while (overLaps(i, offset)) {
            offset += offsetStep;
        }

        offsets[i] = offset;
    }
}

function overLaps(index, offset) {
    const rectangle = calculateRectangle(index, offset);

    for (let i = 0; i < offsets.length; i++) {
        const settledRectangle = calculateRectangle(i);
        let overLapsX = false;
        let overLapsY = false;

        if (rectangle.x1 >= settledRectangle.x1) {
            if (rectangle.x0 < settledRectangle.x1) {
                overLapsX = true;
            }
        } else {
            if (settledRectangle.x0 < rectangle.x1) {
                overLapsX = true;
            }
        }

        if (rectangle.y1 >= settledRectangle.y1) {
            if (rectangle.y0 < settledRectangle.y1) {
                overLapsY = true;
            }
        } else {
            if (settledRectangle.y0 < rectangle.y1) {
                overLapsY = true;
            }
        }

        if (overLapsX && overLapsY) {
            return true;
        }

        if (overlapsPoint(rectangle)) {
            return true;
        }
    }

    return false;
}

function overlapsPoint(rectangle) {
    for (let i = 0; i < options.data.length; i++) {
        const point = getItemPixelCoordinates(i);
        const x0 = point.x - options.pointRadius;
        const x1 = point.x + options.pointRadius;
        const y0 = point.y - options.pointRadius;
        const y1 = point.y + options.pointRadius;

        let overLapsX = false;
        let overLapsY = false;

        if (rectangle.x1 >= x1) {
            if (rectangle.x0 < x1) {
                overLapsX = true;
            }
        } else {
            if (x0 < rectangle.x1) {
                overLapsX = true;
            }
        }

        if (rectangle.y1 >= y1) {
            if (rectangle.y0 < y1) {
                overLapsY = true;
            }
        } else {
            if (y0 < rectangle.y1) {
                overLapsY = true;
            }
        }

        if (overLapsX && overLapsY) {
            return true;
        }
    }

    return false;
}

function calculateRectangle(index, offset = null) {
    let rectangle = getOriginalRectangle(index);

    if (offset === null) {
        offset = offsets[index];
    }

    if (offset) {
        const delta = offset;
        if (rectangle.y0 >= 0) {
            rectangle.y0 += delta;
            rectangle.y1 += delta;
        } else {
            rectangle.y0 -= delta;
            rectangle.y1 -= delta;
        }
    }

    return rectangle;
}

function getOriginalRectangle(index) {
    const width = getLabelWidth(index);
    const height = getlabelHeight();
    const item = getItemPixelCoordinates(index);
    const halfWidth = width / 2;

    let rectangle = {
        x0: item.x - halfWidth,
        x1: item.x + halfWidth,
    };

    if (item.y >= 0) {
        rectangle.y0 = item.y;
        rectangle.y1 = item.y + height;
    } else {
        rectangle.y0 = item.y - height;
        rectangle.y1 = item.y;
    }

    return rectangle;
}

function getLabelWidthBasic(index) {
    const label = options.labels[index];

    if (!label) {
        return 0;
    }

    return label.length * options.fontsize * fontWidthRatio;
}

function getLabelWidth(index) {
    if (!options.canvasContext) {
        return getLabelWidthBasic(index);
    }

    const label = options.labels[index];

    if (!label) {
        return 0;
    }

    return options.canvasContext.measureText(label).width;
}

function getlabelHeight() {
    return options.fontsize * fontHeightRatio;
}

function getItemPixelCoordinates(index) {
    const dataPoint = options.data[index];
    const ppx = getPointsPerPixel('x');
    const ppy = getPointsPerPixel('y');

    return {
        x: dataPoint.x * ppx,
        y: dataPoint.y * ppy,
    };
}

function getPointsPerPixel(axis) {
    if (axis === 'x') {
        const delta = options.maxXValue - options.minXValue;
        return options.chartWidth / delta;
    }

    if (axis === 'y') {
        const delta = options.maxYValue - options.minYValue;
        return options.chartHeight / delta;
    }

    return 0;
}

export default {
    setOptions,
    getOffset,
    getLabelWidth,
    getPointsPerPixel,
};

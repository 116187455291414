import httpClient from 'core/services/httpClient';
import { store } from 'core/store';

const baseUrl = '/parameters-template';

export const getParametersTemplates = async () => {
    return httpClient.get(baseUrl);
};

export const addParametersTemplate = async (request) => {
    return httpClient.post(baseUrl, request);
};

export const getParametersTemplateById = (id) => {
    const parametersTemplates = store.getState().parametersTemplates.parametersTemplates;
    return parametersTemplates.find((parametersTemplate) => parametersTemplate.id === id);
};

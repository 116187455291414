import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    SearchContainer,
    OptionsContainer,
    SearchInput,
    SearchIcon,
    NoDataContainer,
    NoDataTitle,
} from './styles';
import SelectOption from '../../SelectOption';
import { SharedTypes, TextUtils } from 'utils';
import { Images } from 'styles/constant';
import _ from 'lodash';

function SingleSelectOptions(props) {
    const { options, style, onChange, value, searchable, searchPhrase, disabled, forceOpen } = props;
    const [visibleOptions, setVisibleOptions] = useState(20);
    const [phrase, setPhrase] = useState(searchPhrase);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const ref = useRef(null);
    const noOptions = phrase && filteredOptions.length === 0;

    useEffect(() => {
        const newFilteredOptions = options.filter((option) => TextUtils.startWith(option.label, phrase));
        setFilteredOptions(newFilteredOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phrase]);

    useEffect(() => {
        setPhrase(searchPhrase);
    }, [searchPhrase]);

    const handleClick = (option) => {
        onChange(option.value);
    };

    const handleScroll = (event) => {
        const { target } = event;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;

        if (bottom && filteredOptions.length > visibleOptions) {
            setVisibleOptions(visibleOptions + 20);
        }
    };

    const handleSearch = (event) => {
        const { target } = event;
        const { value } = target;
        setPhrase(value);
    };

    return (
        <Container hide={noOptions && !searchable} style={style} forceOpen={forceOpen}>
            {searchable && (
                <SearchContainer>
                    <SearchInput value={phrase || ''} onChange={handleSearch} className="autofocus" />
                    <SearchIcon src={Images.SEARCH} />
                </SearchContainer>
            )}
            <OptionsContainer onScroll={handleScroll} ref={ref} forceOpen={forceOpen}>
                {noOptions && (
                    <NoDataContainer>
                        <NoDataTitle>No options</NoDataTitle>
                    </NoDataContainer>
                )}
                {_.take(phrase ? filteredOptions : options, visibleOptions).map((option, index) => (
                    <SelectOption
                        disabled={option.value === disabled}
                        selected={value === option.value}
                        option={option}
                        key={index}
                        onClick={() => handleClick(option)}
                    />
                ))}
            </OptionsContainer>
        </Container>
    );
}

SingleSelectOptions.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    value: SharedTypes.NumberOrString,
    style: PropTypes.object,
    searchable: PropTypes.bool,
    forceOpen: PropTypes.bool,
    disabled: PropTypes.string,
    searchPhrase: PropTypes.string,
};

SingleSelectOptions.defaultProps = {
    style: {},
    value: null,
    searchPhrase: null,
    searchable: true,
    disabled: null,
    forceOpen: null,
};

export default SingleSelectOptions;

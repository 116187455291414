import { LanguagesTypes } from 'core/types';
import { LanguagesService, ToastService } from 'core/services';

export function getLanguages() {
    return async (dispatch) => {
        try {
            dispatch({ type: LanguagesTypes.GET_LANGUAGES_START });
            const languages = await LanguagesService.getLanguages();
            dispatch({ type: LanguagesTypes.GET_LANGUAGES, payload: { languages } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: LanguagesTypes.GET_LANGUAGES_ERROR });
        }
    };
}

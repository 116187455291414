import update from 'immutability-helper';
import { SearchTypes, AuthTypes } from 'core/types';
import { RelativeDates } from 'constant';

const initialState = {
    search: {
        date: RelativeDates.TODAY,
        parametersMissing: false,
        order: 'desc',
        sort: 'infometer',
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SearchTypes.SET_SEARCH: {
            return update(state, {
                search: { $set: payload.search },
            });
        }

        case SearchTypes.SET_PARAMETERS_MISSING: {
            return update(state, {
                parametersMissing: { $set: payload },
            });
        }

        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

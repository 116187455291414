import React from 'react';
import PropTypes from 'prop-types';
import { Container, Check, Label, CheckContainer } from './styles';
import { Images } from 'styles/constant';

function Checkbox(props) {
    const { onChange, label, style, checked, disabled, name } = props;

    const handleOnChange = (event) => {
        if (disabled) return;
        event.stopPropagation();
        onChange({ name, value: !checked });
    };

    return (
        <Container onClick={handleOnChange} style={style} disabled={disabled}>
            <CheckContainer checked={checked}>{checked && <Check src={Images.CHECK_MARK} />}</CheckContainer>
            {label && <Label>{label}</Label>}
        </Container>
    );
}

Checkbox.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
    style: {},
    label: null,
    onChange: () => {},
    checked: false,
    disabled: false,
};

export default Checkbox;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, OptionContainer } from './styles';
import { Form } from 'styles/components';
import { SharedTypes } from 'utils';
import Checkbox from '../Checkbox';
import _ from 'lodash';

function ExpandedMultiSelect(props) {
    const { label, style, value, dirty, error, onChange, name, options, horizontal } = props;

    const handleOnChange = (optionValue) => {
        onChange({ name, value: _.xor(value, [optionValue]) });
    };

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            <Content horizontal={horizontal}>
                {options.map((option) => (
                    <OptionContainer
                        horizontal={horizontal}
                        onClick={() => handleOnChange(option.value)}
                        key={option.value}
                        title={option.label}
                    >
                        <Checkbox
                            onChange={() => handleOnChange(option.value)}
                            label={option.label}
                            checked={value.includes(option.value)}
                            name={option.label}
                        />
                    </OptionContainer>
                ))}
            </Content>
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

ExpandedMultiSelect.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(SharedTypes.NumberOrString),
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    horizontal: PropTypes.bool,
};

ExpandedMultiSelect.defaultProps = {
    style: {},
    label: null,
    value: [],
    dirty: false,
    error: null,
    disabled: false,
    onBlur: () => {},
    horizontal: false,
};

export default ExpandedMultiSelect;

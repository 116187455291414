import { useDispatch, useSelector } from 'react-redux';
import { ArticleActions } from 'features/article/actions';
import { useLocation, useHistory } from 'react-router-dom';

function useLabeling() {
    const dispatch = useDispatch();
    const { isLabeling } = useSelector((state) => state.article);
    const { articles } = useSelector((state) => state.articles);
    const location = useLocation();
    const history = useHistory();

    const labelArticle = (id, parentId, data, isSkip, callback, status) => {
        dispatch(ArticleActions.labelArticle(id, parentId, data, isSkip, callback, status));
        openNextArticle(id, parentId);
    };

    const openNextArticle = (id, parentId) => {
        const index = articles.findIndex((article) => article.id === id && article.parentId === parentId);
        if (index !== -1 && articles.length !== index + 1) {
            const nextArticle = articles[index + 1];
            const params = new URLSearchParams(location.search);
            params.set('id', nextArticle.id);
            params.set('parentId', nextArticle.parentId);
            history.push({ search: params.toString() });
        } else {
            const params = new URLSearchParams(location.search);
            params.delete('id');
            params.delete('parentId');
            history.push({ search: params.toString() });
        }
    };

    return { isLoading: isLabeling, labelArticle, openNextArticle };
}

export default useLabeling;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    SearchContainer,
    OptionsContainer,
    SearchInput,
    SearchIcon,
    NoDataContainer,
    NoDataTitle,
    SelectedOptionsContainer,
    CollapseToggler,
} from './styles';
import SelectOption from '../../SelectOption';
import { SharedTypes, TextUtils } from 'utils';
import _ from 'lodash';
import { Images } from 'styles/constant';

function MultiSelectOptions(props) {
    const { options, style, onChange, value, large, forceOpen, collapsed, setCollapsed, dropdownOpen } = props;
    const [visibleOptions, setVisibleOptions] = useState(20);
    const [phrase, setPhrase] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const ref = useRef(null);
    const noOptions = (phrase && filteredOptions.length === 0) || options.length === 0;

    useEffect(() => {
        if (dropdownOpen) {
            setPhrase('');
        }
    }, [dropdownOpen]);

    useEffect(() => {
        const newFilteredOptions = [
            ...options.filter((option) => TextUtils.startWith(option.label, phrase)),
            ...options.filter((option) => TextUtils.startWith(option.description, phrase)),
        ].filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
        setFilteredOptions(newFilteredOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phrase]);

    const handleClick = (optionValue) => {
        onChange(_.xor(value, [optionValue]));
    };

    const handleScroll = (event) => {
        const { target } = event;
        const bottom = target.scrollHeight - target.scrollTop - 42 <= target.clientHeight;

        if (bottom && filteredOptions.length > visibleOptions) {
            setVisibleOptions(visibleOptions + 20);
        }
    };

    const handleSearch = (event) => {
        const { target } = event;
        const { value } = target;
        setCollapsed(true);
        setPhrase(value);
    };

    return (
        <Container style={style} forceOpen={forceOpen} large={large}>
            <SearchContainer>
                <SearchInput value={phrase || ''} onChange={handleSearch} className="autofocus" />
                <SearchIcon src={Images.SEARCH} />
            </SearchContainer>
            <OptionsContainer onScroll={handleScroll} ref={ref} large={large} forceOpen={forceOpen}>
                {noOptions && (
                    <NoDataContainer>
                        <NoDataTitle>No options</NoDataTitle>
                    </NoDataContainer>
                )}
                <SelectedOptionsContainer collapsed={collapsed} disabled={value.length === 0}>
                    <CollapseToggler collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
                        Selected options ({options.filter((o) => value.includes(o.value)).length})
                    </CollapseToggler>
                    {options
                        .filter((o) => value.includes(o.value))
                        .map((option, index) => (
                            <SelectOption
                                multi
                                selected={value.includes(option.value)}
                                option={option}
                                key={index}
                                onClick={() => handleClick(option.value)}
                            />
                        ))}
                </SelectedOptionsContainer>
                {_.take(phrase ? filteredOptions : options, visibleOptions).map((option, index) => (
                    <SelectOption
                        multi
                        selected={value.includes(option.value)}
                        option={option}
                        key={index}
                        onClick={() => handleClick(option.value)}
                    />
                ))}
            </OptionsContainer>
        </Container>
    );
}

MultiSelectOptions.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    style: PropTypes.object,
    large: PropTypes.bool,
    forceOpen: PropTypes.bool,
    collapsed: PropTypes.bool,
    setCollapsed: PropTypes.func,
    dropdownOpen: PropTypes.bool,
};

MultiSelectOptions.defaultProps = {
    style: {},
    large: false,
    forceOpen: false,
    collapsed: true,
    selectedOptions: [],
};

export default MultiSelectOptions;

import { store } from 'core/store';
import httpClient from '../httpClient';

const baseUrl = '/domains';

export const getDomains = async () => {
    const params = { subset: 'select' };
    return httpClient.get(baseUrl, { params });
};

export const updateDomains = async (id, request) => {
    return httpClient.put(`users/${id}/domains`, request);
};

export const getDomainById = (id) => {
    const domains = store.getState().domains.domains;
    return domains.find((domain) => domain.id === id);
};

export const getUserDomains = async (id) => {
    const params = { subset: 'detailed' };
    return httpClient.get(`users/${id}/domains`, { params });
};

import React, { forwardRef, useEffect, useRef } from 'react';
import { ColumnCheckbox } from '../styles';
import PropTypes from 'prop-types';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <ColumnCheckbox type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
    indeterminate: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default IndeterminateCheckbox;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

function ScrollToTop(props) {
    const { children } = props;
    let history = useHistory();

    useEffect(() => {
        return history.listen(() => window.scrollTo(0, 0));
    }, [history]);

    return <React.Fragment>{children}</React.Fragment>;
}

ScrollToTop.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ScrollToTop;

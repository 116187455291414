import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../Dropdown';
import { Grid } from '../../../styles/components';
import { CheckBoxWrapper, ColumnCheckbox, ColumnsDropdown, DropdownWrapper, TxtBtn } from '../styles';
import Checkbox from '../../Checkbox';
import IndeterminateCheckbox from '../IndeterminateCheckbox';

function VisibleColumns(props) {
    const { allColumns, getToggleHideAllColumnsProps } = props;

    const renderButton = () => {
        return (
            <TxtBtn muted onClick={() => {}}>
                Toggle visible columns
            </TxtBtn>
        );
    };

    const style = {
        height: 40,
        padding: '0 10px',
        borderBottom: '1px solid #ddd',
    };

    const renderDropdown = () => {
        return (
            <ColumnsDropdown>
                <Grid.Row mb={0} style={style}>
                    <CheckBoxWrapper>
                        <Checkbox
                            name="allColumns"
                            label="All columns"
                            checked={
                                allColumns.filter((c) => c.totalVisibleHeaderCount === 1).length === allColumns.length
                            }
                        />
                        <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
                    </CheckBoxWrapper>
                </Grid.Row>
                {allColumns.map((column, i) => (
                    <Grid.Row key={column.id} mb={0} style={style}>
                        <CheckBoxWrapper>
                            <Checkbox
                                name={column.id}
                                checked={allColumns[i].totalVisibleHeaderCount === 1}
                                label={column.id}
                            />
                            <ColumnCheckbox type="checkbox" {...column.getToggleHiddenProps()} />
                        </CheckBoxWrapper>
                    </Grid.Row>
                ))}
            </ColumnsDropdown>
        );
    };
    return (
        <DropdownWrapper>
            <Dropdown renderButton={renderButton} renderDropdown={renderDropdown} />
        </DropdownWrapper>
    );
}

VisibleColumns.propTypes = {
    allColumns: PropTypes.array,
    getToggleHideAllColumnsProps: PropTypes.func,
};

export default VisibleColumns;

import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { device } from 'styles/device';
import { EDIT_BLACK, EYE_HIDE } from 'styles/constant/images';

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    overflow-y: auto;
    padding: 20px 0 64px;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    @media ${device.tabletPortrait} {
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;

        a {
            width: 100%;
            margin: 0 0 10px;
        }
    }
`;

export const EmailButton = styled.div`
    background: ${(props) => (props.active ? Colors.GREEN : Colors.BADGE_BACKGROUND)};
    font-size: 12px;
    padding: 3px 5px;
    display: inline-block;
    margin: 5px 5px 0 0;
    border-radius: 3px;
`;

export const PreviewLink = styled.a`
    background: url(${EYE_HIDE}) no-repeat center/13px, ${Colors.BADGE_BACKGROUND};
    width: 20px;
    height: 21px;
    display: inline-block;
    transition: 0.3s;
    border-radius: 3px;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.6;
    }
`;

export const EditLink = styled(PreviewLink)`
    background-image: url(${EDIT_BLACK});
    margin-left: 5px;
`;

import styled from 'styled-components';
import { Colors } from '../../styles/constant';

export const Container = styled.form`
    width: 100%;

    span {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
    }

    a {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: none;
        color: ${Colors.BLACK};
        transition: 0.3s;

        &:hover,
        &:active,
        &:focus {
            opacity: 0.77;
        }
    }
`;

import httpClient from '../httpClient';

const baseUrl = '/organisations';

export const getOrganisations = async () => {
    return httpClient.get(`${baseUrl}?subset=organisationForm`);
};

export const createOrganisation = (parameters) => {
    return httpClient.post(baseUrl, parameters);
};

export const updateOrganisation = (id, parameters) => {
    return httpClient.put(`${baseUrl}/${id}`, parameters);
};

export const deleteOrganisation = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

import httpClient from '../httpClient';

const baseUrl = '/searches';

export const getSavedSearches = async () => {
    return {data: [], count: 0};
};

export const addSavedSearch = async (request) => {
    return {};
};

export const deleteSavedSearch = async (id) => {
    return {};
};

export const updateSavedSearch = async (id, request) => {
    return {};
};

import React, { useEffect } from 'react';
import { Container } from './styles';
import { Grid } from 'styles/components';
import { EDIT, FILTER_GRAY, SPHERE, USER } from 'styles/constant/images';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { connect } from 'react-redux';
import { Title } from './styles';
import { COLUMNS } from './config';
import { Table, Loader, Icon, Button } from 'components';
import { useHistory } from 'react-router-dom';
import { UsersActions, AuthActions, InitialActions } from 'core/actions';
import { store } from 'core/store';
import { usePermissions } from 'hooks';

function UsersListPage(props) {
    const { users, isLoading, isLoaded, getUsers, setUsername, getInitialData } = props;
    const history = useHistory();
    const { role } = store.getState().auth.profile;
    const [hasCreateUsersPermission, hasAssignDomainsPermission, hasAssignFiltersPermission] = usePermissions([
        'ROLE_CREATE_USERS',
        'ROLE_ASSIGN_DOMAINS',
        'ROLE_ASSIGN_FILTERS',
    ]);

    useEffect(() => {
        document.title = 'Debunk EU - List of Users';
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSwitchUser = (username) => {
        setUsername(users.find((u) => u.username === username));
        getInitialData();
    };

    return (
        <>
            <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                <Grid.FixedContainer>
                    <Grid.Row pt={20} justifyContent="space-between">
                        <Grid.Col mr={15}>
                            <Title>Users</Title>
                        </Grid.Col>
                        <Grid.Col mb="10">
                            <Button
                                onClick={() => history.push('/user/new')}
                                label="Create new"
                                disabled={!hasCreateUsersPermission}
                            />
                        </Grid.Col>
                    </Grid.Row>
                    {isLoading && (
                        <Grid.Row mb={30} justifyContent="center">
                            <Loader />
                        </Grid.Row>
                    )}
                </Grid.FixedContainer>
            </Grid.FixedContainer>
            <Container>
                {isLoaded && (
                    <Table
                        checkboxes={false}
                        editableCells={[]}
                        updateData={() => {}}
                        columns={[
                            ...COLUMNS,
                            {
                                Header: 'Actions',
                                Cell: function CellRendere(row) {
                                    const id = row.row.original.id;
                                    return (
                                        <Grid.Row>
                                            <Icon
                                                gray
                                                source={EDIT}
                                                title="Edit user"
                                                onClick={() => history.push(`/user/${id}`)}
                                                disabled={!hasCreateUsersPermission}
                                            />
                                            <Icon
                                                gray
                                                source={SPHERE}
                                                title="User domains"
                                                onClick={() => history.push(`/user-domains/${id}`)}
                                                disabled={!hasAssignDomainsPermission}
                                            />
                                            <Icon
                                                gray
                                                source={FILTER_GRAY}
                                                title="User filters"
                                                onClick={() => history.push(`/user-filters/${id}`)}
                                                disabled={!hasAssignFiltersPermission}
                                            />
                                            <Icon
                                                gray
                                                disabled={!(role == 'admin' || role == 'god')}
                                                source={USER}
                                                title="Switch user"
                                                onClick={() => onSwitchUser(row.row.original.username)}
                                            />
                                        </Grid.Row>
                                    );
                                },
                            },
                        ]}
                        data={users}
                        hiddenColumns={['Platform']}
                        type="users"
                        history={history}
                        preSelected={[]}
                    />
                )}
            </Container>
        </>
    );
}

UsersListPage.propTypes = {
    users: PropTypes.arrayOf(SharedTypes.UsersListItemType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool,
    setActiveUser: PropTypes.func,
    getUsers: PropTypes.func,
    setUsername: PropTypes.func,
    getInitialData: PropTypes.func,
};

function mapStateToProps(state) {
    const { users } = state;
    const { isLoading, isLoaded, isUpdated } = users;
    return { users: users.users, isLoading, isLoaded, isUpdated };
}

const mapDispatchToProps = {
    getUsers: UsersActions.getUsers,
    setUsername: AuthActions.setUsername,
    getInitialData: InitialActions.getInitialData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListPage);

import React from 'react';
import { Grid } from 'styles/components';
import { Txt, TxtBtn, Wrapper, TopWrapper } from '../styles';
import PropTypes from 'prop-types';
import VisibleColumns from '../VisibleColumns';

function TableTopButtons(props) {
    const {
        rows,
        checkedRows,
        clearCells,
        setRows,
        type,
        allColumns,
        getToggleHideAllColumnsProps,
        checkboxes,
        selectAll,
        selectNone,
        setShowSelected,
        showSelected,
    } = props;

    return (
        <Grid.Row justifyContent="flex-end" mb={20}>
            <TopWrapper>
                <Grid.FixedContainer>
                    <Wrapper>
                        <Txt bordered={checkboxes}>
                            Found: {rows.length}
                            {checkboxes && (
                                <Grid.Row>
                                    <TxtBtn onClick={selectAll}>Select all</TxtBtn>
                                    <TxtBtn onClick={selectNone}>Select none</TxtBtn>
                                </Grid.Row>
                            )}
                        </Txt>
                        {checkboxes && (
                            <Txt>
                                Selected {type}: {checkedRows ? checkedRows.checkedRows.length : 0}
                                <Grid.Row>
                                    <TxtBtn onClick={setShowSelected}>
                                        {showSelected ? 'Show all' : 'Show selected'}
                                    </TxtBtn>
                                    <TxtBtn
                                        onClick={() => {
                                            setRows((prevState) => ({
                                                ...prevState,
                                                checkedRows: [],
                                            }));
                                            showSelected && setShowSelected();
                                        }}
                                    >
                                        Clear all
                                    </TxtBtn>
                                </Grid.Row>
                            </Txt>
                        )}
                        <VisibleColumns
                            allColumns={allColumns}
                            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                        />

                        <TxtBtn muted onClick={clearCells} style={{ marginRight: 0 }}>
                            Clear table filtering
                        </TxtBtn>
                    </Wrapper>
                </Grid.FixedContainer>
            </TopWrapper>
        </Grid.Row>
    );
}

TableTopButtons.propTypes = {
    type: PropTypes.string.isRequired,
    preSelected: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    rows: PropTypes.array.isRequired,
    checkedRows: PropTypes.object,
    clearCells: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
    selectAll: PropTypes.func,
    selectNone: PropTypes.func,
    allColumns: PropTypes.array,
    getToggleHideAllColumnsProps: PropTypes.func,
    setShowSelected: PropTypes.func,
    showSelected: PropTypes.bool,
    checkboxes: PropTypes.bool,
};

export default TableTopButtons;

import styled from 'styled-components';
import { device } from '../../styles/device';

export const Container = styled.div``;

export const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    input {
        display: block;
        margin-left: auto;

        @media ${device.tabletPortrait} {
            margin: 20px auto 0;
        }
    }

    @media ${device.tabletPortrait} {
        flex-direction: column;

        button,
        input {
            width: 100%;
            max-width: 100%;
        }
    }
`;

import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { Grid } from 'styles/components';
import { OrganisationsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Title } from './styles';
import { COLUMNS } from './config';
import { Table, Button, Icon } from 'components';
import { useHistory } from 'react-router-dom';
import { EDIT, REMOVE } from '../../styles/constant/images';
import Loader from '../../components/Loader';
import { usePermission } from 'hooks';

function OrganisationsPage(props) {
    const { organisations, deleteOrganisation, isLoading, isLoaded, getOrganisations, isUpdated } = props;
    const [deleted, setDeleted] = useState(false);
    const history = useHistory();
    const hasCreateOrganisationsPermission = usePermission('ROLE_CREATE_ORGANISATIONS');

    useEffect(() => {
        document.title = 'Debunk EU - List of Organisations';
        getOrganisations();
    }, [getOrganisations]);

    useEffect(() => {
        if (deleted && isUpdated) {
            getOrganisations();
            setDeleted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    const onDelete = (id) => {
        const confirm = window.confirm('Delete organisation?');
        if (confirm) {
            deleteOrganisation(id);
            setDeleted(true);
        }
    };

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row>
                    <Title>Organisations</Title>
                </Grid.Row>
                <Grid.Row mb={20}>
                    <Button
                        onClick={() => history.push('/organisation/new')}
                        label="Create new"
                        disabled={!hasCreateOrganisationsPermission}
                    />
                </Grid.Row>
                {isLoading && (
                    <Grid.Row mt={30} justifyContent="center">
                        <Loader />
                    </Grid.Row>
                )}
            </Grid.FixedContainer>
            {!isLoading && isLoaded && (
                <Table
                    checkboxes={false}
                    editableCells={[]}
                    updateData={() => {}}
                    columns={[
                        ...COLUMNS,
                        {
                            Header: 'Actions',
                            Cell: function CellRenderer(row) {
                                return (
                                    <Grid.Row justifyContent="center">
                                        <Icon
                                            gray
                                            source={EDIT}
                                            title="Edit organisation"
                                            onClick={() => history.push(`/organisation/${row.row.original.id}`)}
                                            disabled={!hasCreateOrganisationsPermission}
                                        />
                                        <Icon
                                            gray
                                            source={REMOVE}
                                            title="Remove organisation"
                                            onClick={() => onDelete(row.row.original.id)}
                                            iconSmall
                                            disabled={!hasCreateOrganisationsPermission}
                                        />
                                    </Grid.Row>
                                );
                            },
                        },
                    ]}
                    data={organisations}
                    hiddenColumns={[]}
                    type="organisations"
                    history={history}
                    preSelected={[]}
                />
            )}
        </Container>
    );
}

OrganisationsPage.propTypes = {
    organisations: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    deleteOrganisation: PropTypes.func,
    getOrganisations: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { organisations } = state;
    const { isLoading, isLoaded, isUpdated } = organisations;
    return { organisations: organisations.organisations, isLoaded, isLoading, isUpdated };
}

const mapDispatchToProps = {
    getOrganisations: OrganisationsActions.getOrganisations,
    deleteOrganisation: OrganisationsActions.deleteOrganisation,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsPage);

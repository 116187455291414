import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Icon, Title, Content } from './styles';
import { Images } from 'styles/constant';

function SelectedLabelingOption(props) {
    const { label, style, onClear, title, disabled } = props;

    const handleClear = (event) => {
        event.stopPropagation();

        if (disabled) return;
        onClear();
    };

    return (
        <Container disabled={disabled} style={style}>
            <Content>
                {title && <Title>{`${title} \u2192`}</Title>}
                {label && <Label title={title}>{label}</Label>}
            </Content>
            {onClear && <Icon onClick={handleClear} src={Images.CLOSE_WHITE} />}
        </Container>
    );
}

SelectedLabelingOption.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onClear: PropTypes.func,
    style: PropTypes.object,
};

SelectedLabelingOption.defaultProps = {
    style: {},
    title: null,
    label: null,
    onClear: null,
    disabled: false,
};

export default SelectedLabelingOption;

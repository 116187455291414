import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Badge } from 'components';
import { SharedTypes } from 'utils';

const Categories = (props) => {
    const { className, categories } = props;

    return (
        <Styles.Container className={className}>
            {categories.map((category) => (
                <Styles.Category key={category.id}>
                    <Badge label={category.name} />
                </Styles.Category>
            ))}
        </Styles.Container>
    );
};

Categories.propTypes = {
    className: PropTypes.string,
    categories: PropTypes.arrayOf(SharedTypes.CategoryType),
};

Categories.defaultProps = {
    className: null,
    categories: [],
};

export default Categories;

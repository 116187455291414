import update from 'immutability-helper';
import { AuthTypes } from 'core/types';
import { ArticleTypes } from 'features/article/types';

const initialState = {
    article: null,
    isLoaded: false,
    isLoading: false,
    isLabeling: false,
    isImporting: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ArticleTypes.LABEL_ARTICLE_START: {
            return update(state, {
                isLabeling: { $set: true },
            });
        }

        case ArticleTypes.LABEL_ARTICLE: {
            return update(state, {
                isLabeling: { $set: false },
            });
        }

        case ArticleTypes.GET_ARTICLE_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case ArticleTypes.GET_ARTICLE: {
            return update(state, {
                article: { $set: payload.article },
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        case ArticleTypes.IMPORT_ARTICLE_START: {
            return update(state, {
                isImporting: { $set: true },
            });
        }

        case ArticleTypes.IMPORT_ARTICLE: {
            return update(state, {
                isImporting: { $set: false },
            });
        }

        case AuthTypes.LOGOUT:
        case ArticleTypes.LABEL_ARTICLE_ERROR:
        case ArticleTypes.RESET_ARTICLE:
        case ArticleTypes.GET_ARTICLE_ERROR:
        case ArticleTypes.IMPORT_ARTICLE_ERROR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import * as Styles from './styles';
import { Button, Modal } from 'components';

const ParametersNotSavedModal = (props) => {
    const { visible, onClose } = props;

    const renderContent = () => (
        <Styles.Content>
            <Grid.Row>
                {`There is parameter(s) that you didn't finish adding to the publication. Press "OK" and either add or
                remove it.`}
            </Grid.Row>
        </Styles.Content>
    );

    const renderFooter = () => (
        <Grid.Row>
            <Grid.Col>
                <Button label="OK" onClick={onClose} />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Modal title="" renderContent={renderContent} onClose={onClose} visible={visible} renderFooter={renderFooter} />
    );
};

ParametersNotSavedModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

ParametersNotSavedModal.defaultProps = {
    visible: false,
};

export default ParametersNotSavedModal;

import React, { useEffect } from 'react';
import { Grid } from 'styles/components';
import { UserForm } from 'containers';
import { OrganisationsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function UserPage(props) {
    const { organisations, getOrganisations, isLoaded, isLoading } = props;
    const history = useHistory();

    useEffect(() => {
        getOrganisations();
    }, [getOrganisations]);

    return (
        <>
            {isLoading && (
                <Grid.Row mt={30} justifyContent="center">
                    <Loader />
                </Grid.Row>
            )}
            {isLoaded && <UserForm organisations={organisations ? organisations : []} history={history} />}
        </>
    );
}

UserPage.propTypes = {
    getOrganisations: PropTypes.func.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { users, organisations } = state;
    const { isLoading, isLoaded } = users;
    return {
        organisations: organisations.organisations,
        isLoaded,
        isLoading,
    };
}

const mapDispatchToProps = {
    getOrganisations: OrganisationsActions.getOrganisations,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);

export const startWith = (value, compareValue) => {
    const lowerCaseValue = value ? value.trim().toLowerCase() : '';
    const lowerCaseCompareValue = compareValue ? compareValue.trim().toLowerCase() : '';
    return lowerCaseValue.includes(lowerCaseCompareValue);
};

export const getParameterId = (parameterKey) => {
    return Number(parameterKey.substring(parameterKey.lastIndexOf('[') + 1, parameterKey.lastIndexOf(']')));
};

export const getRandomString = () => {
    return Math.random().toString(36).substr(2, 5);
};

export const trim = (value) => {
    return !value ? value : value.trim();
};

export const parseJson = (jsonString, fallback) => {
    let jsonObject;

    try {
        jsonObject = JSON.parse(jsonString);
    } catch (err) {
        jsonObject = fallback;
    }

    return jsonObject;
};

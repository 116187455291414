import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
    display: flex;
`;

export const Header = styled.div`
    width: 100%;
`;

export const MainHeader = styled.div`
    padding-right: ${(props) => (props.labeling ? 400 : 0)}px;
`;

export const RightSide = styled.div`
    width: calc(100vw - 420px);
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
`;

export const Main = styled.main`
    width: 100%;
    height: ${(props) => (props.isCharts ? 'auto' : 'calc(100vh - 136px - 39px)')};

    ${(props) =>
        !props.labeling &&
        css`
            overflow-y: auto;
        `}
`;

export const Side = styled.aside`
    width: 420px;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
`;

import { ArticlesActions, SimilarArticlesActions } from 'features/articles/actions';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useState } from 'react';

const useArticlesListItem = (props) => {
    const { article, similar } = props;
    const { id, parentId } = article;
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [bookmarking, setBookmarking] = useState(false);

    const openArticle = () => {
        const params = new URLSearchParams(location.search);
        params.set('id', id);
        params.set('parentId', parentId);
        history.push({ search: params.toString() });
    };

    const isArticleOpen = () => {
        const params = new URLSearchParams(location.search);
        return params.get('id') === id && params.get('parentId') === parentId.toString();
    };

    const voteArticle = (vote) => {
        setLoading(true);

        if (similar) {
            dispatch(SimilarArticlesActions.voteSimilarArticle(id, parentId, vote, voteCallback));
        } else {
            dispatch(ArticlesActions.voteArticle(id, parentId, vote, voteCallback));
        }
    };

    const voteCallback = () => {
        setLoading(false);
    };

    const bookmarkArticle = () => {
        setBookmarking(true);

        if (similar) {
            dispatch(
                SimilarArticlesActions.bookmarkSimilarArticle(id, parentId, !article.isBookmarked, bookmarkCallback),
            );
        } else {
            dispatch(ArticlesActions.bookmarkArticle(id, parentId, !article.isBookmarked, bookmarkCallback));
        }
    };

    const bookmarkCallback = () => {
        setBookmarking(false);
    };

    const markExpired = () => {
        dispatch(ArticlesActions.markExpired(id, parentId));
    };

    return {
        openArticle,
        isArticleOpen: isArticleOpen(),
        voteArticle,
        isLoading: loading,
        isBookmarking: bookmarking,
        bookmarkArticle,
        markExpired,
    };
};

export default useArticlesListItem;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NavigationItem = styled.a`
    font-size: 14px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: ${(props) => (props.active ? 700 : 400)};
    color: ${(props) => (props.active ? Colors.WHITE : Colors.HEADER_TEXT)};
    border-bottom: 4px solid ${(props) => (props.active ? Colors.HEADER_ACTIVE_BORDER : Colors.BLACK)};
    border-top: 4px solid ${Colors.BLACK};
    text-transform: uppercase;
    padding: 0 10px;
    margin: 0 5px;

    &:hover,
    &.active {
        cursor: pointer;
        font-weight: 700;
        color: ${Colors.WHITE};
        border-bottom: 4px solid ${Colors.HEADER_ACTIVE_BORDER};
    }
`;

export const NavigationItemWrapper = styled.div`
    cursor: ${(props) => (props.disabled ? 'not-allowed;' : 'pointer')};
    ${NavigationItem} {
        pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    }
    opacity: ${(props) => (props.disabled ? 0.65 : 1)};
`;

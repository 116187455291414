import { FiltersTypes } from 'core/types';
import { FiltersService, ToastService } from 'core/services';

export function getFilters(subset = 'select') {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.GET_FILTERS_START });
            const filters = await FiltersService.getFilters(subset);
            dispatch({ type: FiltersTypes.GET_FILTERS, payload: { filters, table: subset !== 'select' } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function createFilter(filter, languages) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.createFilter(filter, languages);
            dispatch({ type: FiltersTypes.CREATE_FILTER });
        } catch (exception) {
            ToastService.showGenericError(exception.response.data.message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function updateFilter(id, filter, languages) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.updateFilter(id, filter, languages);
            dispatch({ type: FiltersTypes.CREATE_FILTER });
        } catch (exception) {
            ToastService.showGenericError(exception.response.data.message);
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export function deleteFilter(id) {
    return async (dispatch) => {
        try {
            dispatch({ type: FiltersTypes.UPDATE_FILTERS_START });
            await FiltersService.deleteFilter(id);
            dispatch({ type: FiltersTypes.REMOVE_FILTER });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: FiltersTypes.GET_FILTERS_ERROR });
        }
    };
}

export const GET_SAVED_SEARCHES_START = 'GET_SAVED_SEARCHES_START';
export const GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES';
export const GET_SAVED_SEARCHES_ERROR = 'GET_SAVED_SEARCHES_ERROR';

export const ADD_SAVED_SEARCHES_START = 'ADD_SAVED_SEARCHES_START';
export const ADD_SAVED_SEARCHES = 'ADD_SAVED_SEARCHES';
export const ADD_SAVED_SEARCHES_ERROR = 'ADD_SAVED_SEARCHES_ERROR';

export const DELETE_SAVED_SEARCH_START = 'DELETE_SAVED_SEARCH_START';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';
export const DELETE_SAVED_SEARCH_ERROR = 'DELETE_SAVED_SEARCH_ERROR';

export const UPDATE_SAVED_SEARCH_START = 'UPDATE_SAVED_SEARCH_START';
export const UPDATE_SAVED_SEARCH = 'UPDATE_SAVED_SEARCH';
export const UPDATE_SAVED_SEARCH_ERROR = 'UPDATE_SAVED_SEARCH_ERROR';

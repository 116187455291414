import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SharedTypes } from 'utils';
import { useHistory } from 'react-router-dom';
import { COLUMNS } from './config';
import { Container, InputContainer, StyledIcon, SearchIconBig } from './styles';
import { EDIT, REMOVE, SEARCH_WHITE } from 'styles/constant/images';
import renderRow from './FilterLanguageList';
import { Grid } from '../../styles/components';
import { Table, Input, Button } from 'components';
import { FiltersActions } from '../../core/actions';
import { usePermission } from 'hooks';

function FiltersList(props) {
    const { filters, deleteFilter, isUpdated, updateFilters } = props;
    const history = useHistory();
    const [value, setValue] = useState('');
    const [filtered, setFiltered] = useState(false);
    const hasCreateFiltersPermission = usePermission('ROLE_CREATE_FILTERS');

    useEffect(() => {
        isUpdated && updateFilters();
        !filtered && setFiltered(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFiltered, updateFilters, isUpdated]);

    // eslint-disable-next-line no-unused-vars
    const onLanguageDelete = (filter, language) => null;

    const removeFilter = (id) => {
        const confirm = window.confirm('Remove filter?');
        confirm && deleteFilter(id);
    };

    const onInputChange = (e) => {
        setValue(e.value);

        let arr = [];

        filters.map((f) => {
            let queryArr = [];

            if (f.lang && f.lang.length > 0) {
                f.lang.map((l) => {
                    if (l.query && l.query.length > 0) {
                        try {
                            JSON.parse(l.query, (key, value) => {
                                key === 'value' && queryArr.push(value.toLowerCase());
                            });
                        } catch (err) {
                            queryArr = [...queryArr, ...l.query.split(',').map((v) => v.toLowerCase())];
                        }
                    }
                });
                queryArr.filter((q) => q.includes(e.value.toLowerCase())).length > 0 && arr.push(f);
            }
        });
        if (e.value.length === 0) arr = false;
        setFiltered(arr);
    };

    const renderRowSubComponent = ({ row, visibleColumns }) =>
        renderRow(filters, row, visibleColumns, onLanguageDelete, hasCreateFiltersPermission, history);

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row mb={20}>
                    <InputContainer>
                        <Button
                            onClick={() => history.push('/filter/create')}
                            label="Create new"
                            disabled={!hasCreateFiltersPermission}
                        />
                        <Input
                            style={{ width: 700, maxWidth: '100%' }}
                            onChange={onInputChange}
                            name="filterSearch"
                            value={value}
                            placeholder="Search by keyword"
                        />
                    </InputContainer>
                </Grid.Row>
            </Grid.FixedContainer>
            <Table
                editableCells={COLUMNS.filter((c) => c.input).map((c) => c.Header)}
                updateData={() => {}}
                columns={[
                    ...COLUMNS,
                    {
                        Header: 'Actions',
                        Cell: function CellRenderer(row) {
                            return (
                                <Grid.Row justifyContent="center">
                                    <SearchIconBig source={SEARCH_WHITE} disabled={!hasCreateFiltersPermission} />
                                    <StyledIcon
                                        gray
                                        source={EDIT}
                                        onClick={() => history.push(`/filter/${row.row.original.id}`)}
                                        disabled={!hasCreateFiltersPermission}
                                    />
                                    <StyledIcon
                                        gray
                                        source={REMOVE}
                                        onClick={() => removeFilter(row.row.original.id)}
                                        iconSmall
                                        disabled={!hasCreateFiltersPermission}
                                    />
                                </Grid.Row>
                            );
                        },
                    },
                ]}
                data={filtered || filters.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)}
                hiddenColumns={['Create date', 'Created by']}
                type="filters"
                history={history}
                preSelected={[]}
                renderRowSubComponent={renderRowSubComponent}
            />
        </Container>
    );
}

FiltersList.propTypes = {
    isUpdating: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    filters: PropTypes.arrayOf(SharedTypes.FilterType),
    deleteFilter: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { filters } = state;
    const { isUpdating, isUpdated } = filters;
    return { isUpdating, isUpdated };
}

const mapDispatchToProps = {
    deleteFilter: FiltersActions.deleteFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersList);

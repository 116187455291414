import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { Container, Section, Title } from 'pages/EmailTemplate/styles';
import { Grid } from 'styles/components';
import { Button, Input, MultiSelect, Slider } from 'components';
import { Buttons } from 'styles/constant';
import PropTypes from 'prop-types';
import { EmailsActions } from 'core/actions';
import { connect } from 'react-redux';
import { PERIODS, VALIDATE_SCHEDULE } from './config';
import { Error } from 'components/Input/styles';

function EmailForm(props) {
    const { email, users, createEmail, history, updateEmail, isUpdated, isUpdating } = props;

    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [period, setPeriod] = useState(1);
    const [minScore, setMinScore] = useState(0);
    const [limit, setLimit] = useState(10);
    const [active, setActive] = useState(true);
    const [user, setUsers] = useState([]);
    const [schedule, setSchedule] = useState(['*', '*', '*', '*', '*']);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        isUpdated && history.push('/emails');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        document.title = email && email.id ? `Debunk EU - Edit Email - ${email.description}` : 'Create New Email';
        if (email && email.id) {
            setName(email.description);
            setSubject(email.subject);
            setPeriod(email.interval);
            setMinScore(email.min_score);
            setLimit(email.articles_limit);
            setActive(email.status);
            setUsers(email.users.map((u) => u.id));
            setSchedule(email.schedule.split(' '));
        }
    }, [email]);

    const onSubmit = () => {
        const data = {
            name,
            title: subject,
            status: active,
            interval: parseInt(period),
            min_score: parseInt(minScore),
            articles_limit: parseInt(limit),
            users: user,
            schedule: schedule.join(' '),
        };

        if (!email || !email.id) {
            createEmail(data);
        } else {
            updateEmail(email.id, data);
        }
    };

    const onScheduleChange = (value, i) => {
        const valid = schedule.map((item, index) => {
            if (i === index) item = value;
            return VALIDATE_SCHEDULE(item, index);
        });

        setSchedule(
            update(schedule, {
                [i]: { $set: value },
            }),
        );

        setErrors(valid.map((v) => (!v ? 'Value not valid' : '')));
    };

    return (
        <Container>
            <Grid.FixedContainer>
                <Grid.Row mb={20}>
                    <Grid.Col flex={1}>
                        <Title>{email ? 'Edit ' : 'Create new '}email template</Title>
                    </Grid.Col>
                    <Grid.Col>
                        <Grid.Row justifyContent="flex-end">
                            <Grid.Col mr={10}>
                                <Button
                                    disabled={
                                        !!errors.find((e) => e.length > 0) ||
                                        !(
                                            name &&
                                            subject &&
                                            period != 0 &&
                                            limit != 0 &&
                                            (minScore || minScore === 0) &&
                                            user.length > 0 &&
                                            schedule.find((s) => s !== '*')
                                        )
                                    }
                                    label="Submit"
                                    loading={isUpdating}
                                    type={Buttons.PRIMARY}
                                    onClick={onSubmit}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <Button
                                    label="Cancel"
                                    type={Buttons.TERTIARY}
                                    onClick={() => history.push('/emails')}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
                <Section>
                    <Grid.Row mb={20}>
                        <Grid.Col flex={1} mr={15}>
                            <Input
                                label="Name, description or comments"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.value)}
                            />
                        </Grid.Col>
                        <Grid.Col flex={1}>
                            <Input
                                label="Subject"
                                name="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.value)}
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <Grid.Col flex={1} mr={15}>
                            <Input
                                type="number"
                                label="Period of days"
                                name="period"
                                value={period}
                                onChange={(e) => setPeriod(e.value)}
                            />
                        </Grid.Col>
                        <Grid.Col flex={1}>
                            <Input
                                type="number"
                                label="Min infometer score"
                                name="minScore"
                                value={minScore}
                                onChange={(e) => setMinScore(e.value)}
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <Grid.Col flex={1} mr={15}>
                            <Input
                                type="number"
                                label="Articles limit in email"
                                name="limit"
                                value={limit}
                                onChange={(e) => setLimit(e.value)}
                            />
                        </Grid.Col>
                        <Grid.Col flex={1}>
                            <MultiSelect
                                label="Users"
                                name="users"
                                value={user}
                                options={users.map((u) => {
                                    return { label: u.username, value: u.id };
                                })}
                                onChange={(e) => setUsers(e.value)}
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row mb={20}>
                        <Slider
                            label={`Status: ${active ? 'active' : 'inactive'}`}
                            name="active"
                            value={active ? 1 : 0}
                            onChange={() => setActive(!active)}
                        />
                    </Grid.Row>
                    <Grid.Row mb={20}></Grid.Row>
                    <Grid.Row alignItems="flex-start">
                        {schedule.map((item, i) => {
                            return (
                                <Grid.Col key={i} ml={i === 0 ? 0 : 20}>
                                    <Input
                                        label={`${PERIODS[i].name} (${PERIODS[i].min}-${PERIODS[i].max})`}
                                        name={schedule[i]}
                                        value={schedule[i]}
                                        error={errors[i]}
                                        dirty
                                        onChange={(e) => onScheduleChange(e.value, i)}
                                    />
                                </Grid.Col>
                            );
                        })}
                    </Grid.Row>
                    {!schedule.find((s) => s !== '*') && (
                        <Grid.Row mt={10}>
                            <Error>At least one value is required</Error>
                        </Grid.Row>
                    )}
                </Section>
            </Grid.FixedContainer>
        </Container>
    );
}

EmailForm.propTypes = {
    email: PropTypes.object,
    templates: PropTypes.array,
    history: PropTypes.object.isRequired,
    users: PropTypes.array,
    createEmail: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool,
    isUpdating: PropTypes.bool,
    usersLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { emails } = state;
    const { isUpdated, isUpdating } = emails;
    return { isUpdated, isUpdating };
}

const mapDispatchToProps = {
    createEmail: EmailsActions.createEmail,
    updateEmail: EmailsActions.updateEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);

import React, { useEffect, useState } from 'react';
import * as Styles from './styles';
import { Grid } from 'styles/components';
import { Loader, Tabs } from 'components';
import { useArticle } from 'features/article/hooks';
import {
    Categories,
    Filters,
    Source,
    Votes,
    Header,
    Content,
    TechnicalData,
    Actions,
} from 'features/article/components';
import PropTypes from 'prop-types';

const TABS = [
    { key: 'article', title: 'Article' },
    { key: 'source', title: 'Source' },
    { key: 'votes', title: 'Votes' },
    { key: 'filters', title: 'Filters' },
    { key: 'categories', title: 'Categories' },
    { key: 'technicalData', title: 'Technical data' },
];

const ArticleDetails = (props) => {
    const { labeling } = props;
    const { article, isLoading } = useArticle();
    const [fontSize, setFontSize] = useState(14);
    const [activeTab, setActiveTab] = useState('article');

    useEffect(() => {
        const lang = article ? article.technicalData.lang : 'en';
        document.documentElement.lang = lang;
    }, [article]);

    if (isLoading) {
        return (
            <Styles.LoaderContainer labeling={labeling}>
                <Loader size={40} />
            </Styles.LoaderContainer>
        );
    }

    if (!article) {
        return <React.Fragment />;
    }

    return (
        <Styles.Container style={{ overflowY: 'auto' }} labeling={labeling}>
            <Grid.Row mb={10}>
                <Header article={article} />
            </Grid.Row>
            <Grid.Row mb={15}>
                <Tabs onSelect={setActiveTab} active={activeTab} tabs={TABS} />
            </Grid.Row>
            <Grid.Row mb={15}>
                <Actions fontSize={fontSize} zoomIn={setFontSize} zoomOut={setFontSize} />
            </Grid.Row>
            <Styles.Wrapper>
                {activeTab === 'article' && (
                    <Content
                        lang={article.technicalData.lang}
                        fontSize={fontSize}
                        content={article.content}
                        id={article.id}
                    />
                )}
                {activeTab === 'source' && <Source fontSize={fontSize} source={article.source} />}
                {activeTab === 'categories' && <Categories categories={article.categories} />}
                {activeTab === 'filters' && <Filters filters={article.filters} />}
                {activeTab === 'votes' && <Votes votes={article.votes} />}
                {activeTab === 'technicalData' && <TechnicalData article={article} />}
            </Styles.Wrapper>
        </Styles.Container>
    );
};

ArticleDetails.propTypes = {
    labeling: PropTypes.bool,
};

ArticleDetails.defaultProps = {
    labeling: false,
};

export default ArticleDetails;

import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import { Mapper, SharedTypes } from 'utils';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import * as Styles from './styles';
import { Grid } from 'styles/components';
import { Button, Textarea, PositivitySelect, SelectedLabelingOption, LabelingTimer } from 'components';
import React, { useEffect, useState } from 'react';
import { CategoriesService, FiltersService, ParametersService } from 'core/services';
import { Buttons } from 'styles/constant';
import ParameterA3AutoComplete from '../ParameterA3AutoComplete';
import ParametersA2AutoComplete from '../ParametersA2AutoComplete';
import _ from 'lodash';
import ParameterA1MultiSelect from '../ParameterA1MultiSelect';
import { useLabeling } from 'features/article';
import { ParameterTypesList } from 'constant';
import { useArticlesCount } from 'features/articles/hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ParametersTemplates from '../ParametersTemplates';
import ParametersTemplateModal from 'containers/ParametersTemplateModal';
import ParametersNotSavedModal from 'containers/ParametersNotSavedModal';
import { useProfile, useSearch } from 'hooks';
import { SET_PARAMETERS, CHECK_PARAMETERS } from './config';
import Collapse from 'react-collapse';

const initialFormData = {
    parameters: [],
    sentiments: [],
    comment: null,
};

const initialSentimentsOpen = {
    filter: true,
    category: true,
};

const initialSentimentsVisible = {
    filter: 0,
    category: 0,
};

function LabelingForm(props) {
    const { parameters, article, isArticleLoaded, labelingData, similarArticles, articles } = props;
    const [formData, setFormData] = useState(initialFormData);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [parametersModalVisible, setParametersModalVisible] = useState(false);
    const [sentimentsOpen, setSentimentsOpen] = useState(initialSentimentsOpen);
    const [showInitial, setShowInitial] = useState(initialSentimentsOpen);
    const [sentimentsVisible, setSentimentsVisible] = useState(initialSentimentsVisible);
    const { profile } = useProfile();
    const { filter } = useSearch();
    const [skip, setSkip] = useState(filter.labeling_status !== 'not_labeled');
    const { labelArticle } = useLabeling();
    const { getSkippedArticlesCount, getLabeledArticlesCount, getReservedArticlesCount, getWaitingArticlesCount } =
        useArticlesCount();
    const buttonDisabled =
        !isArticleLoaded ||
        !article ||
        (!filter.labeling_status &&
            similarArticles.filter((a) => a.id === article.id && a.parentId === article.parentId && a.disabled).length >
                0 &&
            articles.filter((a) => a.id === article.id && a.parentId === article.parentId).length === 0);
    const disabled = buttonDisabled || filter.labeling_status === 'Not labeled';

    useDeepCompareEffect(() => {
        let newSkip =
            _.isEqual(_.omit(formData, ['time_spent']), _.omit(initialFormData, ['time_spent'])) &&
            !!filter.labeling_status;
        const { parameters, sentiments, comment } = formData;
        if ([...parameters, ...sentiments].every((item) => item.length === 0) && !comment) newSkip = true;
        setSkip(newSkip);
    }, [formData]);

    useEffect(() => {
        if (article && article.labelingStatus === 'Labeled') {
            const organisation = profile.organisation ? profile.organisation.id : null;
            setFormData(Mapper.mapLabelingFormData(article, organisation));
        } else {
            setFormData(initialFormData);
        }

        setShowInitial(
            update(showInitial, {
                category: { $set: true },
                filter: { $set: true },
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article]);

    useEffect(() => {
        if (article && labelingData) {
            setFormData(labelingData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labelingData]);

    const submit = () => {
        let inputsEmpty = true;
        document.querySelectorAll('#labelingContainer input').forEach((item) => {
            if (item.value) inputsEmpty = false;
        });

        if (!inputsEmpty) {
            setParametersModalVisible(true);
            return;
        }

        labelArticle(
            article.id,
            article.parentId,
            formData,
            skip,
            () => {
                if (skip) {
                    filter.labeling_status === 'Not labeled' ? getWaitingArticlesCount() : getLabeledArticlesCount();
                    getSkippedArticlesCount();
                } else {
                    getLabeledArticlesCount();
                    getSkippedArticlesCount();
                }
                !filter.labeling_status && getReservedArticlesCount();
            },
            article.labelingStatus,
        );

        setFormData(initialFormData);
        document.getElementById('parametersContainer').scrollTop = 0;
    };

    const clear = () => {
        setFormData(initialFormData);
    };

    const handleChange = (event) => {
        const { name, value } = event;
        setFormData(
            update(formData, {
                [name]: { $set: value ? value : null },
            }),
        );
    };

    const handleParameterValueAdd = (parameterValue) => {
        const { id, parameterId, type } = parameterValue;
        setFormData(
            update(formData, {
                parameters: { $set: [...formData.parameters, { id, parameterId, type }] },
            }),
        );
    };

    const handleSentimentChange = (sentiment) => {
        const { id, value, type } = sentiment;
        const existingIndex = formData.sentiments.findIndex(
            (sentiment) => sentiment.id === id && sentiment.type === type,
        );

        if (!value) {
            setFormData(
                update(formData, {
                    sentiments: {
                        $set: formData.sentiments.filter((sentiment) =>
                            sentiment.id === id ? sentiment.type !== type : sentiment,
                        ),
                    },
                }),
            );
        } else if (existingIndex === -1) {
            setFormData(
                update(formData, {
                    sentiments: { $set: [...formData.sentiments, { id, value, type }] },
                }),
            );
        } else {
            setFormData(
                update(formData, {
                    sentiments: { [existingIndex]: { $set: sentiment } },
                }),
            );
        }
    };

    const handleParameterValueClear = (parameterValueToClear) => {
        setFormData(
            update(formData, {
                parameters: {
                    $set: formData.parameters.filter(
                        (parameterValue) => !_.isEqual(parameterValueToClear, parameterValue),
                    ),
                },
            }),
        );
    };

    const getCategories = () => {
        if (!article) {
            return [];
        }

        return article.categories
            .map((category) => CategoriesService.getCategoryById(category.id))
            .filter((category) => !!category);
    };

    const getFilters = () => {
        if (!article) {
            return [];
        }

        return article.filters.map((filter) => FiltersService.getFilterById(filter.id)).filter((filter) => !!filter);
    };

    const handleTemplateChange = (parametersTemplate) => {
        const { data } = parametersTemplate;
        if (data.length) {
            // old templates
            const newParameters = SET_PARAMETERS(data);
            setFormData(
                update(formData, {
                    parameters: { $set: newParameters.parameters },
                    comment: { $set: newParameters.comment },
                    sentiments: { $set: [] },
                }),
            );
        } else {
            const newParameters = CHECK_PARAMETERS(data.parameters);
            setFormData(
                update(formData, {
                    parameters: { $set: newParameters },
                    comment: { $set: data.comment },
                    sentiments: { $set: data.sentiments },
                }),
            );
        }
    };

    const getA1ParameterValues = (id) => {
        const parameters = formData.parameters || [];
        return parameters.filter(
            (parameter) => parameter.parameterId == id && parameter.type === ParameterTypesList.A1,
        );
    };

    const getA2ParametersValues = () => {
        const parameters = formData.parameters || [];
        return parameters.filter((parameter) => parameter.type === ParameterTypesList.A2);
    };

    const getA3ParameterValues = (id) => {
        const parameters = formData.parameters || [];
        return parameters.filter(
            (parameter) => parameter.parameterId == id && parameter.type === ParameterTypesList.A3,
        );
    };

    const getSentimentValue = (id, type) => {
        const sentiments = formData.sentiments || [];
        const sentiment = sentiments.find((sentiment) => sentiment.id == id && sentiment.type === type);
        return sentiment ? sentiment.value : null;
    };

    const renderParameterValue = (value, index, noTitle) => {
        const parameter = ParametersService.getParameterById(value.parameterId);
        const parameterValue = ParametersService.getParameterValueById(value.parameterId, value.id);

        return parameter ? (
            <SelectedLabelingOption
                key={index}
                disabled={disabled}
                onClear={() => handleParameterValueClear(value)}
                title={!noTitle ? parameter.name : null}
                label={parameterValue ? parameterValue.value : null}
            />
        ) : null;
    };

    const toggleSentimentsOpen = (type) => {
        setSentimentsOpen(
            update(sentimentsOpen, {
                [type]: { $set: !sentimentsOpen[type] },
            }),
        );
        disableInitial(type);
    };

    const disableInitial = (type) => {
        setShowInitial(
            update(showInitial, {
                [type]: { $set: false },
            }),
        );
    };

    const getSentimentsVisible = (type) => {
        const array = type === 'filter' ? getFilters() : getCategories();
        const ids = type === 'filter' ? filter.filters_ids : filter.categories_ids;
        let visibleArray = [];

        if (type === 'filter' && array.length === 1) visibleArray = array;
        else {
            array.map((item) => {
                if (ids && ids.includes(item.id)) visibleArray.push(item);
                else
                    formData.sentiments.map((sentiment) => {
                        sentiment.type === type && sentiment.id === item.id && visibleArray.push(item);
                    });
            });
        }

        sentimentsVisible[type] !== visibleArray.length &&
            setSentimentsVisible(
                update(sentimentsVisible, {
                    [type]: { $set: visibleArray.length },
                }),
            );

        return renderSentiments(visibleArray, type);
    };

    const renderSentiments = (array, type) => {
        return uniqBy(array, 'id').map((item) => (
            <Grid.Row key={item.id} mb={20}>
                <PositivitySelect
                    label={item.name}
                    onChange={({ value }) => handleSentimentChange({ id: item.id, value, type })}
                    value={getSentimentValue(item.id, type)}
                    name={`${type === 'filter' ? 'filters' : 'categories'}[${item.id}]`}
                />
            </Grid.Row>
        ));
    };

    return (
        <Styles.Container id="labelingContainer">
            <Styles.Header>
                <Grid.Col mr={10}>
                    <ParametersTemplates disabled={disabled} onChange={handleTemplateChange} />
                </Grid.Col>
                <Grid.Col>
                    <LabelingTimer
                        buttonDisabled={buttonDisabled}
                        isArticleLoaded={isArticleLoaded}
                        setLabelingTime={handleChange}
                    />
                </Grid.Col>
                <Grid.Col alignItems="flex-end" flex={1}>
                    <Button disabled={buttonDisabled} label={skip ? 'Skip' : 'Next'} onClick={submit} />
                </Grid.Col>
            </Styles.Header>
            <Styles.Content disabled={disabled} id="parametersContainer">
                {_.orderBy(
                    parameters.filter((parameter) => parameter.type === 'A1'),
                    ['id'],
                    ['asc'],
                ).map((parameter) => (
                    <Grid.Row mb={20} key={parameter.id}>
                        <ParameterA1MultiSelect
                            disabled={disabled}
                            selectedParameterValues={getA1ParameterValues(parameter.id)}
                            parameter={parameter}
                            onParameterValueAdd={handleParameterValueAdd}
                            onParameterValueClear={handleParameterValueClear}
                        />
                    </Grid.Row>
                ))}
                <Grid.Row mb={20}>
                    <Grid.Col flex={1}>
                        <ParametersA2AutoComplete
                            disabled={disabled}
                            onParameterValueAdd={handleParameterValueAdd}
                            selectedParametersValues={getA2ParametersValues()}
                            parameters={parameters.filter((parameter) => parameter.type === 'A2')}
                        />
                        <Styles.ValuesContainer>
                            {getA2ParametersValues().map((value, index) => renderParameterValue(value, index))}
                        </Styles.ValuesContainer>
                    </Grid.Col>
                </Grid.Row>
                {_.orderBy(
                    parameters.filter((parameter) => parameter.type === 'A3'),
                    ['id'],
                    ['asc'],
                ).map((parameter) => (
                    <Grid.Row mb={20} key={parameter.id}>
                        <Grid.Col flex={1}>
                            <ParameterA3AutoComplete
                                disabled={disabled}
                                onParameterValueAdd={handleParameterValueAdd}
                                parameter={parameter}
                                selectedParameterValues={getA3ParameterValues(parameter.id)}
                            />
                            <Styles.ValuesContainer>
                                {getA3ParameterValues(parameter.id).map((value, index) =>
                                    renderParameterValue(value, index, true),
                                )}
                            </Styles.ValuesContainer>
                        </Grid.Col>
                    </Grid.Row>
                ))}
                <Grid.Row mb={20}>
                    <Textarea
                        height={140}
                        disabled={disabled}
                        label="Comment"
                        value={formData.comment}
                        onChange={handleChange}
                        name="comment"
                    />
                </Grid.Row>
                {article && article.categories.length > 0 && (
                    <Grid.Col flex={1}>
                        <Styles.CollapseHeader
                            open={
                                (sentimentsOpen['category'] && !showInitial['category']) ||
                                (showInitial['category'] && sentimentsVisible['category'] > 0)
                            }
                            onClick={
                                showInitial['category'] && getCategories().length > sentimentsVisible['category']
                                    ? () => disableInitial('category')
                                    : () => toggleSentimentsOpen('category')
                            }
                        >
                            Category sentiments
                        </Styles.CollapseHeader>
                        <Collapse isOpened={sentimentsOpen['category']}>
                            <Styles.Container>
                                {showInitial['category']
                                    ? getSentimentsVisible('category')
                                    : renderSentiments(getCategories(), 'category')}
                            </Styles.Container>
                        </Collapse>
                    </Grid.Col>
                )}
                {article && article.filters.length > 0 && (
                    <Grid.Col flex={1}>
                        <Styles.CollapseHeader
                            open={
                                (sentimentsOpen['filter'] && !showInitial['filter']) ||
                                (showInitial['filter'] && sentimentsVisible['filter'] > 0)
                            }
                            onClick={
                                showInitial['filter'] && getFilters().length > sentimentsVisible['filter']
                                    ? () => disableInitial('filter')
                                    : () => toggleSentimentsOpen('filter')
                            }
                        >
                            Filter sentiments
                        </Styles.CollapseHeader>
                        <Collapse isOpened={sentimentsOpen['filter']}>
                            <Styles.Container>
                                {showInitial['filter']
                                    ? getSentimentsVisible('filter')
                                    : renderSentiments(getFilters(), 'filter')}
                            </Styles.Container>
                        </Collapse>
                    </Grid.Col>
                )}
            </Styles.Content>
            <Styles.Footer>
                <Styles.FooterSection>
                    <Grid.Col flex={1} mr={10}>
                        <Button disabled={buttonDisabled} label={skip ? 'Skip' : 'Next'} onClick={submit} />
                    </Grid.Col>
                    <Grid.Col mr={10}>
                        <Button
                            disabled={disabled || !formData.parameters || formData.parameters.length === 0}
                            type={Buttons.SECONDARY}
                            label="Save as template"
                            onClick={() => setAddModalVisible(true)}
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Button
                            disabled={disabled || _.isEqual(formData, initialFormData)}
                            type={Buttons.TERTIARY}
                            label="Clear"
                            onClick={clear}
                        />
                    </Grid.Col>
                </Styles.FooterSection>
            </Styles.Footer>
            <ParametersTemplateModal
                data={formData}
                visible={addModalVisible}
                onClose={() => setAddModalVisible(false)}
            />
            <ParametersNotSavedModal
                visible={parametersModalVisible}
                onClose={() => setParametersModalVisible(false)}
            />
        </Styles.Container>
    );
}

LabelingForm.propTypes = {
    languages: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    users: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    categories: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    domains: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    filters: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    article: PropTypes.object,
    labelingData: PropTypes.object,
    similarArticles: PropTypes.array,
    articles: PropTypes.array,
    isArticleLoaded: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { languages, domains, categories, filters, users, parameters, article, articles, similarArticles } = state;
    return {
        languages: Mapper.mapLanguagesToOptions(languages.languages),
        users: Mapper.mapUsersToOptions(users.users),
        domains: Mapper.mapDomainsToOptions(domains.domains),
        categories: Mapper.mapCategoriesToOptions(categories.categories),
        filters: Mapper.mapFiltersToOptions(filters.filters),
        parameters: parameters.parameters,
        article: article.article,
        labelingData: Mapper.mapFailedLabelingForm(article.article, articles.articles),
        isArticleLoaded: article.isLoaded,
        similarArticles: similarArticles.similarArticles,
        articles: articles.articles,
    };
}

export default connect(mapStateToProps)(LabelingForm);

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Label = styled.label`
    font-size: 15px;
    line-height: 1.33;
    display: block;
    color: ${Colors.BLACK};
    margin-bottom: 10px;
`;

import styled, { css } from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Tab = styled.div`
    flex: 1;
    height: 40px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid ${(props) => (props.active ? `2px ${Colors.PRIMARY}` : `1px ${Colors.TAB}`)};

    &:hover {
        border-bottom: 2px solid ${Colors.PRIMARY};
        cursor: pointer;

        span {
            font-weight: 700;
            padding-top: 1px;
        }
    }

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const Title = styled.span`
    color: ${Colors.BLACK};
    font-size: 13px;
    padding-top: ${(props) => (props.active ? 1 : 0)}px;
    font-weight: ${(props) => (props.active ? 700 : 500)};
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Title, CloseIcon, Content, Footer } from './styles';
import ReactModal from 'react-modal';
import hexToRgba from 'hex-to-rgba';
import { Colors, Images } from 'styles/constant';
import { Button } from 'components';

const modalStyle = {
    overlay: {
        backgroundColor: hexToRgba(Colors.BLACK, 0.5),
    },
    content: {
        height: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1001,
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
};

function Modal(props) {
    const { style, renderContent, visible, title, onClose, onShow, renderFooter } = props;

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [visible]);

    const defaultFooter = <Button style={{ marginLeft: 'auto' }} onClick={onClose} label="Close" type="PRIMARY" />;

    return (
        <ReactModal
            ariaHideApp={false}
            onRequestClose={onClose}
            onAfterOpen={onShow}
            isOpen={visible}
            style={modalStyle}
        >
            <Container style={style}>
                <Header>
                    <Title>{title}</Title>
                    <CloseIcon onClick={onClose} src={Images.CLOSE_GREY} />
                </Header>
                <Content>{renderContent()}</Content>
                <Footer>{renderFooter ? renderFooter() : defaultFooter}</Footer>
            </Container>
        </ReactModal>
    );
}

Modal.propTypes = {
    renderContent: PropTypes.func.isRequired,
    renderFooter: PropTypes.func,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onShow: PropTypes.func,
    visible: PropTypes.bool,
    style: PropTypes.object,
};

Modal.defaultProps = {
    style: {},
    visible: false,
    onShow: () => {},
    title: null,
};

export default Modal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Buttons, Images } from 'styles/constant';
import { Container, Name } from './styles';
import { Button } from 'components';
import { useDeleteSavedSearch } from 'hooks';

function SavedSearchListItem(props) {
    const { savedSearch, onSearch, onEdit } = props;
    const [deleteMode, setDeleteMode] = useState(false);
    const { isDeleting, deleteSavedSearch } = useDeleteSavedSearch();

    const handleOnSearch = () => {
        const filter = JSON.parse(savedSearch.data).search;
        onSearch(filter);
    };

    const handleOnEdit = () => {
        const filter = JSON.parse(savedSearch.data).search;
        onEdit({ filter, id: savedSearch.id, name: savedSearch.name });
    };

    const handleDelete = () => {
        deleteSavedSearch(savedSearch.id, () => setDeleteMode(false));
    };

    const renderActions = () => (
        <Grid.Row>
            <Grid.Col mr={10}>
                <Button onClick={() => setDeleteMode(true)} type={Buttons.TERTIARY} leftIcon={Images.REMOVE} />
            </Grid.Col>
            <Grid.Col mr={10}>
                <Button onClick={handleOnEdit} type={Buttons.TERTIARY} leftIcon={Images.EDIT} />
            </Grid.Col>
            <Grid.Col>
                <Button onClick={handleOnSearch} leftIcon={Images.SEARCH_WHITE} />
            </Grid.Col>
        </Grid.Row>
    );

    const renderDeleteActions = () => (
        <Grid.Row>
            <Grid.Col mr={10}>
                <Button onClick={() => setDeleteMode(false)} type={Buttons.TERTIARY} label="Cancel" />
            </Grid.Col>
            <Grid.Col>
                <Button loading={isDeleting} type={Buttons.TERTIARY} onClick={handleDelete} label="Yes, delete" />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Container key={savedSearch.id}>
            <Grid.Col flex={1} mr={10}>
                <Name>{savedSearch.name}</Name>
            </Grid.Col>
            <Grid.Col>{deleteMode ? renderDeleteActions() : renderActions()}</Grid.Col>
        </Container>
    );
}

SavedSearchListItem.propTypes = {
    savedSearch: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default SavedSearchListItem;

import React, { useEffect, useState } from 'react';
import { Grid } from 'styles/components';
import { Input, SingleSelect, Button } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Buttons } from 'styles/constant';
import { Section } from '../../../pages/CreateChart/styles';

const initialFormData = [
    {
        organisation: null,
        language: null,
        title: { value: '', id: null },
        'xAxis[0]': { value: '', id: null },
        'yAxis[0]': { value: '', id: null },
        'yAxis[1]': { value: '', id: null },
        legend: { value: '', id: null },
    },
];

function Translations(props) {
    const { translations, updateTranslations, organisations, languages } = props;
    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        const newTr = {};
        translations.map((t) => {
            const language = t.language_id;

            if (!newTr[language]) {
                newTr[language] = {};
            }
            const item = newTr[language];

            newTr[language] = {
                organisation: t.organisation_id ? t.organisation_id : item.organisation ? item.organisation : null,
                language: language,
                title:
                    t.slot === 'title'
                        ? { value: t.value, id: t.id }
                        : item.title
                        ? item.title
                        : {
                              value: '',
                              id: null,
                          },
                'xAxis[0]':
                    t.slot === 'xAxis[0]'
                        ? {
                              value: t.value,
                              id: t.id,
                          }
                        : item['xAxis[0]']
                        ? item['xAxis[0]']
                        : { value: '', id: null },
                'yAxis[0]':
                    t.slot === 'yAxis[0]'
                        ? {
                              value: t.value,
                              id: t.id,
                          }
                        : item['yAxis[0]']
                        ? item['yAxis[0]']
                        : { value: '', id: null },
                'yAxis[1]':
                    t.slot === 'yAxis[1]'
                        ? {
                              value: t.value,
                              id: t.id,
                          }
                        : item['yAxis[1]']
                        ? item['yAxis[1]']
                        : { value: '', id: null },
                legend:
                    t.slot === 'legend'
                        ? {
                              value: t.value,
                              id: t.id,
                          }
                        : item.legend
                        ? item.legend
                        : { value: '', id: null },
            };
        });
        translations.length > 0 && setFormData(Object.values(newTr));
    }, [translations]);

    const changeTranslationsSelect = (e, i) => {
        const { name, value } = e;
        const copy = [...formData];
        copy[i][name] = value;
        setFormData(copy);
        updateTranslations(copy);
    };

    const changeTranslations = (e, i) => {
        const { name, value } = e;
        const copy = [...formData];
        copy[i][name].value = value;
        setFormData(copy);
        updateTranslations(copy);
    };

    const addTranslation = () => {
        const newData = [...formData, ...initialFormData];
        setFormData(newData);
        updateTranslations(newData);
    };

    const removeTranslation = (index) => {
        const newData = formData.filter((item, i) => i !== index);
        setFormData(newData);
        updateTranslations(newData);
    };

    return (
        <Section>
            {formData.map((translation, i) => {
                return (
                    <div key={i}>
                        <Grid.Row mb={20}>
                            <Grid.Col mr={10} flex={1}>
                                <SingleSelect
                                    label="Organisation"
                                    name="organisation"
                                    value={translation.organisation}
                                    onChange={(e) => changeTranslationsSelect(e, i)}
                                    options={organisations.map((o) => {
                                        return { label: o.name, value: o.id };
                                    })}
                                />
                            </Grid.Col>
                            <Grid.Col ml={10} flex={1}>
                                <SingleSelect
                                    label="Language"
                                    name="language"
                                    value={translation.language}
                                    onChange={(e) => changeTranslationsSelect(e, i)}
                                    options={languages.map((o) => {
                                        return { label: o.name, value: o.id };
                                    })}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row mb={20}>
                            <Input
                                label="Title"
                                name="title"
                                value={translation.title.value}
                                onChange={(e) => changeTranslations(e, i)}
                            />
                        </Grid.Row>
                        <Grid.Row mb={20}>
                            <Grid.Col mr={10} flex={1}>
                                <Input
                                    label="xAxis"
                                    name="xAxis[0]"
                                    value={translation['xAxis[0]'].value}
                                    onChange={(e) => changeTranslations(e, i)}
                                />
                            </Grid.Col>
                            <Grid.Col ml={10} flex={1}>
                                <Input
                                    label="yAxis"
                                    name="yAxis[0]"
                                    value={translation['yAxis[0]'].value}
                                    onChange={(e) => changeTranslations(e, i)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row mb={20}>
                            <Grid.Col mr={10} flex={1}>
                                <Input
                                    label="yAxis (secondary)"
                                    name="yAxis[1]"
                                    value={translation['yAxis[1]'].value}
                                    onChange={(e) => changeTranslations(e, i)}
                                />
                            </Grid.Col>
                            <Grid.Col ml={10} flex={1}>
                                <Input
                                    label="Legend"
                                    name="legend"
                                    value={translation.legend.value}
                                    onChange={(e) => changeTranslations(e, i)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row mb={20} justifyContent="space-between">
                            <Grid.Col mr={10}>
                                <Button label="Add translation" type={Buttons.PRIMARY} onClick={addTranslation} />
                            </Grid.Col>
                            <Grid.Col ml={10}>
                                <Button
                                    label="Remove translation"
                                    type={Buttons.SECONDARY}
                                    onClick={() => removeTranslation(i)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                );
            })}
        </Section>
    );
}

Translations.propTypes = {
    translations: PropTypes.array.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.array,
    updateTranslations: PropTypes.func,
};

function mapStateToProps(state) {
    const { languages } = state;
    return { languages: languages.languages };
}

export default connect(mapStateToProps)(Translations);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import * as Styles from './styles';
import { Button, Input, Modal } from 'components';
import { useAddParametersTemplate } from 'hooks';

const ParametersTemplateModal = (props) => {
    const { visible, onClose, data } = props;
    const [name, setName] = useState(null);
    const { addParametersTemplate, isLoading } = useAddParametersTemplate();

    const submit = () => {
        addParametersTemplate({ data: data, name }, onClose);
    };

    const renderContent = () => (
        <Styles.Content>
            <Input label="Template name" value={name} onChange={({ value }) => setName(value)} name="name" />
        </Styles.Content>
    );

    const renderFooter = () => (
        <Grid.Row>
            <Grid.Col flex={1}>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Save" loading={isLoading} disabled={!name} onClick={submit} />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Modal
            title="Create a new template"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
};

ParametersTemplateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    data: PropTypes.object.isRequired,
};

ParametersTemplateModal.defaultProps = {
    visible: false,
};

export default ParametersTemplateModal;

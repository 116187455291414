import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'components';
import { Wrapper } from '../styles';
import { OPTIONS } from './config';

function ColumnFilter({ column: { setFilter, preFilteredRows, id }, handleCheckedCells, checkedCells, languages }) {
    const idLowerCase = id.toLowerCase();
    const [, setRows] = useState([]);

    useEffect(() => {
        setRows(preFilteredRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add({ value: row.values[id], label: row.values[id] ? row.values[id].toString() : '' });
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    const filter = (e) => {
        let allValues = [];

        if (
            idLowerCase === 'languages' ||
            idLowerCase === 'create date' ||
            idLowerCase === 'update date' ||
            idLowerCase === 'specialization in topic subcategory' ||
            idLowerCase === 'last login' ||
            idLowerCase === 'last sent'
        ) {
            let arr = [];
            e.value.map((val) => {
                options.map((opt) => {
                    opt.value.includes(val) && !arr.includes(opt.value) && arr.push(opt.value);
                    return opt;
                });
                return val;
            });
            allValues = arr;
        } else {
            allValues = e.value;
        }

        allValues
            .map((o) => {
                return parseFloat(o.value).toString() === o ? parseFloat(o) : o;
            })
            .filter(Boolean);

        setFilter(e.value.length > 0 ? allValues : undefined);
    };

    const multiSelectOptions = OPTIONS(options, idLowerCase, languages);

    return (
        <Wrapper
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <MultiSelect
                label=""
                options={multiSelectOptions}
                value={checkedCells ? checkedCells.checkedCells[id] : checkedCells}
                onChange={(e) => {
                    handleCheckedCells(e);
                    filter(e);
                }}
                name={id}
                placeholder=""
            />
        </Wrapper>
    );
}

ColumnFilter.propTypes = {
    column: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    handleCheckedCells: PropTypes.func.isRequired,
    checkedCells: PropTypes.object,
    languages: PropTypes.array,
};

export default ColumnFilter;

import update from 'immutability-helper';
import { AuthTypes, OrganisationsTypes } from 'core/types';

const initialState = {
    organisations: [],
    isLoading: true,
    isLoaded: false,
    isUpdating: false,
    isUpdated: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case OrganisationsTypes.GET_ORGANISATIONS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case OrganisationsTypes.GET_ORGANISATIONS: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                organisations: { $set: payload.organisations },
            });
        }

        case OrganisationsTypes.UPDATE_ORGANISATIONS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case OrganisationsTypes.CREATE_ORGANISATION:
        case OrganisationsTypes.UPDATE_ORGANISATION:
        case OrganisationsTypes.REMOVE_ORGANISATION: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case OrganisationsTypes.GET_ORGANISATIONS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

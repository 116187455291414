export const COLUMNS = [
    {
        Header: 'Domain',
        accessor: (row) => row.full_domain || 'n/d',
        filter: 'multiple',
        width: 200,
    },
    {
        Header: 'Description',
        accessor: 'name',
        filter: 'multiple',
        width: 250,
    },
    {
        Header: 'Languages',
        accessor: (row) =>
            row.lang
                ? row.lang
                      .toUpperCase()
                      .split(',')
                      .map((l) => l.split(':')[0])
                      .join(', ')
                : '',
        filter: 'multiple',
    },
    {
        Header: 'Suspicious',
        accessor: (row) => (row.type == true ? 'yes' : 'no'),
        filter: 'multiple',
        input: true,
    },
    {
        Header: 'Dominating news by geographic coverage',
        accessor: (row) => row.geographic_category || 'n/d',
        filter: 'multiple',
        input: true,
    },
    {
        Header: 'Geographic coverage subcategory',
        accessor: (row) => row.geographic_subcategory || 'n/d',
        filter: 'multiple',
        input: true,
    },
    {
        Header: 'Specialization in topic',
        accessor: (row) => (row.have_specialization == true ? 'yes' : 'no'),
        filter: 'multiple',
        input: true,
    },
    {
        Header: 'Specialization in topic subcategory',
        accessor: (row) => row.specialization_subcategory || 'n/d',
        filter: 'multiple',
        input: true,
    },
    {
        Header: 'DebunkReach',
        accessor: (row) => {
            const reach = row.debunk_reach_json;
            const reachArray = [];

            reach &&
                Object.keys(reach) &&
                Object.values(reach).map((value, i) => {
                    reachArray.push(`${Object.keys(reach)[i]}: ${value}`);
                    return value;
                });
            return reachArray.join('\r\n');
        },
        filter: 'multiple',
    },
    {
        Header: 'Status',
        accessor: (row) => (row.complete == 1 ? 'Active' : 'Historical data only'),
        filter: 'multiple',
    },
];

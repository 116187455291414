import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import * as Styles from './styles';
import { Button, Input, Modal } from 'components';
import { useAddSavedSearch, useUpdateSavedSearch } from 'hooks';

const AddSavedSearchModal = (props) => {
    const { visible, onClose, search, editingSearch } = props;
    const [name, setName] = useState(null);
    const { addSavedSearch, isLoading } = useAddSavedSearch();
    const { updateSavedSearch, isUpdating } = useUpdateSavedSearch();

    useEffect(() => {
        if (editingSearch) {
            setName(editingSearch.name);
        }
    }, [editingSearch]);

    const submit = () => {
        if (editingSearch) {
            updateSavedSearch(editingSearch.id, { search, name }, callBack);
        } else {
            addSavedSearch({ search, name }, callBack);
        }
    };

    const callBack = () => {
        setName(null);
        onClose();
    };

    const renderContent = () => (
        <Styles.Content>
            <Input label="Search name" value={name} onChange={({ value }) => setName(value)} name="name" />
        </Styles.Content>
    );

    const renderFooter = () => (
        <Grid.Row>
            <Grid.Col flex={1}>
                <Button label="Cancel" type={Buttons.TERTIARY} onClick={onClose} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Save" loading={isLoading || isUpdating} disabled={!name} onClick={submit} />
            </Grid.Col>
        </Grid.Row>
    );

    return (
        <Modal
            title="Save search"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
};

AddSavedSearchModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    search: PropTypes.object,
    editingSearch: PropTypes.object,
    visible: PropTypes.bool,
};

AddSavedSearchModal.defaultProps = {
    visible: false,
    search: null,
    editingSearch: null,
};

export default AddSavedSearchModal;

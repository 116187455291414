import styled from 'styled-components/macro';

export const ValueContainer = styled.div`
    display: flex;
    height: 28px;
    flex-shrink: 1;
    overflow: hidden;
`;

export const MoreContainer = styled.div`
    display: flex;
    margin-left: 5px;
    height: 28px;
    flex-shrink: 0;
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div``;

export const Label = styled.span`
    font-size: 13px;
    color: ${Colors.BLACK};
    margin-left: 15px;
`;

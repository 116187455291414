import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ children, tooltipContent, id = 'reactTooltip', place = 'top', className }) => {
    return (
        <>
            <div data-tip data-for={id}>
                {children}
            </div>
            <ReactTooltip id={id} place={place} effect="solid" className={className}>
                {tooltipContent}
            </ReactTooltip>
        </>
    );
};

Tooltip.propTypes = {
    children: PropTypes.element,
    tooltipContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    id: PropTypes.string,
    place: PropTypes.string,
    className: PropTypes.string,
};

export default Tooltip;

import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';

export const ArticleType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    crawlDate: PropTypes.string.isRequired,
    detected: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    publishDate: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(SharedTypes.CategoryType).isRequired,
    votes: PropTypes.arrayOf(SharedTypes.VoteType).isRequired,
    filters: PropTypes.arrayOf(SharedTypes.FilterType).isRequired,
    labelingStatus: PropTypes.string.isRequired,
    labeling: PropTypes.object,
    technicalData: PropTypes.object,
});

import styled from 'styled-components';
import { Grid } from 'styles/components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Content = styled(Grid.Row)`
    width: 100%;
    height: 100%;
`;

export const DropdownContainer = styled.div`
    display: flex;
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';

const twentyMinutes = 1140;

function Timer(props) {
    const { timeLeft, showExpireModal, onExpire } = props;
    const [remainingTime, setRemainingTime] = useState(timeLeft);
    const [dispatchedModal, setDispatchedModal] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => update(remainingTime, dispatchedModal), 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime, dispatchedModal]);

    useEffect(() => {
        if (remainingTime <= 0) {
            onExpire();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTime]);

    const update = (remainingTime, dispatchedModal) => {
        if (remainingTime === 0) return;

        setRemainingTime(remainingTime - 1);

        if (twentyMinutes >= remainingTime && !dispatchedModal) {
            setDispatchedModal(true);
            showExpireModal();
        }
    };

    const format = (time) => {
        return new Date(time * 1000).toISOString().substr(11, 8);
    };

    return (
        <Styles.Container>
            {twentyMinutes >= remainingTime && (
                <Styles.Label>{remainingTime <= 0 ? '00:00' : format(remainingTime)}</Styles.Label>
            )}
        </Styles.Container>
    );
}

Timer.propTypes = {
    showExpireModal: PropTypes.func.isRequired,
    onExpire: PropTypes.func.isRequired,
    timeLeft: PropTypes.number.isRequired,
};

export default Timer;

import React from 'react';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { PaginationButton, Dots } from './styles';
import Button from '../Button';

function Pagination(props) {
    const { pageIndex, pageCount, gotoPage, style } = props;

    return (
        <Grid.Container style={style}>
            {pageCount > 1 && (
                <Grid.Row mt={20} mb={20}>
                    {pageIndex > 0 && (
                        <Button
                            style={{ marginRight: 10 }}
                            type="PRIMARY"
                            onClick={() => gotoPage(pageIndex - 1)}
                            label="Previous page"
                        />
                    )}
                    {pageIndex > 2 && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(0)}>
                            1
                        </PaginationButton>
                    )}
                    {pageIndex > 3 && <Dots largeScreen>...</Dots>}
                    {pageIndex > 1 && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(pageIndex - 2)}>
                            {pageIndex - 1}
                        </PaginationButton>
                    )}
                    {pageIndex > 0 && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(pageIndex - 1)}>
                            {pageIndex}
                        </PaginationButton>
                    )}

                    <PaginationButton active={true} onClick={() => {}}>
                        {pageIndex + 1}
                    </PaginationButton>

                    {pageIndex < pageCount - 1 && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(pageIndex + 1)}>
                            {pageIndex + 2}
                        </PaginationButton>
                    )}
                    {pageIndex < pageCount - 2 && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(pageIndex + 2)}>
                            {pageIndex + 3}
                        </PaginationButton>
                    )}
                    {pageIndex + 4 < pageCount && <Dots largeScreen>...</Dots>}
                    {pageIndex + 3 < pageCount && (
                        <PaginationButton largeScreen type="TERTIARY" onClick={() => gotoPage(pageCount - 1)}>
                            {pageCount}
                        </PaginationButton>
                    )}
                    {pageIndex < pageCount - 1 && (
                        <Button type="PRIMARY" onClick={() => gotoPage(pageIndex + 1)} label="Next page" />
                    )}
                </Grid.Row>
            )}
        </Grid.Container>
    );
}

Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    gotoPage: PropTypes.func.isRequired,
    style: PropTypes.object,
};

export default Pagination;

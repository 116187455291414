import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Link } from 'react-router-dom';
import hexToRgba from 'hex-to-rgba';

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    overflow-y: auto;
    padding: 20px 0 64px;
`;

export const ActionLink = styled(Link)`
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    text-decoration: none;
    color: ${Colors.PRIMARY};
    transition: 0.3s;

    &:last-child {
        margin: 0 !important;
    }

    &:hover,
    &:active,
    &:focus {
        color: ${hexToRgba(Colors.PRIMARY, 0.8)};
    }
`;

import React from 'react';
import { EmailButton } from './styles';
import { Grid } from 'styles/components';
import { Icon } from '../../components';
import { EDIT_BLACK, EYE_HIDE } from '../../styles/constant/images';
import { useHistory } from 'react-router-dom';

export const COLUMNS = [
    // {
    //   Header: 'ID',
    //   accessor: 'id',
    //   filter: "multiple",
    // },
    {
        Header: 'User',
        accessor: (row) => {
            let user = [row.username];
            if (row.user_email) user.push(row.user_email);
            return user.join('\r\n');
        },
        filter: 'multiple',
    },
    {
        Header: 'Newsletter',
        accessor: (row) => {
            let user = [];
            let emails = [];

            if (row.email) user = row.email.split(', ');
            if (row.emails) emails = [...row.emails.map((e) => e.parsed_schedule)].join();

            return user.join('\r\n') + '\r\n' + emails;
        },
        filter: 'multiple',
        Cell: function CellRenderer(content) {
            const row = content.row.original;
            let user = [];
            let emails = [];
            const history = useHistory();

            if (row.email) user = row.email.split(', ');
            if (row.emails)
                emails = row.emails.map((e, i) => {
                    return (
                        <Grid.Row key={i} style={{ flexWrap: 'wrap' }}>
                            {e.parsed_schedule.split(';').map((item, index) => {
                                return (
                                    <EmailButton key={index} active={e.status}>
                                        {item}
                                    </EmailButton>
                                );
                            })}
                            <Grid.Row mt={3} style={{ display: 'inline-flex', width: 'auto' }}>
                                <Icon
                                    small
                                    gray
                                    source={EYE_HIDE}
                                    title="Preview"
                                    onClick={() => history.push(`test/send-email-one-user/${row.id}/${e.id}`)}
                                />
                                <Icon
                                    small
                                    gray
                                    source={EDIT_BLACK}
                                    title="Edit"
                                    onClick={() => history.push(`/email/${e.id}`)}
                                />
                                {/*<PreviewLink href={`test/send-email-one-user/${row.id}/${e.id}`} target='_blank'/>*/}
                                {/*<EditLink href={`/email/${e.id}`} target='_blank'/>*/}
                            </Grid.Row>
                        </Grid.Row>
                    );
                });

            return (
                <div>
                    <div>{user.join('\r\n') + '\r\n'}</div>
                    {emails}
                </div>
            );
        },
    },
    {
        Header: 'Role',
        accessor: 'role' || '',
        filter: 'multiple',
    },
    {
        Header: 'Last login',
        accessor: (row) => {
            let user = [];
            if (row.last_login) {
                user.push(row.last_login.slice(0, 10));
                user.push(row.last_login.slice(11, 16));
            }
            return user.join(' ');
        },
        filter: 'multiple',
    },
    // {
    //   Header: 'Show graphs',
    //   accessor: row=>row.show_graphs ? 'yes' : 'no',
    //   filter: "multiple",
    // },
    {
        Header: 'Organisation',
        accessor: (row) => (row.organisation ? row.organisation.name : ''),
        filter: 'multiple',
    },
    {
        Header: 'Platform',
        accessor: (row) => (row.project ? row.project.name : ''),
        filter: 'multiple',
    },
];

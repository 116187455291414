import React from 'react';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import * as Styles from './styles';
import { Form, Grid } from 'styles/components';
import { Images } from 'styles/constant';
import ParameterSelect from './ParameterSelect';

function CustomParametersSelect(props) {
    const { parameters, value, onChange } = props;
    const takenParameters = value
        .map((parameter) => Object.keys(parameter))
        .flat(1)
        .map((id) => Number(id));

    const handleParameterChange = (oldParameterId, newParameterId) => {
        const filteredValue = value.filter((parameter) => Number(Object.keys(parameter)[0]) !== oldParameterId);
        filteredValue.push({ [newParameterId]: [''] });
        onChange(filteredValue);
    };

    const handleAddParameter = () => {
        const availableId = parameters
            .map((parameter) => parameter.id)
            .filter((id) => !takenParameters.includes(id))[0];
        value.push({ [availableId]: [''] });
        onChange(value);
    };

    const handleRemoveParameter = (parameterId) => {
        const filteredValue = value.filter((parameter) => Number(Object.keys(parameter)[0]) !== parameterId);
        onChange(filteredValue);
    };

    const handleParameterValuesChange = (parameterId, newValue) => {
        const indexForChange = value.findIndex((parameter) => Number(Object.keys(parameter)[0]) === parameterId);
        value[indexForChange] = { [parameterId]: newValue };
        onChange(value);
    };

    const renderAddButton = () => (
        <Styles.AddButton onClick={handleAddParameter}>
            <Grid.Col mr={10}>
                <Styles.Icon src={Images.ADD_CIRCLE} />
            </Grid.Col>
            <Grid.Col>
                <Styles.AddLabel>Add custom parameter</Styles.AddLabel>
            </Grid.Col>
        </Styles.AddButton>
    );

    return (
        <Styles.Container>
            <Form.Label>Custom parameters</Form.Label>
            <Styles.Content>
                {value.map((parameter, index) => (
                    <ParameterSelect
                        takenParameters={takenParameters}
                        onParameterChange={handleParameterChange}
                        key={index}
                        value={parameter}
                        onRemove={handleRemoveParameter}
                        parameters={parameters}
                        onChange={handleParameterValuesChange}
                    />
                ))}
            </Styles.Content>
            {Object.keys(value).length < parameters.length && renderAddButton()}
        </Styles.Container>
    );
}

CustomParametersSelect.propTypes = {
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.object),
};

CustomParametersSelect.defaultProps = {
    value: [],
};

export default CustomParametersSelect;

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Container } from './styles';
import { AuthenticationRoute } from 'containers';
import { LoginPage, ForgotPasswordPage, ResetPasswordPage, ErrorPage } from 'pages';
import { Switch } from 'react-router-dom';

const AuthLayout = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Container>
                <Switch>
                    <AuthenticationRoute path="/auth/login" component={LoginPage} />
                    <AuthenticationRoute path="/auth/forgot-password" component={ForgotPasswordPage} />
                    <AuthenticationRoute path="/auth/reset-password/:token" component={ResetPasswordPage} />
                </Switch>
            </Container>
        </ErrorBoundary>
    );
};

export default AuthLayout;

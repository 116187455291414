import React, { useEffect } from 'react';
import { Grid } from 'styles/components';
import { ChartForm } from 'containers';
import { ChartsActions, OrganisationsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { connect } from 'react-redux';
import { Container } from './styles';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function ChartListPage(props) {
    const {
        charts,
        getCharts,
        getChartTypes,
        getChartOptions,
        options,
        organisations,
        getOrganisations,
        types,
        isLoaded,
        isLoading,
        isOptionsLoaded,
        isTypesLoaded,
        isOptionsLoading,
        isTypesLoading,
    } = props;
    const history = useHistory();

    useEffect(() => {
        getCharts();
        getChartOptions();
        getOrganisations();
        getChartTypes();
    }, [getCharts, getChartOptions, getOrganisations, getChartTypes]);

    return (
        <Container>
            {(isLoading || isTypesLoading || isOptionsLoading) && (
                <Grid.Row mt={30} justifyContent="center">
                    <Loader />
                </Grid.Row>
            )}
            {isLoaded && isOptionsLoaded && isTypesLoaded && (
                <ChartForm
                    charts={charts}
                    options={options}
                    types={types}
                    organisations={organisations}
                    history={history}
                />
            )}
        </Container>
    );
}

ChartListPage.propTypes = {
    charts: PropTypes.arrayOf(SharedTypes.ChartsListItemType).isRequired,
    getChartOptions: PropTypes.func.isRequired,
    getChartTypes: PropTypes.func.isRequired,
    getOrganisations: PropTypes.func.isRequired,
    getCharts: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isOptionsLoaded: PropTypes.bool,
    isTypesLoaded: PropTypes.bool,
    isOptionsLoading: PropTypes.bool,
    isTypesLoading: PropTypes.bool,
};

function mapStateToProps(state) {
    const { charts, organisations } = state;
    const { options, types, isLoading, isLoaded, isOptionsLoaded, isTypesLoaded, isOptionsLoading, isTypesLoading } =
        charts;
    return {
        charts: charts.charts,
        organisations: organisations.organisations,
        options,
        types,
        isLoaded,
        isLoading,
        isOptionsLoaded,
        isTypesLoaded,
        isOptionsLoading,
        isTypesLoading,
    };
}

const mapDispatchToProps = {
    getCharts: ChartsActions.getCharts,
    getChartOptions: ChartsActions.getChartOptions,
    getChartTypes: ChartsActions.getChartTypes,
    getOrganisations: OrganisationsActions.getOrganisations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartListPage);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';

import SingleSelectOptions from '../SingleSelect/SingleSelectOptions';
import { SharedTypes } from 'utils';
import Dropdown from '../Dropdown';
import Input from 'components/Input';

function AutoComplete(props) {
    const { label, error, placeholder, options, onChange, name, value, disabled } = props;
    const ref = useRef();

    const handleOnSelect = (optionValue) => {
        const option = options.find((option) => option.value === optionValue);
        onChange({ name, value: option });
        ref.current.close();
    };

    const handleInputChange = (event) => {
        const { value } = event;
        const option = options.find((option) => option.label === value);
        const dispatchValue = option ? option : { value: null, label: value };
        onChange({ name, value: dispatchValue });
    };

    const onFocus = () => {
        ref.current.open();
    };

    const renderButton = () => (
        <Input
            maxLength={255}
            autoComplete="off"
            onFocus={onFocus}
            value={value.label}
            placeholder={placeholder}
            onChange={handleInputChange}
            name={name}
            disabled={disabled}
            error={error}
            dirty
        />
    );

    const renderDropdown = () => (
        <SingleSelectOptions
            searchPhrase={value.label}
            searchable={false}
            value={value.value}
            onChange={handleOnSelect}
            options={options}
        />
    );

    return (
        <Styles.Container>
            {label && <Styles.Label>{label}</Styles.Label>}
            <Dropdown
                disabled={disabled}
                openOnClick={false}
                ref={ref}
                renderButton={renderButton}
                renderDropdown={renderDropdown}
            />
        </Styles.Container>
    );
}

AutoComplete.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: SharedTypes.OptionType,
};

AutoComplete.defaultProps = {
    onBlur: () => {},
    disabled: false,
    label: null,
    dirty: false,
    error: null,
    value: null,
    placeholder: null,
};

export default AutoComplete;

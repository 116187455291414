import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Content = styled.div`
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    padding: 0 20px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LoaderContainer = styled.div`
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
`;

export const NoDataContainer = styled.div`
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
`;

export const NoDataTitle = styled.p`
    font-size: 14px;
    color: ${Colors.SECONDARY};
`;

import update from 'immutability-helper';
import { EmailsTypes, AuthTypes } from 'core/types';

const initialState = {
    emails: [],
    email: {},
    isLoading: false,
    isLoaded: false,
    isUpdating: false,
    isUpdated: true,
    redirectUrl: '',
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case EmailsTypes.GET_EMAILS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                isUpdated: { $set: false },
                isUpdating: { $set: false },
                redirectUrl: { $set: '' },
            });
        }

        case EmailsTypes.GET_EMAILS: {
            return update(state, {
                isLoading: { $set: false },
                emails: { $set: payload.emails },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case EmailsTypes.GET_EMAIL: {
            return update(state, {
                isLoading: { $set: false },
                email: { $set: payload.email },
                isLoaded: { $set: true },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
            });
        }

        case EmailsTypes.CONVERT_EMAIL_HASH_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                redirectUrl: { $set: '' },
            });
        }

        case EmailsTypes.CONVERT_EMAIL_HASH: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                redirectUrl: { $set: payload.url },
            });
        }

        case EmailsTypes.CONVERT_EMAIL_HASH_ERROR: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: false },
                redirectUrl: { $set: '' },
            });
        }

        case EmailsTypes.UPDATE_EMAILS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case EmailsTypes.CREATE_EMAIL:
        case EmailsTypes.UPDATE_EMAIL:
        case EmailsTypes.REMOVE_EMAIL: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case EmailsTypes.GET_EMAILS_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, LabelContainer } from './styles';
import { Grid } from 'styles/components';
import { SharedTypes } from 'utils';
import Checkbox from 'components/Checkbox';

function SelectOption(props) {
    const { option, style, onClick, selected, multi, disabled } = props;

    return (
        <Container
            selected={selected}
            style={style}
            onClick={onClick}
            disabled={disabled}
            title={option.title || option.label}
        >
            {multi && (
                <Grid.Col mr={10}>
                    <Checkbox disabled checked={selected} name={option.label} />
                </Grid.Col>
            )}
            <LabelContainer>
                <Label>
                    {option.label}
                    <span>
                        {option.lang ? ` - ${option.lang}` : ''}
                        {option.description ? ` - ${option.description}` : ''}
                    </span>
                </Label>
            </LabelContainer>
            {(!!option.count || option.count === 0) && (
                <Grid.Col alignItems="flex-end" flex={1} ml={10}>
                    <Label>({option.count})</Label>
                </Grid.Col>
            )}
        </Container>
    );
}

SelectOption.propTypes = {
    option: SharedTypes.OptionType.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    style: PropTypes.object,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
};

SelectOption.defaultProps = {
    style: {},
    multi: false,
    selected: false,
    disabled: false,
    value: null,
};

export default SelectOption;

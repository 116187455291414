import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Badge } from 'components';
import { SharedTypes, ArticleUtils } from 'utils';

const Filters = (props) => {
    const { className, filters } = props;

    const modifiedFilters = ArticleUtils.addLanguagesToDuplicateFilters(filters);

    return (
        <Styles.Container className={className}>
            {modifiedFilters.map((filter) => (
                <Styles.Filter key={filter.id}>
                    <Badge label={filter.name} />
                </Styles.Filter>
            ))}
        </Styles.Container>
    );
};

Filters.propTypes = {
    className: PropTypes.string,
    filters: PropTypes.arrayOf(SharedTypes.FilterType),
};

Filters.defaultProps = {
    className: null,
    filters: [],
};

export default Filters;

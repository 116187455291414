import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    border-radius: 6px;
    padding: 10px;
    background-color: ${Colors.LIGHT_GREY};
    overflow-y: auto;
`;

export const TechnicalData = styled.pre`
    font-size: ${(props) => props.fontSize - 2}px;
    color: ${Colors.BLACK};
    overflow-x: auto;
`;

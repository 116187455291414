import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';

export const Container = styled(Grid.Row)`
    background-color: ${Colors.BACKGROUND};
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    flex-flow: column;
    padding: 100px 15px;
`;

export const Title = styled.div`
    font-weight: 700;
    font-size: 42px;
    color: ${Colors.BLACK};
    margin-bottom: 40px;
`;

export const Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
    max-width: 500px;
    text-align: center;

    a {
        color: inherit;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
`;

export const LinkButton = styled.a`
    color: ${Colors.WHITE};
    margin-top: 20px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: ${Colors.PRIMARY};
    padding: 0px 20px;
    height: 40px;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.77;
    }
`;

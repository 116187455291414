import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { SharedTypes } from 'features/article/utils';

const TechnicalData = (props) => {
    const { className, article, fontSize } = props;

    return (
        <Styles.Container className={className}>
            <Styles.TechnicalData fontSize={fontSize}>
                {JSON.stringify(article.technicalData, undefined, 4)}
            </Styles.TechnicalData>
        </Styles.Container>
    );
};

TechnicalData.propTypes = {
    className: PropTypes.string,
    article: SharedTypes.ArticleType.isRequired,
    fontSize: PropTypes.number,
};

TechnicalData.defaultProps = {
    className: null,
    fontSize: 16,
};

export default TechnicalData;

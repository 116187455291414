import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { DateUtils, SharedTypes } from 'utils';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';

registerLocale('en', en);

const getDateFrom = (value) => {
    if (value && value.dateFrom) {
        return DateUtils.getDate(value.dateFrom);
    }

    if (value && value.date) {
        return DateUtils.getDate(DateUtils.getRelativeDateRange(value.date)[0]);
    }

    return null;
};

const getDateTo = (value) => {
    if (value && value.dateTo) {
        return DateUtils.getDate(value.dateTo);
    }

    if (value && value.date) {
        return DateUtils.getDate(DateUtils.getRelativeDateRange(value.date)[1]);
    }

    return null;
};

function AbsoluteDateSelect(props) {
    const { value, onChange, selectsRange = true, maxDate } = props;
    const dateFrom = getDateFrom(value);
    const dateTo = getDateTo(value);

    return (
        <Styles.Container>
            <ReactDatePicker
                selected={selectsRange ? dateFrom : value}
                onChange={onChange}
                startDate={dateFrom}
                endDate={dateTo}
                selectsRange={selectsRange}
                monthsShown={2}
                inline
                locale="en"
                maxDate={maxDate}
                // showPreviousMonths
            />
        </Styles.Container>
    );
}

AbsoluteDateSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: SharedTypes.DateRangeValue,
    selectsRange: PropTypes.bool,
    maxDate: PropTypes.date,
};

AbsoluteDateSelect.defaultProps = {
    value: null,
};

export default AbsoluteDateSelect;

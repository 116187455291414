import styled, { css } from 'styled-components';
import { Colors } from 'styles/constant';
import { device } from '../../styles/device';

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    width: 100%;
    padding: 20px;
`;

export const Container = styled.div`
    width: calc(100% - 420px);
    overflow-x: hidden;
    padding: 20px 0;
    margin-left: auto;
    // height: calc(100vh - 116px - 53px - 20px);
    overflow-y: auto;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${Colors.WHITE};
    width: ${(props) => (props.side ? '420px' : '100%')};
    padding: ${(props) => (props.padding ? 20 : 0)}px;

    ${(props) =>
        props.side &&
        css`
            position: fixed;
            left: 0;
            //  background: ${Colors.LIGHT_GREY};
            top: 116px;
            height: calc(100vh - 116px);
            overflow-y: auto;
        `}

    @media ${device.tabletPortrait} {
    }
`;

export const Item = styled.div`
    font-size: 13px;
    color: ${(props) => (props.active ? Colors.PRIMARY : Colors.BLACK)};
    background-color: ${(props) => (props.active ? props.bg : 'transparent')};
    border-bottom: 1px solid ${Colors.BACKGROUND};
    cursor: pointer;
    transition: 0.3s;
    padding: 16px 20px;
    font-weight: 500;
    line-height: 1.4;

    &:last-of-type {
        border-bottom: none;
    }
`;

export const Muted = styled.span`
    color: ${Colors.PLACEHOLDER};
`;

export const Parent = styled.div`
    position: relative;
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};
    max-width: 993px;
    height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
    min-height: ${(props) => (props.minHeight ? props.minHeight + 'px' : 'auto')};
    max-height: ${(props) => (props.height ? props.height + 'px' : 'none')};
    margin: auto;
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Badge = styled.button`
    height: 32px;
    border-radius: 3px;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    background-color: ${(props) => (props.selected ? Colors.BLACK : Colors.BADGE_BACKGROUND)};
    color: ${(props) => (props.selected ? Colors.WHITE : Colors.BADGE_TEXT)};
    border: none;
    font-size: 13px;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};

    &:hover {
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }
`;

import { useDispatch, useSelector } from 'react-redux';
import { SavedSearchesActions } from 'core/actions';

function useDeleteSavedSearch() {
    const dispatch = useDispatch();
    const { isDeleting } = useSelector((state) => state.savedSearches);

    const deleteSavedSearch = (id, callback) => {
        dispatch(SavedSearchesActions.deleteSavedSearch(id, callback));
    };

    return {
        deleteSavedSearch,
        isDeleting,
    };
}

export default useDeleteSavedSearch;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    overflow-y: auto;
`;

export const Content = styled.div`
    font-size: ${(props) => props.fontSize}px !important;
    color: ${Colors.BLACK};
    line-height: 24px;

    * {
        font-size: inherit !important;
    }

    video {
        position: relative;
    }
`;

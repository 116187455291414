import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Badge } from 'components';
import { SharedTypes } from 'utils';

const Votes = (props) => {
    const { className, votes } = props;

    return (
        <Styles.Container className={className}>
            {votes.map((vote) => (
                <Styles.Vote key={vote.id}>
                    {/*<Badge label={`${vote.vote} ${vote.name} (${vote.id})`}/>*/}
                    <Badge label={`${vote.vote} ${vote.name}`} />
                </Styles.Vote>
            ))}
        </Styles.Container>
    );
};

Votes.propTypes = {
    className: PropTypes.string,
    votes: PropTypes.arrayOf(SharedTypes.VoteType),
};

Votes.defaultProps = {
    className: null,
    votes: [],
};

export default Votes;

import React, { useRef } from 'react';
import { Dropdown, Icon } from 'components';
import { Images } from 'styles/constant';
import * as Styles from './styles';
import PropTypes from 'prop-types';
import { useSorting } from 'features/articles/hooks';
import { useFilter } from 'hooks';
import { useLocation } from 'react-router-dom';

const options = [
    { key: 'infometer', order: 'desc', label: 'Infometer (largest first)' },
    { key: 'infometer', order: 'asc', label: 'Infometer (smallest first)' },
    { key: 'date', order: 'desc', label: 'Date published (newest first)' },
    { key: 'date', order: 'asc', label: 'Date published (oldest first)' },
    { key: 'labeling', order: 'desc', label: 'Labelling date (newest first)' },
    { key: 'labeling', order: 'asc', label: 'Labelling date (oldest first)' },
    { key: 'crawldate', order: 'desc', label: 'Date detected (newest first)' },
    { key: 'crawldate', order: 'asc', label: 'Date detected (oldest first)' },
];

function Sorting(props) {
    const { top } = props;
    const { filter } = useFilter();
    const location = useLocation();
    const { updateSort, getValidSort } = useSorting();
    const { labeling_status } = filter;
    const validSort = getValidSort(location);
    const label = options.find((option) => option.key === validSort.sort && option.order === validSort.order).label;
    const ref = useRef();

    const renderButton = () => (
        <Styles.Container>
            <Icon title="Sort" source={Images.SORT} />
            <Styles.Label>Sort by: {label}</Styles.Label>
        </Styles.Container>
    );

    const handleSelect = (key, order) => {
        updateSort(key, order);
        ref.current.close();
    };

    const renderDropdown = () => (
        <Styles.OptionsContainer>
            {options.map((option, i) => (
                <Styles.Option
                    disabled={
                        option.key === 'labeling' &&
                        location.pathname.includes('labelling') &&
                        (!labeling_status || labeling_status === 'Not labeled')
                    }
                    active={option.key === validSort.sort && option.order === validSort.order}
                    onClick={() => handleSelect(option.key, option.order)}
                    key={i}
                >
                    {option.label}
                </Styles.Option>
            ))}
        </Styles.OptionsContainer>
    );

    return (
        <Dropdown
            top={top}
            ref={ref}
            width={210}
            right="auto"
            renderButton={renderButton}
            renderDropdown={renderDropdown}
        />
    );
}

Sorting.propTypes = {
    top: PropTypes.bool,
    labeling: PropTypes.bool,
};

Sorting.defaultProps = {
    top: false,
    labeling: false,
};

export default Sorting;

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { rootReducer } from 'core/reducers';
import localforage from 'localforage';

const persistConfig = {
    key: 'root',
    storage: localforage,
    blacklist: ['initial'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
// persistor.purge();

export { store, persistor };

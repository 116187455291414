import React from 'react';
import { Container, NavigationItem, NavigationItemWrapper } from './styles';
import { useHistory, matchPath } from 'react-router-dom';
import { useFilter, usePermissions } from 'hooks';
import { useSorting } from 'features/articles/hooks';
import _ from 'lodash';

function Navigation() {
    const history = useHistory();
    const { filter, updateFilter } = useFilter();
    const { getDefaultSort } = useSorting();
    const [hasLabelPermission, hasViewChartsPermission] = usePermissions(['ROLE_LABEL', 'ROLE_VIEW_CHARTS']);

    const isRouteActive = (route) => {
        return !!matchPath(history.location.pathname, { path: route });
    };

    const navigate = (pathname) => {
        const sort = getDefaultSort(pathname === '/labelling', filter.labeling_status);
        updateFilter({ ..._.omit(filter, ['chart']), ...sort }, pathname);
    };

    const navItems = [
        {
            label: 'Search',
            to: '/search',
        },
        {
            label: 'Labelling',
            to: '/labelling',
            disabled: !hasLabelPermission,
        },
        {
            label: 'Charts',
            to: '/charts',
            disabled: !hasViewChartsPermission,
        },
    ];

    return (
        <Container>
            {navItems.map((x) => (
                <NavigationItemWrapper key={x.label} disabled={x.disabled}>
                    <NavigationItem active={isRouteActive(x.to)} onClick={() => navigate(x.to)}>
                        {x.label}
                    </NavigationItem>
                </NavigationItemWrapper>
            ))}
        </Container>
    );
}

export default Navigation;

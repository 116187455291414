import update from 'immutability-helper';
import { ParametersTemplatesTypes, AuthTypes } from 'core/types';

const initialState = {
    parametersTemplates: [],
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE_START:
        case ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE:
        case ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                parametersTemplates: { $set: payload.parametersTemplates },
            });
        }

        case ParametersTemplatesTypes.ADD_PARAMETERS_TEMPLATE_ERROR:
        case ParametersTemplatesTypes.GET_PARAMETERS_TEMPLATES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

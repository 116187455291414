import PropTypes from 'prop-types';

export const ParameterValueType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
});

export const NumberOrString = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const ParameterType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(ParameterValueType),
});

export const OptionType = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const ChartsListItemType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.object.isRequired,
});

export const DateRangeValue = PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    date: PropTypes.string,
});

export const UserDomainType = PropTypes.shape({
    assigned: PropTypes.bool,
    available: PropTypes.bool,
    debunk_reach: PropTypes.number,
    debunk_reach_json: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    full_domain: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lang: PropTypes.string,
    name: PropTypes.string.isRequired,
    nmk_country_influence: PropTypes.string,
    nmk_entity_type: PropTypes.string,
    nmk_geo_coverage: PropTypes.string,
    nmk_links_portals: PropTypes.string,
    nmk_marketing_agency: PropTypes.string,
    nmk_mediatype: PropTypes.string,
    nmk_political_influence: PropTypes.string,
    nmk_suspicious: PropTypes.string,
    url: PropTypes.string.isRequired,
});

export const FilterType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.object,
    lang: PropTypes.array,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
});

export const UsersListItemType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    comment_count: PropTypes.number,
    fake_domains_only: PropTypes.bool,
    show_graphs: PropTypes.bool,
    last_login: PropTypes.string,
    manager_email: PropTypes.string,
    user_email: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.string,
    organisation: PropTypes.object,
    project: PropTypes.object,
});

export const CategoryType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    filters: PropTypes.array,
    children: PropTypes.array,
});

export const LanguageType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    iso: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
});

export const VoteType = PropTypes.shape({
    id: PropTypes.number.isRequired,
});

export const LabelingParameterValueType = PropTypes.shape({
    id: PropTypes.number,
    parameterId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
});

export const LabelingSentimentType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
});

export const QueryType = PropTypes.shape({
    logicalOperator: PropTypes.string.isRequired,
    nearSlope: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired,
});

export const GroupType = PropTypes.shape({
    type: PropTypes.string.isRequired,
    query: QueryType.isRequired,
});

export const RuleType = PropTypes.shape({
    rule: PropTypes.string.isRequired,
    selectedOperand: PropTypes.string.isRequired,
    selectedOperator: PropTypes.string.isRequired,
    value: PropTypes.string,
});

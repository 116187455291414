import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Dropdown, SingleSelectOptions } from 'components';
import { Grid } from 'styles/components';
import { Images } from 'styles/constant';
import { useParametersTemplates } from 'hooks';
import { ParametersTemplatesService } from 'core/services';

function ParametersTemplates(props) {
    const { onChange, disabled } = props;
    const { parametersTemplates } = useParametersTemplates();
    const ref = useRef();

    const handleOnSelect = (templateId) => {
        const parametersTemplate = ParametersTemplatesService.getParametersTemplateById(templateId);
        onChange(parametersTemplate);
        ref.current.close();
    };

    const renderButton = () => (
        <Styles.Button disabled={disabled}>
            <Grid.Col mr={10}>
                <Styles.Icon src={Images.SLIDERS} />
            </Grid.Col>
            <Grid.Col>
                <Styles.Label>Parameters template</Styles.Label>
            </Grid.Col>
        </Styles.Button>
    );

    const renderDropdown = () => <SingleSelectOptions onChange={handleOnSelect} options={parametersTemplates} />;

    return (
        <Styles.Container>
            <Dropdown disabled={disabled} ref={ref} renderButton={renderButton} renderDropdown={renderDropdown} />
        </Styles.Container>
    );
}

ParametersTemplates.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.number,
};

ParametersTemplates.defaultProps = {
    disabled: false,
    value: null,
};

export default ParametersTemplates;

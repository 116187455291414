import React, { useEffect, useState } from 'react';
import * as Styles from './styles';
import GroupForm from './GroupForm';
import PropTypes from 'prop-types';
import { TextUtils, restoreTranslateElement } from 'utils';

const initialFormData = {
    logicalOperator: 'All',
    nearSlope: 0,
    children: [],
};

const resetStyles = (navigationHeader, fixedContainer) => {
    if (fixedContainer) {
        fixedContainer.style.top = '0';
    }
    if (navigationHeader) {
        navigationHeader.style.marginTop = '0';
    }
};

function FilterQueryBuilder(props) {
    const navigationHeader = document.getElementById('navigation-header');
    const fixedContainer = document.getElementById('fixed-filter-container');

    const { queryString, onChange, language } = props;
    const [formData, setFormData] = useState(initialFormData);
    const [shouldTranslate, setShouldTranslate] = useState(false);

    useEffect(() => {
        setFormData(TextUtils.parseJson(queryString, initialFormData));
    }, [queryString]);

    useEffect(() => {
        return () => {
            restoreTranslateElement();
            resetStyles(navigationHeader, fixedContainer);
        };
    }, [navigationHeader, fixedContainer]);

    const handleTranslateButtonClick = (clicked) => {
        setShouldTranslate(clicked);

        if (clicked) {
            const translateElement = document.getElementById('google_translate_element');
            if (translateElement) {
                translateElement.style.position = 'fixed';
            }
            if (fixedContainer) {
                fixedContainer.style.top = '41px';
            }
            if (navigationHeader) {
                navigationHeader.style.marginTop = '41px';
            }
        } else {
            resetStyles(navigationHeader, fixedContainer);
        }
    };

    const updateQry = (e) => {
        if (e.logicalOperator !== 'Near') {
            e.nearSlope = 0;
        }
        setFormData(e);
        onChange(e);
    };

    return (
        <Styles.Container>
            <GroupForm
                level={1}
                group={formData}
                onChange={(e) => updateQry(e)}
                language={language}
                shouldTranslate={shouldTranslate}
                handleTranslateButtonClick={handleTranslateButtonClick}
            />
        </Styles.Container>
    );
}

FilterQueryBuilder.propTypes = {
    queryString: PropTypes.string,
    onChange: PropTypes.func,
    language: PropTypes.string,
};

export default FilterQueryBuilder;

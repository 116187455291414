import { parse, stringify } from 'query-string';

export const parseSearch = (search) => {
    return parse(search, { arrayFormat: 'bracket', parseNumbers: true, parseBooleans: true });
};

export const objectToSearch = (object) => {
    return stringify(object, { arrayFormat: 'bracket', skipEmptyString: false, skipNull: true });
};

export const parseSearchWithExcludes = (search, exludes) => {
    const queryParams = new URLSearchParams(search);

    for (let param of exludes) {
        queryParams.delete(param);
    }

    return parseSearch(queryParams.toString());
};

export const parseSearchWithIncludes = (search, includes) => {
    const queryParams = parseSearch(search);
    const object = {};

    for (let param of includes) {
        object[param] = queryParams[param];
    }

    return object;
};

export const objectToSearchWithIncludes = (object, includes) => {
    const queryParams = new URLSearchParams(window.location.search);

    for (let param of includes) {
        object[param] = queryParams.get(param);
    }

    return objectToSearch(object);
};

export const setSearchParam = (name, value) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(name, value);
    return queryParams.toString();
};

export const setSearchParams = (includes) => {
    const queryParams = new URLSearchParams(window.location.search);

    for (const [key, value] of Object.entries(includes)) {
        queryParams.set(key, value);
    }

    return queryParams.toString();
};

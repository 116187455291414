import styled from 'styled-components';
import { Colors } from 'styles/constant';
import * as Grid from '../grid';

export const Container = styled(Grid.Row)`
    height: ${(props) => (props.height ? props.height : 40)}px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid ${(props) => (props.error ? Colors.DANGER : Colors.BACKGROUND)};
    background-color: ${Colors.WHITE};
    padding: 0 ${(props) => (props.multi ? 5 : 10)}px;

    &:hover {
        cursor: pointer;
    }
`;

export const Value = styled.span`
    font-size: 13px;
    white-space: nowrap;
    color: ${Colors.BLACK};
`;

export const Placeholder = styled.div`
    font-size: 13px;
    padding-left: ${(props) => (props.multi ? 5 : 0)}px;
    color: ${Colors.PLACEHOLDER};
`;

export const ValueContainer = styled.div`
    height: 100%;
    display: flex;
    padding: 5px 0;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
    overflow-x: hidden;
`;

export const Label = styled.label`
    font-size: 15px;
    line-height: 1.33;
    display: block;
    color: ${Colors.BLACK};
    margin-bottom: 10px;
`;

export const Error = styled.span`
    font-size: 13px;
    line-height: 1.33;
    display: block;
    color: ${Colors.DANGER};
    margin-top: 5px;
`;

export const ClearAll = styled.span`
    font-size: 12px;
    color: ${Colors.PLACEHOLDER};

    &:hover {
        cursor: pointer;
        color: ${Colors.PRIMARY};
    }
`;

export const Icon = styled.img`
    width: ${(props) => (props.size ? `${props.size}px` : 'auto')};
    height: ${(props) => (props.size ? `${props.size}px` : 'auto')};
`;

export const COLUMNS = [
    {
        Header: 'Languages',
        // Header: 'ID',
        // accessor: row => row.id,
        // filter: "multiple",
    },
    {
        Header: 'Name',
        accessor: (row) => row.name || '-',
        filter: 'multiple',
    },
    {
        Header: 'Category',
        accessor: (row) => (row.category ? row.category.name : '-'),
        filter: 'multiple',
    },
    {
        Header: 'Status',
        accessor: (row) => (row.status ? 'Available' : ''),
        filter: 'multiple',
    },
    {
        Header: 'Create date',
        accessor: (row) => {
            let arr = [row.created_at.slice(0, 10)];
            arr.push(row.created_at.slice(11, 16));
            return arr.join('\r\n');
        },
        filter: 'multiple',
    },
    {
        Header: 'Created by',
        accessor: (row) => (row.created_by ? row.created_by.username : '-'),
        filter: 'multiple',
    },
    {
        Header: 'Update date',
        accessor: (row) => {
            let arr = [row.updated_at.slice(0, 10)];
            arr.push(row.updated_at.slice(11, 16));
            return arr.join('\r\n');
        },
        filter: 'multiple',
    },
    {
        Header: 'Updated by',
        accessor: (row) => (row.updated_by ? row.updated_by.username : '-'),
        filter: 'multiple',
    },
    {
        Header: 'Type',
        accessor: (row) => (row.primary ? 'Primary' : 'Secondary'),
        filter: 'multiple',
    },
];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import MultiSelectOptions from './MultiSelectOptions';
import MultiSelectContainer from './MultiSelectContainer';
import { SharedTypes } from 'utils';
import { Form } from 'styles/components';
import Dropdown from '../Dropdown';

function MultiSelect(props) {
    const { label, style, dirty, error, placeholder, value, options, onChange, name, disabled, forceOpen, large } =
        props;
    const [collapsed, setCollapsed] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleOnClear = (id) => {
        const clearedValue = value.filter((value) => value !== id);
        onChange({ name, value: clearedValue });
    };

    const handleOnClearAll = (event) => {
        event.stopPropagation();
        onChange({ name, value: [] });
    };

    const handleOnChange = (value) => {
        onChange({ name, value });
    };

    const renderButton = () => (
        <MultiSelectContainer
            forceOpen={forceOpen}
            onClearAll={handleOnClearAll}
            onClear={handleOnClear}
            value={value}
            placeholder={placeholder}
            options={options}
        />
    );

    const renderDropdown = () => (
        <MultiSelectOptions
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            forceOpen={forceOpen}
            value={value}
            large={large}
            onChange={handleOnChange}
            options={options}
            dropdownOpen={dropdownOpen}
        />
    );

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            <Dropdown
                large={large}
                forceOpen={forceOpen}
                disabled={disabled}
                renderButton={renderButton}
                renderDropdown={renderDropdown}
                onClose={() => setCollapsed(true)}
                onButtonClick={(active) => setDropdownOpen(active)}
            />
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

MultiSelect.propTypes = {
    options: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    dirty: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    forceOpen: PropTypes.bool,
    large: PropTypes.bool,
};

MultiSelect.defaultProps = {
    style: {},
    disabled: false,
    onBlur: () => {},
    value: [],
    label: null,
    dirty: false,
    forceOpen: false,
    large: false,
    error: null,
    placeholder: null,
};

export default MultiSelect;

import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UrlUtils, ParameterUtils } from 'utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDispatch } from 'react-redux';
import { SearchActions } from 'core/actions';
import { SearchTypes } from 'core/types';
import { useSorting } from 'features/articles/hooks';
import { store } from 'core/store';

const getSearchParams = (search) => {
    return UrlUtils.parseSearchWithExcludes(search, [
        'id',
        'parentId',
        'page',
        'offset',
        'rows',
        'columns',
        'value',
        'language',
    ]);
};

function useUpdateSearch() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { getValidSort, getDefaultSort } = useSorting(location);
    const [searchParams, setSearchParams] = useState(getSearchParams(location.search));
    const { organisation } = store.getState().auth.profile;
    searchParams.project_id = organisation ? organisation.id : null;

    useEffect(() => {
        getValidSort(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDeepCompareEffect(() => {
        const { search, parameterMissing } = ParameterUtils.checkAvailableParameters(searchParams);
        if (!search.sort) {
            const sort = getDefaultSort(location.pathname === '/labelling', search.labeling_status);
            search.sort = sort.sort;
            search.order = sort.order;
        }
        dispatch({
            type: SearchTypes.SET_PARAMETERS_MISSING,
            payload: parameterMissing,
        });
        dispatch(SearchActions.setSearch(search));
    }, [searchParams]);

    useEffect(() => {
        setSearchParams(getSearchParams(location.search));
    }, [location.search]);
}

export default useUpdateSearch;

import React, { useEffect } from 'react';
import { Container, Content, Title, Image, ContentTop } from './styles';
import { LoginForm } from 'containers';
import { connect } from 'react-redux';
import { AuthActions } from 'core/actions';
import PropTypes from 'prop-types';
import { Grid } from 'styles/components';
import { LOGO } from 'styles/constant/images';
import { useHistory } from 'react-router-dom';

function LoginPage(props) {
    const { login, isLoading } = props;
    const history = useHistory();

    useEffect(() => {
        document.title = 'Debunk EU - Sign in';
    }, []);

    const handleLogin = (request) => {
        login(request, () => history.push('/search'));
    };

    return (
        <Container onSubmit={(event) => event.preventDefault()}>
            <ContentTop>
                <Grid.Row justifyContent="center">
                    <Image src={LOGO} />
                </Grid.Row>
            </ContentTop>
            <Content>
                <Grid.Row mb={30}>
                    <Title>Sign in</Title>
                </Grid.Row>
                <LoginForm onSubmit={handleLogin} loading={isLoading} />
            </Content>
        </Container>
    );
}

LoginPage.propTypes = {
    login: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { auth } = state;
    const { isLoading } = auth;
    return { isLoading };
}

const mapDispatchToProps = {
    login: AuthActions.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Buttons } from 'styles/constant';
import { Container, Label, Icon, LoaderContainer } from './styles';
import Loader from '../Loader';

function Button(props) {
    const { className, onClick, label, loading, disabled, type, leftIcon, outline, fullWidth, style } = props;

    return (
        <Container
            className={className}
            label={label}
            fullWidth={fullWidth}
            outline={outline}
            type={type}
            onClick={onClick}
            disabled={disabled || loading}
            style={style}
        >
            {!loading && leftIcon && <Icon label={label} src={leftIcon} />}
            {label && (
                <Label outline={outline} type={type}>
                    {loading ? 'Loading...' : label}
                </Label>
            )}
            {loading && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
        </Container>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    leftIcon: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    outline: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.object,
};

Button.defaultProps = {
    type: Buttons.PRIMARY,
    onClick: () => {},
    fullWidth: false,
    label: null,
    disabled: false,
    loading: false,
    leftIcon: null,
    outline: false,
    style: {},
};

export default Button;

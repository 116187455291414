import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    height: 100%;
    max-width: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: ${Colors.PRIMARY};
`;

export const Label = styled.span`
    font-size: 13px;
    font-weight: ${(props) => (props.title ? 300 : 500)};
    color: ${Colors.WHITE};
`;

export const Title = styled.span`
    font-size: 13px;
    font-weight: 700;
    margin-right: 3px;
    color: ${Colors.WHITE};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
`;

export const Icon = styled.img`
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }
`;

import React, { useEffect, useState } from 'react';
import { Grid } from 'styles/components';
import { EmailForm } from 'containers';
import { EmailsActions, UsersActions } from 'core/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from './styles';
import { Loader } from 'components';
import { useHistory } from 'react-router-dom';

function EmailTemplate(props) {
    const { isLoaded, isLoading, users, getUsers, usersLoaded, getEmail, email } = props;
    const history = useHistory();
    const [id, setId] = useState(null);

    useEffect(() => {
        const id = history.location.pathname.split('/').pop();
        id !== 'create' && setId(id);
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        id && getEmail(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Container>
            {isLoading && (
                <Grid.Row mt={30} justifyContent="center">
                    <Loader />
                </Grid.Row>
            )}
            {isLoaded && usersLoaded && <EmailForm email={!id ? {} : email} users={users} history={history} />}
        </Container>
    );
}

EmailTemplate.propTypes = {
    email: PropTypes.object,
    users: PropTypes.array,
    getEmail: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    usersLoaded: PropTypes.bool,
};

function mapStateToProps(state) {
    const { emails, users } = state;
    const { isLoading, isLoaded, email } = emails;
    return {
        email,
        users: users.users,
        usersLoaded: users.isLoaded,
        isLoaded,
        isLoading,
    };
}

const mapDispatchToProps = {
    getEmail: EmailsActions.getEmail,
    getUsers: UsersActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);

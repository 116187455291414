import React, { useEffect } from 'react';
import { EmailsList } from 'containers';
import { Title, Container } from './styles';
import { Grid } from 'styles/components';
import PropTypes from 'prop-types';
import { EmailsActions } from 'core/actions';
import { connect } from 'react-redux';
import { Loader } from 'components';

function EmailsPage(props) {
    const { getEmails, emails, isLoaded, isLoading } = props;

    useEffect(() => {
        document.title = 'Debunk EU - List of Emails';
        getEmails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Container>
                <Grid.FixedContainer>
                    <Title>Emails</Title>
                    {isLoading && (
                        <Grid.Row mt={30} justifyContent="center">
                            <Loader />
                        </Grid.Row>
                    )}
                </Grid.FixedContainer>
                {isLoaded && <EmailsList emails={emails} />}
            </Container>
        </>
    );
}

EmailsPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    emails: PropTypes.array,
    getEmails: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { emails } = state;
    const { isLoading, isLoaded, isUpdated } = emails;
    return { emails: emails.emails, isLoading, isLoaded, isUpdated };
}

const mapDispatchToProps = {
    getEmails: EmailsActions.getEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsPage);

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    position: relative;

    &:hover {
        .tooltip {
            display: block;
        }
    }
`;

export const Value = styled.span`
    display: inline-block;
    font-size: 15px;
    padding: 0 10px;
    font-weight: 700;
    color: ${Colors.SECONDARY};
    vertical-align: 2px;
`;

export const Tooltip = styled.div`
    position: absolute;
    right: 0;
    padding: 5px 10px;
    bottom: calc(100% + 5px);
    display: none;
    border-radius: 3px;
    background-color: ${Colors.TOOLTIP};
`;

export const Vote = styled.span`
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    display: block;
    color: ${Colors.WHITE};
`;

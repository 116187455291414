import styled from 'styled-components';
import { Colors } from '../../styles/constant';
import { device } from '../../styles/device';

export const PaginationButton = styled.button`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: ${Colors.WHITE};
    margin-right: 10px;
    font-size: 14px;
    color: ${Colors.BADGE_TEXT};
    font-weight: ${(props) => (props.active ? 500 : 400)};
    border: 1px solid ${(props) => (props.active ? Colors.BLACK : 'transparent')};
    transition: 0.3s;
    cursor: ${(props) => (props.active ? 'auto' : 'pointer')};

    &:hover,
    &:active,
    &:focus {
        opacity: ${(props) => (props.active ? 1 : 0.75)};
    }

    &:last-of-type {
        margin-right: 0;
    }

    @media ${device.tabletPortrait} {
        display: ${(props) => (props.largeScreen ? 'none' : 'flex')};
    }
`;

export const Dots = styled.div`
    margin-right: 10px;
    margin-right: 10px;
    font-size: 14px;

    @media ${device.tabletPortrait} {
        display: ${(props) => (props.largeScreen ? 'none' : 'flex')};
    }
`;

import update from 'immutability-helper';
import { SavedSearchesTypes, AuthTypes } from 'core/types';

const initialState = {
    savedSearches: [],
    isLoading: false,
    isLoaded: false,
    isDeleting: false,
    isUpdating: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SavedSearchesTypes.ADD_SAVED_SEARCHES_START:
        case SavedSearchesTypes.GET_SAVED_SEARCHES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case SavedSearchesTypes.DELETE_SAVED_SEARCH_START: {
            return update(state, {
                isDeleting: { $set: true },
            });
        }

        case SavedSearchesTypes.UPDATE_SAVED_SEARCH_START: {
            return update(state, {
                isUpdating: { $set: true },
            });
        }

        case SavedSearchesTypes.DELETE_SAVED_SEARCH: {
            return update(state, {
                isDeleting: { $set: false },
                savedSearches: { $set: payload.savedSearches },
            });
        }

        case SavedSearchesTypes.UPDATE_SAVED_SEARCH: {
            return update(state, {
                isUpdating: { $set: false },
            });
        }

        case SavedSearchesTypes.ADD_SAVED_SEARCHES:
        case SavedSearchesTypes.GET_SAVED_SEARCHES: {
            return update(state, {
                isLoading: { $set: false },
                savedSearches: { $set: payload.savedSearches },
                isLoaded: { $set: true },
            });
        }

        case SavedSearchesTypes.UPDATE_SAVED_SEARCH_ERROR:
        case SavedSearchesTypes.DELETE_SAVED_SEARCH_ERROR:
        case SavedSearchesTypes.ADD_SAVED_SEARCHES_ERROR:
        case SavedSearchesTypes.GET_SAVED_SEARCHES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function AuthenticationRoute(props) {
    const { isLogged } = props;

    if (isLogged) {
        return <Redirect to="/" />;
    }

    return <Route {...props} />;
}

AuthenticationRoute.propTypes = {
    isLogged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const { auth } = state;
    const { isLogged } = auth;
    return { isLogged };
}

export default connect(mapStateToProps)(AuthenticationRoute);

import React from 'react';
import PropTypes from 'prop-types';
import { Total } from './styles';
import { Grid } from 'styles/components';
import { Input, Button, Checkbox } from 'components';

function ChartBottom(props) {
    const { chart, title, updateTitle, setTitle, setShowLabels, showLabels } = props;

    return (
        <>
            <Grid.Row mb={20}>
                <Checkbox name="showLabels" onChange={setShowLabels} checked={showLabels} label="Show labels" />
                <Total>The chart is based on the {chart.total} articles, which met your filters.</Total>
            </Grid.Row>
            <Grid.Row mb={10}>
                <Input onChange={(e) => setTitle(e.value)} name="title" value={title} />
            </Grid.Row>
            <Grid.Row>
                <Button onClick={updateTitle} label="Update title" type="SECONDARY" />
            </Grid.Row>
        </>
    );
}

ChartBottom.propTypes = {
    chart: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    updateTitle: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setShowLabels: PropTypes.func,
    showLabels: PropTypes.bool,
};

export default ChartBottom;

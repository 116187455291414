import { useSelector } from 'react-redux';
import { Mapper } from 'utils';

function useParametersTemplates() {
    const { parametersTemplates, isLoading, isLoaded } = useSelector((state) => state.parametersTemplates);

    return {
        parametersTemplates: Mapper.mapParametersTemplatesToOptions(parametersTemplates),
        isLoading,
        isLoaded,
    };
}

export default useParametersTemplates;

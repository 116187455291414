import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.BACKGROUND};
`;

import styled from 'styled-components';
import { Messages, Colors, Images } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';

const getColor = ({ type }) => {
    switch (type) {
        case Messages.WARNING: {
            return Colors.SECONDARY;
        }

        case Messages.SUCCESS: {
            return Colors.TERTIARY;
        }

        case Messages.DANGER: {
            return Colors.DANGER;
        }

        default: {
            return Colors.WHITE;
        }
    }
};

export const getIcon = (type) => {
    switch (type) {
        case Messages.WARNING: {
            return Images.FLAG;
        }

        case Messages.SUCCESS: {
            return Images.FLAG;
        }

        case Messages.DANGER: {
            return Images.FLAG;
        }

        default: {
            return Images.FLAG;
        }
    }
};

export const getDropdownIcon = (type) => {
    switch (type) {
        case Messages.WARNING: {
            return Images.DANGER_ARROW_DOWN;
        }

        case Messages.SUCCESS: {
            return Images.DANGER_ARROW_DOWN;
        }

        case Messages.DANGER: {
            return Images.DANGER_ARROW_DOWN;
        }

        default: {
            return Images.DANGER_ARROW_DOWN;
        }
    }
};

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px 15px;
    background-color: ${(props) => hexToRgba(getColor(props), 0.1)};
    border: 1px solid ${(props) => getColor(props)};
`;

export const Text = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => getColor(props)};
`;

export const Icon = styled.img`
    margin-right: 15px;
`;

export const DropdownIcon = styled.img``;

export const Content = styled.div`
    display: flex;
    align-items: center;
`;

import moment from 'moment';
import { RelativeDates } from 'constant';

export const formatDate = (date) => {
    if (!date) {
        return null;
    }

    return moment(date).format('YYYY-MM-DD');
};

export const getDate = (date) => {
    if (!date) {
        return null;
    }

    return new Date(date);
};

export const getRelativeDate = (date) => {
    if (!date) {
        return null;
    }

    if (date === RelativeDates.TODAY) {
        return RelativeDates.TODAY;
    }

    if (date === RelativeDates.YESTERDAY) {
        return RelativeDates.YESTERDAY;
    }

    if (date === RelativeDates.LAST_7_DAYS) {
        return RelativeDates.LAST_7_DAYS;
    }

    if (date === RelativeDates.LAST_30_DAYS) {
        return RelativeDates.LAST_30_DAYS;
    }

    if (date === RelativeDates.THIS_MONTH) {
        return RelativeDates.THIS_MONTH;
    }

    if (date === RelativeDates.LAST_MONTH) {
        return RelativeDates.LAST_MONTH;
    }

    if (date === RelativeDates.LAST_100_DAYS) {
        return RelativeDates.LAST_100_DAYS;
    }

    if (date === RelativeDates.THIS_YEAR) {
        return RelativeDates.THIS_YEAR;
    }

    return null;
};

export const getRelativeDateLabel = (date) => {
    if (!date) {
        return null;
    }

    if (date === RelativeDates.TODAY) {
        return 'Today';
    }

    if (date === RelativeDates.YESTERDAY) {
        return 'Yesterday';
    }

    if (date === RelativeDates.LAST_7_DAYS) {
        return 'Last 7 days';
    }

    if (date === RelativeDates.LAST_30_DAYS) {
        return 'Last 30 days';
    }

    if (date === RelativeDates.THIS_MONTH) {
        return 'This month';
    }

    if (date === RelativeDates.LAST_MONTH) {
        return 'Last month';
    }

    if (date === RelativeDates.LAST_100_DAYS) {
        return 'Last 100 days';
    }

    if (date === RelativeDates.THIS_YEAR) {
        return 'This year';
    }

    return null;
};

export const getTodayRange = () => {
    const dateFrom = moment();
    const dateTo = moment();
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getLastMonthRange = () => {
    const dateFrom = moment().subtract(1, 'months').date(1);
    const dateTo = moment().subtract(1, 'months').endOf('month');
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getThisMonthRange = () => {
    const dateFrom = moment().date(1);
    const dateTo = moment().endOf('month');
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getYesterdayRange = () => {
    const dateFrom = moment().subtract(1, 'days');
    const dateTo = moment().subtract(1, 'days');
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getLastDaysRange = (days) => {
    const dateFrom = moment().subtract(days - 1, 'days');
    const dateTo = moment();
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getThisYearRange = () => {
    const dateFrom = moment().startOf('year');
    const dateTo = moment();
    return [formatDate(dateFrom), formatDate(dateTo)];
};

export const getRelativeDateRange = (date) => {
    if (!date) {
        return getTodayRange();
    }

    if (date === RelativeDates.TODAY) {
        return getTodayRange();
    }

    if (date === RelativeDates.YESTERDAY) {
        return getYesterdayRange();
    }

    if (date === RelativeDates.LAST_7_DAYS) {
        return getLastDaysRange(7);
    }

    if (date === RelativeDates.LAST_30_DAYS) {
        return getLastDaysRange(30);
    }

    if (date === RelativeDates.THIS_MONTH) {
        return getThisMonthRange();
    }

    if (date === RelativeDates.LAST_MONTH) {
        return getLastMonthRange();
    }

    if (date === RelativeDates.LAST_100_DAYS) {
        return getLastDaysRange(100);
    }

    if (date === RelativeDates.THIS_YEAR) {
        return getThisYearRange();
    }

    return getTodayRange();
};

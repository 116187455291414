import update from 'immutability-helper';
import { CategoriesTypes, AuthTypes } from 'core/types';

const initialState = {
    categories: [],
    userCategories: [],
    isLoaded: false,
    isLoading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CategoriesTypes.UPDATE_CATEGORIES_START:
        case CategoriesTypes.GET_CATEGORIES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case CategoriesTypes.UPDATE_CATEGORIES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
            });
        }

        case CategoriesTypes.GET_CATEGORIES: {
            return update(state, {
                isLoading: { $set: false },
                categories: { $set: payload.categories },
                isLoaded: { $set: true },
            });
        }

        case CategoriesTypes.GET_USER_CATEGORIES: {
            return update(state, {
                isLoading: { $set: false },
                userCategories: { $set: payload.categories },
                isLoaded: { $set: true },
            });
        }

        case CategoriesTypes.UPDATE_CATEGORIES_ERROR:
        case CategoriesTypes.GET_CATEGORIES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Container, Title, Text, LinkButton } from '../404/styles';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { Buttons } from 'styles/constant';
import { useProfile } from 'hooks';
import { ToastService } from 'core/services';

function Error({ error }) {
    const location = useLocation();
    const { profile } = useProfile();

    const errorMessage = `Error: ${error.message}.\nLocation: ${location.pathname}.\nUser id: ${
        profile.id
    }.\nDate: ${moment().format('YYYY-MM-DD HH:mm')}`;

    const onCopyErrorClick = () => {
        navigator.clipboard.writeText(errorMessage);
        ToastService.showSuccess('Error message copied.');
    };

    return (
        <Container>
            <Title>Sorry, something went wrong</Title>
            <Text>An unexpected error has occured.</Text>
            <Text>
                You can contact <a href="mailto: support@debunkeu.org">support@debunkeu.org</a> for the assistance
            </Text>
            <Button onClick={onCopyErrorClick} label="Copy error information to clipboard" type={Buttons.WARNING} />
            <LinkButton href="/search">Go to main page</LinkButton>
        </Container>
    );
}

Error.propTypes = {
    resetErrorBoundary: PropTypes.func,
    error: PropTypes.object,
};

export default Error;

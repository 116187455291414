import { ArticlesTypes } from 'features/articles/types';
import { ArticlesService } from 'features/articles/services';
import { ToastService } from 'core/services';
import { ArticlesCountActions } from 'features/articles/actions/index';

export function getArticles(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.GET_ARTICLES_START });
            const { articles, total, chart } = await ArticlesService.getArticles(request);
            dispatch({ type: ArticlesTypes.GET_ARTICLES, payload: { articles, count: total, chartData: chart } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.GET_ARTICLES_ERROR });
        }
    };
}

export function markExpired(id, parentId) {
    return {
        type: ArticlesTypes.MARK_EXPIRED,
        payload: { id, parentId },
    };
}

export function voteArticle(id, parentId, vote, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.ARTICLE_VOTE_START, payload: { id, parentId } });
            const { votes } = await ArticlesService.vote(id, parentId, vote);
            dispatch({ type: ArticlesTypes.ARTICLE_VOTE, payload: { id, parentId, votes } });
            ToastService.showSuccess('Article successfully voted');
            callback();
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.ARTICLE_VOTE_ERROR, payload: { id, parentId } });
        }
    };
}

export function bookmarkArticle(id, parentId, isBookmarked, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.ARTICLE_BOOKMARK_START, payload: { id, parentId } });
            await ArticlesService.bookmark(id, parentId);
            dispatch({ type: ArticlesTypes.ARTICLE_BOOKMARK, payload: { id, parentId, isBookmarked } });
            ToastService.showSuccess('Article successfully bookmarked');
            callback();
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.ARTICLE_BOOKMARK_ERROR, payload: { id, parentId } });
        }
    };
}

export function hideForMeArticle(id, parentId, isHiddenForMe, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ME_START, payload: { id, parentId } });
            await ArticlesService.hide(id, parentId, false);
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ME, payload: { id, parentId, isHiddenForMe } });
            ToastService.showSuccess('Article successfully hided');
            callback();
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ME_ERROR, payload: { id, parentId } });
        }
    };
}

export function hideForAllArticle(id, parentId, isHiddenForAll, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ALL_START, payload: { id, parentId } });
            await ArticlesService.hide(id, parentId, true);
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ALL, payload: { id, parentId, isHiddenForAll } });
            ToastService.showSuccess('Article successfully hided');
            callback();
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.ARTICLE_HIDE_FOR_ALL_ERROR, payload: { id, parentId } });
        }
    };
}

export function getReservedArticles(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.GET_RESERVED_ARTICLES_START });
            const { articles, total } = await ArticlesService.getReservedArticles(request);
            dispatch({ type: ArticlesTypes.GET_RESERVED_ARTICLES, payload: { articles, count: total, chartData: {} } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.GET_ARTICLES_ERROR });
        }
    };
}

export function cancelReservedArticles(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.CANCEL_RESERVED_ARTICLES_START });
            await ArticlesService.cancelReservedArticles();
            const { articles, total } = await ArticlesService.getReservedArticles(request);
            dispatch({
                type: ArticlesTypes.CANCEL_RESERVED_ARTICLES,
                payload: { articles, count: total, chartData: {} },
            });
            dispatch(ArticlesCountActions.getReservedArticlesCount(request));
            dispatch(ArticlesCountActions.getWaitingArticlesCount(request));
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesTypes.CANCEL_RESERVED_ARTICLES_ERROR });
        }
    };
}

export function reserveArticles(request, selectedArticlesIds, callback, onError) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.RESERVE_ARTICLES_START });
            await ArticlesService.reserveArticles(request, selectedArticlesIds);
            dispatch({ type: ArticlesTypes.RESERVE_ARTICLES });
            dispatch(ArticlesCountActions.getWaitingArticlesCount(request));
            dispatch(ArticlesCountActions.getReservedArticlesCount());
            ToastService.showSuccess('Articles successfully reserved');
            callback();
        } catch (exception) {
            dispatch({ type: ArticlesTypes.RESERVE_ARTICLES_ERROR });
            onError();
        }
    };
}

export function exportSearch(urlParams) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesTypes.EXPORT_SEARCH_START });
            await ArticlesService.exportSearch(urlParams);
            dispatch({ type: ArticlesTypes.EXPORT_SEARCH });
        } catch (exception) {
            ToastService.showGenericError();
        }
    };
}

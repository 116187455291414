import React, { useState, useEffect } from 'react';
import Input from '../../Input';
import PropTypes from 'prop-types';

function EditableCell({ value: initialValue, row: { index }, column: { id }, updateMyData, editableCells }) {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
        setValue(e.value);
    };

    const onBlur = () => {
        updateMyData(index, id, value);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if (editableCells.includes(id)) {
        return (
            <Input
                type={id === 'Weight' ? 'number' : 'string'}
                disabled={id !== 'Weight'}
                name={id}
                value={value.toString() || ''}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    }
    return <>{initialValue}</>;
}

EditableCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    updateMyData: PropTypes.func.isRequired,
    editableCells: PropTypes.array,
};

export default EditableCell;

import React from 'react';
import ReactDOM from 'react-dom';
import { persistor, store } from 'core/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { PageLoader } from 'components';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import './assets/scss/base.scss';
import './assets/scss/animations.scss';
import './assets/scss/datepicker.scss';
import './assets/scss/charts.scss';
import './assets/scss/article.scss';
import './assets/scss/collapse.scss';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

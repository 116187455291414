import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    min-height: 34px;
    padding: 0 10px;
    background-color: ${Colors.BLACK};

    ${(props) =>
        props.disabled &&
        `
    opacity: 0.6;
  
    &:hover {
      cursor: no-drop;
    }  
  `}
`;

export const Label = styled.span`
    font-size: 13px;
    font-weight: ${(props) => (props.title ? 300 : 500)};
    color: ${Colors.WHITE};
`;

export const Title = styled.span`
    font-size: 13px;
    font-weight: 700;
    margin-right: 3px;
    color: ${Colors.WHITE};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

export const Icon = styled.img`
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }
`;

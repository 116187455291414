import update from 'immutability-helper';
import { AuthTypes } from 'core/types';
import { ArticlesCountTypes } from 'features/articles/types';

const initialState = {
    reservedCount: 0,
    labeledCount: 0,
    skippedCount: 0,
    waitingCount: 0,
    isReservedLoading: false,
    isLabeledLoading: false,
    isWaitingLoading: false,
    isSkippedLoading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT_START: {
            return update(state, {
                isReservedLoading: { $set: true },
            });
        }

        case ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT_START: {
            return update(state, {
                isLabeledLoading: { $set: true },
            });
        }

        case ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT_START: {
            return update(state, {
                isSkippedLoading: { $set: true },
            });
        }

        case ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT_START: {
            return update(state, {
                isWaitingLoading: { $set: true },
            });
        }

        case ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT: {
            return update(state, {
                isReservedLoading: { $set: false },
                reservedCount: { $set: payload.count },
            });
        }

        case ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT: {
            return update(state, {
                isLabeledLoading: { $set: false },
                labeledCount: { $set: payload.count },
            });
        }

        case ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT: {
            return update(state, {
                isSkippedLoading: { $set: false },
                skippedCount: { $set: payload.count },
            });
        }

        case ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT: {
            return update(state, {
                isWaitingLoading: { $set: false },
                waitingCount: { $set: payload.count },
            });
        }

        case ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT_ERROR:
        case ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT_ERROR:
        case ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT_ERROR:
        case ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import { ParameterTypes } from 'core/types';
import { ParametersService, ToastService } from 'core/services';

export function addParameter(request, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: ParameterTypes.ADD_PARAMETER_START });
            const parameter = await ParametersService.addParameter(request);
            dispatch({ type: ParameterTypes.ADD_PARAMETER, payload: { parameter } });
            ToastService.showSuccess('Parameter successfully added');
            callback(parameter);
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ParameterTypes.ADD_PARAMETER_ERROR });
        }
    };
}

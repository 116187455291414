import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Buttons } from 'styles/constant';
import { Content, Footer, HelpList, HelpListItem } from './styles';
import Button from '../Button';

function HelpModal(props) {
    const { visible, onClose } = props;

    const renderContent = () => (
        <Content>
            <HelpList>
                <HelpListItem>
                    If you search for <strong>book</strong>, it will give results containing: book, books, book’s or
                    even hook. This is called fuzzy search. If you need exactly book, use quotes, eg.{' '}
                    <strong>&quot;book&quot;</strong>
                </HelpListItem>
                <HelpListItem>
                    + signifies AND operation, eg. <strong>+Donald +Trump</strong>
                </HelpListItem>
                <HelpListItem>
                    | signifies OR operation, eg. <strong>shop | store</strong>
                </HelpListItem>
                <HelpListItem>
                    - negates a single token, NOT operator, eg. <strong>+Capitol -attack</strong>
                </HelpListItem>
                <HelpListItem>
                    &quot; (quotes) wraps a number of tokens to signify a phrase for searching, eg.{' '}
                    <strong>&quot;Donald Trump&quot;</strong> or <strong>+&quot;Europe&quot; -security</strong>
                </HelpListItem>
                <HelpListItem>
                    * at the end of a term signifies a prefix query, eg. <strong>programm*</strong> would match
                    programming, programmer, programmist… Note: cannot be used inside quotes, eg. &quot;Europ*
                    Union&quot; is incorrect! Also, cannot be used before a word, eg. *rink is incorrect!
                </HelpListItem>
                <HelpListItem>
                    ( and ) signify precedence, eg.{' '}
                    <strong>&quot;Donald Trump&quot; +(stocks | bonds) -inflation</strong>
                </HelpListItem>
                <HelpListItem>
                    ~N after a word signifies edit distance (fuzziness), eg. <strong>book~1</strong> would match book,
                    hook, look, boom… (not yet available, ask for status)
                </HelpListItem>
                <HelpListItem>
                    ~N after a phrase signifies slop amount, eg. <strong>&quot;Recep Erdogan&quot;~1</strong> would
                    match Recep Tayyip Erdogan or any other 1 word in between (not yet available, ask for availability)
                </HelpListItem>
                <HelpListItem>
                    Boolean search using operators and parentheses, eg.{' '}
                    <strong>
                        ((sale* OR admin) AND (&quot;excel&quot; OR &quot;word&quot; OR &quot;power point&quot;)) NOT
                        (&quot;online&quot; OR &quot;web&quot;)
                    </strong>{' '}
                    (not yet available, ask for availability)
                </HelpListItem>
                <HelpListItem>
                    search is not case sensitive, uppercase letters are always converted to lowercase
                </HelpListItem>
                <HelpListItem>
                    if you have questions, contact <a href="mailto:support@debunkeu.org">support@debunkeu.org</a>
                </HelpListItem>
            </HelpList>
        </Content>
    );

    const renderFooter = () => (
        <Footer>
            <Button label="Close" type={Buttons.TERTIARY} onClick={onClose} />
        </Footer>
    );

    return (
        <Modal
            style={{ width: 580 }}
            title="Help"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

HelpModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

HelpModal.defaultProps = {
    visible: false,
};

export default HelpModal;

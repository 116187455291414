import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SharedTypes, UrlUtils } from 'utils';
import { Table, Button } from 'components';
import { Grid } from 'styles/components';
import { Buttons } from 'styles/constant';
import { Container } from './styles';
import { COLUMNS } from './config';
import { CategoriesActions } from 'core/actions';
import { Title, User } from 'pages/Categories/styles';

function CategoriesList(props) {
    const { filterList, userFilters, updateCategories, history, user } = props;
    const [selectedCategories, setSelectedCategories] = useState(false);
    const [customFactors, setFactor] = useState(false);
    const [showSelected, setShowSelected] = useState(false);

    useEffect(() => {
        !selectedCategories &&
            setSelectedCategories((prevState) => ({
                ...prevState,
                selectedCategories: userFilters.filter((f) => f.selected),
            }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCheckboxChange = (selected) => {
        setSelectedCategories((prevState) => ({
            ...prevState,
            selectedCategories: selected,
        }));
    };

    const updateFactor = (rowIndex, columnId, value, filterId) => {
        const factorsCopy = customFactors ? customFactors.customFactors : {};
        factorsCopy[filterId] = value;

        setFactor((prevState) => ({
            ...prevState,
            customFactors: factorsCopy,
        }));
    };

    const onSubmit = () => {
        const user = parseInt(history.location.pathname.split('/').pop());
        const selection =
            selectedCategories && selectedCategories.selectedCategories.length <= userFilters.length
                ? selectedCategories.selectedCategories
                : [];
        const params = {
            filters: JSON.stringify(
                selection.map((f) => {
                    const factor =
                        customFactors && customFactors.customFactors[f]
                            ? parseInt(customFactors.customFactors[f])
                            : userFilters.find((filter) => filter.id == f).filter_weight
                            ? userFilters.find((filter) => filter.id == f).filter_weight
                            : 1;
                    return { id: f, weight: factor };
                }),
            ),
        };

        const req = UrlUtils.objectToSearch(params);
        updateCategories(user, req);
        history.push('/users');
    };

    return (
        <>
            <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                <Grid.FixedContainer>
                    <Grid.Row justifyContent="space-between">
                        <Grid.Col>
                            <Title>
                                Assign filters for
                                <User>{user && user.username}</User>
                            </Title>
                        </Grid.Col>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col mr={10}>
                                    <Button
                                        label="Cancel"
                                        type={Buttons.TERTIARY}
                                        onClick={() => history.push('/users')}
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Button label="Submit" onClick={onSubmit} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.FixedContainer>
            </Grid.FixedContainer>
            <Container>
                <Table
                    checkboxes
                    setShowSelected={() => setShowSelected(!showSelected)}
                    showSelected={showSelected}
                    onCheckboxChange={onCheckboxChange}
                    editableCells={COLUMNS.filter((c) => c.input).map((c) => c.Header)}
                    updateData={updateFactor}
                    columns={COLUMNS}
                    hiddenColumns={[]}
                    data={
                        showSelected
                            ? filterList.filter((category) =>
                                  selectedCategories.selectedCategories.includes(category.id),
                              )
                            : filterList
                    }
                    type="filters"
                    history={history}
                    preSelected={filterList.filter((f) => f.selected).map((f) => f.id)}
                />
            </Container>
        </>
    );
}

CategoriesList.propTypes = {
    filterList: PropTypes.arrayOf(SharedTypes.CategoryType),
    userFilters: PropTypes.arrayOf(SharedTypes.FilterType),
    updateCategories: PropTypes.func,
    history: PropTypes.object,
    user: PropTypes.oneOfType([PropTypes.bool, SharedTypes.UsersListItemType]),
};

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {
    updateCategories: CategoriesActions.updateCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);

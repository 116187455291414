import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: ${(props) => (props.isCharts ? 'calc(100% - 420px)' : '100%')};
    margin-left: ${(props) => (props.isCharts ? 'auto' : 0)};
    padding-top: 10px;
    background-color: ${Colors.LIGHT_GREY};
    border-bottom: 1px solid ${Colors.BACKGROUND};
`;

export const ActiveFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

export const ActiveFilter = styled.div`
    margin-right: 10px;
    margin-bottom: 10px;
    height: 32px;
`;

export const ExportBtn = styled.button`
    background: none;
    border: none;
    color: ${Colors.PRIMARY};
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: auto;
    cursor: pointer;
    transition: 0.3s;

    opacity: ${(props) => (props.disabled ? 0.4 : 1)};

    &:hover,
    &:active,
    &:focus {
        opacity: ${(props) => (props.disabled ? 0.4 : 0.77)};
        cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
    }
`;

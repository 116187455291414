import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
`;

export const Label = styled.label`
    font-size: ${(props) => (props.small ? 12 : 15)}px;
    line-height: 1.33;
    display: block;
    color: ${(props) => (props.small ? Colors.BADGE_TEXT : Colors.BLACK)};
    cursor: ${(props) => (props.small ? 'pointer' : 'auto')};
`;

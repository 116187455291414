import React from 'react';
import * as Styles from './styles';
import PropTypes from 'prop-types';
import { Mapper } from 'features/articles/utils';

function VotesCount(props) {
    const { votes } = props;

    const renderTooltip = () => (
        <Styles.Tooltip className="tooltip">
            {votes.map((vote, index) => (
                // <Styles.Vote key={index}>{`${vote.vote} ${vote.name} (${vote.id})`}</Styles.Vote>
                <Styles.Vote key={index}>{`${vote.vote} ${vote.name}`}</Styles.Vote>
            ))}
        </Styles.Tooltip>
    );

    return (
        <Styles.Container>
            <Styles.Value>{Mapper.countVotes(votes)}</Styles.Value>
            {renderTooltip()}
        </Styles.Container>
    );
}

VotesCount.propTypes = {
    votes: PropTypes.array.isRequired,
};

export default VotesCount;

import React from 'react';
import { TableCell, TableRow } from '../../../components/Table/styles';
import { Colors } from '../../../styles/constant';
import { Grid } from '../../../styles/components';
import { EDIT, REMOVE, SEARCH_WHITE } from 'styles/constant/images';
import { RTLLanguages } from 'constant';
import { Icon } from 'components';
import { SearchIcon } from '../styles';

export default function renderRow(filters, row, visibleColumns, onLanguageDelete, hasCreateFiltersPermission, history) {
    const filter = filters.find((f) => f.id == row.original.id);

    if (filter.lang && filter.lang.length > 0 && visibleColumns.length > 0) {
        const rows = filter.lang.map((l, i) => {
            let translated = l.query && l.query.length > 0;
            let query = 'Not translated';

            if (translated) {
                query = '';
                try {
                    JSON.parse(l.query, (key, value) => {
                        if (key === 'value') query += value + ', ';
                    });
                } catch (err) {
                    query = l.query;
                }
            }

            if (query[query.length - 2] === ',') query = query.slice(0, query.length - 2);

            if (query.length > 150) {
                query = query.slice(0, 200) + '...';
            }

            const bindColumns = visibleColumns
                .filter(
                    (column) =>
                        column.id === 'Languages' ||
                        column.id === 'Create date' ||
                        column.id === 'Update date' ||
                        column.id === 'Actions' ||
                        column.id === 'Created by' ||
                        column.id === 'Updated by',
                )
                .map((c) => c.id);

            const language = l.lang.iso;
            return (
                <TableRow key={i}>
                    {bindColumns.includes('Languages') && (
                        <TableCell bold key={1} color={translated ? Colors.BLACK : Colors.PRIMARY}>
                            {language.toUpperCase()}
                        </TableCell>
                    )}
                    {visibleColumns.length - bindColumns.length > 0 && (
                        <TableCell
                            colSpan={visibleColumns.length - bindColumns.length}
                            color={translated ? Colors.BLACK : Colors.SECONDARY}
                            key={3}
                        >
                            <p dir={RTLLanguages.includes(language) ? 'rtl' : 'ltr'}>{query}</p>
                        </TableCell>
                    )}
                    {bindColumns.includes('Create date') && (
                        <TableCell key={4}>
                            {l.created_at.slice(0, 10)} <br />
                            {l.created_at.slice(11, 16)} <br />
                        </TableCell>
                    )}
                    {bindColumns.includes('Created by') && (
                        <TableCell key={5}>{l.created_by ? l.created_by.username : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Update date') && (
                        <TableCell key={6}>
                            {l.updated_at.slice(0, 10)} <br />
                            {l.updated_at.slice(11, 16)} <br />
                        </TableCell>
                    )}
                    {bindColumns.includes('Updated by') && (
                        <TableCell key={7}>{l.updated_by ? l.updated_by.username : '-'}</TableCell>
                    )}
                    {bindColumns.includes('Actions') && (
                        <TableCell key={8}>
                            <Grid.Row justifyContent="center">
                                <SearchIcon source={SEARCH_WHITE} disabled={!hasCreateFiltersPermission} />
                                <Icon
                                    gray
                                    source={EDIT}
                                    onClick={() => history.push(`/filter/${filter.id}?language=${l.lang.iso}`)}
                                    disabled={!hasCreateFiltersPermission}
                                />
                                <Icon
                                    gray
                                    source={REMOVE}
                                    onClick={() => onLanguageDelete(filter, l)}
                                    iconSmall
                                    disabled={!hasCreateFiltersPermission}
                                />
                            </Grid.Row>
                        </TableCell>
                    )}
                </TableRow>
            );
        });

        return <>{visibleColumns.length > 0 && rows}</>;
    }
}

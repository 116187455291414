import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Section = styled.div`
    padding: 15px;
    background-color: ${Colors.LIGHT_GREY};
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    overflow-y: auto;
    padding: 20px 0 64px;
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
`;

export const SmallText = styled.span`
    font-size: 12px;
    color: ${Colors.BADGE_TEXT};
`;

export const Label = styled.span`
    margin-left: ${(props) => (props.indent ? -90 : 0)}px;
    font-size: 14px;
`;

export const Icon = styled.img`
    width: 20px;
    height: auto;
    z-index: 1;
    margin: 0 0 0 -30px;
    cursor: pointer;
`;

export const Name = styled.span`
    color: ${Colors.PRIMARY};
`;

const size = {
    mobileS: '375px',
    mobile: '576px',
    tabletPortrait: '768px',
    tabletLandscape: '992px',
    desktop: '12000px',
};

export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobile: `(max-width: ${size.mobile})`,
    tabletLandscape: `(max-width: ${size.tabletLandscape})`,
    tabletPortrait: `(max-width: ${size.tabletPortrait})`,
    desktop: `(max-width: ${size.desktop})`,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Text, Icon, getIcon, Content, DropdownIcon, getDropdownIcon } from './styles';
import { Messages } from 'styles/constant';

function Message(props) {
    const { style, type, message } = props;

    return (
        <Container type={type} style={style}>
            <Content>
                <Icon src={getIcon(type)} />
                <Text type={type}>{message}</Text>
            </Content>
            <DropdownIcon src={getDropdownIcon(type)} />
        </Container>
    );
}

Message.propTypes = {
    message: PropTypes.string.isRequired,
    style: PropTypes.object,
    type: PropTypes.string,
};

Message.defaultProps = {
    style: {},
    type: Messages.WARNING,
};

export default Message;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SharedTypes, UrlUtils } from 'utils';
import { Button, Checkbox, Table } from 'components';
import { Grid } from 'styles/components';
import { COLUMNS } from './config';
import { Container } from './styles';
import { Buttons } from 'styles/constant';
import { DomainsActions, UsersActions } from 'core/actions';
import { Title, User } from 'pages/Domains/styles';

function DomainsList(props) {
    const { userDomains, updateDomains, history, user, isUpdating, isUpdated, getUsers } = props;
    const [isAllAssigned, setIsAllAssigned] = useState(false);
    const [limitSuspicious, setLimitSuspicious] = useState(false);
    const [selectedDomains, setSelectedDomains] = useState(false);
    const [showSelected, setShowSelected] = useState(false);

    useEffect(() => {
        if (isUpdated) {
            getUsers();
            history.push('/users');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        if (userDomains.filter((d) => d.selected == false).length === userDomains.length) {
            setIsAllAssigned(true);
            user.fake_domains_only && setLimitSuspicious(true);
        } else {
            setIsAllAssigned(false);
            onCheckboxChange(userDomains.filter((d) => d.selected));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsAllAssigned]);

    const onCheckboxChange = (selected) => {
        setSelectedDomains((prevState) => ({
            ...prevState,
            selectedDomains: selected.map((s) => s.id || s),
        }));
    };

    const onSubmit = () => {
        const user = parseInt(history.location.pathname.split('/').pop());

        const params = {
            domains: selectedDomains
                ? selectedDomains.selectedDomains
                : userDomains.filter((d) => d.selected).map((d) => d.id),
        };

        if (isAllAssigned) {
            delete params['domains'];
            params.suspicious_only = limitSuspicious ? 1 : 0;
        }

        const req = UrlUtils.objectToSearch(params);
        updateDomains(user, req);
    };

    return (
        <>
            <Grid.FixedContainer sticky style={{ maxWidth: 'none' }}>
                <Grid.FixedContainer>
                    <Grid.Row justifyContent="space-between">
                        <Grid.Col>
                            <Title>
                                Assign domains for
                                <User>{user && user.username}</User>
                            </Title>
                        </Grid.Col>
                        <Grid.Col>
                            <Grid.Row>
                                <Grid.Col mr={10}>
                                    <Button
                                        label="Cancel"
                                        type={Buttons.TERTIARY}
                                        onClick={() => history.push('/users')}
                                    />
                                </Grid.Col>
                                <Grid.Col>
                                    <Button label="Submit" onClick={onSubmit} loading={isUpdating} />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.FixedContainer>
            </Grid.FixedContainer>

            <Container>
                <Grid.FixedContainer>
                    <Grid.Row mb={20} mt={20}>
                        <Checkbox
                            label={`Assign all (${
                                userDomains ? userDomains.filter((d) => d.available).length : 0
                            }) domains (automatically assigns newly added domains)`}
                            name="all"
                            checked={isAllAssigned}
                            onChange={() => setIsAllAssigned(!isAllAssigned)}
                        />
                    </Grid.Row>
                    <Grid.Row pl={30}>
                        <Checkbox
                            disabled={!isAllAssigned}
                            label={`Limit to suspicious (${
                                userDomains ? userDomains.filter((d) => d.available && d.type === 1).length : 0
                            }) domains only`}
                            name="all"
                            checked={limitSuspicious}
                            onChange={() => setLimitSuspicious(!limitSuspicious)}
                        />
                    </Grid.Row>
                </Grid.FixedContainer>

                {!isAllAssigned && (
                    <Table
                        setShowSelected={() => setShowSelected(!showSelected)}
                        showSelected={showSelected}
                        onCheckboxChange={onCheckboxChange}
                        checkboxes
                        editableCells={COLUMNS.filter((c) => c.input).map((c) => c.Header)}
                        updateData={() => {}}
                        columns={COLUMNS}
                        data={
                            showSelected
                                ? userDomains.filter((domain) => selectedDomains.selectedDomains.includes(domain.id))
                                : userDomains
                        }
                        hiddenColumns={['DebunkReach', 'Status']}
                        type="domains"
                        history={history}
                        preSelected={userDomains.filter((domain) => domain.selected).map((d) => d.id)}
                        cellStyle={(data) => ({ color: `${data.complete === 2 ? '#bfbfbf' : 'inherit'}` })}
                    />
                )}
            </Container>
        </>
    );
}

DomainsList.propTypes = {
    userDomains: PropTypes.arrayOf(SharedTypes.UserDomainType),
    getUserDomains: PropTypes.func,
    getUsers: PropTypes.func,
    updateDomains: PropTypes.func,
    history: PropTypes.object,
    user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    isUpdating: PropTypes.bool,
    isUpdated: PropTypes.bool,
};

function mapStateToProps(state) {
    const { domains } = state;
    const { isUpdating, isUpdated } = domains;
    return { isUpdated, isUpdating };
}

const mapDispatchToProps = {
    updateDomains: DomainsActions.updateDomains,
    resetUpdating: DomainsActions.resetUpdating,
    getUsers: UsersActions.getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainsList);

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import { Buttons } from 'styles/constant';
import { Content } from './styles';
import Button from '../Button';
import { Grid } from '../../styles/components';

function ParametersMissingModal(props) {
    const { visible, onClose } = props;

    const renderContent = () => (
        <Content>
            Some search parameters or values from your saved search does not exist anymore or you don’t have permissions
            to use them. The search was performed without the missing parameters and the results might differ from what
            was expected.
        </Content>
    );

    const renderFooter = () => (
        <Grid.Row justifyContent="flex-end">
            <Button label="Close" type={Buttons.PRIMARY} onClick={onClose} />
        </Grid.Row>
    );

    return (
        <Modal
            style={{ width: 580 }}
            title="Some search parameters are not allowed"
            renderContent={renderContent}
            onClose={onClose}
            visible={visible}
            renderFooter={renderFooter}
        />
    );
}

ParametersMissingModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

ParametersMissingModal.defaultProps = {
    visible: false,
};

export default ParametersMissingModal;

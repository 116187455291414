export const handleTranslateContainer = () => {
    const container = document.getElementById('google_translate_element');
    const articleContainer = document.getElementById('main');
    const articles = document.querySelector('aside');
    const parameters = document.getElementById('labelingContainer');

    if (container.classList.contains('show')) {
        container && container.classList.remove('show');
        articleContainer && articleContainer.classList.remove('shrink');
        articles && articles.classList.remove('shrink');
        parameters && parameters.classList.remove('shrink');

        const iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
        if (!iframe) return;

        const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        const restore_el = innerDoc.getElementsByTagName('button');

        for (let i = 0; i < restore_el.length; i++) {
            if (restore_el[i].id.indexOf('restore') >= 0) {
                restore_el[i].click();
                const close_el = innerDoc.getElementsByClassName('goog-close-link');
                close_el[0].click();
                return;
            }
        }
        return false;
    } else {
        container.classList.add('show');
        articleContainer && articleContainer.classList.add('shrink');
        articles && articles.classList.add('shrink');
        parameters && parameters.classList.add('shrink');
        return true;
    }
};

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    width: auto;
    justify-content: center;
    padding: 0 10px;
    border-radius: 4px;
    background-color: ${Colors.LIGHT_GREY};
    font-size: 12px;
    color: ${Colors.SECONDARY};
`;

export const Pause = styled.div`
    cursor: pointer;
    transition: 0.3s;
    height: 13px;
    width: 8px;
    border-left: 3px solid ${(props) => (props.isPaused ? Colors.PRIMARY : Colors.GREEN)};
    border-right: 3px solid ${(props) => (props.isPaused ? Colors.PRIMARY : Colors.GREEN)};
    margin-left: 5px;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

export const Text = styled.p`
    font-size: 14px;
`;

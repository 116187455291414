import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    overflow-y: auto;
`;

export const Source = styled.p`
    font-size: ${(props) => props.fontSize}px;
    color: ${Colors.BLACK};
    line-height: 24px;
`;

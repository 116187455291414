import React, { useEffect, useState } from 'react';
import { Switch, matchPath, useHistory, Redirect } from 'react-router-dom';
import { ActiveFilters, Filter, ProtectedRoute, Search } from 'containers';
import * as Styles from './styles';
import { ChartsPage, LabelingPage, SearchPage } from 'pages';
import { ExportModal, HelpModal } from 'components';
import { ArticlesList } from 'features/articles';
import { DateUtils } from 'utils';
import { ArticlesActions } from 'features/articles/actions';
import { connect } from 'react-redux';
import { useFilter } from 'hooks';
import PropTypes from 'prop-types';

const ArticlesLayout = (props) => {
    const { isExporting, exportSearch } = props;
    const history = useHistory();
    const { filter, getFilters } = useFilter();
    const [localFilter, setLocalFilter] = useState(filter);
    const [filterVisible, setFilterVisible] = useState(false);
    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const isCharts = !!matchPath(history.location.pathname, { path: '/charts' });
    const isLabeling = !!matchPath(history.location.pathname, { path: '/labelling' });
    const excludes = ['rows', 'value', 'columns', 'offset', 'project_id'];

    useEffect(() => {
        exportModalVisible && searchToExport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportModalVisible]);

    useEffect(() => {
        exportModalVisible && !isExporting && setExportModalVisible(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExporting]);

    const searchToExport = (withContent) => {
        const fltr = { ...filter };
        for (let param of excludes) {
            if (fltr[param]) delete fltr[param];
        }
        if (fltr.date) {
            const date = DateUtils.getRelativeDateRange(fltr.date);
            fltr.from = date[0];
            fltr.to = date[1];
            delete fltr.date;
        }
        if (withContent) {
            fltr.show_content = 1;
        }
        const urlParams = getFilters(fltr);
        exportSearch(urlParams);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, [history.location]);

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'initial';
        };
    }, []);

    const handleDateChange = (event) => {
        const { value } = event;

        if (DateUtils.getRelativeDate(value.date)) {
            const newLocalFilterMain = { ...localFilter };
            newLocalFilterMain.date = value.date;
            delete newLocalFilterMain.from;
            delete newLocalFilterMain.to;
            setLocalFilter(newLocalFilterMain);
        }

        if (DateUtils.getDate(value.dateFrom) && DateUtils.getDate(value.dateTo)) {
            const newLocalFilterMain = { ...localFilter };
            newLocalFilterMain.to = value.dateTo;
            newLocalFilterMain.from = value.dateFrom;
            delete newLocalFilterMain.date;
            setLocalFilter(newLocalFilterMain);
        }
    };

    const handleSearchExport = (withContent) => {
        setExportModalVisible(true);
        searchToExport(withContent);
    };

    return (
        <Styles.Container>
            <Styles.Content>
                {!isCharts && (
                    <Styles.Side>
                        <ArticlesList labeling={isLabeling} />
                    </Styles.Side>
                )}
                <Styles.RightSide>
                    <Styles.MainHeader id="mainHeader" labeling={isLabeling}>
                        <Search
                            handleDateChange={handleDateChange}
                            localFilterMain={localFilter}
                            setLocalFilterMain={setLocalFilter}
                            isLabeling={isLabeling}
                            isCharts={isCharts}
                            onShowHelpModal={() => setHelpModalVisible(true)}
                            onShowFilter={() => setFilterVisible(true)}
                        />
                        <Filter
                            handleDateChange={handleDateChange}
                            localFilterMain={localFilter}
                            setLocalFilterMain={setLocalFilter}
                            isLabeling={isLabeling}
                            isCharts={isCharts}
                            onShowHelpModal={() => setHelpModalVisible(true)}
                            onClose={() => setFilterVisible(false)}
                            visible={filterVisible}
                            handleSearchExport={handleSearchExport}
                        />
                        <HelpModal visible={helpModalVisible} onClose={() => setHelpModalVisible(false)} />
                        <ExportModal onClose={() => setExportModalVisible(false)} visible={exportModalVisible} />
                        <ActiveFilters isCharts={isCharts} setExportModalVisible={setExportModalVisible} />
                    </Styles.MainHeader>
                    <Styles.Main id="main" isCharts={isCharts} labeling={isLabeling}>
                        <Switch>
                            <ProtectedRoute exact path="/" render={() => <Redirect to="/search" />} />
                            <ProtectedRoute path="/search" component={SearchPage} />
                            <ProtectedRoute path="/labelling" component={LabelingPage} />
                            <ProtectedRoute path="/charts" component={ChartsPage} />
                        </Switch>
                    </Styles.Main>
                </Styles.RightSide>
            </Styles.Content>
        </Styles.Container>
    );
};

ArticlesLayout.propTypes = {
    isExporting: PropTypes.bool,
    exportSearch: PropTypes.func,
};

function mapStateToProps(state) {
    const { articles } = state;
    return { isExporting: articles.isExporting };
}

const mapDispatchToProps = {
    exportSearch: ArticlesActions.exportSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesLayout);

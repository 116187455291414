import _ from 'lodash';

export const CATEGORYLIST = (categories) => {
    const list = [];

    categories.map((category, i) => {
        let level = i + 1 + '.';
        list.push({ label: level + ' ' + category.name, value: category.id || '', title: category.name });

        category.children && update(category.children, level);

        function update(children, level) {
            return children.reduce((count, child, index) => {
                let sub = index + 1;
                level = (sub > 1 ? level.replace(`.${index}.`, '.') : level) + sub + '.';

                child.children && update(child.children, level);

                list.push({ label: level + ' ' + child.name, value: child.id || '', title: child.name });
            }, 0);
        }

        return category;
    });

    return list;
};

export const LANGUAGELIST = (languages) => {
    const list = [];

    languages.map((language) => {
        list.push({ label: language.name, value: language.id, title: language.name, key: language.iso });
    });

    return list;
};

export const FORM_DATA = function (
    filter,
    queries,
    languages,
    namesTranslated,
    category,
    name,
    active,
    categoryList,
    primary,
) {
    const values_array = [];

    const CHECK_VALUES = (obj) => {
        _.forOwn(obj, (value, key) => {
            if (key === 'value') values_array.push(value);
        });
        if (obj.children) obj.children.map((child) => CHECK_VALUES(child.query));
    };

    const cat = categoryList.find((c) => c.value === category.value);

    const newLangs = [];

    Object.values(queries).map((lang, i) => {
        const iso = Object.keys(queries)[i];

        if (lang.query) {
            CHECK_VALUES(JSON.parse(lang.query));
            newLangs.push({
                lang_id: languages.find((l) => l.iso === iso).id,
                filter_lang_id: lang.filter_lang_id || null,
                name_translated: namesTranslated[iso] || '',
                query: JSON.parse(lang.query),
            });
        }
    });

    if (values_array.includes(null) || values_array.includes('')) {
        alert('Value cannot be empty');
        return;
    }

    return {
        filter: {
            id: filter.id || null,
            category_id: cat ? cat.value : null,
            name: name,
            status: active ? 1 : 0,
            primary: primary,
        },
        languages: newLangs,
    };
};

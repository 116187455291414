import { store } from 'core/store';
import httpClient from '../httpClient';

const baseUrl = '/users';

export const getUsers = async (subset = '?subset=userTable') => {
    return httpClient.get(baseUrl + subset);
};

export const getUser = (id, subset) => {
    return !id ? {} : httpClient.get(`${baseUrl}/${id}${subset ? '?subset=userForm' : ''}`);
};

export const getUserById = (id) => {
    const users = store.getState().users.users;
    return users.find((user) => user.id === id);
};

export const createUser = (user) => {
    return httpClient.post(baseUrl, user);
};

export const updateUser = (id, user) => {
    return httpClient.put(`${baseUrl}/${id}`, user);
};

export const deleteUser = (id) => {
    return httpClient.delete(`${baseUrl}/${id}`);
};

export const getProjects = async () => {
    return httpClient.get('/projects');
};

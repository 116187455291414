import React, { useEffect } from 'react';
import { Container } from './styles';
import { Grid } from 'styles/components';
import { ChartsActions } from 'core/actions';
import PropTypes from 'prop-types';
import { SharedTypes } from 'utils';
import { connect } from 'react-redux';
import { ChartsContainer } from 'containers';

function ChartsPage(props) {
    const { charts, getCharts } = props;

    useEffect(() => {
        document.title = 'Debunk EU - Charts';
    }, []);

    useEffect(() => {
        getCharts();
    }, [getCharts]);

    return (
        <Container>
            <Grid.FixedContainer>
                <ChartsContainer chartsArray={charts} />
            </Grid.FixedContainer>
        </Container>
    );
}

ChartsPage.propTypes = {
    charts: PropTypes.arrayOf(SharedTypes.ChartsListItemType).isRequired,
    getCharts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { charts } = state;
    return { charts: charts.charts };
}

const mapDispatchToProps = {
    getCharts: ChartsActions.getCharts,
    getChart: ChartsActions.getChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsPage);

import { store } from 'core/store';
import httpClient from '../httpClient';
import { TextUtils } from 'utils';
import { ParameterTypesList } from 'constant';

const baseUrl = '/parameters';

export const getParameters = async () => {
    return httpClient.get(`${baseUrl}?type=*`);
};

export const addParameter = async (request) => {
    return httpClient.post('/parameters', request);
};

export const getParameterById = (id) => {
    const parameters = store.getState().parameters.parameters;
    return parameters.find((parameter) => parameter.id === id);
};

export const getParameterValueById = (id, valueId) => {
    const parameter = getParameterById(id);
    const values = parameter && parameter.values ? parameter.values : [];
    return values.find((parameterValue) => parameterValue.id === valueId);
};

export const getParameterValueByValue = (id, value) => {
    const parameter = getParameterById(id);
    const values = parameter.values || [];
    return values.find((parameterValue) => parameterValue.value === value);
};

export const isA2Parameter = (id) => {
    const parameter = getParameterById(id);
    if (!parameter) return false;
    return parameter.type === ParameterTypesList.A2;
};

export const isA2ParameterKey = (key) => {
    if (!key.startsWith('parameters')) return false;
    return isA2Parameter(TextUtils.getParameterId(key));
};

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';

export const Container = styled.div`
    width: 100%;
    border: 1px solid ${Colors.TAB};
    border-left: 3px solid ${(props) => (props.border ? props.border : 'transparent')};
`;

export const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: ${Colors.LIGHT_GREY};
    padding: 0 15px;
`;

export const Title = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};
`;

export const InputContainer = styled.div`
    width: 200px;
`;

export const Content = styled.div`
    background-color: ${Colors.WHITE};
    padding: 15px;
`;

export const Tooltip = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    top: auto;
    bottom: calc(100% + 3px);
    width: 200px;
    display: none;
    border-radius: 3px;
    background-color: ${Colors.TOOLTIP};
    color: ${Colors.WHITE};
    font-size: 14px;
`;

export const Message = styled.div`
    position: relative;

    img {
        width: 15px;
        margin-top: 5px;
        filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(70%) contrast(117%);
    }

    &:hover {
        .tooltip {
            display: block;
        }
    }
`;

export const QuestionMarkCol = styled(Grid.Col)`
    width: 17px;
    svg {
        fill: ${Colors.DANGER};
    }
`;

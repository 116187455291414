import { httpClient } from 'core/services';
import { Mapper } from 'features/article/utils';

const baseUrl = '/articles';
const labelingUrl = '/labeling';

export const getArticle = async (id, parentId) => {
    const article = await httpClient.get(`${baseUrl}/${id}/${parentId}`);
    return Mapper.mapArticle(article);
};

export const labelArticle = async (id, parentId, data, status) => {
    return await httpClient.post(`${labelingUrl}/${id}/${parentId}`, mapToLabelRequest(data, status));
};

export const mapToLabelRequest = (data, status) => {
    const parameters = data.parameters || [];
    const sentiments = data.sentiments || [];
    return {
        updating: status === 'Labeled' || status === 'Skipped' ? 1 : 0,
        comment: data.comment,
        time_spent: data.time_spent,
        parameters: parameters.map((parameter) => ({
            parameter_id: parameter.parameterId,
            value_id: parameter.id,
            type: parameter.type,
        })),
        sentiments: sentiments.map((sentiment) => ({
            id: sentiment.id,
            value: sentiment.value,
            type: sentiment.type,
        })),
    };
};

export const importArticle = async (data) => {
    return await httpClient.post('/manual-import', data);
};

import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0 0;
`;

export const Category = styled.div`
    margin-right: 5px;
    margin-bottom: 5px;
`;

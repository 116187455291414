import styled, { css } from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    background-color: ${Colors.WHITE};
    display: ${(props) => (props.hide ? 'none' : 'block')};

    ${(props) =>
        props.forceOpen &&
        css`
            overflow-y: hidden;
            height: ${(props) => (props.large ? 557 : 470)}px;
        `}
`;

export const OptionsContainer = styled.div`
    width: 100%;
    max-height: ${(props) => (props.forceOpen || props.large ? 'calc(100% - 46px)' : '275px')};
    overflow-y: auto;
    padding: 10px;
`;

export const SearchContainer = styled.div`
    width: 100%;
    padding: 10px 10px 0 10px;
    position: relative;
`;

export const SearchInput = styled.input`
    height: 36px;
    width: 100%;
    border-radius: 5px;
    font-size: 13px;
    color: ${Colors.BLACK};
    border: 1px solid ${Colors.BACKGROUND};
    background-color: ${Colors.LIGHT_GREY};
    padding: 0 10px;
    padding-right: 40px;
`;

export const SearchIcon = styled.img`
    position: absolute;
    right: 20px;
    top: 10px;
    bottom: 0;
    margin: auto;
`;

export const NoDataContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const NoDataTitle = styled.span`
    font-size: 14px;
    line-height: 30px;
    color: ${Colors.PLACEHOLDER};
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Link } from 'react-router-dom';
import { device } from '../../styles/device';
import hexToRgba from 'hex-to-rgba';

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};

    span {
        color: ${Colors.PRIMARY};
    }
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    padding: 20px 0 64px;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    @media ${device.tabletPortrait} {
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 10px;

        a {
            width: 100%;
            margin: 0 0 10px;
        }
    }
`;

export const Button = styled(Link)`
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 15px;
    background-color: ${Colors.PRIMARY};
    color: ${Colors.WHITE};
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px 20px 0;
    text-decoration: none;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.8;
    }
`;

export const ActionLink = styled(Link)`
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    text-decoration: none;
    color: ${Colors.PRIMARY};
    transition: 0.3s;

    &:last-child {
        margin: 0 !important;
    }

    &:hover,
    &:active,
    &:focus {
        color: ${hexToRgba(Colors.PRIMARY, 0.8)};
    }
`;

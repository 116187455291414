import React from 'react';
import PropTypes from 'prop-types';

import { Container, TextInput } from './styles';
import { Form } from 'styles/components';

function Textarea(props) {
    const { style, placeholder, error, name, value, onBlur, onChange, label, dirty, disabled, height } = props;

    const handleOnChange = (event) => {
        const { target } = event;
        const { value } = target;
        onChange({ name, value });
    };

    const handleOnBlur = () => {
        onBlur({ name });
    };

    return (
        <Container style={style}>
            {label && <Form.Label>{label}</Form.Label>}
            <TextInput
                height={height}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                value={value || ''}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                error={error}
            />
            {dirty && error && <Form.Error>{error}</Form.Error>}
        </Container>
    );
}

Textarea.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    label: PropTypes.string,
    height: PropTypes.number,
};

Textarea.defaultProps = {
    style: {},
    onBlur: () => {},
    error: null,
    value: '',
    disabled: false,
    placeholder: null,
    dirty: false,
    label: false,
    height: 60,
};

export default Textarea;

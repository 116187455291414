import PropTypes from 'prop-types';

export const InfometerType = PropTypes.shape({
    total: PropTypes.string.isRequired,
    social: PropTypes.string.isRequired,
    reach: PropTypes.string.isRequired,
    importance: PropTypes.string.isRequired,
});

export const ArticleType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    parentId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    publishDate: PropTypes.string.isRequired,
    similarCount: PropTypes.number.isRequired,
    votes: PropTypes.array.isRequired,
    infometer: InfometerType.isRequired,
    isBookmarked: PropTypes.bool,
    isHiddenForMe: PropTypes.bool.isRequired,
    isHiddenForAll: PropTypes.bool.isRequired,
});

export const GET_ARTICLES_START = 'GET_ARTICLES_START';
export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export const ARTICLE_BOOKMARK_START = 'ARTICLE_BOOKMARK_START';
export const ARTICLE_BOOKMARK = 'ARTICLE_BOOKMARK';
export const ARTICLE_BOOKMARK_ERROR = 'ARTICLE_BOOKMARK_ERROR';

export const ARTICLE_VOTE_START = 'ARTICLE_VOTE_START';
export const ARTICLE_VOTE = 'ARTICLE_VOTE';
export const ARTICLE_VOTE_ERROR = 'ARTICLE_VOTE_ERROR';

export const ARTICLE_HIDE_FOR_ME_START = 'ARTICLE_HIDE_FOR_ME_START';
export const ARTICLE_HIDE_FOR_ME = 'ARTICLE_HIDE_FOR_ME';
export const ARTICLE_HIDE_FOR_ME_ERROR = 'ARTICLE_HIDE_FOR_ME_ERROR';

export const ARTICLE_HIDE_FOR_ALL_START = 'ARTICLE_HIDE_FOR_ALL_START';
export const ARTICLE_HIDE_FOR_ALL = 'ARTICLE_HIDE_FOR_ALL';
export const ARTICLE_HIDE_FOR_ALL_ERROR = 'ARTICLE_HIDE_FOR_ALL_ERROR';

export const GET_RESERVED_ARTICLES_START = 'GET_RESERVED_ARTICLES_START';
export const GET_RESERVED_ARTICLES = 'GET_RESERVED_ARTICLES';
export const GET_RESERVED_ARTICLES_ERROR = 'GET_RESERVED_ARTICLES_ERROR';

export const CANCEL_RESERVED_ARTICLES_START = 'CANCEL_RESERVED_ARTICLES_START';
export const CANCEL_RESERVED_ARTICLES = 'CANCEL_RESERVED_ARTICLES';
export const CANCEL_RESERVED_ARTICLES_ERROR = 'CANCEL_RESERVED_ARTICLES_ERROR';

export const RESERVE_ARTICLES_START = 'RESERVE_ARTICLES_START';
export const RESERVE_ARTICLES = 'RESERVE_ARTICLES';
export const RESERVE_ARTICLES_ERROR = 'RESERVE_ARTICLES_ERROR';

export const REMOVE_ARTICLE = 'REMOVE_ARTICLE';

export const MARK_EXPIRED = 'MARK_EXPIRED';

export const EXPORT_SEARCH_START = 'EXPORT_SEARCH_START';
export const EXPORT_SEARCH = 'EXPORT_SEARCH';

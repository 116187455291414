import update from 'immutability-helper';
import { LanguagesTypes, AuthTypes } from 'core/types';

const initialState = {
    languages: [],
    isLoading: false,
    isLoaded: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LanguagesTypes.GET_LANGUAGES_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
            });
        }

        case LanguagesTypes.GET_LANGUAGES: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                languages: { $set: payload.languages },
            });
        }

        case LanguagesTypes.GET_LANGUAGES_ERROR:
        case AuthTypes.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

import { store } from 'core/store';
import httpClient from '../httpClient';

const baseUrl = '/categories';

export const getCategories = async () => {
    const params = { subset: 'select' };
    return httpClient.get(baseUrl, { params });
};

export const updateCategories = async (id, request) => {
    return httpClient.put(`users/${id}/filters`, request);
};

export const getUserFilters = async (id) => {
    return httpClient.get(`users/${id}/filters`);
};

export const getCategoryById = (id) => {
    const categories = store.getState().categories.categories;
    return categories.find((category) => category.id === id);
};

import { useSelector } from 'react-redux';

function useSearch() {
    const { search } = useSelector((state) => state.search);

    return {
        filter: search,
    };
}

export default useSearch;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Icon, Title, Content } from './styles';
import { Images } from 'styles/constant';

function SelectedOption(props) {
    const { label, style, onClear, title } = props;
    const [parsedLabel, setParsedLabel] = useState('');

    useEffect(() => {
        if (Array.isArray(label)) {
            let labels = [];
            label.map((l) => {
                labels.push(l.length >= 90 ? l.slice(0, 90) + '...' : l);
                return l;
            });

            if (labels.length >= 3) {
                if (labels.find((l) => l.length >= 90)) {
                    labels = labels.map((l) => (l.length >= 40 ? l.slice(0, 40) + '...' : l));
                }
                labels = labels.slice(0, 3);
                label.length > 3 && labels.push(` +${label.length - 3}`);
            }
            setParsedLabel(labels.join(', '));
        } else setParsedLabel(label.length >= 90 ? label.slice(0, 90) + '...' : label);
    }, [label]);

    const handleClear = (event) => {
        event.stopPropagation();
        onClear();
    };

    return (
        <Container
            style={style}
            title={`${title ? title + ': ' : ''}${Array.isArray(label) ? label.join(', ') : label}`}
        >
            <Content>
                {title && <Title>{`${title.slice(0, 90) + (title.length >= 90 ? '...' : '')} :`}</Title>}
                <Label>{parsedLabel}</Label>
            </Content>
            {onClear && <Icon onClick={handleClear} src={Images.CLOSE_WHITE} />}
        </Container>
    );
}

SelectedOption.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    title: PropTypes.string,
    onClear: PropTypes.func,
    style: PropTypes.object,
};

SelectedOption.defaultProps = {
    style: {},
    title: null,
    onClear: null,
};

export default SelectedOption;

import React from 'react';
import { Container, Title, Text, LinkButton } from '../404/styles';

function Forbidden() {
    return (
        <Container>
            <Title>No permissions</Title>
            <Text>You do not have permissions to access this page.</Text>
            <Text>
                Please contact your administrator or <a href="mailto: support@debunkeu.org">support@debunkeu.org</a>
            </Text>
            <LinkButton href="/search">Go to main page</LinkButton>
        </Container>
    );
}

export default Forbidden;

import { CategoriesService, FiltersService } from 'core/services';

export const mapArticle = (article) => {
    const articleObject = {
        id: article.id,
        title: article.title,
        parentId: Number(article._parent),
        crawlDate: article.date_crawled,
        detected: article.detected,
        domain: article.domain,
        publishDate: article.date_published,
        source: article.plain_text,
        content: article.content,
        categories: mapCategories(article.categories),
        votes: article.votes,
        labeling: article.labeling,
        labelingStatus: article.labelingStatus,
        filters: mapFilters(article.filters),
        technicalData: article,
        author: article.author,
    };

    return articleObject;
};

export const mapFilters = (filters = []) => {
    return filters
        .map((filter) => ({
            ...FiltersService.getFilterById(filter.id),
            language: filter.lang,
        }))
        .filter((filter) => !!filter);
};

export const mapCategories = (categories) => {
    return categories
        .map((category) => CategoriesService.getCategoryById(category.id))
        .filter((category) => !!category);
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Content, ButtonWrapper, MenuItem, DropdownToggle, SearchButton } from './styles';
import { Buttons, Images } from 'styles/constant';
import { Grid } from 'styles/components';
import { Button, DatePicker, Input, MultiSelect, Dropdown } from 'components';
import { connect } from 'react-redux';
import { Mapper, SharedTypes } from 'utils';
import { useFilter, useSavedSearches } from 'hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ChartsActions } from 'core/actions';
import { ArticlesActions, ArticlesCountActions } from 'features/articles/actions';
import usePaging from 'features/articles/hooks/usePaging';
import _ from 'lodash';

const Search = (props) => {
    const {
        handleDateChange,
        onShowFilter,
        languages,
        onShowHelpModal,
        isCharts,
        isLabeling,
        chart,
        getChart,
        getArticles,
        getReservedArticles,
        getLabeledArticlesCount,
        getReservedArticlesCount,
        getSkippedArticlesCount,
        getWaitingArticlesCount,
        localFilterMain,
        setLocalFilterMain,
    } = props;
    const { filter, updateFilter } = useFilter();
    const [searchClicked, setSearchClicked] = useState(false);
    const { getSavedSearches, savedSearches } = useSavedSearches();
    const { paging } = usePaging();
    const sort = { sort: filter.sort, order: filter.order };

    useEffect(() => {
        getSavedSearches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (searchClicked) {
            isCharts && chart.graph && getChart(chart.graph.id, filter);
            !isCharts && !isLabeling && getArticles({ filter, paging });
            if (isLabeling) {
                !filter.labeling_status ? getReservedArticles({ sort, paging }) : getArticles({ filter, paging });
                getReservedArticlesCount({ filter });
                getWaitingArticlesCount({ filter });
                getLabeledArticlesCount({ filter });
                getSkippedArticlesCount({ filter });
            }
            setSearchClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchClicked]);

    useDeepCompareEffect(() => {
        setLocalFilterMain(filter);
    }, [filter]);

    const handleChange = (event) => {
        const { value, name } = event;
        setLocalFilterMain({ ...localFilterMain, [name]: value });
    };

    const search = () => {
        const excludes = ['rows', 'value', 'columns', 'offset'];

        for (let param of excludes) {
            if (localFilterMain[param]) delete localFilterMain[param];
        }

        if (_.isEqual(filter, localFilterMain)) setSearchClicked(true);
        updateFilter(localFilterMain);
    };

    const selectSavedSearch = (savedSearch) => {
        updateFilter(JSON.parse(savedSearch.data).search);
    };

    const renderButton = () => {
        return <DropdownToggle />;
    };

    const renderDropdown = () => {
        return savedSearches.map((s, i) => {
            return (
                <MenuItem className="dropdown-item" key={i} onClick={() => selectSavedSearch(s)}>
                    {s.name}
                </MenuItem>
            );
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    return (
        <Container>
            <Grid.FluidContainer>
                <Grid.Row>
                    <Grid.Col flex={1}>
                        <Content>
                            <Input
                                value={localFilterMain['filters[must]']}
                                onChange={handleChange}
                                leftIcon={Images.SEARCH}
                                name="filters[must]"
                                help="Help"
                                onHelpClick={onShowHelpModal}
                                placeholder="Search ALL of these words"
                                onKeyDown={handleKeyDown}
                            />
                            <DatePicker
                                value={{
                                    dateFrom: localFilterMain.from,
                                    dateTo: localFilterMain.to,
                                    date: localFilterMain.date,
                                }}
                                onChange={handleDateChange}
                                name="date"
                                placeholder="Select date range"
                            />
                            <MultiSelect
                                options={languages}
                                value={localFilterMain.lang}
                                onChange={handleChange}
                                name="lang"
                                placeholder="Languages"
                            />
                        </Content>
                    </Grid.Col>
                    <Grid.Col flexShrink={0} ml={10}>
                        <Button type={Buttons.SECONDARY} label="Advanced" onClick={onShowFilter} />
                    </Grid.Col>
                    <Grid.Col flexShrink={0} ml={10}>
                        <ButtonWrapper>
                            <SearchButton onClick={search} />
                            <Dropdown
                                renderButton={renderButton}
                                renderDropdown={renderDropdown}
                                onClose={() => {}}
                                openOnClick
                                disabled={savedSearches.length === 0}
                            />
                        </ButtonWrapper>
                    </Grid.Col>
                </Grid.Row>
            </Grid.FluidContainer>
        </Container>
    );
};

Search.propTypes = {
    onShowFilter: PropTypes.func.isRequired,
    onShowHelpModal: PropTypes.func.isRequired,
    languages: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    isLabeling: PropTypes.bool,
    isCharts: PropTypes.bool,
    chart: PropTypes.object,
    getChart: PropTypes.func,
    getArticles: PropTypes.func,
    getReservedArticles: PropTypes.func,
    getReservedArticlesCount: PropTypes.func,
    getWaitingArticlesCount: PropTypes.func,
    getLabeledArticlesCount: PropTypes.func,
    getSkippedArticlesCount: PropTypes.func,
    setLocalFilterMain: PropTypes.func,
    handleDateChange: PropTypes.func,
    localFilterMain: PropTypes.object,
};

function mapStateToProps(state) {
    const { languages, charts } = state;
    return { languages: Mapper.mapLanguagesToOptions(languages.languages), chart: charts.chart };
}

const mapDispatchToProps = {
    getChart: ChartsActions.getChart,
    getArticles: ArticlesActions.getArticles,
    getReservedArticles: ArticlesActions.getReservedArticles,
    getReservedArticlesCount: ArticlesCountActions.getReservedArticlesCount,
    getWaitingArticlesCount: ArticlesCountActions.getWaitingArticlesCount,
    getLabeledArticlesCount: ArticlesCountActions.getLabeledArticlesCount,
    getSkippedArticlesCount: ArticlesCountActions.getSkippedArticlesCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

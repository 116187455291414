import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.h3`
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
`;

export const Description = styled.span`
    font-size: 13px;
    color: ${Colors.SECONDARY};
`;

export const Domain = styled.a`
    text-decoration: underline;
    font-size: 13px;
    color: ${Colors.SECONDARY};
`;

export const Social = styled.div`
    display: flex;
    align-items: center;

    svg {
        fill: #555555;
        width: 20px;
        height: 20px;
    }
`;

export const SocialValue = styled.span`
    margin-left: 5px;
    font-size: 13px;
    color: ${Colors.SECONDARY};
`;

export const Tooltip = styled(ReactTooltip)`
    padding: 8px 10px !important;
`;

export const TooltipContentContainer = styled.div``;

export const TooltipContentRow = styled.div`
    display: flex;
    &:not(:first-child) {
        margin-top: 10px;
    }
`;

export const TooltipContentSocial = styled.div`
    width: 80px;
`;

export const TooltipContentInteractionCount = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;

    svg {
        width: 17px;
        height: 17px;
        fill: #ffffff;
    }
`;

import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    display: flex;
    align-items: center;
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    transition: 0.3s;

    &:hover {
        cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    }

    &:hover,
    &:active,
    &:focus {
        opacity: 0.77;
    }
`;

export const CheckContainer = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.checked ? Colors.PRIMARY : 'transparent')};
    border: 1px solid ${(props) => (props.checked ? Colors.PRIMARY : Colors.BACKGROUND)};
`;

export const Check = styled.img`
    padding-right: 1px;
    padding-top: 1px;
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};
    margin-left: 10px;
`;

import { GOOGLE, IMG_SEARCH } from 'styles/constant/images';

export const addImageButtons = (id) => {
    let images = document.querySelectorAll('#article-content img');
    let imageCount = 0;
    for (let image of images) {
        imageCount++;

        let wrapper = document.createElement('div');
        wrapper.className = 'image-meta-wrapper';

        let buttonWrapper = document.createElement('div');
        buttonWrapper.className = 'image-search-button-wrapper';

        let url = image.parentNode.href;

        !url &&
            Array.from(image.parentNode.children).forEach((item) => {
                const content = item.getAttribute('content');
                if (content && content.includes('http')) url = content;
            });

        // create google button and add click event
        let googleButton = document.createElement('button');
        let tooltip = document.createElement('div');
        googleButton.className = 'mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised';
        googleButton.setAttribute('data-article', id);
        googleButton.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            let googleUrl = 'https://www.google.com/searchbyimage?&image_url=' + url;
            window.open(googleUrl, '_blank', 'noopener noreferrer');
        });
        googleButton.innerHTML = `<img src="${GOOGLE}">`;

        tooltip.className = 'tooltip';
        tooltip.innerHTML = 'Reverse image search on Google';

        googleButton.appendChild(tooltip);
        buttonWrapper.appendChild(googleButton);

        // create tinEye button and add click event
        let tineyeButton = document.createElement('button');
        let tooltipEye = document.createElement('div');
        tineyeButton.className = 'mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised';
        tineyeButton.setAttribute('data-article', id);
        tineyeButton.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            let form = document.createElement('form');
            form.action = 'https://www.tineye.com/search';
            form.target = '_blank';
            form.rel = 'noopener noreferrer';
            form.innerHTML = `<input name="url" value="${url}" />`;
            document.body.appendChild(form);
            form.submit();
            form.remove();
        });
        tineyeButton.innerHTML = `<img src="${IMG_SEARCH}">`;

        tooltipEye.className = 'tooltip';
        tooltipEye.innerHTML = 'Reverse image search on Tineye';
        tineyeButton.appendChild(tooltipEye);
        buttonWrapper.appendChild(tineyeButton);

        // create Image Title and Buttons' label
        let imageTitle = image.getAttribute('title') || image.getAttribute('alt');
        if (!imageTitle) {
            imageTitle = 'Image ' + imageCount;
        }
        let imageTitleEl = document.createElement('p');
        imageTitleEl.innerHTML = '<i><strong>' + imageTitle + '</strong></i>';

        wrapper.appendChild(buttonWrapper);

        // exclude link from image search buttons
        let parent = image.parentNode;
        let sibling = image.nextSibling;
        if (parent.nodeName === 'A') {
            sibling = parent.nextSibling;
            parent = parent.parentNode;
        }

        parent.insertBefore(imageTitleEl, sibling);
        parent.insertBefore(wrapper, sibling);
    }
};

export const updateLinks = () => {
    let links = document.querySelectorAll('#article-content a');

    for (let link of links) {
        link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute('target', '_blank');
    }
};

export const updateTweets = () => {
    const tweetLinks = document.querySelectorAll('#article-content .twitter-tweet a');
    const tweetCards = document.querySelectorAll('#article-content .twitter-tweet');
    const tweets = [];
    tweetLinks.forEach((tweet) => {
        tweet = tweet.getAttribute('href').split('status/')[1];
        if (tweet) {
            tweet = tweet.split('?')[0];
            tweets.push(tweet);
        }
        return tweet;
    });

    let tweetList = document.querySelector('#tweetlist');
    let tweet = '';

    for (let i = 0; i < tweets.length; i++) {
        let id = tweets[i];
        tweet +=
            "<div id='" +
            tweets[i] +
            "' class='tweet'><a class='link' href='https://twitter.com/intent/tweet?in_reply_to=" +
            id +
            "&hashtags=bacon&text=Give%20Me'>";
        tweet += '</a></div>';
    }

    tweetList.innerHTML += tweet;

    const tweetItems = tweetList.querySelectorAll('.tweet');

    for (let i = 0; i < tweetItems.length; i++) {
        let id = tweetItems[i].getAttribute('id');
        let tweet = tweetItems[i];
        window.twttr.widgets.createTweet(id, tweet, {
            align: 'right',
            cards: 'hidden',
            follow: false,
        });

        tweetCards[i].replaceWith(tweet);
    }
};

export const addLanguagesToDuplicateFilters = (filters) => {
    const duplicateCounts = filters.reduce((result, value) => {
        return {
            ...result,
            [value.id]: (result[value.id] || 0) + 1,
        };
    }, {});

    return filters.reduce(
        (result, value) =>
            duplicateCounts[value.id] > 1 && value.language
                ? [...result, { ...value, name: `${value.name} [${value.language.toUpperCase()}]` }]
                : [...result, value],
        [],
    );
};

import { useDispatch } from 'react-redux';
import { ArticlesActions, SimilarArticlesActions } from 'features/articles/actions';
import { useState } from 'react';

function useArticleHide(props) {
    const { article, similar } = props;
    const dispatch = useDispatch();
    const [isForMeLoading, setIsForMeLoading] = useState(false);
    const [isForAllLoading, setIsForAllLoading] = useState(false);

    const hideArticleForMe = (callback) => {
        setIsForMeLoading(true);

        if (similar) {
            dispatch(
                SimilarArticlesActions.hideForMeSimilarArticle(
                    article.id,
                    article.parentId,
                    !article.isHiddenForMe,
                    () => {
                        setIsForMeLoading(false);
                        callback();
                    },
                ),
            );
        } else {
            dispatch(
                ArticlesActions.hideForMeArticle(article.id, article.parentId, !article.isHiddenForMe, () => {
                    setIsForMeLoading(false);
                    callback();
                }),
            );
        }
    };

    const hideArticleForAll = (callback) => {
        setIsForAllLoading(true);

        if (similar) {
            dispatch(
                SimilarArticlesActions.hideForAllSimilarArticle(
                    article.id,
                    article.parentId,
                    !article.isHiddenForAll,
                    () => {
                        setIsForAllLoading(false);
                        callback();
                    },
                ),
            );
        } else {
            dispatch(
                ArticlesActions.hideForAllArticle(article.id, article.parentId, !article.isHiddenForAll, () => {
                    setIsForAllLoading(false);
                    callback();
                }),
            );
        }
    };

    return {
        hideArticleForMe,
        hideArticleForAll,
        isForMeLoading,
        isForAllLoading,
    };
}

export default useArticleHide;

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';
import ReactTooltip from 'react-tooltip';

export const Container = styled.div`
    width: 100%;
    padding: ${(props) => (props.hasTransliteration ? '20px 20px 40px' : '20px')};
    display: flex;
    align-items: center;
    background-color: ${Colors.LIGHT_GREY};
`;

export const Columns = styled.div`
    display: flex;
    align-items: center; ;
`;

export const InputContainer = styled.div`
    width: 200px;
    position: relative;
`;

export const ReasoningInputContainer = styled.div`
    width: 240px;
    position: relative;
`;

export const Title = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};
`;

export const TransliterateColumn = styled(Grid.Col)`
    position: relative;
`;

export const TransliteratedText = styled.p`
    position: absolute;
    padding: 5px;
    height: 38px;
    font-size: 13px;
    max-width: 175px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const Action = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.BADGE_BACKGROUND};

    &:hover {
        cursor: pointer;
    }
`;

export const TranslateButtonWrapper = styled.div`
    position: relative;
    position: absolute;
    bottom: -33px;
    right: 0px;
`;

export const TranslateButton = styled(Action)`
    background-color: ${(props) => (props.clicked ? 'rgba(66, 133, 244, 0.12)' : Colors.BADGE_BACKGROUND)};
    transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${(props) => (props.clicked ? 'inset 0px 0px 5px #c1c1c1' : 'none')};

    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
`;

export const Icon = styled.img`
    width: 24px;
`;

export const Tooltip = styled(ReactTooltip)`
    max-width: 300px;
    overflow-wrap: break-word;
`;

import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';

const Source = (props) => {
    const { className, source, fontSize } = props;

    return (
        <Styles.Container className={className}>
            <Styles.Source fontSize={fontSize}>{source}</Styles.Source>
        </Styles.Container>
    );
};

Source.propTypes = {
    className: PropTypes.string,
    source: PropTypes.string,
    fontSize: PropTypes.number,
};

Source.defaultProps = {
    className: null,
    source: null,
    fontSize: 16,
};

export default Source;

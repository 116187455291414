import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

export const CheckContainer = styled.div`
    width: 20px;
    border-radius: 10px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => (props.checked ? Colors.PRIMARY : Colors.BACKGROUND)};
`;

export const Check = styled.div`
    background-color: ${Colors.PRIMARY};
    width: 12px;
    border-radius: 6px;
    height: 12px;
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};
    margin-left: 10px;
`;

import { useDispatch, useSelector } from 'react-redux';
import { ParametersTemplatesActions } from 'core/actions';

function useAddParametersTemplate() {
    const dispatch = useDispatch();
    const { isLoading, isLoaded } = useSelector((state) => state.parametersTemplates);

    const addParametersTemplate = (request, callback) => {
        dispatch(ParametersTemplatesActions.addParametersTemplates(request, callback));
    };

    return {
        addParametersTemplate,
        isLoading,
        isLoaded,
    };
}

export default useAddParametersTemplate;

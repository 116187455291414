import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    overflow-x: auto;
    && {
        &::-webkit-scrollbar {
            height: 5px;
        }
    }
`;

export const Tab = styled.div`
    flex: 1;
    padding: 15px 5px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid ${(props) => (props.active ? `2px ${Colors.PRIMARY}` : `1px ${Colors.TAB}`)};

    &:hover {
        border-bottom: 2px solid ${Colors.PRIMARY};
        cursor: pointer;

        > span {
            font-weight: 700;
            padding-top: 1px;
        }
    }
`;

export const Title = styled.span`
    color: ${Colors.BLACK};
    font-size: 14px;
    padding-top: ${(props) => (props.active ? 1 : 0)}px;
    font-weight: ${(props) => (props.active ? 700 : 500)};
`;

import styled, { css } from 'styled-components/macro';
import { Colors } from 'styles/constant';
import Icon from '../Icon';

const getPaddingRight = ({ help, showCharsLeft }) => {
    if (help) {
        return 40;
    }

    if (showCharsLeft) {
        return 60;
    }

    return 10;
};

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const inputStyles = css`
    border-radius: 5px;
    font-size: 13px;
    width: 100%;
    color: ${Colors.BLACK};
    border: 1px solid ${(props) => (props.error ? Colors.DANGER : Colors.BACKGROUND)};
    background-color: ${Colors.WHITE};
    padding-right: ${(props) => getPaddingRight(props)}px;
    padding-left: ${(props) => (props.leftIcon ? 40 : 10)}px;

    ${(props) =>
        props.disabled &&
        `
    opacity: 0.6;
  
    &:hover {
      cursor: no-drop;
    }  
  `}
`;

export const TextInput = styled.input`
    height: 40px;
    ${inputStyles}
`;

export const TextArea = styled.textarea`
    ${inputStyles}
    resize: none;
    padding: 10px;
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const Label = styled.label`
    font-size: 15px;
    line-height: 1.33;
    display: block;
    color: ${Colors.BLACK};
    margin-bottom: 10px;
`;

export const Error = styled.span`
    font-size: 13px;
    line-height: 1.33;
    display: block;
    color: ${Colors.DANGER};
    margin-top: 5px;
`;

export const HelpIcon = styled(Icon)`
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const LeftIcon = styled.img`
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
`;

export const MaxLength = styled.span`
    font-size: 12px;
    position: absolute;
    color: ${Colors.BLACK};
    right: 10px;
    top: 14px;
`;

export function readTranslateCookie() {
    var c = document.cookie.split('; '),
        cookies = {},
        i,
        C;

    for (i = c.length - 1; i >= 0; i--) {
        C = c[i].split('=');
        cookies[C[0]] = C[1];
    }

    return cookies['googtrans'];
}

export function setTranslateCookie() {
    document.cookie = 'googtrans=/auto/en; path=/;';
}

export function removeTranslateCookie() {
    document.cookie = 'googtrans=; Max-Age=-99999999;';
}

export const getIsTranslateActive = () => {
    const translateElement = document.getElementById('google_translate_element');
    return translateElement.classList.contains('show');
};

export const restoreTranslateElement = () => {
    const iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
    if (!iframe) return;

    const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    const restore_el = innerDoc.getElementsByTagName('button');

    for (let i = 0; i < restore_el.length; i++) {
        if (restore_el[i].id.indexOf('restore') >= 0) {
            restore_el[i].click();
            const close_el = innerDoc.getElementsByClassName('goog-close-link');
            close_el[0].click();
            return;
        }
    }
};

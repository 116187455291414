import uniq from 'lodash/uniq';
import { Grid } from 'styles/components';
import {
    Tooltip,
    Container,
    Title,
    Description,
    Domain,
    Social,
    SocialValue,
    TooltipContentContainer,
    TooltipContentRow,
    TooltipContentSocial,
    TooltipContentInteractionCount,
} from './styles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RTLLanguages } from 'constant';
import { ReactComponent as ThumbSvg } from 'assets/img/thumb.svg';
import { ReactComponent as CommentSvg } from 'assets/img/comment.svg';
import { ReactComponent as ShareSvg } from 'assets/img/share.svg';
import { ReactComponent as EyeSvg } from 'assets/img/eye.svg';

const interactionTypes = ['reactions', 'comments', 'shares', 'views'];

const getSocialValue = (socialMetrics, type) => {
    if (socialMetrics) {
        const metric = socialMetrics[type];
        if (metric.facebook) {
            return metric.total;
        }
        return `${metric.total}*`;
    }
    return '?';
};

const getSocialCounts = (socialMetrics) =>
    interactionTypes.reduce((acc, value) => {
        return {
            ...acc,
            [value]: getSocialValue(socialMetrics, value),
        };
    }, {});

const getTooltipData = (socialMetrics) => {
    if (socialMetrics) {
        const socialNetworks = uniq(
            interactionTypes.reduce((acc, value) => {
                return [...acc, ...Object.keys(socialMetrics[value])];
            }, []),
        ).filter((x) => x !== 'total');
        return socialNetworks.map((x) => ({
            name: x,
            data: [
                socialMetrics.reactions[x],
                socialMetrics.comments[x],
                socialMetrics.shares[x],
                socialMetrics.views[x],
            ],
        }));
    }
};

const Header = ({ article }) => {
    const { Col, Row } = Grid;
    const { title, publishDate, domain, author, technicalData } = article;
    const lang = technicalData.lang;
    const url = technicalData.url;

    useEffect(() => {
        document.getElementById('title').setAttribute('translate', 'yes');
    }, []);

    const tooltipData = getTooltipData(technicalData.social_metrics);

    const socialCounts = getSocialCounts(technicalData.social_metrics);

    return (
        <Container>
            <Row mb={10}>
                <Title id="title" lang={lang} dir={RTLLanguages.includes(lang) ? 'rtl' : 'ltr'}>
                    {title}
                </Title>
            </Row>
            <Row>
                <Col flex={1}>
                    <Row>
                        <Col mr={10}>
                            <Description>{publishDate}</Description>
                        </Col>
                        <Col mr={10}>
                            <Domain target="_blank" rel="noreferrer noopener" href={url}>
                                {domain}
                            </Domain>
                        </Col>
                        {author && (
                            <Col>
                                <Description>Author: {author}</Description>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col data-tip data-for="socialsTooltip">
                    <Row>
                        <Col>
                            <Social>
                                <ThumbSvg />
                                <SocialValue>{socialCounts.reactions}</SocialValue>
                            </Social>
                        </Col>
                        <Col ml={20}>
                            <Social>
                                <CommentSvg />
                                <SocialValue>{socialCounts.comments}</SocialValue>
                            </Social>
                        </Col>
                        <Col ml={20}>
                            <Social>
                                <ShareSvg />
                                <SocialValue>{socialCounts.shares}</SocialValue>
                            </Social>
                        </Col>
                        <Col ml={20}>
                            <Social>
                                <EyeSvg />
                                <SocialValue>{socialCounts.views}</SocialValue>
                            </Social>
                        </Col>
                    </Row>
                </Col>

                {tooltipData && (
                    <Tooltip id="socialsTooltip" place="bottom" effect="solid">
                        <TooltipContentContainer>
                            <TooltipContentRow>
                                <TooltipContentSocial />
                                <TooltipContentInteractionCount>
                                    <ThumbSvg />
                                </TooltipContentInteractionCount>
                                <TooltipContentInteractionCount>
                                    <CommentSvg />
                                </TooltipContentInteractionCount>
                                <TooltipContentInteractionCount>
                                    <ShareSvg />
                                </TooltipContentInteractionCount>
                                <TooltipContentInteractionCount>
                                    <EyeSvg />
                                </TooltipContentInteractionCount>
                            </TooltipContentRow>
                            {tooltipData.map((social) => (
                                <TooltipContentRow key={social.name}>
                                    <TooltipContentSocial>{social.name}</TooltipContentSocial>
                                    {social.data.map((x, i) => (
                                        <TooltipContentInteractionCount key={i}>
                                            {x === undefined ? '-' : x}
                                        </TooltipContentInteractionCount>
                                    ))}
                                </TooltipContentRow>
                            ))}
                            <TooltipContentRow>
                                * facebook social metrics not yet collected. Try later.
                            </TooltipContentRow>
                        </TooltipContentContainer>
                    </Tooltip>
                )}
            </Row>
        </Container>
    );
};

Header.propTypes = {
    article: PropTypes.object,
};

Header.defaultProps = {
    upVotesCount: null,
    commentsCount: null,
    sharesCount: null,
    lang: 'en',
};

export default Header;

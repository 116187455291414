export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'multiple',
        width: 200,
    },
    {
        Header: 'Languages',
        accessor: (row) => {
            let langs = [];
            row.lang &&
                row.lang.map((l) => {
                    langs.push(l.lang.iso.toUpperCase());
                    return l;
                });
            langs = langs.sort((a, b) => a.localeCompare(b));
            return langs.join(', ');
        },
        filter: 'multiple',
    },
    {
        Header: 'Root category',
        accessor: 'root',
        filter: 'multiple',
        width: 150,
    },
    {
        Header: 'Subcategory level 1',
        accessor: (row) => row.sub1 || '-',
        filter: 'multiple',
        width: 150,
    },
    {
        Header: 'Subcategory level 2',
        accessor: (row) => row.sub2 || '-',
        filter: 'multiple',
        width: 150,
    },
    {
        Header: 'Subcategory level 3',
        accessor: (row) => row.sub3 || '-',
        filter: 'multiple',
        width: 150,
    },
    {
        Header: 'Weight',
        accessor: (row) => row.filter_weight || '',
        filter: 'multiple',
        input: true,
    },
    // {
    //     Header: 'ID',
    //     accessor: row => row.id || 'null',
    //     filter: "multiple",
    //     width: 100
    // },
];

export const LIST = (filters) => {
    const list = [];

    filters.map((filter) => {
        if (filter.category) {
            const category = filter.category;
            if (category.parent) {
                const parent = category.parent;
                if (parent.parent) {
                    const parent2 = parent.parent;
                    if (parent2.parent) {
                        const parent3 = parent2.parent;
                        list.push({
                            ...filter,
                            root: parent3.name,
                            sub1: parent2.name,
                            sub2: parent.name,
                            sub3: category.name,
                        });
                    } else {
                        list.push({
                            ...filter,
                            root: parent2.name,
                            sub1: parent.name,
                            sub2: category.name,
                        });
                    }
                } else {
                    list.push({
                        ...filter,
                        root: parent.name,
                        sub1: category.name,
                    });
                }
            } else {
                list.push({
                    ...filter,
                    root: category.name,
                });
            }
        } else {
            list.push({
                ...filter,
                root: '-',
            });
        }
        return filter;
    });

    return list;
};

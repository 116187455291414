import React, { useEffect, useState } from 'react';
import { Section, Title } from '../../pages/Organisation/styles';
import { Grid } from '../../styles/components';
import { Button, Input, MultiSelect } from '../../components';
import { Buttons } from '../../styles/constant';
import PropTypes from 'prop-types';
import { OrganisationsActions } from '../../core/actions';
import { connect } from 'react-redux';

function OrganisationForm(props) {
    const { organisations, createOrganisation, history, updateOrganisation, isUpdating, languages, charts } = props;
    const [organisation, setOrganisation] = useState(false);
    const [name, setName] = useState('');
    const [languagesAssigned, setLanguages] = useState([]);
    const [chartsAssigned, setCharts] = useState([]);

    useEffect(() => {
        document.title = organisation.id
            ? `Debunk EU - Edit Organisation - ${organisation.name}`
            : 'Create New Organisation';

        const id = history.location.pathname.split('/').pop();
        if (!organisation && id !== 'new') {
            const currentOrganisation = organisations.find((o) => o.id == id);
            setOrganisation(currentOrganisation);
            setName(currentOrganisation.name);
            currentOrganisation.charts && setCharts(currentOrganisation.charts.map((c) => c.id));
            setLanguages(currentOrganisation.languages.map((l) => l.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    const onSubmit = () => {
        const data = { name, languages: languagesAssigned, charts: chartsAssigned };
        if (!organisation) {
            createOrganisation(data);
        } else {
            updateOrganisation(organisation.id, data);
        }
    };

    return (
        <Grid.FixedContainer>
            <Grid.Row>
                <Title>{organisation ? 'Edit ' : 'Create new '}organisation</Title>
            </Grid.Row>

            <Section>
                <Grid.Row mb={20}>
                    <Input onChange={(e) => setName(e.value)} value={name} name="name" label="Name" />
                </Grid.Row>
                <Grid.Row mb={20}>
                    <Grid.Col mr={20} flex={1}>
                        <MultiSelect
                            large
                            forceOpen
                            onChange={(e) => setLanguages(e.value)}
                            value={languagesAssigned}
                            name="languages"
                            label="Languages"
                            options={languages.map((l) => {
                                return { label: l.name, value: l.id };
                            })}
                        />
                    </Grid.Col>
                    <Grid.Col flex={1}>
                        <MultiSelect
                            large
                            forceOpen
                            onChange={(e) => setCharts(e.value)}
                            value={chartsAssigned}
                            name="charts"
                            label="Charts"
                            options={charts.map((l) => {
                                return { label: `${l.name} (ID ${l.id})`, value: l.id };
                            })}
                        />
                    </Grid.Col>
                </Grid.Row>
            </Section>

            <Grid.Row>
                <Grid.Col mr={10}>
                    <Button label="Submit" loading={isUpdating} type={Buttons.PRIMARY} onClick={onSubmit} />
                </Grid.Col>
                <Grid.Col>
                    <Button label="Cancel" type={Buttons.TERTIARY} onClick={() => history.push('/organisations')} />
                </Grid.Col>
            </Grid.Row>
        </Grid.FixedContainer>
    );
}

OrganisationForm.propTypes = {
    history: PropTypes.object.isRequired,
    organisations: PropTypes.arrayOf(PropTypes.object),
    languages: PropTypes.arrayOf(PropTypes.object),
    charts: PropTypes.arrayOf(PropTypes.object),
    createOrganisation: PropTypes.func.isRequired,
    updateOrganisation: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool,
    isUpdating: PropTypes.bool,
};

function mapStateToProps(state) {
    const { organisations } = state;
    const { isUpdated, isUpdating } = organisations;
    return { isUpdated, isUpdating };
}

const mapDispatchToProps = {
    createOrganisation: OrganisationsActions.createOrganisation,
    updateOrganisation: OrganisationsActions.updateOrganisation,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationForm);

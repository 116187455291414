import styled from 'styled-components';
import { Colors } from 'styles/constant';
import { Grid } from 'styles/components';

export const Container = styled(Grid.Row)`
    width: 100%;
    height: 42px;
    padding: 0 5px;
    overflow-x: hidden;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    background-color: ${(props) => (props.selected ? Colors.LIGHT_GREY : Colors.WHITE)};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    &:hover {
        cursor: pointer;
        background-color: ${Colors.LIGHT_GREY};
    }
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${Colors.BLACK};

    span {
        color: ${Colors.HEADER_TEXT};
    }
`;

export const LabelContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

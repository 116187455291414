import { ArticlesCountTypes } from 'features/articles/types';
import { ArticlesService } from 'features/articles/services';
import { ToastService } from 'core/services';

export function getReservedArticlesCount() {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT_START });
            const { total } = await ArticlesService.getReservedArticlesCount();
            dispatch({ type: ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT, payload: { count: total } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesCountTypes.GET_RESERVED_ARTICLES_COUNT_ERROR });
        }
    };
}

export function getWaitingArticlesCount(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT_START });
            const { total } = await ArticlesService.getArticlesCount(request, 'Not labeled');
            dispatch({ type: ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT, payload: { count: total } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesCountTypes.GET_WAITING_ARTICLES_COUNT_ERROR });
        }
    };
}

export function getLabeledArticlesCount(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT_START });
            const { total } = await ArticlesService.getArticlesCount(request, 'Labeled');
            dispatch({ type: ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT, payload: { count: total } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesCountTypes.GET_LABELED_ARTICLES_COUNT_ERROR });
        }
    };
}

export function getSkippedArticlesCount(request) {
    return async (dispatch) => {
        try {
            dispatch({ type: ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT_START });
            const { total } = await ArticlesService.getArticlesCount(request, 'Skipped');
            dispatch({ type: ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT, payload: { count: total } });
        } catch (exception) {
            ToastService.showGenericError();
            dispatch({ type: ArticlesCountTypes.GET_SKIPPED_ARTICLES_COUNT_ERROR });
        }
    };
}

import update from 'immutability-helper';
import { ChartsTypes } from 'core/types';

const initialState = {
    charts: [],
    active: null,
    isLoading: true,
    isLoaded: false,
    chart: {},
    isChartLoading: false,
    isChartLoaded: false,
    isDataLoading: false,
    isDataLoaded: false,
    error: false,
    isTypesLoading: true,
    isTypesLoaded: false,
    isOptionsLoading: false,
    isOptionsLoaded: false,
    options: {},
    types: [],
    isUpdating: false,
    isUpdated: false,
    chartArticles: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ChartsTypes.GET_CHARTS_START: {
            return update(state, {
                isLoading: { $set: true },
                isLoaded: { $set: false },
                error: { $set: false },
                chartArticles: { $set: {} },
                chart: { $set: {} },
            });
        }

        case ChartsTypes.GET_CHART_START: {
            return update(state, {
                isChartLoading: { $set: true },
                isChartLoaded: { $set: false },
                error: { $set: false },
                chartArticles: { $set: {} },
            });
        }

        case ChartsTypes.GET_CHART_ARTICLES_START: {
            return update(state, {
                isDataLoading: { $set: true },
                isDataLoaded: { $set: false },
                error: { $set: false },
            });
        }

        case ChartsTypes.GET_OPTIONS_START: {
            return update(state, {
                isOptionsLoading: { $set: true },
                isOptionsLoaded: { $set: false },
            });
        }

        case ChartsTypes.GET_TYPES_START: {
            return update(state, {
                isTypesLoading: { $set: true },
                isTypesLoaded: { $set: false },
            });
        }

        case ChartsTypes.GET_CHARTS: {
            return update(state, {
                isLoading: { $set: false },
                isLoaded: { $set: true },
                charts: { $set: payload.charts },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                isDataLoaded: { $set: false },
            });
        }

        case ChartsTypes.GET_CHART: {
            return update(state, {
                chart: { $set: payload.chart },
                isChartLoading: { $set: false },
                isChartLoaded: { $set: true },
                isDataLoaded: { $set: false },
            });
        }

        case ChartsTypes.GET_CHART_ARTICLES: {
            return update(state, {
                chartArticles: { $set: payload.chartArticles },
                isDataLoading: { $set: false },
                isDataLoaded: { $set: true },
            });
        }

        case ChartsTypes.GET_CHARTS_OPTIONS: {
            return update(state, {
                options: { $set: payload.options },
                isOptionsLoaded: { $set: true },
                isOptionsLoading: { $set: false },
            });
        }

        case ChartsTypes.GET_CHARTS_TYPES: {
            return update(state, {
                types: { $set: payload.types },
                isTypesLoaded: { $set: true },
                isTypesLoading: { $set: false },
            });
        }

        case ChartsTypes.UPDATE_CHARTS_START: {
            return update(state, {
                isUpdating: { $set: true },
                isUpdated: { $set: false },
            });
        }

        case ChartsTypes.CREATE_CHART:
        case ChartsTypes.UPDATE_CHART:
        case ChartsTypes.REMOVE_CHART: {
            return update(state, {
                isUpdating: { $set: false },
                isUpdated: { $set: true },
            });
        }

        case ChartsTypes.GET_CHARTS_ERROR:
            return update(state, {
                error: { $set: true },
                isLoading: { $set: false },
                isLoaded: { $set: true },
                isChartLoading: { $set: false },
                isChartLoaded: { $set: false },
                isUpdating: { $set: false },
                isUpdated: { $set: false },
                isDataLoading: { $set: false },
                isDataLoaded: { $set: false },
            });

        case ChartsTypes.GET_CHART_ARTICLES_ERROR:
            return update(state, {
                isDataLoading: { $set: false },
                isDataLoaded: { $set: true },
                chartArticles: { $set: payload.exception },
            });

        case ChartsTypes.RESET_CHART_ARTICLES:
            return update(state, {
                isDataLoading: { $set: false },
                isDataLoaded: { $set: false },
                chartArticles: { $set: {} },
            });

        default: {
            return state;
        }
    }
};

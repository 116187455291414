import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import { Images } from 'styles/constant';

function CloseIcon(props) {
    const { onClick } = props;

    return (
        <Styles.Container onClick={onClick}>
            <Styles.Icon src={Images.CLOSE_WHITE_2} />
        </Styles.Container>
    );
}

CloseIcon.propTypes = {
    onClick: PropTypes.func,
};

CloseIcon.defaultProps = {
    onClick: () => {},
};

export default CloseIcon;

import styled from 'styled-components/macro';
import { Colors } from 'styles/constant';
import { CHART } from '../../../../styles/constant/images';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${Colors.LIGHT_GREY};
    border-right: 1px solid ${Colors.BACKGROUND};
`;

export const Header = styled.div`
    flex-shrink: 0;
    display: flex;
    padding: 0 15px;
    align-items: center;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    height: 60px;
    background-color: ${Colors.WHITE};
`;

export const Footer = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.WHITE};
    border-top: 1px solid ${Colors.BACKGROUND};
    padding: 10px 15px;
`;

export const Content = styled.div`
    display: flex;
    padding: 15px;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
`;

export const LabelingHeader = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border-bottom: 1px solid ${Colors.BACKGROUND};
    background-color: ${Colors.WHITE};
`;

export const LoaderContainer = styled.div`
    padding: 25px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FullPageLoaderContainer = styled(LoaderContainer)`
    height: 100%;
`;

export const NoData = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.BLACK};
    font-size: 14px;
`;

export const ArticleContainer = styled.div`
    margin-bottom: 5px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const SimilarArticlesContainer = styled.div`
    padding-left: 20px;
    padding-top: 10px;
    margin-bottom: 10px;
    border-left: 1px solid
        ${(props) => (props.loading === 'true' || props.articlesCount === 0 ? 'transparent' : Colors.SIMILAR_BORDER)};

    .article-container {
        position: relative;

        &:last-of-type:before {
            border-left: 1px solid ${Colors.LIGHT_GREY};
        }

        &:before {
            content: '';
            position: absolute;
            left: -21px;
            width: 20px;
            height: 50%;
            border-top: 1px solid ${Colors.SIMILAR_BORDER};
            bottom: 0;
            border-left: 1px solid ${Colors.SIMILAR_BORDER};
            background-color: ${Colors.LIGHT_GREY};
        }
    }
`;

export const Label = styled.span`
    color: ${Colors.BADGE_TEXT};
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0 5px 25px;
`;

export const ShowChartButton = styled.div`
    background: url(${CHART}) no-repeat left/20px;
    color: ${Colors.BADGE_TEXT};
    font-size: 14px;
    padding-left: 30px;
    line-height: 25px;
    margin-top: 14px;
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

import React from 'react';
import { LinkWrapper, IconBtn } from './styles';
import PropTypes from 'prop-types';

const IconButton = (props) => {
    const { to, onClick, label, bg, small, type, disabled } = props;

    return (
        <LinkWrapper disabled={disabled}>
            <IconBtn to={to} onClick={onClick} bg={bg} small={small} text={label} type={type}>
                {label}
            </IconBtn>
        </LinkWrapper>
    );
};

IconButton.propTypes = {
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    label: PropTypes.string,
    bg: PropTypes.string,
    small: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
};

export default IconButton;

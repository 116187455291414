import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    width: 135px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${Colors.TAB};
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
`;

export const Header = styled.div`
    background-color: ${Colors.BADGE_BACKGROUND};
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${Colors.TAB};
`;

export const Title = styled.span`
    font-size: 15px;
    font-weight: bold;
    color: ${Colors.BLACK};
`;

export const DatesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding: 8px;
    width: 100%;
`;

export const Date = styled.button`
    font-size: 13px;
    height: 30px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 2px;
    color: ${(props) => (props.active ? Colors.WHITE : Colors.BLACK)};
    background-color: ${(props) => (props.active ? Colors.PRIMARY : Colors.WHITE)};
    border: none;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => (props.active ? Colors.PRIMARY : Colors.HOVER)};
    }
`;

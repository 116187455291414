import React, { useEffect, memo } from 'react';
import transliterate from '@sindresorhus/transliterate';
import {
    Container,
    InputContainer,
    Title,
    TransliteratedText,
    Columns,
    TransliterateColumn,
    ReasoningInputContainer,
    TranslateButton,
    Icon,
    TranslateButtonWrapper,
    Tooltip,
} from './styles';
import { Grid } from 'styles/components';
import { Checkbox, CloseIcon, Input, SingleSelect } from 'components';
import { OPERATORS, RTLLanguages } from 'constant';
import PropTypes from 'prop-types';
import { SharedTypes, TranslateUtils } from 'utils';
import update from 'immutability-helper';
import { Images } from 'styles/constant';

const TRANSLITERATED_LANGUAGES = [
    'ab',
    'sq',
    'ar',
    'bs',
    'bg',
    'zh-cn',
    'hr',
    'ka',
    'el',
    'mk',
    'ps',
    'fa',
    'ru',
    'sr',
    'ur',
];

const RuleFormComponent = memo(function RuleForm(props) {
    const { rule, onChange, onRemove, language, shouldTranslate, handleTranslateButtonClick } = props;

    useEffect(() => {
        document.querySelectorAll('.transliteratedText').forEach((el) => el.setAttribute('translate', 'yes'));
    }, []);

    const handleOnChange = (event) => {
        const { name, value } = event;

        onChange(
            update(rule, {
                [name]: { $set: value },
            }),
        );
    };

    const handleOnBlur = () => {
        onChange(
            update(rule, {
                value: { $set: rule.value && rule.value.trim() },
            }),
        );
    };

    const hasTransliteration = TRANSLITERATED_LANGUAGES.includes(language);

    const onTranslateButtonClick = () => {
        const isClicked = TranslateUtils.handleTranslateContainer();
        handleTranslateButtonClick(isClicked);
    };

    const examplesTransliteratedText = rule.examples
        ? shouldTranslate
            ? rule.examples
            : transliterate(rule.examples)
        : '';

    return (
        <Container hasTransliteration={hasTransliteration}>
            <Columns>
                <Grid.Col mr={10}>
                    <Title>Content</Title>
                </Grid.Col>
                <Grid.Col mr={10}>
                    <InputContainer>
                        <SingleSelect
                            clearable={false}
                            value={rule.selectedOperator}
                            searchable={false}
                            onChange={handleOnChange}
                            name="selectedOperator"
                            options={OPERATORS}
                        />
                    </InputContainer>
                </Grid.Col>
                <TransliterateColumn mr={10}>
                    <InputContainer>
                        <Input
                            onBlur={handleOnBlur}
                            placeholder="Value"
                            value={rule.value}
                            onChange={handleOnChange}
                            name="value"
                            dir={RTLLanguages.includes(language) ? 'rtl' : 'ltr'}
                        />
                    </InputContainer>
                    {hasTransliteration && (
                        <InputContainer>
                            <TransliteratedText>{rule.value ? transliterate(rule.value) : ''}</TransliteratedText>
                        </InputContainer>
                    )}
                </TransliterateColumn>
                <TransliterateColumn mr={10}>
                    <InputContainer data-tip data-for={rule.examples}>
                        <Input
                            onBlur={handleOnBlur}
                            placeholder="Examples in full words"
                            value={rule.examples}
                            onChange={handleOnChange}
                            name="examples"
                            dir={RTLLanguages.includes(language) ? 'rtl' : 'ltr'}
                        />
                        {rule.examples && (
                            <Tooltip id={rule.examples} place="top" effect="solid">
                                {rule.examples}
                            </Tooltip>
                        )}
                    </InputContainer>
                    {hasTransliteration && (
                        <InputContainer data-tip data-for={examplesTransliteratedText + 'transliteratedText'}>
                            <TransliteratedText className="transliteratedText">
                                {examplesTransliteratedText}
                            </TransliteratedText>
                            {examplesTransliteratedText && (
                                <Tooltip
                                    id={examplesTransliteratedText + 'transliteratedText'}
                                    place="left"
                                    effect="solid"
                                >
                                    <p translate="yes">{examplesTransliteratedText}</p>
                                </Tooltip>
                            )}
                        </InputContainer>
                    )}
                    {hasTransliteration && rule.examples && (
                        <TranslateButtonWrapper>
                            <TranslateButton
                                onClick={onTranslateButtonClick}
                                clicked={shouldTranslate}
                                data-tip
                                data-for="translateButton"
                            >
                                <Icon src={Images.TRANSLATION} />
                            </TranslateButton>

                            <Tooltip id="translateButton" place="top" effect="solid">
                                {shouldTranslate ? 'Transliterate' : 'Translate'}
                            </Tooltip>
                        </TranslateButtonWrapper>
                    )}
                </TransliterateColumn>
                <Grid.Col mr={10}>
                    <ReasoningInputContainer data-tip data-for={rule.reasoning}>
                        <Input
                            onBlur={handleOnBlur}
                            placeholder="Reasoning / Explanation / Comments"
                            value={rule.reasoning}
                            onChange={handleOnChange}
                            name="reasoning"
                        />
                        {rule.reasoning && (
                            <Tooltip id={rule.reasoning} place="top" effect="solid">
                                {rule.reasoning}
                            </Tooltip>
                        )}
                    </ReasoningInputContainer>
                </Grid.Col>
                <Grid.Col flex={1} mr={10}>
                    <Checkbox
                        checked={rule.caseSensitive}
                        name="caseSensitive"
                        label="Case sensitive"
                        onChange={handleOnChange}
                    />
                </Grid.Col>
                <Grid.Col>
                    <CloseIcon onClick={onRemove} />
                </Grid.Col>
            </Columns>
        </Container>
    );
});

RuleFormComponent.propTypes = {
    rule: SharedTypes.RuleType.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    language: PropTypes.string,
    shouldTranslate: PropTypes.bool,
    handleTranslateButtonClick: PropTypes.func,
};

export default RuleFormComponent;

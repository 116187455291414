import styled, { css } from 'styled-components/macro';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    background-color: ${Colors.WHITE};

    ${(props) =>
        props.forceOpen &&
        css`
            overflow-y: hidden;
            height: ${(props) => (props.large ? 557 : 470)}px;
        `}
`;

export const OptionsContainer = styled.div`
    width: 100%;
    max-height: ${(props) => (props.forceOpen || props.large ? 'calc(100% - 46px)' : '275px')};
    overflow-y: auto;
    padding: 10px;
`;

export const SearchContainer = styled.div`
    width: 100%;
    padding: 10px 10px 0 10px;
    position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
    top: 0;
    background-color: ${(props) => (props.sticky ? Colors.WHITE : 'transparent')};
`;

export const SearchInput = styled.input`
    height: 36px;
    width: 100%;
    border-radius: 5px;
    font-size: 13px;
    color: ${Colors.BLACK};
    border: 1px solid ${Colors.BACKGROUND};
    background-color: ${Colors.LIGHT_GREY};
    padding: 0 10px;
    padding-right: 40px;
`;

export const SearchIcon = styled.img`
    position: absolute;
    right: 20px;
    top: 10px;
    bottom: 0;
    margin: auto;
`;

export const NoDataContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const NoDataTitle = styled.span`
    font-size: 14px;
    line-height: 30px;
    color: ${Colors.PLACEHOLDER};
`;

export const SelectedOptionsContainer = styled.div`
    height: ${(props) => (props.collapsed ? '36px' : 'auto')};
    overflow: hidden;
    border-bottom: 1px solid ${(props) => (props.disabled ? Colors.PLACEHOLDER : Colors.BLACK)};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    padding: ${(props) => (props.collapsed ? 0 : '0 10px')};
    margin: ${(props) => (props.collapsed ? 0 : '0 -10px')};
`;

export const CollapseToggler = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;

    &:after {
        content: '';
        border: solid ${Colors.BLACK};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2px;
        transform: ${(props) => (props.collapsed ? 'rotate(-45deg)' : 'rotate(-135deg)')};
        transition: 0.3s;
        margin-left: auto;
        margin-right: 2px;
    }

    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
`;

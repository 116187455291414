import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Text = styled.div`
    font-size: 14px;
    color: ${Colors.BLACK};
    line-height: 1.5;
    display: flex;
    font-weight: 300;

    a {
        text-decoration: none;
        color: ${Colors.BADGE_TEXT};
    }
`;

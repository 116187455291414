import { useDispatch, useSelector } from 'react-redux';
import { ArticlesCountActions } from 'features/articles/actions';
import { useSearch } from 'hooks';

function useArticlesCount() {
    const { filter } = useSearch();
    const dispatch = useDispatch();
    const {
        reservedCount,
        labeledCount,
        skippedCount,
        waitingCount,
        isReservedLoading,
        isLabeledLoading,
        isWaitingLoading,
        isSkippedLoading,
    } = useSelector((state) => state.articlesCount);

    // useDeepCompareEffect(() => {
    //   dispatch(ArticlesCountActions.getReservedArticlesCount());
    //   dispatch(ArticlesCountActions.getLabeledArticlesCount({filter}));
    //   dispatch(ArticlesCountActions.getSkippedArticlesCount({filter}));
    //   dispatch(ArticlesCountActions.getWaitingArticlesCount({filter}));
    // }, [dispatch, filter]);

    const getArticlesCount = () => {
        dispatch(ArticlesCountActions.getReservedArticlesCount());
        dispatch(ArticlesCountActions.getLabeledArticlesCount({ filter }));
        dispatch(ArticlesCountActions.getSkippedArticlesCount({ filter }));
        dispatch(ArticlesCountActions.getWaitingArticlesCount({ filter }));
    };

    const getReservedArticlesCount = () => {
        dispatch(ArticlesCountActions.getReservedArticlesCount({ filter }));
    };

    const getWaitingArticlesCount = () => {
        dispatch(ArticlesCountActions.getWaitingArticlesCount({ filter }));
    };

    const getLabeledArticlesCount = () => {
        dispatch(ArticlesCountActions.getLabeledArticlesCount({ filter }));
    };

    const getSkippedArticlesCount = () => {
        dispatch(ArticlesCountActions.getSkippedArticlesCount({ filter }));
    };

    return {
        reservedCount,
        labeledCount,
        skippedCount,
        waitingCount,
        isReservedLoading,
        isLabeledLoading,
        isWaitingLoading,
        isSkippedLoading,
        getReservedArticlesCount,
        getWaitingArticlesCount,
        getLabeledArticlesCount,
        getSkippedArticlesCount,
        getArticlesCount,
    };
}

export default useArticlesCount;

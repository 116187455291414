import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Container = styled.div`
    max-width: 230px;
`;

export const Button = styled.div`
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    height: 40px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    transition: 0.3s;

    &:hover,
    &:active,
    &:focus {
        opacity: 0.77;
    }

    * {
        cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
`;

export const Icon = styled.img``;

export const Label = styled.label`
    color: ${Colors.BADGE_TEXT};
    font-size: 14px;
    font-weight: 500;
`;

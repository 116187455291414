import React from 'react';
import { ScrollToTop } from 'components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthenticationRoute, ProtectedRoute } from 'containers';
import { AdminLayout, AuthLayout } from 'layouts';
import { ToastContainer } from 'react-toastify';
import { EmailRedirectPage } from './pages';

function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop>
                    <Switch>
                        <Route path="/email/campaign/:id" component={EmailRedirectPage} />
                        <AuthenticationRoute path="/auth" component={AuthLayout} />
                        <ProtectedRoute path="/" component={AdminLayout} />
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
            <ToastContainer />
        </>
    );
}

export default App;

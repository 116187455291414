import styled, { css } from 'styled-components/macro';
import { Colors } from 'styles/constant';
import hexToRgba from 'hex-to-rgba';
import { device } from '../../styles/device';

export const TopWrapper = styled.div`
    padding: 24px 0 4px;
    width: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media ${device.tabletPortrait} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        > div,
        button {
            width: 100%;
        }

        > div {
            margin-bottom: 10px;
        }
    }
`;

export const TableMain = styled.table`
    display: table;
    background-color: ${Colors.WHITE};
    border-radius: 5px;
    min-width: 100%;
    border-spacing: 0;
    border: 2px solid ${Colors.BLACK};
`;

export const TableRow = styled.tr`
    display: table-row !important;
    transition: 0.3s;
    width: 100% !important;
    box-shadow: inset 0 -1px 0 0 rgba(85, 85, 85, 0.15);

    &:hover,
    &:active,
    &:focus {
        background-color: ${hexToRgba(Colors.LIGHT_GREY, 0.5)};
    }
`;

const cellStyles = css`
    font-size: 13px;
    color: ${(props) => (props.color ? props.color : Colors.BLACK)};
    background-color: ${(props) => (props.bg ? props.bg : 'transparent')};
    padding: 16px 6px;
    font-weight: ${(props) => (props.bold ? 500 : 300)};
    white-space: pre-line;
    line-height: 1.4;
    display: table-cell !important;
    height: 0;
    text-align: left;
    min-width: 70px;
    border-top: ${(props) => (props.border ? '2px solid' + Colors.BLACK : 'none')};
    max-width: 350px;

    &:first-of-type,
    &:first-of-type > div > div {
        padding-left: ${(props) => (props.checkboxes ? 16 : 35)}px;
    }

    &:last-of-type,
    &:last-of-type > div > div {
        padding-right: 34px;
    }

    @media ${device.tabletLandscape} {
        font-size: 12px;
    }
`;

export const TableCell = styled.td`
    ${cellStyles};
    > div > div {
        padding: 0 !important;
    }
`;

export const TableCellHeader = styled.th`
    ${cellStyles};
    padding: 0 !important;
    box-shadow: 0 1px 0 0 rgba(85, 85, 85, 0.15);
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const HeaderWrapper = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    width: 100%;
`;

export const HeaderTitle = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px 6px;
    font-size: 13px;
    background-color: ${Colors.SECONDARY};
    color: ${Colors.WHITE};
    display: flex;
    align-items: flex-end;
`;

export const HeaderFilter = styled.div`
    width: 100%;
    position: relative;
    padding: 16px 6px;
    background-color: ${Colors.TABLE_FILTERS};
    min-height: 72px;

    > div {
        height: 40px;
        justify-content: center;

        > div > div > div:last-of-type:not(:first-child) {
            min-width: 200px;
        }
    }
`;

export const TableWrapper = styled.div`
    max-width: 100%;
    min-width: 100%;
    overflow-x: auto;
    min-height: 60vh;
    padding: 0 15px;

    @media (min-width: 991px) {
        width: ${(props) => (props.fixedWidth ? '100%!important' : 'auto')};
    }
`;

export const Txt = styled.div`
    padding-right: 33px;
    font-size: 16px;
    font-weight: 500;
    position: relative;

    &:after {
        content: '';
        width: 1px;
        height: 24px;
        background-color: ${Colors.BLACK};
        position: absolute;
        right: 20px;
        left: auto;
        top: 6px;
        display: ${(props) => (props.bordered ? 'block' : 'none')};

        @media ${device.tabletPortrait} {
            display: none;
        }
    }
`;

export const TxtBtn = styled.span`
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
    transition: 0.3s;
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => (props.muted ? Colors.SECONDARY : Colors.PRIMARY)};
    text-decoration: ${(props) => (props.muted ? 'none' : 'underline')};
    margin-top: ${(props) => (props.muted ? 20 : 3)}px;

    &:hover,
    &:active,
    &focus {
        opacity: 0.75;
    }
`;

export const ColumnCheckbox = styled.input`
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    height: 100%;
    margin: 0;
`;

export const CheckBoxWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const ColumnsDropdown = styled.div`
    padding: 10px;
`;

export const DropdownWrapper = styled.div`
    margin-left: auto;
    margin-right: 10px;

    > div > div:last-of-type:not(:first-child) {
        min-width: 255px;
    }
`;

export const SortIndicator = styled.span`
    margin-left: 10px;
    margin-bottom: -3px;
    font-size: 18px;
    color: ${Colors.WHITE};
`;

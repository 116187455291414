import styled from 'styled-components';
import { Colors } from 'styles/constant';

export const Section = styled.div`
    padding: 15px;
    background-color: ${Colors.LIGHT_GREY};
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const Container = styled.div`
    background: white;
    width: 100%;
    padding: 20px 0 64px;
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: ${Colors.BLACK};
    margin-bottom: 20px;
`;

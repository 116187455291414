import { useDispatch, useSelector } from 'react-redux';
import { SimilarArticlesActions } from 'features/articles/actions';
import { useEffect } from 'react';

function useSimilarArticles(props, status) {
    const { id, parentId } = props;
    const dispatch = useDispatch();
    const { similarArticles, isLoading, isLoaded, count } = useSelector((state) => state.similarArticles);

    useEffect(() => {
        if (id && parentId) {
            dispatch(SimilarArticlesActions.getSimilarArticles(id, parentId, status));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, parentId, dispatch]);

    return {
        similarArticles,
        isSimilarLoading: isLoading,
        isLoaded,
        count,
    };
}

export default useSimilarArticles;

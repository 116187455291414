import { ParametersService } from 'core/services';

export const SET_PARAMETERS = (parameters) => {
    const params = { parameters: [], comment: '' };
    parameters.map((parameter) => {
        let { parameter_id, value_id, value } = parameter;

        if (!parameter_id && parameter.parameterId) {
            parameter_id = parameter.parameterId;
        }

        if (!value_id && parameter.id) {
            value_id = parameter.id;
        }

        if (value) {
            params.comment = value;
        } else {
            const parameterMain = ParametersService.getParameterById(parseFloat(parameter_id));
            const parameterValue = ParametersService.getParameterValueById(
                parseFloat(parameter_id),
                parseFloat(value_id),
            );
            const obj = {};

            if (parameterValue) {
                obj.id = parameterValue.id;
                obj.parameterId = parameterMain.id;
                obj.type = parameterMain.type;
            } else if (parameterMain) {
                obj.id = parameterMain.id;
                obj.parameterId = parameterMain.id;
                obj.type = parameterMain.type;
            }
            Object.keys(obj).length > 0 && params.parameters.push(obj);
        }
    });
    return params;
};

export const CHECK_PARAMETERS = (parameters) => {
    const array = [];
    parameters.map((parameter) => {
        const { parameterId, id } = parameter;
        const parameterMain = ParametersService.getParameterById(parseFloat(parameterId));
        const parameterValue = ParametersService.getParameterValueById(parseFloat(parameterId), parseFloat(id));
        if (parameterValue) {
            array.push(parameter);
        } else if (parameterMain) {
            delete parameter[id];
            array.push(parameter);
        }
        return parameter;
    });
    return array;
};

import PropTypes from 'prop-types';
import { Mapper, SharedTypes, TextUtils } from 'utils';
import { connect } from 'react-redux';
import {
    CloseIcon,
    CloseIconContainer,
    Container,
    Content,
    Footer,
    FooterSection,
    Header,
    Section,
    Title,
    Wrapper,
    SelectCol,
    DropdownToggle,
    MenuItem,
    ButtonWrapper,
    ExportButton,
    SliderRow,
} from './styles';
import { Buttons, Images } from 'styles/constant';
import { Grid } from 'styles/components';
import {
    Button,
    CustomParametersSelect,
    DatePicker,
    ExpandedMultiSelect,
    GridContainer,
    Input,
    MultiSelect,
    Slider,
    ExpandedSelect,
    Dropdown,
} from 'components';
import { LABELING_STATUSES } from 'constant';
import React, { useEffect, useState } from 'react';
import { useFilter, usePermissions } from 'hooks';
import { ParametersService } from 'core/services';
import AddSavedSearchModal from '../AddSavedSearchModal';
import SavedSearchesModal from '../SavedSearchesModal';
import { ArticlesActions, ArticlesCountActions } from 'features/articles/actions';
import { ChartsActions } from 'core/actions';
import usePaging from 'features/articles/hooks/usePaging';

const isInputNumberValid = (value) => {
    const valueNum = +value;
    const isNotNumber = isNaN(valueNum);

    return !isNotNumber;
};

function Filter(props) {
    const {
        handleDateChange,
        languages,
        users,
        categories,
        domains,
        filters,
        parameters,
        visible,
        onClose,
        onShowHelpModal,
        isCharts,
        isLabeling,
        chart,
        getChart,
        getArticles,
        getReservedArticles,
        getLabeledArticlesCount,
        getReservedArticlesCount,
        getSkippedArticlesCount,
        getWaitingArticlesCount,
        handleSearchExport,
        localFilterMain,
        setLocalFilterMain,
    } = props;
    const { filter, updateFilter } = useFilter();
    const [localFilter, setLocalFilter] = useState(filter);
    const [searchesVisible, setSearchesVisible] = useState(false);
    const [addSearchVisible, setAddSearchVisible] = useState(false);
    const [editingSearch, setEditingSearch] = useState(null);
    const parametersA1 = parameters ? parameters.filter((parameter) => parameter.type === 'A1') : [];
    const parametersA2 = parameters ? parameters.filter((parameter) => parameter.type === 'A2') : [];
    const parametersA3 = parameters ? parameters.filter((parameter) => parameter.type === 'A3') : [];
    const excludes = ['rows', 'value', 'columns', 'offset'];
    const [searchClicked, setSearchClicked] = useState(false);
    const { paging } = usePaging();
    const sort = { sort: filter.sort, order: filter.order };
    const [
        hasExportPermission,
        hasExportContentPermission,
        hasOverrideDomainsPermission,
        hasOverrideFiltersPermission,
    ] = usePermissions(['ROLE_EXPORT', 'ROLE_EXPORT_CONTENT', 'ROLE_OVERRIDE_DOMAINS', 'ROLE_OVERRIDE_FILTERS']);

    useEffect(() => {
        setLocalFilter(filter);
    }, [filter]);

    useEffect(() => {
        const newFilter = { ...localFilter };
        newFilter.date && delete newFilter.date;
        newFilter.from && delete newFilter.from;
        newFilter.to && delete newFilter.to;
        setLocalFilter({ ...localFilterMain, ...newFilter });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFilterMain]);

    useEffect(() => {
        if (searchClicked) {
            isCharts && chart.graph && getChart(chart.graph.id, filter);
            !isCharts && !isLabeling && getArticles({ filter, paging });
            if (isLabeling) {
                !filter.labeling_status ? getReservedArticles({ sort, paging }) : getArticles({ filter, paging });
                getReservedArticlesCount({ filter });
                getWaitingArticlesCount({ filter });
                getLabeledArticlesCount({ filter });
                getSkippedArticlesCount({ filter });
            }
            setSearchClicked(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchClicked]);

    const handleChange = (event, mainFilters = false) => {
        const { value, name } = event;
        mainFilters
            ? setLocalFilterMain({ ...localFilterMain, [name]: value })
            : setLocalFilter({ ...localFilter, [name]: value });
    };

    const getA2ParametersKeys = () => {
        return Object.keys(localFilter).filter(
            (key) => key.startsWith('parameters') && ParametersService.isA2Parameter(TextUtils.getParameterId(key)),
        );
    };

    const handleParameters2Change = (value) => {
        const parametersA2Values = {};

        for (let parameter of value) {
            const id = Object.keys(parameter)[0];
            parametersA2Values[`parameters[${id}]`] = parameter[id];
        }

        const newLocalFilter = localFilter;
        for (let key of Object.keys(newLocalFilter)) {
            if (ParametersService.isA2ParameterKey(key)) {
                delete newLocalFilter[key];
            }
        }

        setLocalFilter({ ...newLocalFilter, ...parametersA2Values });
    };

    const getParametersA2Value = () => {
        const value = [];
        for (let key of getA2ParametersKeys()) {
            const keyValue = localFilter[key].filter((value) => value !== '');
            value.push({ [TextUtils.getParameterId(key)]: keyValue });
        }
        return value;
    };

    const reset = () => {
        const date = 'today';
        setLocalFilterMain({ date });
        setLocalFilter({});
    };

    const search = () => {
        const newFilter = localFilter;

        for (let param of excludes) {
            if (newFilter[param]) delete newFilter[param];
        }

        const nonEmptyFilter = Object.entries(newFilter).reduce((a, [k, v]) => (v === '' ? a : ((a[k] = v), a)), {});

        if (JSON.stringify(localFilter) === JSON.stringify(filter)) setSearchClicked(true);
        updateFilter(nonEmptyFilter);
        onClose();
    };

    const onOpenSearch = (search) => {
        for (let param of excludes) {
            if (search[param]) delete search[param];
        }

        updateFilter(search);
        onClose();
    };

    const handleEdit = (search) => {
        setEditingSearch(search);
        setLocalFilter(search.filter);
    };

    const handleCancelEdit = () => {
        reset();
        setEditingSearch(null);
    };

    const handleEditAction = () => {
        setAddSearchVisible(true);
    };

    const handleSuccessEdit = () => {
        setEditingSearch(null);
        setAddSearchVisible(false);
    };

    const renderButton = () => {
        return <DropdownToggle />;
    };

    const handleExportDropdownChange = (option) => {
        switch (option.value) {
            case 'exportWithContent': {
                handleSearchExport(true);
                return;
            }
            default:
                return;
        }
    };

    const exportOptions = [
        { name: 'Export with content', value: 'exportWithContent', disabled: !hasExportContentPermission },
    ];

    const renderDropdown = () => {
        return exportOptions.map((option, i) => {
            return (
                <MenuItem
                    className="dropdown-item"
                    key={i}
                    onClick={() => (option.disabled ? {} : handleExportDropdownChange(option))}
                    disabled={option.disabled}
                >
                    {option.name}
                </MenuItem>
            );
        });
    };

    const renderActions = () => (
        <FooterSection>
            <SelectCol mr={15}>
                <ButtonWrapper>
                    <ExportButton
                        type={Buttons.TERTIARY}
                        label="Export search"
                        onClick={() => handleSearchExport(false)}
                        disabled={!hasExportPermission}
                    />
                    <Dropdown
                        renderButton={renderButton}
                        renderDropdown={renderDropdown}
                        onClose={() => {}}
                        openOnClick
                        top
                        onChange={handleExportDropdownChange}
                    />
                </ButtonWrapper>
            </SelectCol>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} label="Clear all" onClick={reset} />
            </Grid.Col>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} onClick={() => setAddSearchVisible(true)} label="Save search" />
            </Grid.Col>
            <Grid.Col>
                <Button label="Search" onClick={search} />
            </Grid.Col>
        </FooterSection>
    );

    const renderEditActions = () => (
        <FooterSection>
            <Grid.Col mr={15}>
                <Button type={Buttons.TERTIARY} label="Cancel" onClick={handleCancelEdit} />
            </Grid.Col>
            <Grid.Col>
                <Button label="Update search" onClick={handleEditAction} />
            </Grid.Col>
        </FooterSection>
    );

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    return (
        <Container visible={visible}>
            <Wrapper>
                <Header>
                    <Title>Advanced search {editingSearch && ` (${editingSearch.name})`}</Title>
                    <CloseIconContainer onClick={onClose}>
                        <CloseIcon src={Images.CLOSE_WHITE_2} />
                    </CloseIconContainer>
                </Header>
                <Content>
                    <Section>
                        <Grid.Row mb={15}>
                            <Input
                                onKeyDown={handleKeyDown}
                                value={localFilterMain['filters[must]']}
                                onChange={(e) => handleChange(e, true)}
                                leftIcon={Images.SEARCH}
                                name="filters[must]"
                                help="Help"
                                onHelpClick={onShowHelpModal}
                                placeholder="Search ALL of these words"
                            />
                        </Grid.Row>
                        <GridContainer>
                            <DatePicker
                                label="Date"
                                value={{
                                    dateFrom: localFilterMain.from,
                                    dateTo: localFilterMain.to,
                                    date: localFilterMain.date,
                                }}
                                onChange={handleDateChange}
                                name="date"
                                placeholder="Select date range"
                            />
                            <MultiSelect
                                label="Languages"
                                options={languages}
                                value={localFilterMain.lang}
                                onChange={(e) => handleChange(e, true)}
                                name="lang"
                                placeholder="Search by language"
                            />
                            <MultiSelect
                                label="Categories"
                                options={categories}
                                value={localFilter.categories_ids}
                                onChange={handleChange}
                                name="categories_ids"
                                placeholder="Search by name"
                            />
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Domains"
                                        options={domains}
                                        value={localFilter.domain_ids}
                                        onChange={handleChange}
                                        name="domain_ids"
                                        placeholder="Search by name"
                                    />
                                </Grid.Row>
                                <SliderRow disabled={!hasOverrideDomainsPermission}>
                                    <Slider
                                        label="Ignore my limitations by domains"
                                        onChange={handleChange}
                                        value={localFilter.override_user_domains}
                                        name="override_user_domains"
                                        disabled={!hasOverrideDomainsPermission}
                                    />
                                </SliderRow>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <MultiSelect
                                        label="Filters"
                                        options={filters}
                                        value={localFilter.filters_ids}
                                        onChange={handleChange}
                                        name="filters_ids"
                                        placeholder="Search by name"
                                    />
                                </Grid.Row>
                                <SliderRow disabled={!hasOverrideFiltersPermission}>
                                    <Slider
                                        label="Ignore my limitations by filters"
                                        onChange={handleChange}
                                        value={localFilter.override_user_filters}
                                        name="override_user_filters"
                                        disabled={!hasOverrideFiltersPermission}
                                    />
                                </SliderRow>
                            </Grid.Col>
                            <Grid.Col justifyContent="flex-start">
                                <Grid.Row mb={10}>
                                    <Input
                                        value={localFilter.url}
                                        onChange={handleChange}
                                        label="URL address"
                                        name="url"
                                    />
                                </Grid.Row>
                                <Grid.Row>
                                    <Slider
                                        label="Exact URL match"
                                        onChange={handleChange}
                                        value={localFilter.exact_url}
                                        name="exact_url"
                                    />
                                </Grid.Row>
                            </Grid.Col>
                        </GridContainer>
                    </Section>
                    <Section>
                        <GridContainer>
                            <ExpandedSelect
                                label="Labelling status"
                                options={LABELING_STATUSES}
                                value={localFilter.labeling_status}
                                onChange={handleChange}
                                name="labeling_status"
                            />
                            <MultiSelect
                                label="Labelled by"
                                options={users}
                                value={localFilter.labeled_by}
                                onChange={handleChange}
                                name="labeled_by"
                                placeholder="Search by name"
                            />
                            {parametersA2.length > 0 && (
                                <CustomParametersSelect
                                    onChange={handleParameters2Change}
                                    value={getParametersA2Value()}
                                    parameters={parametersA2}
                                />
                            )}
                        </GridContainer>
                    </Section>

                    <Section>
                        <GridContainer>
                            <Input
                                value={localFilter.infometer_total}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer total, minimum"
                                name="infometer_total"
                                placeholder="eg. 30.7"
                            />
                            <div />
                            <div />
                            <Input
                                value={localFilter.infometer_relevance}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer relevance, minimum"
                                name="infometer_relevance"
                                placeholder="eg. 30.7"
                            />
                            <Input
                                value={localFilter.infometer_social}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer social, minimum"
                                name="infometer_social"
                                placeholder="eg. 30.7"
                            />
                            <Input
                                value={localFilter.infometer_reach}
                                onChange={(e) => isInputNumberValid(e.value) && handleChange(e)}
                                label="Infometer reach, minimum"
                                name="infometer_reach"
                                placeholder="eg. 30.7"
                            />
                        </GridContainer>
                    </Section>

                    {parametersA3.length > 0 && (
                        <Section>
                            <GridContainer>
                                {parametersA3.map((parameter) => (
                                    <MultiSelect
                                        key={parameter.id}
                                        label={parameter.name}
                                        value={localFilter[`parameters[${parameter.id}]`]}
                                        options={Mapper.mapParameterValuesToOptions(parameter.values)}
                                        onChange={handleChange}
                                        name={`parameters[${parameter.id}]`}
                                        placeholder="Search by name"
                                    />
                                ))}
                            </GridContainer>
                        </Section>
                    )}
                    <Section>
                        <GridContainer>
                            {parametersA1.map((parameter) => (
                                <ExpandedMultiSelect
                                    key={parameter.id}
                                    label={parameter.name}
                                    value={localFilter[`parameters[${parameter.id}]`]}
                                    options={Mapper.mapParameterValuesToOptions(parameter.values)}
                                    onChange={handleChange}
                                    name={`parameters[${parameter.id}]`}
                                />
                            ))}
                        </GridContainer>
                    </Section>
                </Content>
                <Footer>
                    <FooterSection>
                        <Grid.Col>
                            <Button
                                outline
                                leftIcon={Images.SAVE}
                                onClick={() => setSearchesVisible(true)}
                                type={Buttons.TERTIARY}
                                label="Saved searches"
                            />
                        </Grid.Col>
                    </FooterSection>
                    {editingSearch ? renderEditActions() : renderActions()}
                </Footer>
            </Wrapper>
            <Grid.Col flex={1} style={{ height: '100%' }} onClick={onClose} />
            <AddSavedSearchModal
                search={localFilter}
                editingSearch={editingSearch}
                visible={addSearchVisible}
                onClose={handleSuccessEdit}
            />
            <SavedSearchesModal
                onEdit={handleEdit}
                visible={searchesVisible}
                onSearch={onOpenSearch}
                onClose={() => setSearchesVisible(false)}
            />
        </Container>
    );
}

Filter.propTypes = {
    visible: PropTypes.bool.isRequired,
    onShowHelpModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    languages: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    users: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    categories: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    domains: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    filters: PropTypes.arrayOf(SharedTypes.OptionType).isRequired,
    parameters: PropTypes.arrayOf(SharedTypes.ParameterType).isRequired,
    isLabeling: PropTypes.bool,
    isCharts: PropTypes.bool,
    chart: PropTypes.object,
    getChart: PropTypes.func,
    getArticles: PropTypes.func,
    getReservedArticles: PropTypes.func,
    getReservedArticlesCount: PropTypes.func,
    getWaitingArticlesCount: PropTypes.func,
    getLabeledArticlesCount: PropTypes.func,
    getSkippedArticlesCount: PropTypes.func,
    handleSearchExport: PropTypes.func,
    setLocalFilterMain: PropTypes.func,
    handleDateChange: PropTypes.func,
    localFilterMain: PropTypes.object,
};

function mapStateToProps(state) {
    const { languages, domains, categories, filters, users, parameters, charts } = state;
    return {
        languages: Mapper.mapLanguagesToOptions(languages.languages),
        users: Mapper.mapUsersToOptions(users.users),
        domains: Mapper.mapDomainsToOptions(domains.domains),
        categories: Mapper.mapCategoriesToOptions(categories.categories),
        filters: Mapper.mapFiltersToOptions(filters.filters),
        parameters: parameters.parameters,
        chart: charts.chart,
    };
}

const mapDispatchToProps = {
    getChart: ChartsActions.getChart,
    getArticles: ArticlesActions.getArticles,
    getReservedArticles: ArticlesActions.getReservedArticles,
    getReservedArticlesCount: ArticlesCountActions.getReservedArticlesCount,
    getWaitingArticlesCount: ArticlesCountActions.getWaitingArticlesCount,
    getLabeledArticlesCount: ArticlesCountActions.getLabeledArticlesCount,
    getSkippedArticlesCount: ArticlesCountActions.getSkippedArticlesCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);

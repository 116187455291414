import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import ReactHtmlParser from 'react-html-parser';
import { ArticleUtils } from 'utils';
import { RTLLanguages } from 'constant';

const Content = (props) => {
    const { className, content, fontSize, id, lang } = props;
    const tooltips = useRef();

    const removeTooltips = () => {
        const selectedTooltips = document.querySelectorAll('.filter-highlight-tooltip');
        selectedTooltips.forEach((el) => el.remove());
        return selectedTooltips;
    };

    const displayTooltip = (targetElement, tooltipClass) => {
        const content = document.getElementById('article-content');

        const { top, left } = targetElement.getBoundingClientRect();
        const elementId = targetElement.getAttribute('id');

        tooltips.current.forEach((el) => {
            if (el.getAttribute('data-mdl-for') === elementId) {
                const tooltip = el;
                tooltip.style.left = left + 'px';
                tooltip.style.top = top + 'px';
                tooltip.classList.add(tooltipClass);
                tooltip.setAttribute('translate', 'no');

                content.appendChild(tooltip);
            }
        });
    };

    useEffect(() => {
        const selectedTooltips = removeTooltips();
        tooltips.current = selectedTooltips;

        const handler = (event) => {
            const closestHighLight = event.target.closest('.filter-highlight');

            if (event.target.classList && event.target.classList.contains('filter-highlight')) {
                const { target } = event;
                displayTooltip(target, 'showTooltip');
            } else if (closestHighLight && closestHighLight.contains(event.target)) {
                displayTooltip(closestHighLight, 'displayTooltipOverTranslate');
            } else {
                const tooltips = document.querySelectorAll('.showTooltip');
                tooltips.forEach((el) => el.classList.remove('showTooltip'));
                removeTooltips();
            }
        };

        window.addEventListener('mousemove', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
        };
    }, []);

    useEffect(() => {
        document.getElementById('article-content').setAttribute('translate', 'yes');
        ArticleUtils.addImageButtons(id);
        ArticleUtils.updateLinks();
        ArticleUtils.updateTweets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const transform = (node) => {
        if (node.type === 'tag' && node.name === 'img') {
            return <img key={node.attribs.src} alt={node.attribs.alt} src={node.attribs.src} />;
        }
    };

    return (
        <Styles.Container className={className}>
            <ul id="tweetlist"></ul>
            <Styles.Content
                id="article-content"
                lang={lang}
                fontSize={fontSize}
                dir={RTLLanguages.includes(lang) ? 'rtl' : 'ltr'}
            >
                {ReactHtmlParser(content, { transform })}
            </Styles.Content>
        </Styles.Container>
    );
};

Content.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string,
    fontSize: PropTypes.number,
    id: PropTypes.string,
    lang: PropTypes.string,
};

Content.defaultProps = {
    className: null,
    content: null,
    id: '',
    fontSize: 16,
    lang: 'en',
};

export default Content;
